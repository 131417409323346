import { useEffect, useState } from "react";
import df from "../../utils/dateFormater";
import request from "../request";

const useTableQ = (initialData, columns, totalPages, items = 25) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(items);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  // Function to check if column is a date
  const isDateColumn = (columnName) => {
    return columnName.toLowerCase().includes("date");
  };

  const handleSortByColumn = (columnName) => {
    if (orderBy === columnName) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnName);
      setOrder("asc");
    }
  };

  const sortedData = initialData.slice().sort((a, b) => {
    if (!orderBy) return 0;

    // Usamos getPropertyByPath para acceder a propiedades anidadas y manejar valores nulos o indefinidos
    const valueA =
      getPropertyByPath(a, orderBy)?.toString().toUpperCase() || "";
    const valueB =
      getPropertyByPath(b, orderBy)?.toString().toUpperCase() || "";

    // Función para dividir cadenas en partes alfabéticas y numéricas
    const splitAlphaNumeric = (str) =>
      str
        .match(/(\d+|\D+)/g)
        ?.map((part) => (isNaN(part) ? part : parseFloat(part))) || [];

    const partsA = splitAlphaNumeric(valueA);
    const partsB = splitAlphaNumeric(valueB);

    // Comparar cada parte de la cadena
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i];
      const partB = partsB[i];

      // Si una de las partes no existe, el valor más corto va primero
      if (partA === undefined) return order === "asc" ? -1 : 1;
      if (partB === undefined) return order === "asc" ? 1 : -1;

      // Comparación numérica si ambas partes son números
      if (typeof partA === "number" && typeof partB === "number") {
        if (partA < partB) return order === "asc" ? -1 : 1;
        if (partA > partB) return order === "asc" ? 1 : -1;
      }

      // Comparación alfabética si ambas partes son texto
      if (typeof partA === "string" && typeof partB === "string") {
        if (partA < partB) return order === "asc" ? -1 : 1;
        if (partA > partB) return order === "asc" ? 1 : -1;
      }

      // Si una parte es número y la otra texto, el texto va primero
      if (typeof partA !== typeof partB) {
        return typeof partA === "string"
          ? order === "asc"
            ? -1
            : 1
          : order === "asc"
          ? 1
          : -1;
      }
    }

    // Si todas las partes son iguales
    return 0;
  });

  function getPropertyByPath(obj, path) {
    const keys = path.split(".");
    let value = obj;

    for (const key of keys) {
      if (value && typeof value === "object" && key in value) {
        value = value[key];
      } else {
        return undefined; // La propiedad no existe o es nula/undefined
      }
    }

    return value;
  }

  const filteredData = sortedData.filter((item) => {
    for (const key of columns) {
      // Itera sobre las keys especificadas en columns
      const propertyValue = getPropertyByPath(item, key);
      if (propertyValue !== undefined) {
        if (isDateColumn(key)) {
          // Check if the column is a date column
          const formattedDate = df(propertyValue, "dateTime");
          if (
            String(formattedDate)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            return true;
          }
        } else {
          // For non-date columns, perform a normal string comparison
          if (
            String(propertyValue)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            return true;
          }
        }
      }
    }
    return false;
  });

  // Pagination
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData;

  // Total pages
  // const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Change page
  const handleChangePage = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) setCurrentPage(pageNumber);
  };

  // Change items per page
  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
    setOrderBy(null); // Reset sorting
  };

  useEffect(() => {
    if (totalPages === 0) setCurrentPage(1);
    else if (currentPage > totalPages) setCurrentPage(totalPages);
  }, [totalPages]);

  return {
    searchTerm,
    currentPage,
    currentItems,
    totalPages,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
    setSearchTerm,
  };
};

export default useTableQ;
