import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
    root: {
        backgroundColor: "#F2F2F2",
        height:"100vh",
        color: theme.palette.terragene.lightMain,
        fontFamily:"Montserrat",
        display: 'grid',
        gridTemplateRows: 'repeat(6, 1fr)',
    },
    textContainer: {
        fontWeight: 'bold',
        gridArea:2,
        textAlign: 'center',
    },
    logoTerrageneContainer: {
        margin: 5,
        textAlign: 'center',
        gridArea: 1 ,
    },
    cardContainer: {
        gridArea: 3,
        textAlign: 'center',
        marginLeft:"15%"
    },
    logoBionovaContainer: {
        gridArea: 5,
        textAlign: 'center',
    },
    logoBionova: {
        width: 170,
    },

    logoTerragene: {
        width: 170,
      
    }
}))
