import React, { useState } from 'react'
import LoginInput from '../../LoginInput';
import ShowPassword from './ShowPassword';
import InputQ from "../../FormComponents/InputQ/InputQ.jsx";

   const ViewPasswordButton = (props) => {
    const {locale,setPassword,password,setError,error,loading,style,validate,validationMessage} = props;
    const [viewPassword, setViewPassword] = useState(false)
   function changePasswordView(){
   viewPassword ? setViewPassword(false) : setViewPassword(true);
  }

   //Función para manejar cambios en la contraseña
   const handleChangePassword = (value) => {
    setPassword(value.target.value); 
    setError({ type: "", message: "" });
  };

  return (
    <>
      <InputQ
        placeholder={locale.form.input.password}
        label={locale.form.input.password}
        labelTop={true}
        containerWidth={"100%"}
        containerMargin={"10px"}
        disabled={loading}
        handleChange={handleChangePassword}
        value={password}
        required={true}
        type={"password"}
        // validate={validate}
        errorMessage={error.type === "password" ? error.message : ""}
        validationMessage={validationMessage}
      />
      {/* {validationMessage && (
        <p style={{ color: "red", fontSize: "0.8em", textIndent: "-270px"}}>{validationMessage}</p>
        )} */}

     
    </>
  );
}
export default ViewPasswordButton;

