import React, { useEffect, useState } from "react";

import WashCycleCard from "../../../../components/WashCycleCard/WashCycleCard";
import CycleColumn from "../../../../components/CycleColumn/CycleColumn";
import InfoBar from "../../../../components/MenuQ/infoBar/InfoBar";
import SelectQ from "../../../../components/FormComponents/SelectQ/SelectQ";
import FilterDate from "../../../../components/FilterDate/FilterDate";
import ModalLoading from "../../../../components/ModalLoading/ModalLoading";

import { getSectorId, getUserCompany } from "../../../../utils/sessionHandler";
import t from "../../../../utils/translation";
import request from "../../../../utils/request";

import toStartImg from "../../../../assets/images/Washing/1. To startPurple.svg";
import startedImg from "../../../../assets/images/Washing/2. In progressPurple.svg";
import finishedImg from "../../../../assets/images/Washing/3. FinishedPurple.svg";
import toStartIcon from "../../../../assets/images/Washing/Icono To startPurple.svg";
import inProgressIcon from "../../../../assets/images/Washing/Icono In progressPurple.svg";
import finishedIcon from "../../../../assets/images/Washing/Icono FinishedPurple.svg";

import Swal from "sweetalert2";

import styles from "./WashCycleBoard.module.css";
import { toast } from "react-toastify";

const WashCycleBoard = ({
  locale,
  handleLoad,
  getLoads,
  toStartLoads,
  startedLoads,
  finishedLoads,
  handleChangeDate,
  dateTo,
  dateFrom,
  modalLoading,
  handleFormData,
  handleModal,
  handleLoadId,
  handleStartDate,
  handleDrop,
  handleLoadingFinishModal,
  handleFilterWasher,
}) => {
  const [washers, setWashers] = useState([]);
  const [selectedWasher, setSelectedWasher] = useState("");

  const getSterilizer = async () => {
    try {
      const response = await request().get(
        `/api/washer/getBySector?sectorId=${getSectorId()}&companyId=${
          getUserCompany().Id
        }`
      );

      const washers = response.data.map((item) => ({
        value: item.id,
        name: item.name,
        modelId: item.companyMachineModelId,
      }));
      setWashers(washers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  const handleChangeWasher = (event) => {
    setSelectedWasher(parseInt(event.target.value));
    handleFilterWasher(event.target.value);
  };

  const handleLoadDetail = (chargeWashingId) => {
    if (chargeWashingId) {
      handleLoad(chargeWashingId);
      // getLoadIndicators(chargeWashingId);
    } else {
      handleLoad(null);
    }
  };

  const onFinishedDrop = async (e, status, item) => {
    let itemID;
    let startDate;
    let chargeStatus;
    if (item) {
      itemID = item.chargeWashingId;
      startDate = item.startDate;
      chargeStatus = item.chargeStatus;
    } else {
      itemID = e.dataTransfer.getData("itemID");
      startDate = e.dataTransfer.getData("startDate");
      chargeStatus = e.dataTransfer.getData("chargeStatus");
    }

    if (status == chargeStatus) {
      return;
    }
    if (status < chargeStatus) {
      toast.error("Error");
      return;
    } else {
      if (parseInt(chargeStatus) + 1 < 4) {
        toast.error(
          "A cycle that has yet to start cannot be marked as Finished."
        );
      } else {
        handleLoadingFinishModal(true);
        handleModal(true);
        await request()
          .get(`/api/chargeWashing/chargeWashingDto?chargeWashingId=${itemID}`)
          .then((response) => {
            handleFormData({
              ...response.data,
              cicleFinishDate: new Date(),
              chargeStatus: 4,
              chargeWashingId: itemID,
            });
          });
        handleLoadingFinishModal(false);
        handleLoadId(itemID);
        handleStartDate(startDate);
      }
    }
  };

  const onInProgressDrop = async (e, status, item) => {
    let itemID;
    let startDate;
    let chargeStatus;
    if (item) {
      itemID = item.chargeWashingId;
      startDate = item.startDate;
      chargeStatus = item.chargeStatus;
    } else {
      itemID = e.dataTransfer.getData("itemID");
      startDate = e.dataTransfer.getData("startDate");
      chargeStatus = e.dataTransfer.getData("chargeStatus");
    }

    if (status == chargeStatus) {
      return;
    }
    if (status < chargeStatus) {
      toast.error("Error");
      return;
    }

    handleLoadingFinishModal(true);
    handleModal(true);
    await request()
      .get(`/api/chargeWashing/chargeWashingDto?chargeWashingId=${itemID}`)
      .then((response) => {
        handleFormData({
          ...response.data,
          chargeStatus: 3,
          chargeWashingId: itemID,
          timeUnit: t("Minutes") || "Minutes",
          cicleStartDate: new Date(),
        });
      });
    handleLoadingFinishModal(false);
    handleLoadId(itemID);
    handleStartDate(startDate);
  };
  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.chargeWashingId);
    e.dataTransfer.setData("chargeStatus", item.chargeStatus);
    e.dataTransfer.setData("startDate", item.cicleStartDate);
  };

  const onDrop = (e, status) => {
    const itemID = e.dataTransfer.getData("itemID");
    const chargeStatus = parseInt(e.dataTransfer.getData("chargeStatus"));
    if (status == chargeStatus) {
      return;
    }

    if (status < chargeStatus) {
      toast.error("Error");
      return;
    }
    if (status !== chargeStatus) {
      if (chargeStatus - 1 === status) {
        handleDrop(itemID, status, e);
      } else {
        if (chargeStatus > status) {
          handleLoadId(itemID);

          Swal.fire({
            title: "Are you sure?",
            text: "You are going back one cycle",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, continue",
            cancelButtonText: "Cancel",
            customClass: {
              confirmButton: styles.customConfirmButton,
              cancelButton: styles.customCancelButton,
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              handleDrop(itemID, status, e);
            } else if (result.isDismissed) {
            }
          });
        } else if (status !== chargeStatus + 2) {
          handleDrop(itemID, status, e);
        }
      }
    }
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getSterilizer();
  }, []);

  return (
    <>
      <ModalLoading category={"washing"} open={modalLoading} />
      <div className={styles.container}>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <SelectQ
              value={selectedWasher}
              handleSelect={handleChangeWasher}
              width={"100%"}
              containerWidth={"100%"}
              options={washers}
              placeholder={t("SelectWasherSpan") || "Select a Washer"}
              hasPlaceholder={true}
            />
          </div>
          <div className={styles.filter}>
            <FilterDate
              handleChange={handleChangeDate}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
        </div>
        <div className={styles.board}>
          <CycleColumn
            scroll={true}
            title={t("ToStartSpan") || "To Start"}
            borderColor={"#5099B5"}
            onDrop={onDrop}
            dragOver={draggingOver}
            status={2}
            info={
              <InfoBar
                title={t("ToStartSpan")}
                color={"purple"}
                data={toStartLoads}
              />
            }
            img={toStartImg}
          >
            {toStartLoads?.map((charge) => (
              <WashCycleCard
                handleLoadDetail={handleLoadDetail}
                key={charge.chargeWashingId}
                charge={charge}
                borderColor={"#5099B5"}
                startDrag={startDrag}
                img={toStartIcon}
                handleDoubleClick={onInProgressDrop}
              />
            ))}
          </CycleColumn>
          <CycleColumn
            scroll={true}
            title={t("StartedSpan") || "Started"}
            borderColor={"#ffc107"}
            onDrop={(e) => onInProgressDrop(e, 3)}
            dragOver={draggingOver}
            status={3}
            info={
              <InfoBar
                title={t("StartedSpan")}
                color={"yellow"}
                data={startedLoads}
              />
            }
            img={startedImg}
          >
            {startedLoads?.map((charge) => (
              <WashCycleCard
                handleLoadDetail={handleLoadDetail}
                key={charge.chargeWashingId}
                charge={charge}
                borderColor={"#ffc107"}
                startDrag={startDrag}
                img={inProgressIcon}
                handleDoubleClick={onFinishedDrop}
              />
            ))}
          </CycleColumn>

          <CycleColumn
            scroll={true}
            title={t("FinishedSpan") || "Finished"}
            borderColor={"#20c997"}
            onDrop={(e) => onFinishedDrop(e, 4)}
            dragOver={draggingOver}
            status={4}
            info={
              <InfoBar
                title={t("FinishedSpan")}
                color={"purpleInverted"}
                data={finishedLoads}
              />
            }
            img={finishedImg}
          >
            {finishedLoads?.map((charge) => (
              <WashCycleCard
                handleLoadDetail={handleLoadDetail}
                key={charge.chargeWashingId}
                charge={charge}
                borderColor={"#20c997"}
                startDrag={startDrag}
                img={finishedIcon}
              />
            ))}
          </CycleColumn>
        </div>
      </div>
    </>
  );
};

export default WashCycleBoard;
