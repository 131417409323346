// WashingStepper.jsx

import React from "react";
import Redirect from "../../../utils/Redirect";
import styles from "./WashingStepperStyles.module.css";
import t from "../../../utils/translation";

export default function WashingStepper({ currentStepString }) {
  const steps = ["WasherLoadings", "WashCycles"];
  const stepUrls = {
    WasherLoadings: "/appmanagewasherloadings",
    WashCycles: "/appmanagewashcycle",
  };

  const currentIndex = steps.indexOf(currentStepString);

  const getSwitchValue = (caseValue) => {
    switch (caseValue) {
      case "WasherLoadings":
        return t("WasherLoadingSpan") || "Washer Loadings";
      case "WashCycles":
        return t("WashCycleSpan") || "Wash Cycles";
      default:
        return null;
    }
  };

  return (
    <nav className={styles.nav}>
      {steps.map((stepName, index) => {
        let stepClass = styles.link;

        if (index < currentIndex) {
          stepClass += ` ${styles.done}`;
        } else if (index === currentIndex) {
          stepClass += ` ${styles.current}`;
        }

        const handleClick = (e) => {
          e.preventDefault();
          Redirect({ redirect: true, path: stepUrls[stepName] || "#" });
        };

        return (
          <span
            key={stepName}
            className={stepClass}
            onClick={handleClick}
            aria-current={index === currentIndex ? "step" : undefined}
          >
            {getSwitchValue(stepName)}
          </span>
        );
      })}
    </nav>
  );
}
