import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import request from "../../utils/request.js";
import t from "../../utils/translation.js";

import SearchDescription from "../../components/SearchDescription/SearchDescription.js";
import CrudIndicator from "../../components/CrudIndicator/CrudIndicator.jsx";

import BarcodeReader from "react-barcode-reader";

import { parseBarcode } from "../../utils/BarcodeParser.js";
import DragAndDropCharges from "../../components/DragAndDropCharges/DragAndDropCharges.js";
import LocalizationContext from "../../utils/LocalizationContext.js";
import { v4 as uuidv4 } from "uuid";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import { getSession, getUserCompany } from "../../utils/sessionHandler.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import PageContainer from "../../components/PageContainer/PageContainer.jsx";

import styles from "./CreateLoads.module.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TextAreaQ from "../../components/FormComponents/TextAreaQ/TextAreaQ.jsx";
import ModalLoading from "../../components/ModalLoading/ModalLoading.js";

const MySwal = withReactContent(Swal);

export default function CreateLoads() {
  const [indicators, setIndicators] = useState([]);
  const [usedProgram, setUsedProgram] = useState("");
  const [cycleNumber, setCycleNumber] = useState("");
  const [observations, setObservations] = useState(null);
  const [packages, setPackages] = useState([]);
  const [editPackages, setEditPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState("");
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFilteredPackages, setSelectedFilteredPackages] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [editId, setEditId] = useState(null);
  const [sterilizers, setSterilizers] = useState([]);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const [validate, setValidate] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [methodName, setMethodName] = useState("");
  const [modelAreas, setModelAreas] = useState([]);
  const [methodId, setMethodId] = useState(null);
  const [stopButtons, setStopButtons] = useState(false);
  const [idDistributorCompany, setIdDistributorCompany] = useState(null);
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [packageQuantity, setpackageQuantity] = useState(1000000);
  const [clearSearch, setClearSearch] = useState(null);

  const locale = useContext(LocalizationContext);
  const usedProgramRef = useRef();
  const cycleRef = useRef();
  const observationsRef = useRef();

  const validateFields = (status) => {
    let notValid = false;

    if (!usedProgram?.length > 0 || usedProgram?.trim() === "") {
      notValid = true;
    }

    if (!selectedSterilizer || selectedSterilizer === "") {
      notValid = true;
    }

    return notValid;
  };

  const getPackages = async (method, addPackages) => {
    setModalLoading(true);
    let filter = addPackages.filter((p) => p.methodId == method);
    try {
      if (!method) {
        setPackages([]);
      } else {
        const response = await request().get(
          `/api/Package/list?&pageSize=${packageQuantity}&Status=2&isInCharge=${false}&method=${method}`
        );
        setPackages([...filter, ...response.data.packageList]);
      }
    } catch (e) {
      localStorage.removeItem("packageEditId");
    }
    setModalLoading(false);
  };

  const getModelAreas = async (id) => {
    try {
      const response = await request().get(`/api/ModelAreas/${id}`);

      return setModelAreas(response.data);
    } catch (error) {
      console.error(`Error al obtener model areas para el id ${id}:`, error);
      return [];
    }
  };

  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/charge`);

      const sterilizers = response.data.sterilizers.map((item) => ({
        value: item.sterilizerId,
        name: item.name,
        methodName: item.sterilizerMethodName,
        methodId: item.sterilizerMethodId,
        companyMachineModelId: item.companyMachineModelId,
      }));

      setSterilizers(sterilizers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  const loadEditId = localStorage.getItem("loadEditId");

  const getEditCharges = () => {
    try {
      setModalEditLoading(true);

      request()
        .get(`/api/charge/chargeDto?chargeId=${loadEditId}`)
        .then((response) => {
          if (response.data.id) localStorage.removeItem("loadEditId");

          let indicatorsFormat = [
            ...response.data.indicators,
            ...response.data.pcdList,
          ];

          let newIndicators = [];
          indicatorsFormat.map((i) =>
            i.pcd
              ? (newIndicators = [
                  ...newIndicators,

                  {
                    uuid: uuidv4(),
                    modelAreasId: i.pcd.zoneId,
                    modelAreaName: i.pcd.zone,
                    pcd: {
                      ...i.pcd,

                      indicator: {
                        name: i.pcd.indicatorName,
                      },
                      uuid: uuidv4(),
                    },
                    biologicalIndicator: {
                      ...i.biologicalIndicator,

                      indicator: {
                        name: i.biologicalIndicator.indicatorName,
                      },
                      uuid: uuidv4(),
                    },
                    chemicalIndicator: {
                      ...i.chemicalIndicator,

                      indicator: {
                        name: i.chemicalIndicator.indicatorName,
                      },
                      uuid: uuidv4(),
                    },
                  },
                ])
              : (newIndicators = [
                  ...newIndicators,
                  {
                    modelAreaName: i.indicatorModelArea.description,
                    ...i,
                    indicator_Id: i.indicatorId,
                    uuid: uuidv4(),
                    expirationDate: i.indicatorExpirationDate,
                  },
                ])
          );

          let newPackages = [];
          response.data.packages.map(
            (p) =>
              (newPackages = [
                ...newPackages,
                { ...p.package, packageGeneralId: p.packageId },
              ])
          );

          setUsedProgram(response.data.usedProgram);
          setCycleNumber(response.data.cicleNumber);
          setSelectedSterilizer(response.data.sterilizerId);
          setSelectedPackages(newPackages);

          setIndicators(newIndicators);
          setEditId(response.data.id);
          getModelAreas(response.data.sterilizer.companyMachineModelId);
          setMethodName(response.data.sterilizer.methodName);
          setEditPackages(newPackages);
          getPackages(response.data.sterilizer.methodId, newPackages);
          setMethodId(response.data.sterilizer.methodId);
          setObservations(response.data.observations);
        });
    } catch (e) {
      localStorage.removeItem("loadEditId");
    } finally {
      setModalEditLoading(false);
    }
  };

  const handleSearchActive = (bool) => setSearchActive(bool);

  const handleIndicators = (data) => {
    setIndicators(data);
  };
  const handleChangeObservations = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setObservations(item);
  };

  const handleUsedProgram = (value, scan = false) => {
    if (!(value.target?.value?.length == 0 && !validate)) {
      setValidate(false);
    }
    const inputValue = scan ? value : value.target.value;
    setUsedProgram(inputValue);
  };
  const handleCycleNumber = (value, scan = false) => {
    const inputValue = scan ? value : value.target.value;

    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
      if (inputValue.startsWith("0")) {
        return;
      } else {
        setCycleNumber(inputValue);
      }
    }
  };

  const handleFilteredPackages = (data) => {
    setFilteredPackages(data);
  };
  const handleSelectedPackages = (data) => {
    setSelectedPackages(data);
  };
  const handleSelectedFilteredPackages = (data) => {
    setSelectedFilteredPackages(data);
  };

  const handleChangeSterilizer = (e) => {
    const selectedValue = e.target.value;
    const selectedSterilizer = sterilizers?.find(
      (s) => s.value == selectedValue
    );

    if (
      selectedSterilizer &&
      (selectedPackages.length > 0 || indicators.length > 0)
    ) {
      MySwal.fire({
        title: "Are you sure you want to continue?",
        text: "All entered packages and indicators will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedSterilizer(selectedValue);

          const method = {
            name: selectedSterilizer?.methodName,
            id: selectedSterilizer?.methodId,
          };
          getModelAreas(selectedSterilizer?.companyMachineModelId);

          setMethodName(selectedValue ? method.name : "");
          setMethodId(selectedValue ? method.id : "");
          getPackages(selectedValue ? method.id : [], editPackages);
          clearFields(false);
        }
      });
    } else {
      setSelectedSterilizer(selectedValue);

      const method = {
        name: selectedSterilizer?.methodName,
        id: selectedSterilizer?.methodId,
      };
      getModelAreas(selectedSterilizer?.companyMachineModelId);

      setMethodName(selectedValue ? method.name : "");
      setMethodId(selectedValue ? method.id : "");
      getPackages(selectedValue ? method.id : [], editPackages);
      clearFields(false);
    }
  };

  const handleClearSearch = (data) => {
    setClearSearch(data);
  };

  const onHandleScan = async (data) => {
    if (!selectedSterilizer) return;

    const companyPackage = data.includes(idDistributorCompany);

    if (companyPackage) {
      handleClearSearch(data);
      let item = packages.find(
        (p) => `${idDistributorCompany}${p.packageNumber}` == data
      );
      let itemExist = selectedPackages.find((p) => p == item);
      if (item && !itemExist) {
        if (methodId == item.methodId) {
          setScanCode(data);
          setSelectedPackages([...selectedPackages, item]);
          toast.success(t("PackageAdded", locale) || "Package added");
          return;
        } else {
          toast.error(
            t("WrongMethod", locale) ||
              "The selected package does not match the sterilizer method."
          );
        }
      } else if (item && itemExist) {
        setScanCode(data);
        setSelectedPackages(selectedPackages.filter((p) => p != item));
        toast.success(t("PackageRemoved", locale) || "Package Removed");
        return;
      } else {
        try {
          const res = await request().get(
            `/api/charge/packageId?packageNumber=${data.replace(
              idDistributorCompany,
              ""
            )}`
          );

          toast.error(
            `The scanned package ${data.replace(
              idDistributorCompany,
              ""
            )} is assigned to the load ${res.data.usedProgram}.`
          );
        } catch {
          toast.error("Package not found");
        }
      }
    } else {
      try {
        const variableField = parseBarcode(data).parsedCodeItems[3];
        const Gtin = parseBarcode(data).parsedCodeItems[0];

        let lot = "";

        const handleResponse = async (url) => {
          const response = await request().get(url);
          if (response.data.id) {
            setScanCode(data);
            let newIndicator = response.data;

            setIndicators([
              ...indicators,
              {
                ...newIndicator,
                uuid: uuidv4(),
                modelAreasId: modelAreas[0]?.id,
                modelAreaName: modelAreas[0]?.description,
              },
            ]);

            toast.success(t("IndicatorAdded", locale) || "Indicator Added");
            setUsedProgram(usedProgram.replace(data, ""));
          }
        };

        if (variableField.ai === "10") {
          const url = `/api/indicatorLot/GTINforPackage?gtin=${Gtin.data}&lot=${
            variableField.data
          }&disId=${getUserCompany().DistributorId}&methodId=${methodId}`;
          await handleResponse(url);
        } else if (variableField.data.includes("PANTONE")) {
          let match = variableField.data.match(/(.{6})95PANTONE/);
          if (match) {
            lot = match[1];
          }
          const url = `/api/indicatorLot/GTINforPackage?gtin=${
            Gtin.data
          }&lot=${lot}&disId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`;
          await handleResponse(url);
        } else {
          const answer = variableField.data;

          let product;

          const indexA = answer.lastIndexOf("A");
          const indexF = answer.lastIndexOf("F");
          const indexB = answer.lastIndexOf("B");
          const indexBT = answer.lastIndexOf("BT");
          const indexPCD = answer.lastIndexOf("PCD");
          const indexCDWA = answer.lastIndexOf("CDWA");

          if (indexA < indexB) {
            if (indexB !== -1) {
              lot = answer.substring(indexB);
              const startAfterB = indexB - 2;

              if (indexCDWA !== -1) {
                product = answer.substring(indexCDWA, startAfterB);
              }
            }
          } else if (indexA > indexF) {
            if (indexA !== -1) {
              lot = answer.substring(indexA);

              const startAfterA = indexA - 2;

              if (indexBT !== -1) {
                product = answer.substring(indexBT, startAfterA);
              }
            }
          } else if (indexA < indexF) {
            if (indexF !== -1) {
              lot = answer.substring(indexF);
              const startAfterF = indexF - 2;

              if (indexPCD !== -1) {
                product = answer.substring(indexPCD, startAfterF);
              }
            }
          }

          const url = `/api/IndicatorLot/lotForCharge?Product=${product}&lot=${lot}&distId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`;

          await handleResponse(url);
        }
      } catch (e) {
        toast.error(e.response?.data?.message);
      } finally {
      }
    }
  };

  const getIdDistributorCompany = () => {
    const infouser = getSession();

    if (infouser.SectorId)
      request()
        .get(`/api/Sector/${infouser.SectorId}`)
        .then((res) => {
          infouser.SectorName = res.data.name;
          setIdDistributorCompany(
            infouser.IdDistributorCompany.replace(/-/g, "")
          );
        });
    else return;
  };

  const createCharge = async (status) => {
    setModalLoading(true);

    let pcdCounter = 1;

    let newIndicators = indicators.flatMap((i) =>
      i.pcd
        ? [
            {
              IndicatorId: i.pcd.indicator_Id,
              quantity: 1,
              serial: i.pcd.serial ? i.pcd.serial : null,
              lot: i.pcd.lot,
              modelAreasId: i.modelAreasId,

              pcd_group: pcdCounter,
            },
            {
              IndicatorId: i.biologicalIndicator.indicator_Id,
              quantity: 1,
              serial: i.biologicalIndicator.serial
                ? i.biologicalIndicator.serial
                : null,
              lot: i.biologicalIndicator.lot,
              modelAreasId: i.modelAreasId,
              pcd_group: pcdCounter,
            },
            {
              IndicatorId: i.chemicalIndicator.indicator_Id,
              quantity: 1,
              serial: i.chemicalIndicator.serial
                ? i.chemicalIndicator.serial
                : null,
              lot: i.chemicalIndicator.lot,
              modelAreasId: i.modelAreasId,
              pcd_group: pcdCounter,
            },
          ].map((item) => {
            pcdCounter++;
            return item;
          })
        : [
            {
              IndicatorId: i.indicator_Id,
              quantity: 1,
              serial: i.serial ? i.serial : null,
              lot: i.lot,
              modelAreasId: i.modelAreasId,
            },
          ]
    );

    const idsIndicators = newIndicators.map(
      (indicator) => indicator.IndicatorId
    );
    let newPackages = selectedPackages.map((p) => ({
      PackageId: p.packageGeneralId,
    }));

    // const implant = newPackages.some(pkg => pkg.isImplant === true);
    const idsPackages = newPackages.map((pkg) => pkg.PackageId);
    // const empty = newPackages.length === 0;
    const requestData = {
      sterilizerId: selectedSterilizer,
      usedProgram,
      chargeStatus: status,
      indicators: newIndicators,
      packages: newPackages,
      observations,
      cicleNumber: cycleNumber,
    };
    const now = new Date();
    const padToTwoDigits = (num) => num.toString().padStart(2, "0");
    const time = `${padToTwoDigits(now.getHours())}:${padToTwoDigits(
      now.getMinutes()
    )}`;
    const verifyRules = {
      indicators: idsIndicators,
      packages: idsPackages,
      methodId: methodId,
      time: time,
      sterilizerId: selectedSterilizer,
    };

    const completeLoad = {
      verify: true,
      indicators: idsIndicators,
      packages: idsPackages,
      methodId: methodId,
      time: time,
      sterilizerId: selectedSterilizer,
    };

    const acceptRuleMandatory = {
      verify: false,
      indicators: idsIndicators,
      packages: idsPackages,
      methodId: methodId,
      time: time,
      sterilizerId: selectedSterilizer,
      cancel: true,
    };

    try {
      setStopButtons(true);
      if (!editId) {
        if (status === 1) {
          // Hacer el post directamente si el status es igual a 1
          await request()
            .post("/api/charge", requestData)
            .then((res) => {
              setEditId(res.data);
            });

          toast.success(
            t("LoadSaved", locale) || "The Load was successfully saved"
          );
        } else {
          const response = await request().put("/api/rule/verify", verifyRules);
          const { mandatory, nonMandatory, packagesWithImplants } =
            response.data;

          if (mandatory.length === 0 && nonMandatory.length === 0) {
            await request()
              .post("/api/charge", requestData)
              .then((res) => {
                status === 1 && setEditId(res.data);
              });
            status === 1
              ? toast.success(
                  t("LoadSaved", locale) || "The Load was successfully saved"
                )
              : toast.success(
                  t("LoadCompleted", locale) ||
                    "The Load was successfully completed"
                );
            if (status !== 1) clearFields();
          } else if (mandatory.length > 0 && nonMandatory.length > 0) {
            const missingConditionsMandatory = mandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const missingConditionsNoMandatory = nonMandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const namePackage = packagesWithImplants
              .map((r) => r.packageName)
              .join("<br>");
            MySwal.fire({
              title: "Pending mandatory rules",
              // text: "You must review mandatory and non-mandatory requirements.",
              html: `You still need to load these rules:<br>
              Mandatory:<br>
              <strong>${missingConditionsMandatory}</strong><br>
              Non-mandatory:<br>
              <strong>${missingConditionsNoMandatory}</strong><br>
              Packages with implants:<br>
              <strong>${namePackage}</strong>`,
              icon: "info",
              confirmButtonText: "Accept",
              customClass: { confirmButton: "confirmButton" },
              confirmButtonColor: "var(--primary-green)",
              backgroundColor: "#f4f4f4",
            }).then(async (result) => {
              if (result.isConfirmed) {
                request().put("/api/rule/verify", acceptRuleMandatory);
              }
            });
          } else if (mandatory.length > 0) {
            const missingConditionsMandatory = mandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const namePackage = packagesWithImplants
              .map((r) => r.packageName)
              .join("<br>");
            MySwal.fire({
              title: "Pending mandatory rules",
              // text: "This action cannot be performed.",
              html: `You still need to load these mandatory rules:<br><strong>${missingConditionsMandatory}</strong>
              <br>Packages with implants:<br>
              <strong>${namePackage}</strong>`,
              icon: "warning",
              confirmButtonText: "Accept",
              customClass: { confirmButton: "confirmButton" },
              confirmButtonColor: "var(--primary-green)",
              backgroundColor: "#f4f4f4",
            }).then(async (result) => {
              if (result.isConfirmed) {
                request().put("/api/rule/verify", acceptRuleMandatory);
              }
            });
          } else if (nonMandatory.length > 0) {
            const missingConditionsNoMandatory = nonMandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const namePackage = packagesWithImplants
              .map((r) => r.packageName)
              .join("<br>");
            MySwal.fire({
              title: "Pending non-mandatory rules",
              // text: "Do you wish to proceed?",
              html: `Are you sure you want to continue?<br>
              Pending non-mandatory rules: <br><strong> ${missingConditionsNoMandatory}</strong>
              <br> Packages with implants:<br>
              <strong>${namePackage}</strong>`,
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              customClass: {
                confirmButton: "confirmButton",
                cancelButton: "cancelButton",
              },
              confirmButtonColor: "var(--primary-green)",
              cancelButtonColor: "var(--delete)",
              backgroundColor: "#f4f4f4",
            }).then(async (result) => {
              if (result.isConfirmed) {
                await request()
                  .post("/api/charge", requestData)
                  .then((res) => {
                    const chargeId = res.data;
                    const completeData = { ...completeLoad, chargeId };
                    request().put("/api/rule/verify", completeData);
                    status === 1 && setEditId(res.data);
                  });
                status === 1
                  ? toast.success(
                      t("LoadSaved", locale) ||
                        "The Load was successfully saved"
                    )
                  : toast.success(
                      t("LoadCompleted", locale) ||
                        "The Load was successfully completed"
                    );
                if (status !== 1) clearFields();
              } else if (result.isDismissed) {
                await request().put("/api/rule/verify", acceptRuleMandatory);
              }
            });
          }
        }
      } else {
        // Lógica cuando editId ya está definido
        if (status === 1) {
          // Si el status es 1, hacer el PUT para actualizar
          requestData.id = editId;
          await request().put("/api/charge", requestData);
          toast.success(
            t("LoadModified", locale) || "The Load was successfully modified"
          );
        } else {
          const response = await request().put("/api/rule/verify", verifyRules);
          const { mandatory, nonMandatory, packagesWithImplants } =
            response.data;

          if (mandatory.length === 0 && nonMandatory.length === 0) {
            // Ambos arrays están vacíos, hacer el put directamente
            await request();
            requestData.id = editId;
            await request().put("/api/charge", requestData);
            toast.success(
              t("LoadModified", locale) || "The Load was successfully modified"
            );
            clearFields();
          } else if (mandatory.length > 0 && nonMandatory.length > 0) {
            const missingConditionsMandatory = mandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const namePackage = packagesWithImplants
              .map((r) => r.packageName)
              .join("<br>");
            const missingConditionsNoMandatory = nonMandatory
              .map((r) => r.ruleName)
              .join("<br>");
            MySwal.fire({
              title: "Pending mandatory rules",
              // text: "You must review mandatory and non-mandatory requirements.",
              html: `You still need to load these rules:<br>
              Mandatory:<br>
              <strong>${missingConditionsMandatory}</strong><br>
              Non-mandatory:<br>
              <strong>${missingConditionsNoMandatory}</strong><br> 
              Packages with implants:<br>
              <strong>${namePackage}</strong>`,
              icon: "info",
              confirmButtonText: "Accept",
              customClass: { confirmButton: "confirmButton" },
              confirmButtonColor: "var(--primary-green)",
              backgroundColor: "#f4f4f4",
            }).then(async (result) => {
              if (result.isConfirmed) {
                request().put("/api/rule/verify", acceptRuleMandatory);
              }
            });
          } else if (mandatory.length > 0) {
            const missingConditionsMandatory = mandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const namePackage = packagesWithImplants
              .map((r) => r.packageName)
              .join("<br>");
            MySwal.fire({
              title: "Pending mandatory rules",
              // text: "This action cannot be performed.",
              html: `You still need to load these mandatory rules:<br><strong>${missingConditionsMandatory}</strong>
               <br>Packages with implants:<br>
              <strong>${namePackage}</strong>`,
              icon: "warning",
              confirmButtonText: "Accept",
              customClass: { confirmButton: "confirmButton" },
              confirmButtonColor: "var(--primary-green)",
              backgroundColor: "#f4f4f4",
            }).then(async (result) => {
              if (result.isConfirmed) {
                request().put("/api/rule/verify", acceptRuleMandatory);
              }
            });
          } else if (nonMandatory.length > 0) {
            const missingConditionsNoMandatory = nonMandatory
              .map((r) => r.ruleName)
              .join("<br>");
            const namePackage = packagesWithImplants
              .map((r) => r.packageName)
              .join("<br>");
            MySwal.fire({
              title: "Pending non-mandatory rules",
              // text: "Do you wish to proceed?",
              html: `Are you sure you want to continue?<br>
              Pending non-mandatory rules: <br><strong> ${missingConditionsNoMandatory}</strong>
              <br>Packages with implants:<br>
              <strong>${namePackage}</strong>`,
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              customClass: {
                confirmButton: "confirmButton",
                cancelButton: "cancelButton",
              },
              confirmButtonColor: "var(--primary-green)",
              cancelButtonColor: "var(--delete)",
              backgroundColor: "#f4f4f4",
            }).then(async (result) => {
              if (result.isConfirmed) {
                requestData.id = editId;
                await request()
                  .put("/api/charge", requestData)
                  .then((res) => {
                    const chargeId = res.data;
                    const completeData = { ...completeLoad, chargeId };
                    request().put("/api/rule/verify", completeData);
                    toast.success(
                      t("LoadModified", locale) ||
                        "The Load was successfully modified"
                    );
                    clearFields();
                  });
              } else if (result.isDismissed) {
                await request().put("/api/rule/verify", acceptRuleMandatory);
              }
            });
          }
        }
      }
      // if (status !== 1) clearFields();
    } catch (e) {
      toast.error(e.response.data.message);
    } finally {
      setModalLoading(false);
      setStopButtons(false);
    }
  };

  const clearFields = (sterilizerChange = true) => {
    sterilizerChange && setEditId(null);
    sterilizerChange && setSelectedSterilizer("");
    sterilizerChange && setMethodName("");
    sterilizerChange && setMethodId(null);
    setUsedProgram("");

    setCycleNumber("");
    setObservations("");
    setIndicators([]);
    setSelectedPackages([]);
    setPackages([]);
  };

  const packagesMethod = useMemo(() => {
    return packages.filter((p) => p.methodId === methodId) ?? [];
  }, [methodId, packages]);

  useEffect(() => {
    setFilteredPackages(packagesMethod);
  }, [packagesMethod, packages]);

  useEffect(() => {
    getEditCharges();
    getParametersLoad();
    getIdDistributorCompany();
  }, []);

  useEffect(() => {
    setUsedProgram(usedProgram.replace(scanCode, ""));
    setScanCode("");
  }, [scanCode]);

  const showValidationErrors = (status) => {
    if (!usedProgram.length > 0 || usedProgram.trim() === "") {
      toast.error(t("NameRequired", locale) || "The Name field is required");
    }
    if (!selectedSterilizer || selectedSterilizer === "") {
      toast.error(
        t("YouMustSelectSterilizer") || "You must select a Sterilizer"
      );
    }
  };

  const handleButtonClick = async (status) => {
    const notValid = validateFields(status);

    if (notValid) {
      setValidate(notValid);
      showValidationErrors(status);
    } else createCharge(status);
  };

  const isSaveDisabled = useMemo(
    () => validateFields(1),
    [usedProgram, selectedSterilizer, indicators]
  );
  const isFinishDisabled = useMemo(
    () => validateFields(2),
    [usedProgram, selectedSterilizer, indicators]
  );

  return (
    <PageContainer
      categoryId={1}
      currentStep={2}
      category={t("SterilizationSpan")}
      subcategory={t("LoadSpan")}
      title={t("CreateLoad", locale)}
      backUrl={"/appmanageloads"}
      scan={true}
      progress={true}
    >
      {!modalEditLoading && !modalLoading && (
        <BarcodeReader onScan={onHandleScan} />
      )}
      <ModalLoading open={modalEditLoading} />
      <ModalLoading open={modalLoading} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "68%",
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <SelectQ
                selectValue={selectedSterilizer}
                handleSelect={handleChangeSterilizer}
                options={sterilizers}
                label={t("SelectASterilizerOption") || "Select a Sterilizer"}
                placeholder={
                  t("SelectASterilizerOption") || "Select a Sterilizer"
                }
                containerWidth={"50%"}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
              />
              <InputQ
                label={
                  t("SterilizationMethodSpan", locale) || "Sterilization method"
                }
                value={methodName}
                containerWidth={"50%"}
                disabled={true}
              />
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <InputQ
                label={t("UsedProgramSpan") || "Used Program"}
                handleChange={handleUsedProgram}
                value={usedProgram}
                containerWidth={"100%"}
                inputRef={usedProgramRef}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
                onHandleScan={onHandleScan}
              />
              <InputQ
                label={t("CycleNumberSpan") || "Cycle Number"}
                handleChange={handleCycleNumber}
                value={cycleNumber}
                containerWidth={"100%"}
                inputRef={cycleRef}
                onHandleScan={onHandleScan}
              />
            </div>
          </div>

          <DragAndDropCharges
            filter1={
              <SearchDescription
                data={packagesMethod.length >= 1 && packagesMethod}
                handleFilter={handleFilteredPackages}
                placeholder={t("SearchPackage") || "Search package..."}
                searchActive={() => {}}
                scanCode={scanCode}
                onHandleScan={onHandleScan}
                handleClear={handleClearSearch}
                clearData={clearSearch}
              />
            }
            filter2={
              <SearchDescription
                data={selectedPackages}
                handleFilter={handleSelectedFilteredPackages}
                placeholder={
                  t("SearchAddedPackage") || "Search added package..."
                }
                searchActive={handleSearchActive}
                scanCode={scanCode}
                handleClear={handleClearSearch}
                clearData={clearSearch}
                onHandleScan={onHandleScan}
              />
            }
            data={filteredPackages}
            selectedData={selectedPackages}
            selectedFilteredData={
              searchActive ? selectedFilteredPackages : selectedPackages
            }
            handleSelectedData={handleSelectedPackages}
            leftColumnName={t("PackageToInclude", locale)}
            rightColumnName={t("PackageIncluded", locale)}
          />
        </div>
        <div style={{ width: "30%", minHeight: "100%" }}>
          <CrudIndicator
            indicators={indicators}
            handleIndicators={handleIndicators}
            onHandleScan={onHandleScan}
            modelAreas={modelAreas}
            zone={true}
            methodId={methodId}
            select={selectedSterilizer}
            condition={"a sterilizer"}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "2.5rem",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <TextAreaQ
          label={t("ObservationSpan")}
          handleChange={handleChangeObservations}
          value={observations}
          width={"68%"}
          inputRef={observationsRef}
          onHandleScan={onHandleScan}
        />

        <div className={styles.containerButtons}>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => clearFields()}
          >
            {t("Cancel") || "Cancel"}
          </button>
          <button
            className={`${styles.button} ${styles.save} ${
              isSaveDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            {t("Save") || "Save"}
          </button>
          <button
            className={`${styles.button} ${styles.finish} ${
              isFinishDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(2)}
          >
            {t("Finish") || "Finish"}
          </button>
        </div>
      </div>
    </PageContainer>
  );
}
