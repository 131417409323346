import React, { useContext, useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";
import Swal from "sweetalert2";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";

import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import Redirect from "../../utils/Redirect";
import styles from "./ManagePackages.module.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import printTicket from "../../utils/printTicket.js";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation.js";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import PackageDetail from "../../components/PackageDetail/PackageDetail.jsx";
import PackageLabel from "../../components/packageLabel/PackageLabel.jsx";
import withReactContent from "sweetalert2-react-content";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import t from "../../utils/translation.js";
import { getFilterValue, setFilterValue } from "../../utils/sessionFilters";

const startOfYesterday = new Date();
startOfYesterday.setDate(startOfYesterday.getDate() - 1);
startOfYesterday.setHours(0, 0, 0, 0);

const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 999);

export default function ManagePackages() {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [methods, setMethods] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  const [filters, setFilters] = useState({
    packageNumber: getFilterValue("packageNumber", "", "managePackages"),
    dateFrom: getFilterValue("dateFrom", startOfYesterday, "managePackages"),
    dateTo: getFilterValue("dateTo", endOfToday, "managePackages"),
    name: getFilterValue("name", "", "managePackages"),
    method: getFilterValue("method", "", "managePackages"),
    status: getFilterValue("status", 5, "managePackages"),
  });

  const fetchPackages = async (filters, currentPage, itemsPerPage) => {
    setLoadingPackages(true);

    try {
      const {
        packageNumber = "",
        dateFrom = startOfYesterday,
        dateTo = endOfToday,
        name = "",
        method = "",
        status = 5,
      } = filters;

      const response = await request().get(
        `/api/Package/list?includeCount=true&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setPackages(response.data.packageList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    setFilterValue(key, value, "managePackages");
  };

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      const clearedFilters = { ...filters, dateFrom: "", dateTo: "" };
      setFilters(clearedFilters);
      setFilterValue("dateFrom", "", "managePackages");
      setFilterValue("dateTo", "", "managePackages");
    } else {
      handleFilterChange(type, value);
    }
  };
  const getMethods = () => {
    request()
      .get("/api/method/1")
      .then((res) =>
        setMethods(
          res.data.map((m) => ({ value: m.id, name: m.methodTranslation }))
        )
      );
  };

  const handleDetail = (packageGeneralId) => {
    const item = packages.find((p) => p.packageGeneralId === packageGeneralId);
    setPackageDetail(item);
    setIsDetailVisible(true);
  };

  const getPrintPackage = (id) => {
    request()
      .get(`/api/package/info?packageId=${id}`)
      .then((res) => showPrintAlert(res.data));
  };

  const showPrintAlert = (packageDetail) => {
    const ReactSwal = withReactContent(Swal);
    ReactSwal.fire({
      html: <PackageLabel packageDetail={packageDetail} />,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: t("Print"),
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(packageDetail);
      }
    });
  };

  const {
    currentPage,
    currentItems,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    packages,
    [
      "packageNumber",
      getRegionalFormat().dateFormat,
      "description",
      "methodId",
      "packageStatus",
    ],
    totalPages
  );

  const handleDelete = (packageGeneralId) => {
    const objectToDelete = packages.find(
      (pkg) => pkg.packageGeneralId === packageGeneralId
    );

    const adjustedObject = {
      ...objectToDelete,
      method: {
        name: objectToDelete.methodName,
      },
      creationDate: objectToDelete.date,
      id: objectToDelete.packageGeneralId,
    };
    request()
      .delete(`/api/package`, adjustedObject)
      .then(() => {
        const newData = packages.filter(
          (pkg) => pkg.packageGeneralId !== packageGeneralId
        );
        setPackages(newData);
        setPackageDetail(null);
      });
  };

  const onDelete = (packageGeneralId) => {
    confirm({
      title: "Delete Package",
      text: "Are you sure you want to delete this package?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      onConfirm: () => handleDelete(packageGeneralId),
    });
  };

  useEffect(() => {
    getMethods();
  }, []);

  useEffect(() => {
    fetchPackages(filters, currentPage, itemsPerPage);
  }, [filters, currentPage, itemsPerPage]);

  const arrayStatus = [
    { value: 5, name: t("SelectStatusOption") },
    { value: 1, name: t("AssemblingSpan", locale) },
    { value: 2, name: t("ReadyForLoadSpan", locale) },
  ];

  return (
    <PageContainer
      categoryId={1}
      currentStep={1}
      backUrl={"/appsterilization"}
      category={t("SterilizationSpan")}
      subcategory={t("PackageSpan")}
      title={t("ManagePackages", locale)}
      newButton={!isDetailVisible}
      newButtonUrl={"/appcreatepackages"}
      progress={true}
      subtitle={isDetailVisible ? t("PackageDetail", locale) : null}
      clickBack={isDetailVisible}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={(e) =>
                  handleFilterChange("packageNumber", e.target.value)
                }
                placeholder={t("NumberSpan") || "Number"}
                value={filters.packageNumber}
                containerWidth="100%"
              />
              <FilterDate
                handleChange={handleDateChange}
                dateTo={filters.dateTo}
                dateFrom={filters.dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                handleChange={(e) => handleFilterChange("name", e.target.value)}
                placeholder={t("NameSpan")}
                value={filters.name}
                containerWidth="100%"
              />
              <SelectQ
                placeholder={t("SelectMethodOption")}
                hasPlaceholder={true}
                options={methods}
                handleSelect={(e) =>
                  handleFilterChange("method", e.target.value)
                }
                selectValue={filters.method}
                containerWidth={"100%"}
              />
              <SelectQ
                options={arrayStatus}
                handleSelect={(e) =>
                  handleFilterChange("status", e.target.value)
                }
                selectValue={filters.status}
                containerWidth={"100%"}
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions={true}>
                {/* Table Header */}
                <TableHeaderCellQ
                  date={true}
                  handleClick={() => handleSortByColumn("packageNumber")}
                >
                  {t("NumberSpan", locale) || "Number"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  date={true}
                  handleClick={() => handleSortByColumn("date")}
                >
                  {t("DateSpan") || "Date"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("description")}
                >
                  {t("NameSpan", locale)}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("Method", locale)}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageStatus")}
                >
                  {t("Status", locale)}
                </TableHeaderCellQ>
              </TableHeadQ>
              {/* Table Body */}
              {loadingPackages ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((p) => (
                    <TableRowQ
                      key={p.packageGeneralId}
                      actions={true}
                      edit={p.packageStatus === 1}
                      print={p.packageStatus === 2}
                      detail={p.packageStatus === 1 || p.packageStatus === 2}
                      deleteRow={true}
                      onPrint={() => getPrintPackage(p.packageGeneralId)}
                      onEdit={() =>
                        Redirect({
                          redirect: true,
                          path: "/appcreatepackages",
                          parameters: { packageEditId: p.packageGeneralId },
                        })
                      }
                      onDelete={() => onDelete(p.packageGeneralId)}
                      onDetail={() => handleDetail(p.packageGeneralId)}
                    >
                      <TableRowCellQ date={true}>
                        <TruncatedText text={p.packageNumber} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ date={true}>
                        <TruncatedText
                          text={df(p.date, "date")}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.description} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.methodName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[p.packageStatus === 1 ? "yellow" : "green"]
                          }`}
                        ></span>
                        <TruncatedText
                          text={
                            p.packageStatus === 1
                              ? t("AssemblingSpan")
                              : t("ReadyForLoadSpan")
                          }
                          maxLength={25}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}
              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <PackageDetail
              packageId={packageDetail.packageGeneralId}
              indicatorsWithoutDetail={true}
              locale={locale}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
