import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import request from "../../utils/request";
import Redirect from "../../utils/Redirect";

import DragAndDropRules from "../../components/DragAndDropRules/DragAndDropRules";
import Search from "../../components/Search/Search";

import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { getUserCompany } from "../../utils/sessionHandler";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Checkbox, Grid } from "@material-ui/core";
import styles from "./RuleForm.module.css";
import PageContainer from "../../components/PageContainer/PageContainer";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ";
import InputQ from "../../components/FormComponents/InputQ/InputQ";
import InputDateQ from "../../components/FormComponents/InputDateQ/InputDateQ";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ";
import TimeRule from "../../components/TimeRule/TimeRule"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function RuleForm() {
  const company = getUserCompany();

  const [ruleId, setRuleId] = useState(null);
  const [ruleEditId, setEditRuleId] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [newRule, setNewRule] = useState(false);
  const [name, setName] = useState("");
  const [privateName, setPrivateName] = useState("");
  const [loadTypes, setLoadTypes] = useState([]);
  const [loadTypeId, setLoadTypeId] = useState(null);
  const [helpUser, setHelpUser] = useState("");
  const [methods, setMethods] = useState([]);
  const [sterilizers, setSterilizers] = useState([]);
  const [methodId, setMethodId] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedSterilizer, setSelectedSterilizer] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [date, setDate] = useState(null);
  const [days, setDays] = useState(null);
  const [process, setProcess] = useState(["Sterilization", "Washing"]);
  const [applyTo, setApplyTo] = useState(["Load", "Packages"]);
  const [mandatory, setMandatory] = useState(false);
  const [active, setActive] = useState(false);
  const [implant, setImplant] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [forAny, setForAny] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredSelectedProducts, setFilteredSelectedProducts] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedEditTime, setSelectedEditTime] = useState(null);
  const [validate, setValidate] = useState(false);
  const [validateDays, setValidateDays] = useState(false);
  const [validateProducts, setValidateProducts] = useState(false);
  const [validateDate, setValidateDate] = useState(false);
  const [validateTime, setValidateTime] = useState(false)
  const [validateMethod, setValidateMethod] = useState(false)
  const [validateSterilizer, setValidateSterilizer] = useState(false)
  const [methodName, setMethodName] = useState("");
  const [options, setOptions] = useState("");

  const locale = useContext(LocalizationContext);

  const nameRef = useRef();
  const frequencyRef = useRef();

  useEffect(() => {
    fetchDataRule();
    fetchSterilizers()
  }, []);

  const clearFields = () => {
    setRuleId(null);
    setName("");
    setHelpUser("");
    setSelectedMethod("");
    setSelectedSterilizer("");
    setMandatory(false);
    setActive(true);
    setImplant(false)
    setEmpty(false)
    setForAny(false)
    setFilteredProducts([])
    setSelectedProducts([])
    setNewRule(true);
    setMethodId(null)
    setSelectedTime(null)
    setDate(null)
    setDays(null)
    setMethodName("")
  };


  const fetchSterilizers = () => {
    setLoadingData(true);
    request()
      .get(`/api/parameter/rule`)
      .then((response) => {
        const sterilizers = response.data.sterilizers.map((item) => ({
          value: item.sterilizerId,
          name: item.name,
          methodName: item.sterilizerMethodName,
          methodId: item.sterilizerMethodId,
          companyMachineModelId: item.companyMachineModelId,
        }));
        setSterilizers(sterilizers);
        const options = response.data.frequencies.map((item) => ({
          name: item.name,
          days: item.days,
          translationName: item.translation
        }));
        setOptions(options)
        setTimeout(() => {
          setLoadingData(false);
        }, 200);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const fetchProducts = (newMethod) => {
    setLoadingData(true);
    request()
      .get(`/api/product/list?methodId=${newMethod}&process=0`)
      .then((response) => {
        setProducts(
          response.data.map((p) => {
            return { id: p.id, name: p.name, description: p.name };
          })
        );
        setFilteredProducts(
          response.data.map((p) => {
            return { id: p.id, name: p.name, description: p.name };
          })
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const fetchDataRule = () => {
    const ruleEditId = localStorage.getItem("ruleEditId");
    setEditRuleId(ruleEditId)
    if (!ruleEditId) {
      clearFields()
      return;
    }

    setLoadingData(true);
    request()
      .get(`/api/Rule/list`)
      .then((response) => {
      const objectToEdit = response.data.find((obj) => obj.ruleId == ruleEditId);
      if (objectToEdit) {
        setRuleId(objectToEdit.ruleId);
        setName(objectToEdit.ruleName);
        setHelpUser(objectToEdit.helpUser);
        setSelectedSterilizer(objectToEdit.sterilizerId);
        setMethodName(objectToEdit.methodName);
        setMandatory(objectToEdit.mandatory);
        setActive(objectToEdit.active);
        setEmpty(objectToEdit.empty)
        // setForAny(objectToEdit.mustRepeat)
        if (objectToEdit.days == 0) {
        setForAny(true)
        }
        setImplant(objectToEdit.implant)
        setDays(objectToEdit.days)
        setDate(objectToEdit.startDate)
        setSelectedProducts(
          objectToEdit.products.map((p) => {
            return { id: p.productId, name: p.productName };
          })
        );
        setNewRule(false);
        removeLocalStorage();
        // console.log(JSON.stringify(response.data));
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
  }})
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validateFields = () => {
    let error = false;
    if (
      (name == null || name == undefined || name.length == 0) &&
      privateName.length == 0
    ) {
      setLoadingData(false);
      setValidate(true);
      error = true;
      //nameRef.current.style.borderBottom = "4px solid #cc3300";
    }
    if (forAny == true) {
      setValidateDays(false);
    } else if (days == null || days == undefined || days < 1) {
      setLoadingData(false);
      setValidateDays(true);
      error = true;
    }
    // if (forAny == true) {
    //   setValidateTime(false);
    // } else if (!selectedTime ||
    // selectedTime.length === 0 || 
    // selectedTime.some(item => 
    //   (Array.isArray(item.day) && item.day.length === 0) || 
    //   item.time == null
    // )) {
    //   setLoadingData(false); 
    //   setValidateTime(true);
    //   error = true;
    // }
    if (
      selectedProducts == null ||
      selectedProducts == undefined ||
      selectedProducts.length < 1
    ) {
      setLoadingData(false);
      setValidateProducts(true);
      error = true;
    }
    if (
      (!selectedSterilizer || selectedSterilizer === "" || selectedSterilizer == null) 
    ) {
      setLoadingData(false);
      setValidateSterilizer(true);
      error = true;
    }
    return error;
  };

  const handleChangeName = (value) => {
    setName(value.target.value);
  };

  const handleDate = (date) => {
    setDate(date)
    setValidateDate(false);
  }

  const handleDays = (days) => {
    setDays(days)
    setValidateDays(false)
  }

  const removeLocalStorage = () => {
    const itemToRemove = localStorage.getItem("ruleEditId");

    if (itemToRemove) localStorage.removeItem("ruleEditId");
  };

  const onCancelClick = () => {
    removeLocalStorage();

    Redirect({
      redirect: true,
      path: "/apprules",
    });
  };

  const onSaveClick = () => {
    setLoadingData(true);

    if (validateFields()) return;

    const ruleInput = {
      Name:
        name != null && name != undefined && name != "" ? name : privateName,
      HelpUser: helpUser,
      Days: days,
      SterilizerId: selectedSterilizer,
      StartDate: date,
      ApplyTo: 0,
      Process: 0,
      Mandatory: mandatory,
      Active: active,
      Implant: implant,
      Empty: empty,
      productIds: selectedProducts.map((sp) => {
        return sp.id;
      }),
      companyId: company.Id,
    };
    if (!newRule) ruleInput.id = ruleId;

    removeLocalStorage();
    if (!newRule) {
      updateRule(ruleInput);
    } else {
      addRule(ruleInput);
    }
  };

  const updateRule = (ruleInput) => {
    request()
      .put(`/api/rule`, ruleInput)
      .then(() => {
        setLoadingData(false);
        toast.success(
          t("RuleSuccessfullyModified") || "The rule was successfully modified"
        );
      })
      .catch((error) => {
        setLoadingData(false);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const addRule = (ruleInput) => {
      request()
    .post(`/api/rule`, ruleInput)
    .then(() => {
      setLoadingData(false);
      toast.success(
        t("RuleSuccessfullyCompleted") ||
          "The Rule was successfully completed"
      );
      clearFields();
    })
    .catch((error) => {
      setLoadingData(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
    });
  };


  const handleLoadType = (lt) => setLoadTypeId(lt.target.value);
  const handleChangeHelpUser = (value) => {
    setHelpUser(value.target.value);
  };
  
  // const handleMethod = async (e) => {
  //   const newMethod = e.target.value;
  //   setSelectedMethod(newMethod);
  //   if (newMethod) {  
  //     fetchProducts(newMethod);
  //   } else {
  //     setProducts([])
  //     setFilteredProducts([])
  //   }
  // };

  const handleSterilizer = async (e) => { 
    const selectedValue = e.target.value;
    const selectedSterilizer = sterilizers?.find(
      (s) => s.value == selectedValue
    );
    setSelectedProducts([]);
    if(!forAny && !ruleEditId){
      setDays(null)
    }
    setSelectedSterilizer(selectedValue);

    const method = {
      name: selectedSterilizer?.methodName,
      id: selectedSterilizer?.methodId,
    };
  
    setMethodName(selectedValue ? method.name : "");
    setMethodId(selectedValue ? method.id : "");
  
    if (selectedSterilizer) {
      fetchProducts(method.id);
    }
  
    setValidateSterilizer(false);
  };


  useEffect(() => {
    if (selectedSterilizer) {
      const sterilizer = sterilizers?.find((s) => s.value === selectedSterilizer);
      if (sterilizer) {
        setMethodId(sterilizer.methodId); 
        setMethodName(sterilizer.methodName); 
        fetchProducts(sterilizer.methodId); 
      }
    }
  }, [selectedSterilizer, sterilizers]);


  const handleChangeMandatory = (m) => setMandatory(m.target.checked);
  const handleChangeActive = (a) => setActive(a.target.checked);
  
  const handleChangeEmpty = (event) => {
    const isChecked = event.target.checked;
    setEmpty(isChecked);
    if (isChecked) 
      setImplant(false);
  };
  const handleChangeImplant = (e) => {
    const isChecked = e.target.checked;
    setImplant(isChecked);
    if (isChecked) setEmpty(false); 
  };
  const handleChangeForAny = (a) => {
    const isChecked = a.target.checked
    setForAny(isChecked);
    setDays(0)
  };
  
  const handleSelectedProducts = (sl) => {
    setSelectedProducts(sl);
    setValidateProducts(false);
  };
  const handleProducts = (arr) => {
    /*let filtered = products.filter((d) =>
            d.description.toLowerCase().includes(arr.toLowerCase())
        );*/
    setFilteredProducts(arr);
  };

  const handleFilteredSelectedProducts = (arr) => {
    /*let filtered = products.filter((d) =>
            d.description.toLowerCase().includes(arr.toLowerCase())
        );*/
    setFilteredSelectedProducts(arr);
  };

  const handleSearchActive = (bool) => setSearchActive(bool);

  const subtitle =
    (newRule ? t("CreateLabel") || "Create" : t("Save") || "Save")

  return (
    <PageContainer
      category={t("SterilizationSpan") || "Sterilization"}
      backUrl={"/apprules"}
      subcategory={t("BlankEntry3") || "Rules"}
      title={t("Create") || "Create"}
      categoryId={1}
    >
      <>
        {loadingData ? (
          <div className="spinnerContainer">
              <SpinnerQ />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "25px",
                marginLeft: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Grid container spacing={2}>
                  {/* OPCION 2 */}
                  <Grid container spacing={2}>
                    {/* Columna Izquierda */}
                    <Grid container item xs={12} sm={6} spacing={2} direction="column">
                      {/* Name */}
                      <Grid item>
                        <InputQ
                          label={t("NameSpan") || "Name"}
                          value={name}
                          handleChange={handleChangeName}
                          containerWidth="100%"
                          required
                          errorMessage={t("RequiredFieldError") || "This field is required"}
                          validate={validate}
                        />
                      </Grid>

                      {/* Description */}
                      <Grid item>
                        <InputQ
                          value={helpUser}
                          handleChange={handleChangeHelpUser}
                          label={t("DescriptionLabel") || "Description"}
                          containerWidth="100%"
                        />
                      </Grid>

                      {/* Process y Apply to en la misma fila */}
                      <Grid container item spacing={2}>
                        <Grid item xs={6}>
                          <SelectQ
                            selectValue="loadTypeId"
                            options={process}
                            handleSelect={handleLoadType}
                            containerWidth="100%"
                            label={t("Process") || "Process"}
                            placeholder={t("SterilizationSpan") || "Sterilization"}
                            errorMessage={t("RequiredFieldError") || "This field is required"}
                            validate={validate}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectQ
                            selectValue="loadTypeId"
                            options={applyTo}
                            handleSelect={handleLoadType}
                            containerWidth="100%"
                            label={t("ApplyTo") || "Apply to"}
                            placeholder={t("LoadSpan") || "Load"}
                            errorMessage={t("RequiredFieldError") || "This field is required"}
                            validate={validate}
                            disabled
                          />
                        </Grid>
                      </Grid>
                       {/* Sterilizers */}
                       <Grid item>
                        <SelectQ
                          selectValue={selectedSterilizer}
                          handleSelect={handleSterilizer}
                          options={sterilizers}
                          label={t("SterilizerSpan") || "Sterilizer"}
                          placeholder={t("SelectASterilizerOption") || "Select a Sterilizer"}
                          containerWidth="100%"
                          required={true}
                          errorMessage={t("RequiredFieldError") || "This field is required"}
                          validate={validateSterilizer}
                        />
                      </Grid>
                        
                       {/* Method */}
                      <Grid item>
                      <InputQ
                        label={
                          t("SterilizationMethodSpan", locale) || "Sterilization method"
                        }
                        value={methodName}
                        containerWidth={"100%"}
                        disabled={true}
                      />
                      </Grid>
                    </Grid>

                   {/* Columna Derecha */}
                   <Grid container item xs={12} sm={6} spacing={2} direction="column">
                        {/* Checkboxes en dos filas */}
                      <Grid className={styles.whiteBackgroundCheck}>
                        <Grid container item spacing={3}>
                          {/* Primera fila de checkboxes */}
                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px",
                                marginLeft:"40px"
                              }}
                            >
                              <label>{t("Mandatory") || "Mandatory"}:</label>
                            </div>
                            <Checkbox checked={mandatory} onChange={handleChangeMandatory} />
                          </Grid>

                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px"
                              }}
                            >
                              <label>{t("Active") || "Active"}:</label>
                            </div>
                            <Checkbox checked={active} onChange={handleChangeActive} />
                          </Grid>
                        </Grid>

                        <Grid container item spacing={3}>
                          {/* Segunda fila de checkboxes */}
                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px",
                                marginLeft:"40px"
                              }}
                            >
                              <label>{t("Empty") || "Empty"}:</label>
                            </div>
                            <Checkbox checked={empty} onChange={handleChangeEmpty} />
                          </Grid>
                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px",
                              }}
                            >
                              <label>{t("Implant") || "Implant"}:</label>
                            </div>
                            <Checkbox checked={implant} onChange={handleChangeImplant} />
                          </Grid>

                          <Grid item xs={12} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginLeft:"40px",
                                marginTop:"10px",
                              }}
                            >
                              <label>{t("BlankEntry6") || "For every load"}:</label>
                            </div>
                            <Checkbox checked={forAny} onChange={handleChangeForAny} />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Selector de días */}
                      <Grid item className={styles.whiteBackground}>
                        <div style={{ color: "#555555", marginLeft:"30px" }}>
                        <div className={styles.label}>
                          <TimeRule 
                          options={options} 
                          validateDays={validateDays}  
                          initialSelectedDay={days}  
                          initialSelectedDate={date} 
                          forAny={forAny} 
                          ruleEditId={ruleEditId}  
                          onDateChange={handleDate} 
                          onDaysChange={handleDays} 
                          setLoadingData={loadingData}/>
                        </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  

                  <Grid item xs={12} sm={4}></Grid>
                  <div style={{ width: "100%" }}>
                    <DragAndDropRules
                      filter1={
                        <Search
                          data={products}
                          handleFilter={handleProducts}
                          placeholder={
                            t("Search indicators to add") ||
                            "Search indicators to add"
                          }
                          searchActive={() => {}}
                        />
                      }
                      filter2={
                        <Search
                          data={selectedProducts}
                          handleFilter={handleFilteredSelectedProducts}
                          placeholder={
                            t("Search added indicators") ||
                            "Search added indicators"
                          }
                          searchActive={handleSearchActive}
                        />
                      }
                      leftColumnName={t("BlankEntry4") || "Indicators to include"}
                      rightColumnName={t("IndicatorsIncluded") || "Indicators included"}
                      data={filteredProducts}
                      selectedData={selectedProducts}
                      selectedFilteredData={
                        searchActive
                          ? filteredSelectedProducts
                          : selectedProducts
                      }
                      handleSelectedData={handleSelectedProducts}
                    />
                  </div>
                  <Grid item xs={5}>
                    {validateProducts ? (
                      <p style={{ color: "#FF0027", fontWeight: "700",  fontSize: "12px" }}>
                        *
                        {t("NeedToAddAtLeastOneProduct") ||
                          "You need to add at least one product"}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={styles.buttonContainer}>
                      <button
                        className={`${styles.button} ${styles.cancel}`}
                        onClick={() => onCancelClick()}
                      >
                        {t("CancelButton") || "Cancel"}
                      </button>
                      <button
                        className={`${styles.button} ${styles.save}`}
                        onClick={() => onSaveClick()}
                      >
                        {subtitle} 
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </>
    </PageContainer>
  );
}
