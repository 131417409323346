import React, { useState } from "react";

import styles from "./ChemicalVinculation.module.css";

import t from "../../utils/translation";

import ModalQ from "../TableBionovaQ/ModalQ/ModalQ";
import SelectQ from "../FormComponents/SelectQ/SelectQ";
import TextAreaQ from "../FormComponents/TextAreaQ/TextAreaQ";
import ImageDrop from "../ImageDrop/ImageDrop";
import { toast } from "react-toastify";

const ChemicalVinculation = ({ isChemical, onClose, handleSubmit, category = "sterilization" }) => {
  const [result, setResult] = useState(4);
  const [observations, setObservations] = useState("");
  const [image, setImage] = useState("");

  const resultOptions = [
    { value: 4, name: t("SafeSpan") },
    { value: 3, name: t("UnsafeSpan") },
  ];

  const handleResult = (e) => {
    setResult(e.target.value);
  };
  const handleObservations = (e) => {
    setObservations(e.target.value);
  };
  const handleImage = (data) => {
    setImage(data.base64Image);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!image) {
      toast.error(t("BlankEntry20"));
      return;
    }

    handleSubmit(result, observations, image);
  };

  return (
    <ModalQ
      open={isChemical}
      title={t("BlankEntry14") || "Chemical Vinculation"}
      handleClose={onClose}
    >
      <SelectQ
        selectValue={result}
        handleSelect={handleResult}
        label={t("SelectResultOption")}
        name={"result"}
        width={"100%"}
        containerWidth={"48%"}
        options={resultOptions}
        required={true}
        category={category}
      />
      <TextAreaQ
        width="100%"
        label={t("ObservationSpan") || "Observations"}
        name="observations"
        handleChange={handleObservations}
        value={observations}
        category={category}
      />
      <ImageDrop
        onFileChange={handleImage}
        required={true}
        label={t("BlankEntry15") || "Drag your file here or click to upload"}
      />

      <div className={styles.buttonGroup}>
        <button
          className={`${styles.button} ${category.toUpperCase() === "WASHING" ? styles.blue : styles.green}`}
          type="submit"
          onClick={(e) => onSubmit(e)}
        >
          {t("Save") || "Save"}
        </button>
      </div>
    </ModalQ>
  );
};

export default ChemicalVinculation;
