import React, { useContext, useEffect, useState } from "react";

import styles from "../ConsultPackage/ConsultPackage.module.css";

import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import parseFiltersDate from "../../utils/parseFiltersDate.js";

import { FiPackage } from "react-icons/fi";

import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import CircularProgressIndicators from "../../components/CircularProgressIndicators/CircularIndicator.jsx";
import Redirect from "../../utils/Redirect.js";
import { getFilterValue, setFilterValue } from "../../utils/sessionFilters";

export default function BatchRecord() {
  const [cycles, setCycles] = useState([]);
  const [loadingCycles, setLoadingCycles] = useState(false);

  const [filters, setFilters] = useState({
    loadNumber: getFilterValue("loadNumber", "", "batchRecord"),
    usedProgram: getFilterValue("usedProgram", "", "batchRecord"),
    operator: getFilterValue("operator", "", "batchRecord"),
    selectedSterilizer: getFilterValue("selectedSterilizer", "", "batchRecord"),
    dateFrom: getFilterValue("dateFrom", "", "batchRecord"),
    dateTo: getFilterValue("dateTo", "", "batchRecord"),
  });

  const [sterilizers, setSterilizers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const locale = useContext(LocalizationContext);

  const fetchCycles = async (filters, currentPage, itemsPerPage) => {
    setLoadingCycles(true);

    try {
      const {
        loadNumber,
        usedProgram,
        operator,
        selectedSterilizer,
        dateFrom,
        dateTo,
      } = filters;


      const response = await request().get(
        `/api/Charge/list?includeCount=true&pageSize=${itemsPerPage}&cycleNumber=${loadNumber}&operator=${operator}&status=4&page=${currentPage}&search=${usedProgram}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setCycles(response.data.chargeListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching cycles:", error);
    } finally {
      setLoadingCycles(false);
    }
  };

  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    setFilterValue(key, value, "batchRecord");
  };

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      const clearedFilters = { dateFrom: "", dateTo: "" };
      setFilters((prevFilters) => ({ ...prevFilters, ...clearedFilters }));
      setFilterValue("dateFrom", "", "batchRecord");
      setFilterValue("dateTo", "", "batchRecord");
    } else {
      handleFilterChange(type, value);
    }
  };

  

  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/charge`);
      const sterilizers = response.data.sterilizers.map((item) => ({
        value: item.sterilizerId,
        name: item.name,
      }));
      const operators = response.data.operators.map((item) => ({
        value: item.name.replace(/ /g, "+"),
        name: item.name,
      }));
      setSterilizers(sterilizers);
      setOperators(operators);
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  const handleDetail = (id) => {
    Redirect({
      redirect: true,
      path: `/appcycledetail/${id}`,
      parameters: { cycleRecord: true },
    });
  };

  const {
    currentPage,
    currentItems,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
  } = useTableQ(
    cycles,
    [
      "cycleNumber",
      "usedProgram",
      "operator",
      "sterilizerName",
      "creationDate",
    ],
    totalPages
  );

  useEffect(() => {
    getParametersLoad();
  }, []);

  useEffect(() => {
    fetchCycles(filters, currentPage, itemsPerPage);
  }, [filters, currentPage, itemsPerPage]);


  return (
    <PageContainer
      categoryId={1}
      category={t("SterilizationSpan")}
      backUrl={"/appsterilization"}
      title={t("BatchRecordSpan")}
    >
      <div className={styles.container}>
        <div className={styles.tableContainer}>
          <div className={styles.filterContainer}>
            <InputQ
              icon={<FiPackage />}
              handleChange={(e) =>
                handleFilterChange("loadNumber", e.target.value)
              }
              placeholder={t("NumberSpan")}
              value={filters.loadNumber}
              containerWidth="100%"
            />
            <InputQ
              icon={<FiPackage />}
              handleChange={(e) =>
                handleFilterChange("usedProgram", e.target.value)
              }
              placeholder={t("UsedProgramSpan")}
              value={filters.usedProgram}
              containerWidth="100%"
            />
            <SelectQ
              selectValue={filters.operator}
              handleSelect={(e) => {
                handleFilterChange("operator", e.target.value);
              }}
              options={operators}
              placeholder={t("SelectOperatorOption", locale)}
              hasPlaceholder={true}
              containerWidth="100%"
            />
            <SelectQ
              selectValue={filters.selectedSterilizer}
              handleSelect={(e) =>
                handleFilterChange("selectedSterilizer", e.target.value)
              }
              options={sterilizers}
              placeholder={t("SelectASterilizerOption", locale)}
              hasPlaceholder={true}
              containerWidth="100%"
            />
            <FilterDate
              handleChange={handleDateChange}
              dateTo={filters.dateTo}
              dateFrom={filters.dateFrom}
            />
          </div>
          <TableContainerQ>
            <TableHeadQ actions={true} progress={true}>
              <TableHeaderCellQ
                handleClick={() => handleSortByColumn("cycleNumber")}
              >
                {t("CycleNumberSpan", locale)}
              </TableHeaderCellQ>
              <TableHeaderCellQ
                handleClick={() => handleSortByColumn("usedProgram")}
              >
                {t("UsedProgramSpan", locale)}
              </TableHeaderCellQ>
              <TableHeaderCellQ
                handleClick={() => handleSortByColumn("operator")}
              >
                {t("OperatorSpan", locale)}
              </TableHeaderCellQ>
              <TableHeaderCellQ
                handleClick={() => handleSortByColumn("sterilizerName")}
              >
                {t("SterilizerSpan", locale)}
              </TableHeaderCellQ>
              <TableHeaderCellQ
                handleClick={() => handleSortByColumn("creationDate")}
              >
                {t("DateSpan", locale)}
              </TableHeaderCellQ>
            </TableHeadQ>
            {loadingCycles ? (
              <div className="spinnerContainer">
                <SpinnerQ />
              </div>
            ) : (
              <TableBodyQ>
                {currentItems.map((row) => (
                  <TableRowQ
                    key={row.chargeId}
                    progress={
                      <CircularProgressIndicators
                        totalIndicators={row.totalIndicators}
                        vinculatedIndicators={row.vinculatedIndicators}
                        hasPositive={row.hasPositive}
                      />
                    }
                    actions={true}
                    detail={row}
                    onClick={() => handleDetail(row.chargeId)}
                    onDetail={() => handleDetail(row.chargeId)}
                  >
                    <TableRowCellQ>{row.cycleNumber}</TableRowCellQ>
                    <TableRowCellQ>{row.usedProgram}</TableRowCellQ>
                    <TableRowCellQ>{row.operator}</TableRowCellQ>
                    <TableRowCellQ>{row.sterilizerName}</TableRowCellQ>
                    <TableRowCellQ>
                      {df(row.creationDate, "date")}
                    </TableRowCellQ>
                  </TableRowQ>
                ))}
              </TableBodyQ>
            )}
            <TablePaginationQ
              handleChangePage={handleChangePage}
              handleChangeItemsPerPage={handleChangeItemsPerPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </TableContainerQ>
        </div>
      </div>
    </PageContainer>
  );
}
