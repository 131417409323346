import React, { useEffect, useRef, useState } from "react";
import InputQ from "../FormComponents/InputQ/InputQ.jsx";

const SearchDescription = ({
  data,
  handleFilter,
  placeholder,
  searchActive,
  onHandleScan,
  scanCode,
  category,
  clearData,
  handleClear,
}) => {
  const [search, setSearch] = useState("");
  const searchRef = useRef();

  const handleSearch = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setSearch(item);
  };

  let results = !search
    ? data
    : Array.isArray(data)
    ? data.filter((d) =>
        d.name.toLowerCase().includes(search.toLocaleLowerCase())
      )
    : data;

  useEffect(() => {
    search ? searchActive(true) : searchActive(false);
    handleFilter(results);
  }, [search, data]);

  useEffect(() => {
    if(clearData){
      setSearch(prev => prev.replace(clearData, ""))
      handleClear(null)
    }
  }, [clearData])

  return (
    <div style={{ marginBottom: "10px", width: "100%" }}>
      <InputQ
        category={category}
        value={search}
        handleChange={handleSearch}
        placeholder={placeholder}
        containerWidth={"100%"}
        inputStyleMod={{ padding: "4px" }}
        inputRef={searchRef}
        onHandleScan={onHandleScan}
      />
    </div>
  );
};

export default SearchDescription;
