// Librerías de terceros
import React, { useContext, useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";

// Componentes locales
import PageContainer from "../../../components/PageContainer/PageContainer";
import TableContainerQ from "../../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import LoadDetail from "../../../components/LoadWashingDetail/LoadWashingDetail.jsx";

// Utilidades
import useTableQ from "../../../utils/Hooks/useTableQ";
import useConfirmation from "../../../utils/Hooks/useConfirmation/useConfirmation";
import LocalizationContext from "../../../utils/LocalizationContext";
import request from "../../../utils/request";
import df from "../../../utils/dateFormater";
import Redirect from "../../../utils/Redirect";
// Estilos e imágenes
import styles from "./ManageWasherLoadings.module.css";
import FilterDate from "../../../components/FilterDate/FilterDate.jsx";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ.jsx";
import TruncatedText from "../../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerWashing from "../../../components/SpinnerWashing/SpinnerWashing.jsx";
import InputQ from "../../../components/FormComponents/InputQ/InputQ.jsx";
import t from "../../../utils/translation.js";
import parseFiltersDate from "../../../utils/parseFiltersDate.js";
import {
  getFilterValue,
  setFilterValue,
} from "../../../utils/sessionFilters.js";
import { getRegionalFormat } from "../../../utils/sessionHandler.js";

export default function ManageWasherLoadings() {
  const [loads, setLoads] = useState([]);
  const [loadDetail, setLoadDetail] = useState(null);
  const [filters, setFilters] = useState({
    dateFrom: getFilterValue("dateFrom", new Date(), "manageWasherLoadings"),
    dateTo: getFilterValue("dateTo", new Date(), "manageWasherLoadings"),
    cicle: getFilterValue("cicle", "", "manageWasherLoadings"),
    usedProgram: getFilterValue("usedProgram", "", "manageWasherLoadings"),
    status: getFilterValue("status", 5, "manageWasherLoadings"),
    method: getFilterValue("method", "", "manageWasherLoadings"),
    selectedWasher: getFilterValue(
      "selectedWasher",
      "",
      "manageWasherLoadings"
    ),
  });
  const [loadingLoads, setLoadingLoads] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [washers, setWashers] = useState([]);
  const [methods, setMethods] = useState([]);
  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  const getLoads = async () => {
    setLoadingLoads(true);
    try {
      const response = await request().get(
        `/api/Chargewashing/list?includeCount=true&pageSize=${itemsPerPage}&number=${
          filters.cicle
        }&status=${filters.status}&page=${currentPage}&search=${
          filters.usedProgram
        }&method=${filters.method}&washer=${
          filters.selectedWasher
        }&dateFrom=${parseFiltersDate(
          df(filters.dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(filters.dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setLoads(response.data.chargeWashingListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching loads:", error);
    } finally {
      setLoadingLoads(false);
    }
  };

  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/chargeWashing`);
      setWashers(
        response.data.washers.map((item) => ({
          value: item.washerId,
          name: item.name,
        }))
      );
      setMethods(
        response.data.methods.map((item) => ({
          value: item.methodId,
          name: item.methodTranslated,
        }))
      );
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [field]: value };
      setFilterValue(field, value, "manageWasherLoadings");
      return updatedFilters;
    });
  };

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      const clearedFilters = { ...filters, dateFrom: "", dateTo: "" };
      setFilters(clearedFilters);
      setFilterValue("dateFrom", "", "manageWasherLoadings");
      setFilterValue("dateTo", "", "manageWasherLoadings");
    } else {
      handleFilterChange(type, value);
    }
  };

  const onEdit = (chargeWashingId) => {
    localStorage.setItem("loadingEditId", chargeWashingId);
    Redirect({ redirect: true, path: "/appcreatewasherloading" });
  };

  const onDelete = (chargeWashingId) => {
    confirm({
      title: t("DeleteConfirmationTitle"),
      text: t("DeleteConfirmationText"),
      icon: "warning",
      confirmButtonText: t("YesDelete"),
      cancelButtonText: t("Cancel"),
      onConfirm: () => handleDelete(chargeWashingId),
    });
  };

  const handleDelete = (chargeWashingId) => {
    request()
      .delete(`/api/chargeWashing`, { id: chargeWashingId })
      .then(() => {
        setLoads((prevLoads) =>
          prevLoads.filter((load) => load.chargeWashingId !== chargeWashingId)
        );
        setLoadDetail(null);
      });
  };

  const handleDetail = (id) => {
    setLoadDetail(id)
    setIsDetailVisible(true)
  }

  const closeDetail = () => {
    setLoadDetail(null)
    setIsDetailVisible(false)
  }

  useEffect(() => {
    getLoads();
    getParametersLoad();
  }, [filters]);

  const {
    currentPage,
    currentItems,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
  } = useTableQ(
    loads,
    [
      "cicle",
      "creationDate",
      "usedProgram",
      "washerName",
      "methodName",
      "chargeStatus",
    ],
    totalPages
  );

  return (
    <PageContainer
      category={t("WashingSpan")}
      categoryId={2}
      currentStep={4}
      backUrl="/appwashingmenu"
      subcategory={t("WasherLoadingSpan")}
      title={t("ManageWasherLoadingSpan")}
      newButton={!isDetailVisible}
      newButtonUrl="/appcreatewasherloading"
      progress={true}
      subtitle={isDetailVisible ? t("DetailSpan") : null}
      clickBack={isDetailVisible ? () => closeDetail() : null}
      onClickBack={isDetailVisible ? () => closeDetail() : null}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={(e) =>
                  handleFilterChange("cicle", e.target.value)
                }
                placeholder={t("NumberSpan")}
                value={filters.cicle}
                containerWidth="100%"
                category="washing"
              />
               <FilterDate
                handleChange={handleDateChange}
                dateTo={filters.dateTo}
                dateFrom={filters.dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                handleChange={(e) => handleFilterChange("usedProgram", e.target.value)}
                placeholder={t("UsedProgramSpan")}
                value={filters.usedProgram}
                containerWidth="100%"
                category="washing"
              />
              <SelectQ
                options={washers}
                handleSelect={(e) =>
                  handleFilterChange("selectedWasher", e.target.value)
                }
                selectValue={filters.selectedWasher}
                placeholder={t("SelectWasherSpan", locale)}
                hasPlaceholder={true}
                containerWidth="100%"
                category="washing"
              />
              <SelectQ
                options={methods}
                handleSelect={(e) =>
                  handleFilterChange("method", e.target.value)
                }
                selectValue={filters.method}
                placeholder={t("SelectMethodOption")}
                hasPlaceholder={true}
                containerWidth="100%"
                category="washing"
              />
              <SelectQ
                options={[
                  { value: 5, name: t("SelectStatusOption") },
                  { value: 1, name: t("BuildingWashingLoadSpan") },
                  { value: 2, name: t("ToWashSpan") },
                ]}
                handleSelect={(e) =>
                  handleFilterChange("status", e.target.value)
                }
                selectValue={filters.status}
                containerWidth="100%"
                category="washing"
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("cicle")}
                >
                  {t("CycleNumberSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("creationDate")}
                >
                  {t("DateSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("usedProgram")}
                >
                  {t("UsedProgramSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("washerName")}
                >
                  {t("WasherSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("MethodSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeStatus")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {loadingLoads ? (
                <div className="spinnerContainer">
                  <SpinnerWashing />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((load) => (
                    <TableRowQ
                      key={load.chargeWashingId}
                      actions
                      edit={load.chargeStatus === 1}
                      detail
                      deleteRow
                      onEdit={() => onEdit(load.chargeWashingId)}
                      onDelete={() => onDelete(load.chargeWashingId)}
                      onDetail={() => handleDetail(load.chargeWashingId)}
                    >
                      <TableRowCellQ>
                        <TruncatedText
                          text={load.cicle}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={df(load.creationDate, "date")}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={load.usedProgram}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={load.washerName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={load.methodName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[load.chargeStatus === 1 ? "yellow" : "green"]
                          }`}
                        ></span>
                        <TruncatedText
                          text={
                            load.chargeStatus === 1
                              ? t("BuildingWashingLoadSpan")
                              : t("ToWashSpan")
                          }
                          maxLength={25}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}
              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <LoadDetail loadId={loadDetail} locale={locale} />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
