import df from "./dateFormater";
import request from "./request";
import { getSession } from "./sessionHandler";

const print = (packageDetail, id) => {
  const content = `
 ^XA
^JUS
^XZ
^XA
^FX Top section with logo, name and address.
^CF0,60
^CF0,30
^FO146,40^FD${packageDetail?.packageNumber} ${packageDetail?.description}^FS
^FO146,70^FD${packageDetail?.methodName}^FS
^FO146,100^FD ${packageDetail?.operator}^FS
^FO346,70^FD${df(packageDetail?.creationDate, "dateTime")}^FS
^FO146,130^GB570,3,3^FS
^FX Third section with bar code.
^BY2,2,70
^FO146,145^BC^FD${id}${packageDetail?.packageNumber}^FS
^FO146,240^FD${packageDetail?.observations}^FS
^XZ
  `;
  // Crear un documento HTML temporal
  var ventanaImpresion = window.open("", "", "width=600,height=600");
  ventanaImpresion.document.open();

  // Agregar el content del archivo TXT al documento HTML
  ventanaImpresion.document.write("<pre>" + content + "</pre>");

  ventanaImpresion.document.close();

  // Llamar a la función de impresión
  ventanaImpresion.print();

  // Cerrar la ventana temporal después de la impresión
  ventanaImpresion.close();
};

export default function printTicket(packageDetail) {
  const infouser = getSession();
  let user;
  console.log(packageDetail)


  if (infouser.SectorId)
    request()
      .get(`/api/Sector/${infouser.SectorId}`)
      .then((res) => {
        infouser.SectorName = res.data.name;
        print(packageDetail, infouser.IdDistributorCompany.replace(/-/g, ""));
      });
  else return;
}
