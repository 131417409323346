import React, { useEffect, useState } from "react";

import IndicatorLabel from "../../components/IndicatorLabel/IndicatorLabel";
import PackageCard from "../../components/CicleModal/PackageCard";

import indicatorsImg from "../../assets/images/INDICATORS.svg";
import biologicalImg from "../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../assets/images/QUIMICO.svg";
import toStartIcon from "../../assets/images/Icono To start.svg";
import inProgressIcon from "../../assets/images/Icono In progress.svg";
import finishedIcon from "../../assets/images/Icono Finished.svg";
import sterilizerIcon from "../../assets/images/greenSterilizer.svg";

import PersonIcon from "@material-ui/icons/Person";

import trazantoImg from "../../assets/images/QApp.png";

import t from "../../utils/translation";
import df from "../../utils/dateFormater";
import minutesToHourFormat from "../../utils/minutesToHours";
import request from "../../utils/request";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import styles from "./HygieneMonitoringDetail.module.css";

import { CiEdit, CiTimer, CiUser } from "react-icons/ci";
import { GoLink, GoUnlink } from "react-icons/go";
import { Tooltip } from "@material-ui/core";
import { IoReload } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { FaTemperatureHigh } from "react-icons/fa";
import PCDItemCycle from "../../components/PCDItemCycle/PCDItemCycle";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import hasPositive from "../../utils/hasPositive";
import Redirect from "../../utils/Redirect";

const MySwal = withReactContent(Swal);

const HygieneMonitoringDetail = ({
  loadId,
  locale,
  handleIndicator,
  handleFormData,
  openEdit,
  handleLoadId,
  handleIsEdit,
  reloadDetail,
  handleReload,
  packageId,
}) => {
  const [cycleDetail, setCycleDetail] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [indicators, setIndicators] = useState({
    indicators: [],
    finishedIndicators: [],
    linkedIndicators: [],
    unlinkedIndicators: [],
    linkedIndicatorsCH: [],
    unlinkedIndicatorsCH: [],
  });

  const status = (value) => {
    return value === 2
      ? t("ToStartSpan", locale) || "To Start"
      : value === 3
      ? t("StartedSpan", locale) || "Started"
      : t("FinishedSpan", locale) || "Finished";
  };

  const findImg = (value) => {
    return value === 2
      ? toStartIcon
      : value === 3
      ? inProgressIcon
      : finishedIcon;
  };

  const getResultText = (result) => {
    switch (result) {
      case 1:
        return t("PositiveSpan");
      case 2:
        return t("NegativeSpan");
      case 3:
        return t("CanceledSpan");
      default:
        return "";
    }
  };

  const getResultCH = (result) => {
    switch (result) {
      case 3:
        return t("UnsafeSpan");
      case 4:
        return t("SafeSpan");
      default:
        return "";
    }
  };

  const getUserResultCH = (result) => {
    switch (result) {
      case 0:
        return t("AgreeSpan");
      case 1:
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const unlinkHandler = (id, isChemical) => {
    const url = isChemical ? "CHE_unlink" : "BI_Unlink";
    request()
      .put(`/api/Charge/${url}?chargeIndicatorId=${id}`)
      .then(() => getLoadId());
  };

  const handleIndicators = (value) => {
    setIndicators(value);
  };

  const unlinkConfirmation = (id, isChemical = false) => {
    MySwal.fire({
      title: "Are you sure you want to continue?",
      text: "Unlink.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "confirmButton",
        cancelButton: "cancelButton",
      },
      confirmButtonColor: "var(--primary-green)",
      cancelButtonColor: "var(--delete)",
      backgroundColor: "#f4f4f4",
    }).then((result) => {
      if (result.isConfirmed) {
        unlinkHandler(id, isChemical);
      }
    });
  };

  const getLoadId = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/hygiene/${packageId}`)
        .then((response) => {
          const data = response.data;
          setCycleDetail(data);
          const indicators = [...(data.indicators || [])];
          //   const finishedIndicators = [
          //     ...(data.indicators || []),
          //     ...(data.pcdList || []).flatMap((pcd) => [
          //       pcd.biologicalIndicator,
          //       pcd.chemicalIndicator,
          //     ]),
          //   ];
          handleIndicators({
            indicators: indicators,
            // finishedIndicators: finishedIndicators,
            // linkedIndicators: finishedIndicators.filter(
            //   (i) =>
            //     i.readingBi != null &&
            //     ((i.indicator?.name?.toUpperCase() || "").includes("BT") ||
            //       (i.indicatorName?.toUpperCase() || "").includes("BT"))
            // ),
            // unlinkedIndicators: finishedIndicators.filter(
            //   (i) =>
            //     i.readingBi == null &&
            //     ((i.indicator?.name?.toUpperCase() || "").includes("BT") ||
            //       (i.indicatorName?.toUpperCase() || "").includes("BT"))
            // ),
            // linkedIndicatorsCH: finishedIndicators.filter(
            //   (i) =>
            //     i.readingCheSterilizer != null &&
            //     !(
            //       (i.indicator?.name?.toUpperCase() || "").includes("BT") ||
            //       (i.indicatorName?.toUpperCase() || "").includes("BT")
            //     )
            // ),
            // unlinkedIndicatorsCH: finishedIndicators.filter(
            //   (i) =>
            //     i.readingCheSterilizer == null &&
            //     !(
            //       (i.indicator?.name?.toUpperCase() || "").includes("BT") ||
            //       (i.indicatorName?.toUpperCase() || "").includes("BT")
            //     )
            // ),
          });
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  const mappedIndicators =
    cycleDetail.chargeStatus === 4
      ? indicators.finishedIndicators
      : indicators.indicators;

  const onEdit = () => {
    handleFormData(cycleDetail);
    openEdit(true);
    handleLoadId(loadId);
    handleIsEdit(true);
  };

  const handleCycleRecordDetail = (id) => {
    if (cycleDetail.chargeStatus !== 4) return;
    Redirect({
      redirect: true,
      path: `/appcycledetail/${id}`,
    });
  };

  useEffect(() => {
    getLoadId();
  }, []);

  useEffect(() => {
    if (reloadDetail) {
      getLoadId();
      handleReload(false);
    }
  }, [reloadDetail]);

  if (modalLoading) return <ModalLoading open={modalLoading} />;
  return (
    <div className={styles.container}>
         <section className={styles.section}>
          <div className={styles.imageTitleContainer}>
            <img src={chemicalImg} alt="Indicators" className={styles.image} />
            <h2 className={styles.sectionTitle}>
              {t("PackageDetailIndicators", locale)}
            </h2>
          </div>
          {cycleDetail.indicators && cycleDetail.indicators.length === 0 ? (
            <p className={styles.noDataMessage}>{t("NoIndicatorsSpan")}.</p>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {cycleDetail.indicators?.map((indicator) => (
                <div className={styles.indicatorItem}>
                  <IndicatorLabel
                    backgroundColor={"white"}
                    name={indicator.indicator.name}
                    lot={indicator.lot}
                    serial={indicator.serial}
                    quantity={indicator.quantity}
                    expirationDate={indicator.expirationDate}
                    key={indicator.indicatorId} 
                    border={true}
                  />
                </div>
              ))}
            </div>
          )}
        </section>
    </div>
  );
};

export default HygieneMonitoringDetail;
