import React from "react";

import IC1020 from "../../assets/images/IC1020.png";
import FRLCD from "../../assets/images/FRLCD.png";
import PHOTON from "../../assets/images/PHOTON.png";
import HYPER from "../../assets/images/HYPER.png";
import MINIBIO from "../../assets/images/MINIBIO.png";
import MINIPRO from "../../assets/images/MINIPRO.png";
import TwelvePositions from "./twelvePositions/TwelvePositions";
import ThreePositions from "./threePositions/ThreePositions";
import TwoPositions from "./twoPositions/TwoPositions";
import { useState } from "react";
import { useEffect } from "react";
import request from "../../utils/request";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { useContext } from "react";
import TwelvePositionsPro from "./twelvePositions&Pro/TwelvePositionPro";

function ShowIncubator({
  twoPositionsMapped,
  loadingImage,
  incubator,
  twelvePositions,
  threePositions,
  twoPositions,
  handleClick = () => {},
  vinculation,
  position,
  hygiene,
  status
}) {
  const [programs, setPrograms] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const locale = useContext(LocalizationContext);

  useEffect(() => {
    request()
      .get(`/api/program`)
      .then((response) => {
        setPrograms(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const programFiltered = (value) => {
    let program = programs?.find((program) => program.id == value);
    return program ? program.nombre : "-";
  };

  const imgPositionsFiltered = (value) => {
    const condition = value.toUpperCase();
    setSelectedImage(condition);
    return condition === "PHOTON"
      ? PHOTON
      : condition === "HYPER"
      ? HYPER
      : condition === "MINIPRO"
      ? MINIPRO
      : condition === "MINIBIO"
      ? MINIBIO
      : condition === "IC1020FR"
      ? IC1020
      : condition === "FRLCD"
      ? FRLCD
      : null;
  };

  const colorStatus = (value) => {
    return value === 1 ? "#ffc107" : "#20c997";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        heigh: "100%",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "5px",
          height: "550px",
          width: "100%",

          backgroundColor: "#fff",

          "@media (max-width: 1060px)": {
            height: "500px",
          },
          "@media (max-width: 680px)": {
            height: "300px",
            width: "300px",
          },
        }}
      >
       {hygiene ? (
            <TwelvePositionsPro
              incubator={incubator}
              loadingImage={loadingImage}
              imgPositionsFiltered={imgPositionsFiltered}
              twelvePositions={twelvePositions}
              programFiltered={programFiltered}
              colorStatus={colorStatus}
              t={t}
              locale={locale}
              condition={selectedImage}
              handleClick={handleClick}
              vinculation={vinculation}
              position={position}
            />
          ) : (
            <TwelvePositions
              incubator={incubator}
              loadingImage={loadingImage}
              imgPositionsFiltered={imgPositionsFiltered}
              twelvePositions={twelvePositions}
              programFiltered={programFiltered}
              colorStatus={colorStatus}
              t={t}
              locale={locale}
              condition={selectedImage}
              handleClick={handleClick}
              vinculation={vinculation}
              position={position}
            />
          )}
        <ThreePositions
          incubator={incubator}
          loadingImage={loadingImage}
          imgPositionsFiltered={imgPositionsFiltered}
          threePositions={threePositions}
          programFiltered={programFiltered}
          colorStatus={colorStatus}
          t={t}
          locale={locale}
          handleClick={handleClick}
          vinculation={vinculation}
          position={position}
        />
        <TwoPositions
          incubator={incubator}
          loadingImage={loadingImage}
          imgPositionsFiltered={imgPositionsFiltered}
          twoPositionsMapped={twoPositionsMapped}
          twoPositions={twoPositions}
          programFiltered={programFiltered}
          colorStatus={colorStatus}
          t={t}
          status={status}
          locale={locale}
          handleClick={handleClick}
          vinculation={vinculation}
          position={position}
        />
      </div>
    </div>
  );
}

export default ShowIncubator;
