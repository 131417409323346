import React from "react";
import styles from "./TableRowQ.module.css";

import editImg from "../../../assets/images/EDIT.svg";
import printImg from "../../../assets/images/PRINT.svg";
import deleteImg from "../../../assets/images/GARBAGE.svg";
import { IoSearch } from "react-icons/io5";
import { GoLink, GoUnlink } from "react-icons/go";

export default function TableRowQ({
  children,
  onClick,
  actions = false,
  edit = false,
  onEdit = () => {},
  print = false,
  onPrint = () => {},
  deleteRow = false,
  onDelete = () => {},
  onDetail = () => {},
  doubleClick = () => {},
  detail = false,
  progress,
  vinculation = false,
  onVinculation = () => {},
  tubeResultPRO
}) {
  const handleActionClick = (event, action) => {
    event.stopPropagation();
    action();
  };

  return (
    <div
      className={styles.bodyRow}
      onDoubleClick={doubleClick}
      onClick={onClick}
    > 
    {progress && <div className={styles.progress}>
    {progress}
  </div>}
      <div className={styles.content}>
        {children}
      </div>
      {actions && (
        <div className={styles.actionsCell}>
          {edit && (
            <div
              className={`${styles.buttonContainer} ${styles.green}`}
              onClick={(event) => handleActionClick(event, onEdit)}
            >
              <img src={editImg} alt="Edit" />
            </div>
          )}
          {print && (
            <div
              className={`${styles.buttonContainer} ${styles.green}`}
              onClick={(event) => handleActionClick(event, onPrint)}
            >
              <img src={printImg} alt="Print" />
            </div>
          )}
          {deleteRow && (
            <div
              className={`${styles.buttonContainer} ${styles.red}`}
              onClick={(event) => handleActionClick(event, onDelete)}
            >
              <img src={deleteImg} alt="Delete" />
            </div>
          )}
          {detail && (
            <div
              className={`${styles.buttonContainer} ${styles.green}`}
              onClick={(event) => handleActionClick(event, onDetail)}
            >
              <IoSearch style={{ color: 'white', fontSize: '1.25rem' }}>
              </IoSearch>
            </div>
          )}
           {vinculation && (
              <div
                className={`${styles.buttonContainer} ${styles.green}`}
                onClick={(event) => handleActionClick(event, onVinculation)}
              >
                {tubeResultPRO !== null ? (
                  <GoUnlink style={{ color: 'white', fontSize: '1.25rem' }} />
                ) : (
                  <GoLink style={{ color: 'white', fontSize: '1.25rem' }} />
                )}
              </div>
           )}
        </div>
      )}
    </div>
  );
}
