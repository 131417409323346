// Librerías de terceros
import React, { useState, useEffect, useContext } from "react";
import { getRegionalFormat, getUserId } from "../../utils/sessionHandler";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import Redirect from "../../utils/Redirect";

// Estilos e imágenes
import styles from "./Sterilization.module.css";
import pkgImg from "../../assets/images/MENU PACKAGE.svg";
import loadImg from "../../assets/images/MENU LOAD.svg";
import sterilizerImg from "../../assets/images/MENU STERILIZATORS.svg";
import autoReadersImg from "../../assets/images/MENU AUTOREADERS.svg";

// Componentes locales
import PageContainer from "../../components/PageContainer/PageContainer";
import MenuTitleQ from "../../components/MenuQ/MenuTitleQ/MenuTitleQ";
import MenuItemQ from "../../components/MenuQ/MenuItemQ/MenuItemQ";
import InfoBar from "../../components/MenuQ/infoBar/InfoBar";
import ButtonSterilization from "../../components/ButtonSterilization";
import df from "../../utils/dateFormater";
import parseFiltersDate from "../../utils/parseFiltersDate";
import ButtonQ from "../../components/ButtonQ";

export default function Sterilization() {
  const [incubators, setIncubators] = useState([]);
  const [inProgressPackages, setInProgressPackages] = useState([]);
  const [finishedPackages, setFinishedPackages] = useState([]);
  const [savedLoads, setSavedLoads] = useState([]);
  const [toStartLoads, setToStartLoads] = useState([]);
  const [inProgressLoads, setInProgressLoads] = useState([]);
  const [finishedLoads, setFinishedLoads] = useState([]);

  const locale = useContext(LocalizationContext);

  const getPackages = async () => {
    await request()
      .get(
        `/api/Package/list?includeCount=${true}&pageSize=${1000000000}&dateFrom=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      )
      .then((response) => {
        setInProgressPackages(
          response.data.packageList.filter((p) => p.packageStatus === 1)
        );
        setFinishedPackages(
          response.data.packageList.filter((p) => p.packageStatus === 2)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLoads = async () => {
    request()
      .get(
        `/api/Charge/list?includeCount=${true}&pageSize=${1000000000}&dateFrom=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      )
      .then((response) => {
        setSavedLoads(
          response.data.chargeListatorList.filter((c) => c.chargeStatus === 1)
        );
        setToStartLoads(
          response.data.chargeListatorList.filter((c) => c.chargeStatus === 2)
        );
        setInProgressLoads(
          response.data.chargeListatorList.filter((c) => c.chargeStatus === 3)
        );
        setFinishedLoads(
          response.data.chargeListatorList.filter((c) => c.chargeStatus === 4)
        );
      })
      .catch((error) => console.log(error));
  };

  const getIncubators = async () => {
    request()
      .get(`/api/Incubator?${getUserId()}`)
      .then((response) => {
        setIncubators(response.data.filter((i) => i.status === 1));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getIncubators();
    getPackages();
    getLoads();
  }, []);

  return (
    <PageContainer
      categoryId={1}
      category={t("SterilizationSpan")}
      backUrl={"/appmainQ"}
      progress={true}
    >
      <div className={styles.container}>
        <div className={styles.columnContainer}>
          <div className={styles.column}>
            <MenuTitleQ title={t("PackagesTitle", locale)} image={pkgImg} />
            <MenuItemQ
              title={t("ManagePackagesTitle", locale)}
              url={"/appmanagepackages"}
              addUrl={"/appcreatepackages"}
            >
              <InfoBar
                title={t("PackageAssemblingStatusTitle", locale)}
                color={"yellow"}
                data={inProgressPackages}
              />
              <InfoBar
                title={t("PackageReadyForLoadStatusTitle", locale)}
                color={"green"}
                data={finishedPackages}
              />
            </MenuItemQ>
          </div>
          <div className={styles.column}>
            <MenuTitleQ title={t("LoadsTitle", locale)} image={loadImg} />
            <MenuItemQ
              title={t("ManageLoads", locale)}
              url={"/appmanageloads"}
              addUrl={"/appcreateloads"}
            >
              <InfoBar
                title={t("LoadsBuildingTitle", locale)}
                color={"yellow"}
                data={savedLoads}
              />
              <InfoBar
                title={t("LoadsToSterilizeTitle", locale)}
                color={"green"}
                data={toStartLoads}
              />
            </MenuItemQ>
          </div>
          {/* <div className={styles.column}>
            <MenuTitleQ title={"Sterilizers"} image={sterilizerImg} />
            <MenuItemQ title={"Manage Sterilizers"}>
              <InfoBar
                title={"In use"}
                color={"yellowInverted"}
                data={[1, 2]}
              />
              <InfoBar title={"Ready"} color={"green"} data={[1]} />
              <InfoBar title={"Not ready"} color={"red"} data={[1]} />
            </MenuItemQ>
          </div> */}
          <div className={styles.column}>
            <MenuTitleQ title={t("CyclesTitle", locale)} image={loadImg} />
            <MenuItemQ
              title={t("ManageCyclesTitle", locale)}
              add={false}
              url={"/appmanagecycles"}
            >
              <InfoBar
                title={t("CycleToStart", locale)}
                color={"green"}
                data={toStartLoads}
              />
              <InfoBar
                title={t("CycleInProgressDetail", locale)}
                color={"yellow"}
                data={inProgressLoads}
              />
              <InfoBar
                title={t("CycleFinishedTitle", locale)}
                color={"greenInverted"}
                data={finishedLoads}
              />
            </MenuItemQ>
          </div>
        </div>
        <div></div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonQ
          caption={t("ConsultPackages", locale)}
          onClick={() =>
            Redirect({
              redirect: true,
              path: "/appconsultpackage",
            })
          }
          color="secondary"
          isVerticalMargined={true}
        />
        <ButtonQ
          caption={t("BatchRecordTitle", locale)}
          onClick={() =>
            Redirect({
              redirect: true,
              path: "/appcyclerecord",
            })
          }
          color="secondary"
          isVerticalMargined={true}
        />
        <ButtonQ
          caption={t("AutoReaderDevicesTitle", locale)}
          onClick={() =>
            Redirect({
              redirect: true,
              path: "/appautoreaders",
            })
          }
          color="secondary"
          isVerticalMargined={true}
        />
        <ButtonQ
          caption={t("BlankEntry3", locale)}
          onClick={() =>
            Redirect({
              redirect: true,
              path: "/apprules",
            })
          }
          color="secondary"
          isVerticalMargined={true}
        />
      </div>
    </PageContainer>
  );
}
