import React, { useState } from "react";
import df from "../../utils/dateFormater";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import TableRowCellQ from "../TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TableRowQ from "../TableBionovaQ/TableRowQ/TableRowQ";
import minutesToHourFormat from "../../utils/minutesToHours";

const TableRowItemIS = ({ rbi, onLink }) => {
  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 1:
        return "#E2A60A";
      case 3:
        return "#00b189";
      case 4:
        return "#0E579A";
      default:
        return "";
    }
  };

  const getResultBackgroundColor = (result) => {
    switch (result) {
      case 0: // NO_RESULT
        return "#D6D6D6";
      case 1: // NEGATIVE
        return "#9EEF6C";
      case 2: // CANCELED
        return "#A0A0A0";
      case 3: // POSITIVE
        return "#FF5C5C";
      case 4: // NEGATIVE_WITHOUT_PENCIL
        return "#909090";
      case 263: // PEN_NOT_VALID
        return "#B0B0B0";
      case 770: // PEN_PULLED_OUT
        return "#808080";
      case 10: // ALERT
        return "#FFD40E";
      case 11: // TO_TAKE_ACTION
        return "#F50000";
      case 12: // ASSOCIATE_AREA
        return "#707070";
      default:
        return "";
    }
  };

  const getResultText = (result) => {
    switch (result) {
      case null:
        return "No result";
      case 1:
        return "Negative";
      case 2:
        return "Cancelled";
      case 3:
        return "Positive";
      case 4:
        return "Negative without pencil";
      case 263:
        return "Pen not valid";
      case 770: 
        return "Pen pulled out"
      case 10: 
        return "Alert"
      case 11: 
        return "Action"
      case 12: 
        return "Associate area"
      default:
        return "";
    }
  };

  const getStatusText = (result) => {
    switch (result) {
      case 1:
        return "IN PROGRESS";
      case 3:
        return "FINISHED";
      default:
        return "";
    }
  };

  return (
    <TableRowQ key={rbi.id} onClick={() => onLink(rbi.id)}>
      <TableRowCellQ>
        <span
          style={{
            backgroundColor: getStatusBackgroundColor(rbi.status),
            padding: "5px 0",
            width: "100%",
            borderRadius: "5px",
            color: "white",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {getStatusText(rbi.status)}
        </span>
      </TableRowCellQ>
      <TableRowCellQ>{rbi.readNumber}</TableRowCellQ>
      <TableRowCellQ>
        <span
          style={{
            backgroundColor: getResultBackgroundColor(rbi.resultStatus),
            padding: "5px 0",
            width: "100%",
            borderRadius: "5px",
            color: "white",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {getResultText(rbi.result)}
        </span>
      </TableRowCellQ>
      <TableRowCellQ>{df(rbi.startedTime, "dateTime")}</TableRowCellQ>
      <TableRowCellQ>{df(rbi.resultDate, "dateTime")}</TableRowCellQ>
      <TableRowCellQ>{rbi.averageTemperatureProFormat}</TableRowCellQ>
      <TableRowCellQ>{minutesToHourFormat(rbi.incubationTime)}</TableRowCellQ>
      {/* <TableRowCellQ>{selectedIndicator.relatedWasherProgram}</TableRowCellQ> */}
      <TableRowCellQ>{rbi.proteinFormat}</TableRowCellQ>
    </TableRowQ>
  );
};

export default TableRowItemIS;
