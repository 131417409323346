// SterilizationStepper.jsx

import React from "react";
import Redirect from "../../../utils/Redirect";
import styles from "./SterilizationStepperStyles.module.css";
import t from "../../../utils/translation";

export default function SterilizationStepper({ currentStepString }) {
  const steps = ["Packages", "Loads", "Cycles"];

  const stepUrls = {
    Packages: "/appmanagepackages",
    Loads: "/appmanageloads",
    Cycles: "/appmanagecycles",
  };

  const currentIndex = steps.indexOf(currentStepString);

    const getSwitchValue = (caseValue) => {
      switch (caseValue) {
        case "Packages":
          return t("PackageSpan") || "Packages";
        case "Loads":
          return t("BlankEntry30") || "Loads";
        case "Cycles":
          return t("BlankEntry31") || "Cycles";
        default:
          return null; 
      }
    };

  return (
    <nav className={styles.nav}>
      {steps.map((stepName, index) => {
        let stepClass = styles.link;

        if (index < currentIndex) {
          stepClass += ` ${styles.done}`;
        } else if (index === currentIndex) {
          stepClass += ` ${styles.current}`;
        }

        const handleClick = (e) => {
          e.preventDefault();
          Redirect({ redirect: true, path: stepUrls[stepName] || "#" });
        };

        return (
          <span
            key={stepName}
            className={stepClass}
            onClick={handleClick}
            aria-current={index === currentIndex ? "step" : undefined}
          >
            {getSwitchValue(stepName)}
          </span>
        );
      })}
    </nav>
  );
}
