import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import request from "../../utils/request.js";
import t from "../../utils/translation.js";

import SearchMaterial from "../../components/SearchMaterial/SearchMaterial.js";
import CrudIndicator from "../../components/CrudIndicator/CrudIndicator.jsx";

import BarcodeReader from "react-barcode-reader";

import { parseBarcode } from "../../utils/BarcodeParser.js";
import DragAndDropCharges from "../../components/DragAndDropCharges/DragAndDropCharges.js";
import LocalizationContext from "../../utils/LocalizationContext.js";
import { v4 as uuidv4 } from "uuid";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import { getSession, getUserCompany } from "../../utils/sessionHandler.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import PageContainer from "../../components/PageContainer/PageContainer.jsx";

import styles from "./CreateHygieneMonitoring.module.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TextAreaQ from "../../components/FormComponents/TextAreaQ/TextAreaQ.jsx";
import ModalLoading from "../../components/ModalLoading/ModalLoading.js";
import SelectorMaterialHygiene from "../../components/SelectorMaterialHygiene/SelectorMaterialHygiene.js";
import { Checkbox } from "@material-ui/core";
import Redirect from "../../utils/Redirect";
import VinculationPro from "../../components/VinculationPro/VinculationPro.jsx";
import Search from "../../components/Search/Search.js";

const MySwal = withReactContent(Swal);

export default function CreateHygieneMonitoring( {location} ) {
  const [indicators, setIndicators] = useState([]);
  const [usedProgram, setUsedProgram] = useState("");
  const [cycleNumber, setCycleNumber] = useState("");
  const [observations, setObservations] = useState(null);
  const [packages, setPackages] = useState([]);
  const [editPackages, setEditPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState("");
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFilteredPackages, setSelectedFilteredPackages] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [editId, setEditId] = useState(null);
  const [sterilizers, setSterilizers] = useState([]);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const [validate, setValidate] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [methodName, setMethodName] = useState("");
  const [modelAreas, setModelAreas] = useState([]);
  const [methodId, setMethodId] = useState(null);
  const [stopButtons, setStopButtons] = useState(false);
  const [idDistributorCompany, setIdDistributorCompany] = useState(null);
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [packageQuantity, setpackageQuantity] = useState(1000000);
  const [clearSearch, setClearSearch] = useState(null);
  const [Washers, setWashers] = useState(null);
  const [selectedWasher,setSelectedWasher] = useState();
  const [vinculation, setVinculation] = useState()
  const [filters, setFilters] = useState()
  const [error, setError] = useState(null);
  const [relatedChargeWashingId,setRelatedChargeWashingId] = useState("")
  const [isEndoscope,setIsEndoscope] = useState()
  const [chargeId, setChargeId] = useState(null)
  const [selectedIndicator, setSelectedIndicator] = useState([])
  const [reload, setReload] = useState(false)
  const locale = useContext(LocalizationContext);

  const usedProgramRef = useRef();
  const cycleRef = useRef();
  const observationsRef = useRef();

const validateFields = (status) => {
    let notValid = false;
if (status === 1) {
  if (!selectedWasher || selectedWasher === "") {
    notValid = true;
  }
  if (!cycleNumber || cycleNumber === "") {
    notValid = true;
  }
  if (!selectedPackages || selectedPackages.length === 0) {
    notValid = true;
  }

} else {
  if (!selectedWasher || selectedWasher === "") {
    notValid = true;
  }

  if (!cycleNumber || cycleNumber === "") {
    notValid = true;
  }

  if (!selectedPackages || selectedPackages.length === 0) {
    notValid = true;
  }

  if (!indicators || indicators.length === 0) {
    notValid = true;
  }
}

    return notValid;
  };

  // const getPackages = async (method, addPackages) => {
  //   setModalLoading(true);
  //   let filter = addPackages.filter((p) => p.methodId == method);
  //   try {
  //     if (!method) {
  //       setPackages([]);
  //     } else {
  //       const response = await request().get(
  //         `/api/Package/list?&pageSize=${packageQuantity}&Status=2&isInCharge=${false}&method=${method}`
  //       );
  //       setPackages([...filter, ...response.data.packageList]);
  //     }
  //   } catch (e) {
  //     localStorage.removeItem("packageEditId");
  //   }
  //   setModalLoading(false);
  // };


  // const fetchMaterials = async (filters) => {
  //   setModalLoading(true);

  //   try {

  //     const response = await request().get(
  //       `/api/Material/byWashingCycle/92` 
  //     );

  //     setFilteredPackages(response.data);
  //   } catch (error) {
  //     console.error("Error fetching packages:", error);
  //   } finally {
  //     setModalLoading(true);
  //   }
  // };

  
   const fetchMaterials = async (cycleNumber) => {
    setModalLoading(true);
    setError(null); 
    try {
      const response = await request().get(`/api/Material/byWashingCycle?CycleNumber=${cycleNumber}&washerId=${selectedWasher}`);
      setPackages(response.data);
      const relatedChargeWashingId = response.data.length > 0 ? response.data[0].relatedChargeWashingId : null;
      setRelatedChargeWashingId(relatedChargeWashingId);
    } catch (error) {
      console.error("Error fetching materials:", error);
      setError("Error al obtener los materiales."); 
    } finally {
      setModalLoading(false);
    }
  };

  const getModelAreas = async (id) => {
    try {
      const response = await request().get(`/api/ModelAreas/${id}`);

      return setModelAreas(response.data);
    } catch (error) {
      console.error(`Error al obtener model areas para el id ${id}:`, error);
      return [];
    }
  };

  // const onHandleScan = async (data) => {
  //     let itemMaterial = packages.find((m) => m.serial == data);
  //     let materialCondition = selectedPackages.find((m) => m.serial == data);
  //     if (itemMaterial) {
  //       setScanCode(data);
  //       if (materialCondition) {
  //         setSelectedPackages(
  //           selectedPackages.filter((m) => m != itemMaterial)
  //         );
  //         toast.success(t("MaterialRemoved", locale) || "Material removed");
  //       } else {
  //         setSelectedPackages([...selectedPackages, itemMaterial]);
  //         toast.success(t("MaterialAdded", locale) || "Material added");
  //       }
  //     }
  //   }

  // const getParametersLoad = async () => {
  //   try {
  //     const response = await request().get(`/api/parameter/charge`);

  //     const sterilizers = response.data.sterilizers.map((item) => ({
  //       value: item.sterilizerId,
  //       name: item.name,
  //       methodName: item.sterilizerMethodName,
  //       methodId: item.sterilizerMethodId,
  //       companyMachineModelId: item.companyMachineModelId,
  //     }));

  //     setSterilizers(sterilizers);
  //   } catch (error) {
  //     console.error("Error al obtener esterilizadores:", error);
  //   }
  // };

  const getParametersCreate = async () => {
    try {
      const response = await request().get(`/api/parameter/chargeWashing`);

      const washers = response.data.washers.map((item) => ({
        value: item.washerId,
        ...item,
      }));

      setWashers(washers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  const hygieneEditId = location?.state?.hygieneEditId

  const getEditCharges = () => {
    try {
      setModalEditLoading(true);

      request()
        .get(`/api/hygiene/${hygieneEditId}`)
        .then((response) => {

          // if (response.data.id) localStorage.removeItem("hygieneEditId");
          setSelectedWasher(response.data.washerId)
          setCycleNumber(response.data.relatedCicleNumber);
          fetchMaterials(response.data.relatedCicleNumber)
          setRelatedChargeWashingId(response.data.chargeWashingId)
          setSelectedPackages(
            response.data.materials.map((m) => ({
              ...m,
              name: m.material.name,
              serial: m.material.serial,
              materialId: m.materialId,
            }))
          );
          setEditId(response.data.id);
          setObservations(response.data.description);
           setIndicators(
                      response.data.indicators.map((i) => ({
                        ...i,
                        indicator: { name: i.indicator.name },
                        indicator_Id: i.indicatorId,
                        uuid: uuidv4(),
                      }))
                    );
          location.state.hygieneEditId = null;
        });
    } catch (e) {
      location.state.hygieneEditId = null;
      setEditId(null)
    } finally {
      setModalEditLoading(false);
    }
  };

  const handleSearchActive = (bool) => setSearchActive(bool);

  const handleIndicators = (data) => {
    setIndicators(data);
  };

  const handleSelectedIndicator = (data) => {
    setSelectedIndicator(data);
  };
  const handleChangeObservations = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setObservations(item);
  };


  const handleCycleNumber = async (e) => {
    const newCycleNumber = e.target.value;
    setCycleNumber(newCycleNumber); 

    if (newCycleNumber.trim() === "") {
      setFilteredPackages(""); 
      setSelectedPackages([])
      setPackages([])
      return;
    }
    fetchMaterials(newCycleNumber);
  };

  //  const handleFilterChange = (key, value) => {
  //   const inputValue =  value;

  //   if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
  //     setCycleNumber(inputValue);
  //   }
  //     const updatedFilters = { ...filters, [key]: value };
  //     setFilters(updatedFilters);
  //     fetchMaterials(inputValue);
  //   };

  const handleFilteredPackages = (data) => {
    setFilteredPackages(data);
  };
  const handleSelectedPackages = (data) => {
    setSelectedPackages(data);
    if (data) {
      setIsEndoscope(data.isEndoscope)
    } else {
      setIsEndoscope(null)
    }
  };
  const handleSelectedFilteredPackages = (data) => {
    setSelectedFilteredPackages(data);
  };


  const handleChangeWasher = (e) => {
    const selectedValue = e.target.value;
    const selectedWasher = Washers?.find((s) => s.value == selectedValue);
      setSelectedWasher(selectedValue);
      setCycleNumber("");
      setFilteredPackages("")
      setPackages([])

      // getModelAreas(selectedWasher?.companyMachineModelId);
      // setMethodName(!selectedValue ? "" : selectedWasher.washerMethodName);
      // setMethodId(!selectedValue ? "" : selectedWasher.washerMethodId);
      // clearFields(false);
  };

  const handleClearSearch = (data) => {
    setClearSearch(data);
  };

  const handleReload = async (shouldRedirect) => {
    if (shouldRedirect) {
      Redirect({
        redirect: true,
        path: "/appmanagehygienemonitoring",
      })
    }
  };


  const getIdDistributorCompany = () => {
    const infouser = getSession();

    if (infouser.SectorId)
      request()
        .get(`/api/Sector/${infouser.SectorId}`)
        .then((res) => {
          infouser.SectorName = res.data.name;
          setIdDistributorCompany(
            infouser.IdDistributorCompany.replace(/-/g, "")
          );
        });
    else return;
  };

  // const createCharge = async (status) => {
  //   const newMaterials = [
  //     {
  //       materialId: selectedPackages.id,
  //       quantity: 1,
  //     }
  //   ];
  
  //   const newIndicators = indicators.map((indicator) => ({
  //     indicatorId: indicator.indicator_Id,
  //     quantity: indicator.quantity || 1,
  //     lot: indicator.lot || null,
  //   }));
  
  //   const requestData = {
  //     Description: observations,
  //     Materials: newMaterials,
  //     Indicators: newIndicators,
  //     WasherId: selectedWasher,
  //     ChargeWashingId: relatedChargeWashingId,
  //     status: status === 1 ? 1 : 4
  //   };

  //   try {
  //     setModalLoading(true);
  //     setStopButtons(true);
    
  //     if (!editId) {
  //       // Enviar el POST para crear la carga
  //       const response = await request().post("/api/hygiene", requestData); 
        
  //       // Obtener el ID de la carga recién creada
  //       const chargeId = response.data;
  //       setChargeId(chargeId);
  //       setEditId(chargeId)
    
  //       // Solo si el status es distinto de 1, hacemos el GET para obtener más información
  //       if (status !== 1) {
  //         const detailsResponse = await request().get(`/api/hygiene/${chargeId}`);
  //         // Procesar los detalles de la carga
  //         setSelectedIndicator(detailsResponse.data.indicators);
  //         setVinculation(true);
  //       }
    
  //       // Mostrar un mensaje de éxito según el estado
  //       status === 1
  //         ? toast.success("Testing saved successfully")
  //         : toast.success("Testing completed successfully");
    
  //     } else {
  //       // Enviar un PUT si estamos editando una carga existente
  //       requestData.id = editId;
  //       await request().put("/api/hygiene", requestData); 
  //       toast.success("Testing modified successfully");
  //     }
    
  //     // Limpiar campos si el estado no es 1
  //     if (status !== 1) {
  //       clearFields(true);
  //     }
    
  //   } catch (e) {
  //     // Manejo de errores
  //     toast.error(e.response?.data?.message || "An error occurred");
  //   } finally {
  //     setModalLoading(false);
  //     setStopButtons(false);
  //   }
  // };
  const createCharge = async (status) => {
    const newMaterials = [
      {
        materialId: selectedPackages.id,
        quantity: 1,
      }
    ];

    const newIndicators = indicators.map((indicator) => ({
      indicatorId: indicator.indicator_Id,
      quantity: indicator.quantity || 1,
      lot: indicator.lot || null,
    }));

    const requestData = {
      Description: observations,
      Materials: newMaterials,
      Indicators: newIndicators,
      WasherId: selectedWasher,
      ChargeWashingId: relatedChargeWashingId,
      status: status === 1 ? 1 : 4,
    };

    try {
      setModalLoading(true);
      setStopButtons(true);

      if (!editId) {
        // Si no existe editId, hacer un POST para crear la carga
        await request()
          .post("/api/hygiene", requestData)
          .then((res) => {
            const chargeId = res.data;
            setChargeId(chargeId);

            // Solo si el status no es 1, obtener más detalles
            if (status !== 1) {
              request()
                .get(`/api/hygiene/${chargeId}`)
                .then((detailsRes) => {
                  setSelectedIndicator(detailsRes.data.indicators);
                  setVinculation(true);
                });
            }

            // Mostrar mensaje de éxito según el estado
            status === 1
              ? toast.success("Testing saved successfully")
              : toast.success("Testing completed successfully");
          });
      } else {
        // Si editId existe, hacer un PUT para editar la carga existente
        requestData.id = editId;
        await request()
          .put("/api/hygiene", requestData)
          .then(() => {
            toast.success("Testing modified successfully");
          });
      }

      // Limpiar campos si el estado no es 1
      if (status !== 1) {
        clearFields(true);
      }

    } catch (e) {
      toast.error(e.response?.data?.message || "An error occurred");
    } finally {
      setModalLoading(false);
      setStopButtons(false);
    }
};

  const clearFields = (sterilizerChange = true) => {
    sterilizerChange && setUsedProgram("");
    sterilizerChange && setEditId(null);
    sterilizerChange && setSelectedSterilizer("");
    sterilizerChange && setMethodName("");
    sterilizerChange && setMethodId(null);
    setCycleNumber("");
    setObservations("");
    setIndicators([]);
    setFilteredPackages("")
    setSelectedPackages([]);
    setPackages([]);
    setSelectedWasher([])
  };


  useEffect(() => {
    getEditCharges();
    // getParametersLoad();
    getIdDistributorCompany();
    getParametersCreate()
  }, [selectedWasher]);


  const showValidationErrors = (status) => {

    if (status === 1) {

      
    if (!selectedWasher || selectedWasher === "") {
      toast.error(
        t("YouMustSelectWasher") || "You must select a Washer"
      );
    }

    if (!cycleNumber || cycleNumber === "") {
      toast.error(
        t("YouMustSelectCycleNumber") || "You must select a Cycle Washer"
      );
    }

    if (!selectedPackages || selectedPackages.length === 0) {
      toast.error(
        t("YouMustSelectMaterial") || "You must select a Material"
      );
    }
    } else {

      if (!selectedWasher || selectedWasher === "") {
        toast.error(
          t("YouMustSelectWasher") || "You must select a Washer"
        );
      }
  
      if (!cycleNumber || cycleNumber === "") {
        toast.error(
          t("YouMustSelectCycleNumber") || "You must select a Cycle Washer"
        );
      }
  
      if (!selectedPackages || selectedPackages.length === 0) {
        toast.error(
          t("YouMustSelectMaterial") || "You must select a Material"
        );
      }
  
      if (!indicators || indicators.length === 0) {
        toast.error(
          t("YouMustSelectIndicators") || "You must select a Indicators"
        );
      }
  
    }
  };

  const handleButtonClick = async (status) => {
    const notValid = validateFields(status);

    if (notValid) {
      setValidate(notValid);
      showValidationErrors(status);
    } else 
      await createCharge(status); 
      // if (status === 2 && !notValid) {
      //   setVinculation(true); 
      // }
  };

  const isSaveDisabled = useMemo(
    () => validateFields(1),
    [selectedWasher,cycleNumber,selectedPackages]
  );
  const isFinishDisabled = useMemo(
    () => validateFields(2),
    [selectedWasher, cycleNumber, indicators,selectedPackages]
  );


  return (
    <PageContainer
      category={"Hygiene Monitoring"}
      title={vinculation ? "Linking Indicators" : "Create"}
      backUrl={"/appmanagehygienemonitoring"}
      scan={true}
      progress={false}
    >
    {vinculation ? (
        <VinculationPro
          selectedIndicator={selectedIndicator}
          reloadDetail={reload}
          handleReload={setReload}
          handleSelectedIndicator={handleSelectedIndicator}
          chargeId={chargeId}
          idManage={false}
        />
      ) : (
        <div>
      {/* {!modalEditLoading && !modalLoading && (
        <BarcodeReader onScan={onHandleScan} />
      )} */}
      <ModalLoading open={modalEditLoading} />
      <ModalLoading open={modalLoading} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "68%",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <InputQ
                label={t("UsedProgramSpan") || "Used Program"}
                handleChange={handleUsedProgram}
                value={usedProgram}
                containerWidth={"100%"}
                inputRef={usedProgramRef}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
                onHandleScan={onHandleScan}
              /> */}
              {/* <InputQ
                label={t("CycleNumberSpan") || "Cycle Number"}
                handleChange={handleCycleNumber}
                value={cycleNumber}
                containerWidth={"100%"}
                inputRef={cycleRef}
                onHandleScan={onHandleScan}
              /> */}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <SelectQ
                selectValue={selectedSterilizer}
                handleSelect={handleChangeSterilizer}
                options={sterilizers}
                label={t("SelectASterilizerOption") || "Select a Sterilizer"}
                placeholder={
                  t("SelectASterilizerOption") || "Select a Sterilizer"
                }
                containerWidth={"50%"}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
              /> */}
               <SelectQ
                 selectValue={selectedWasher}
                  handleSelect={handleChangeWasher}
                  options={Washers}
                  label={t("SelectWasherSpan") || "Select a Washer"}
                  placeholder={t("SelectWasherSpan") || "Select a Washer"}
                  containerWidth={"40%"}
                  required={true}
                  errorMessage={
                    t("RequiredFieldError") || "This field is required"
                  }
                  validate={validate}
                  category={"hygieneMonitoring"}
                />
              <InputQ
                label={t("CycleNumberSpan") || "Cycle Number"}
                value={cycleNumber}
                containerWidth={"40%"}
                inputRef={cycleRef}
                category={"hygieneMonitoring"}
                handleChange={handleCycleNumber}
                disabled={selectedWasher ? false : true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
              />
                <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <label className={styles.printLabel}>
                  {"Ignore From IQAS"}
                </label>
                <Checkbox
                  type="checkbox"
                  // checked={printLabel}
                  // onChange={handlePrintLabel}
                />
              </div>
            </div>
          </div>

          <SelectorMaterialHygiene
            filter1={
              <SearchMaterial
                data={packages}
                handleFilter={handleFilteredPackages}
                placeholder={"Search material to include..."}
                searchActive={() => {}}
                scanCode={scanCode}
                handleClear={handleClearSearch}
                clearData={clearSearch}
              />
            }
            data={filteredPackages}
            indicators={indicators}
            editId={editId}
            selectedData={selectedPackages}
            selectedFilteredData={
              searchActive ? selectedFilteredPackages : selectedPackages
            }
            handleSelectedData={handleSelectedPackages}
            // leftColumnName={t("MaterialToInclude", locale) || "Material to include"}
            // rightColumnName={t("MaterialIncluded", locale) || "Material included"}
          />
        </div>
        <div style={{ width: "30%", minHeight: "100%", height: "30rem" }}>
          <CrudIndicator
            indicators={indicators}
            handleIndicators={handleIndicators}
            modelAreas={modelAreas}
            zone={true}
            category={"hygieneMonitoring"}
            isEndoscope={isEndoscope}
            condition={"a washer, cycle number and material"}
            select={selectedWasher}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <TextAreaQ
          label={t("ObservationSpan")}
          handleChange={handleChangeObservations}
          value={observations}
          width={"68%"}
          inputRef={observationsRef}
          category={"hygieneMonitoring"}
        />

        <div className={styles.containerButtons}>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => clearFields()}
          >
            {t("ClearFields", locale) || "Clear Fields"}
          </button>
          {/* <button
            className={`${styles.button} ${styles.save} ${
              isSaveDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            {t("Save", locale) || "Save"}
          </button> */}
          <button
            className={`${styles.button} ${styles.finish} ${
              isFinishDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(2)}
          >
            {t("FinishSpan", locale) || "Finish"}
          </button>
        </div>
      </div>
      </div>
      )}
    </PageContainer>
  );
}


//  const createCharge = async (status) => {
//   const newMaterials = ([{
//     materialId: selectedPackages.id,
//     quantity: 1,
//   }]);

//   const newIndicators = indicators.map((indicator) => ({
//     indicatorId: indicator.indicator_Id,
//     quantity: indicator.quantity || 1,
//     lot: indicator.lot || null,
//   }));

//   const requestData = {
//     Description: observations,
//     Materials: newMaterials,
//     Indicators: newIndicators,
//     WasherId : selectedWasher,
//     ChargeWashingId: relatedChargeWashingId,
//   };

//   try {
//     setModalLoading(true);
//     setStopButtons(true);
//     if (!editId) {
//       await request()
//         .post("/api/hygiene", requestData)
//         .then((res) => {
//           const chargeId = res.data
//           setChargeId(chargeId)
//           .get(/api/hygiene/${chargeId})
//          setSelectedIndicator(
//                     response.data.indicators.map((i) => ({
//                       ...i,
//                       indicator: { name: i.indicatorName },
//                       indicator_Id: i.indicatorId,
//                     }))
//                   )
//       });
//       status === 1
//         ? toast.success(
//             t("PackageSaved", locale) || "The Package was successfully saved"
//           )
//         : toast.success(
//             t("PackageCompleted", locale) ||
//               "The package was successfully completed"
//           );
//     } else {
//       requestData.id = editId;
//       await request()
//         .put("/api/hygiene", requestData)
//       toast.success(
//         t("PackageModified", locale) ||
//           "The package was successfully modified"
//       );
//     }

//     status !== 1 && clearFields(true);
//   } catch (e) {
//     toast.error(e.response.data.message);
//   } finally {
//     setModalLoading(false);
//     setStopButtons(false);
//   }
// };