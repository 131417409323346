import React, { useContext, useEffect } from "react";
import { CiCalendar, CiUser } from "react-icons/ci";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { IconButton, Tooltip } from "@material-ui/core";
import { useState } from "react";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import styles from "./PackageCard.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";
import packageImage from "../../assets/images/PACKAGE IN A LOAD.svg";
import { GoLink, GoUnlink } from "react-icons/go";
import minutesToHourFormat from "../../utils/minutesToHours";

import biologicalImg from "../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../assets/images/QUIMICO.svg";

import trazantoImg from "../../assets/images/QApp.png";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import request from "../../utils/request";
import { getUserId } from "../../utils/sessionHandler";
import ChemicalVinculation from "../ChemicalVinculation/ChemicalVinculation";

const MySwal = withReactContent(Swal);

const PackageCard = ({
  pkg,
  toggle = true,
  status = false,
  cycleStatus,
  getLoadId,
  handleIndicator,
  selectedIndicator,
  isChemicalIndicator,
  closeChemicalVinculation,
  load,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [indicators, setIndicators] = useState({});
  const [chemicalLoading, setChemicalLoading] = useState(false);

  const locale = useContext(LocalizationContext);

  let materials = pkg?.package.materials;
  let observations = pkg?.package.observations;

  const getResultText = (result) => {
    switch (result) {
      case 1:
        return t("PositiveSpan");
      case 2:
        return t("NegativeSpan");
      case 3:
        return t("CanceledSpan");
      default:
        return "";
    }
  };

  const getResultCH = (result) => {
    switch (result) {
      case 3:
        return t("UnsafeSpan");
      case 4:
        return t("SafeSpan");
      default:
        return "";
    }
  };

  const getUserResultCH = (result) => {
    switch (result) {
      case 0:
        return t("AgreeSpan");
      case 1:
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const unlinkHandler = (id, isChemical = false) => {
    let url = isChemical ? "charge/CHE_packageUnlink" : "package/BI_Unlink";

    request()
      .put(`/api/${url}?PackageIndicatorId=${id}`)
      .then(() => getLoadId());
  };

  const unlinkConfirmation = (id, isChemical = false) => {
    console.log(id);
    MySwal.fire({
      title: "Are you sure you want to continue?",
      text: "Unlink.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "confirmButton",
        cancelButton: "cancelButton",
      },
      confirmButtonColor: "var(--primary-green)",
      cancelButtonColor: "var(--delete)",
      backgroundColor: "#f4f4f4",
    }).then((result) => {
      if (result.isConfirmed) {
        unlinkHandler(id, isChemical);
      }
    });
  };

  const toggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const mappedIndicators =
    cycleStatus === 4 ? indicators.finishedIndicators : indicators.indicators;

  const getPackageId = async () => {
    // setModalLoading(true);
    try {
      await request()
        .get(`/api/package/info?packageId=${pkg.packageId}`)
        .then((response) => {
          setIndicators({
            indicators: response.data.indicators,
            finishedIndicators: response.data.indicators,
            linkedIndicators: response.data.indicators.filter(
              (i) =>
                i.readingBI != null &&
                ((i.indicatorName.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT"))
            ),
            unlinkedIndicators: response.data.indicators.filter(
              (i) =>
                i.readingBI == null &&
                ((i.indicatorName.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT"))
            ),
            linkedIndicatorsCH: response.data.indicators.filter(
              (i) =>
                i.reading != null &&
                !(
                  (i.indicatorName.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT")
                )
            ),
            unlinkedIndicatorsCH: response.data.indicators.filter(
              (i) =>
                i.reading == null &&
                !(
                  (i.indicatorName.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT")
                )
            ),
          });
        });
    } catch {
    } finally {
      // setModalLoading(false);
    }
  };

  const onChemicalPackageVinculation = async (result, observations, image) => {
    setChemicalLoading(true);
    let requestBody = {
      // El usuario pone 0 (safe) o 1 (unsafe)
      result: result,
      loteProduct: selectedIndicator.lot,
      gtin: "07798164678151",
      productName: selectedIndicator.indicatorName,
      expirationDate: selectedIndicator.expirationDate,
      manufacureDate: selectedIndicator.expirationDate,
      manufacturer: "BionovaQ",
      // guid: load.GUID.toUpperCase(),
      // deviceSN: DeviceInfo.getSerialNumberSync(),
      base64Image: image,
      // User observation
      EditUserId: getUserId(),
      DeviceSn: "BionovaQ",
      Guid: "00000000-0000-0000-0000-000000000000",
      VisualResult: 0,
      Observations: observations,
      isFromPackage: true,
      isFromCharge: false,
      packageIndicatorId: selectedIndicator.packageIndicatorId,
      PackageNumber: pkg.package.packageNumber,
    };
    try {
      await request().post("/api/ReadingCheLens/PackageLink", requestBody);
      closeChemicalVinculation();
    } catch (err) {
      console.error(err);
    } finally {
      setChemicalLoading(false);
    }
  };

  useEffect(() => {
    getPackageId();
  }, []);

  return (
    <div className={styles.packageCard}>
      {isChemicalIndicator && (
        <ChemicalVinculation
          isChemical={isChemicalIndicator}
          onClose={closeChemicalVinculation}
          handleSubmit={onChemicalPackageVinculation}
        />
      )}
      {toggle && (
        <div className={styles.iconsContainer}>
          <IconButton
            className={styles.iconButton}
            onClick={(event) => toggleExpand(event)}
          >
            {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </div>
      )}
      <div className={styles.packageBody}>
        <p style={{ margin: 0, fontSize: "14px" }}>
          {pkg.package.packageNumber} - {pkg.package.description}
        </p>
        {status && (
          <p style={{ fontSize: "14px", marginBottom: "5px" }}>
            {t("StatusSpan", locale) || "Status"}:{" "}
            {pkg.packageStatus == 1
              ? t("SavedSpan", locale) || "Saved"
              : t("FinishedSpan", locale) || "Finished"}
          </p>
        )}
        {(isExpanded || !toggle) && (
          <div className={styles.detail}>
            <div>
              <p className={`${styles.titles}`}>{t("MaterialsSpan")}</p>
              <div className={styles.materialsContainer}>
                {materials.map((material) => (
                  <div className={styles.material} key={material.id}>
                    <div className={styles.imgMaterialContainer}></div>
                    <div className={styles.materialTextContainer}>
                      <p className={styles.materialName}>
                        {material.material.name}
                      </p>
                      <p className={styles.materialQuantity}>
                        {material.material.serial
                          ? `${t("SerialSpan")} ${material.material.serial}`
                          : `${t("QuantitySpan")}: ${material.quantity}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <>
              <div className={styles.indicators}>
                {mappedIndicators
                  ?.filter((i) => (cycleStatus === 4 ? null : true))
                  .map((indicator) => (
                    <Tooltip
                      key={indicator.id}
                      title={
                        <div>
                          <div>
                            {t("ProductSpan")}: {indicator.indicatorName}
                          </div>
                          <div>
                            {t("LotSpan")}: {indicator.lot}
                          </div>
                          {indicator.serial && (
                            <div>
                              {t("SerialSpan")}: {indicator.serial}
                            </div>
                          )}
                          <div>
                            {t("QuantitySpan")}: {indicator.quantity}
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <div
                        className={styles.indicatorItemGeneral}
                        style={{ cursor: "auto" }}
                      >
                        <IndicatorLabel
                          name={indicator.indicatorName}
                          lot={indicator.lot}
                          serial={indicator.serial}
                          expirationDate={indicator.expirationDate}
                          backgroundColor={"white"}
                          quantity={indicator.quantity}
                          border={true}
                        />
                        {cycleStatus === 4 && (
                          <img
                            src={trazantoImg}
                            className={styles.trazantoIcon}
                          />
                        )}
                      </div>
                    </Tooltip>
                  ))}
              </div>
              {/* //////////////////////////// BIOLOGICOS ////////////////////////////// */}
              {cycleStatus === 4 && (
                <div className={styles.indicatorSection}>
                  <div>
                    <div className={styles.titleContainer}>
                      <div className={styles.imageTitleContainer}>
                        <img src={biologicalImg} />
                      </div>
                      <p className={styles.indicatorsTitle}>
                        {t("UnlinkedBiologicalIndicatorSpan")}
                      </p>
                    </div>

                    <div className={styles.indicatorsContainer}>
                      {indicators.unlinkedIndicators?.map((indicator) => (
                        <Tooltip
                          key={indicator.id}
                          title={
                            <div>
                              <div>
                                {t("ProductSpan")}: {indicator.indicatorName}
                              </div>
                              <div>
                                {t("LotSpan")}: {indicator.lot}
                              </div>
                              {indicator.serial && (
                                <div>
                                  {t("SerialSpan")}: {indicator.serial}
                                </div>
                              )}
                              <div>
                                {t("QuantitySpan")}: {indicator.quantity}
                              </div>
                            </div>
                          }
                          arrow
                        >
                          <div
                            className={styles.indicatorItem}
                            onClick={() =>
                              handleIndicator(
                                {
                                  ...indicator,
                                  id: indicator.packageIndicatorId,
                                  indicator: { name: indicator.indicatorName },
                                },
                                false,
                                "package"
                              )
                            }
                          >
                            <IndicatorLabel
                              name={indicator.indicatorName}
                              lot={indicator.lot}
                              serial={indicator.serial}
                              expirationDate={indicator.expirationDate}
                              backgroundColor={"white"}
                              border={true}
                              animation={true}
                            />
                            <GoLink className={styles.link} />
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className={styles.titleContainer}>
                      <div className={styles.imageTitleContainer}>
                        <img src={biologicalImg} />
                      </div>
                      <p className={styles.indicatorsTitle}>
                        {t("LinkedBiologicalIndicatorSpan")}
                      </p>
                    </div>
                    <div className={styles.indicatorsContainer}>
                      {indicators.linkedIndicators?.map((indicator) => (
                        <Tooltip
                          key={indicator.id}
                          title={
                            <div>
                              <div>
                                {t("ProductSpan")}: {indicator.indicatorName}
                              </div>
                              <div>
                                {t("LotSpan")}: {indicator.lot}
                              </div>
                              {indicator.serial && (
                                <div>
                                  {t("SerialSpan")}: {indicator.serial}
                                </div>
                              )}
                              <div>
                                {t("QuantitySpan")}: {indicator.quantity}
                              </div>
                              {indicator.readingBI && (
                                <div>
                                  <div>Incubator:</div>
                                  <div>
                                    {t("PositionSpan")}:{" "}
                                    {indicator.readingBI.positionNumber}
                                  </div>
                                  <div>
                                    {t("StatusSpan")}:{" "}
                                    {indicator.readingBI.statusDescription}
                                  </div>
                                  <div>
                                    {t("ReadingNumber")}:{" "}
                                    {indicator.readingBI.readNumber}
                                  </div>
                                  <div>
                                    {t("ResultSpan")}:
                                    {getResultText(indicator.readingBI.result)}
                                  </div>
                                  <div>
                                    {t("StartSpan")}:{" "}
                                    {df(
                                      indicator.readingBI.startedTime,
                                      "dateTime"
                                    )}
                                  </div>
                                  <div>
                                    {t("FinishSpan")}:{" "}
                                    {df(
                                      indicator.readingBI.resultDate,
                                      "dateTime"
                                    )}
                                  </div>
                                  <div>
                                    {t("IncubationTimeSpan")}:{" "}
                                    {minutesToHourFormat(
                                      indicator.readingBI.incubationTime
                                    )}
                                  </div>
                                  <div>
                                    Program:{" "}
                                    {indicator.readingBI.program?.nombre}
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                          arrow
                        >
                          <div className={styles.indicatorItem}>
                            <IndicatorLabel
                              name={indicator.indicatorName}
                              lot={indicator.lot}
                              serial={indicator.serial}
                              expirationDate={indicator.expirationDate}
                              backgroundColor={"white"}
                              border={true}
                              resultBiologic={indicator.readingBI?.resultEnum}
                              detail={true}
                            />
                            <GoUnlink
                              className={styles.unlink}
                              onClick={() =>
                                unlinkConfirmation(indicator.packageIndicatorId)
                              }
                            />
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* ///////////////////////////// QUIMICOS /////////////////////////////////////////// */}
              {cycleStatus === 4 && (
                <div className={styles.indicatorSection}>
                  <div>
                    <div className={styles.titleContainer}>
                      <div className={styles.imageTitleContainer}>
                        <img src={chemicalImg} />
                      </div>
                      <p className={styles.indicatorsTitle}>
                        {t("UnlinkedChemicalIndicatorSpan")}
                      </p>
                    </div>

                    <div className={styles.indicatorsContainer}>
                      {indicators.unlinkedIndicatorsCH?.map((indicator) => (
                        <Tooltip
                          key={indicator.id}
                          title={
                            <div>
                              <div>
                                {t("ProductSpan")}: {indicator.indicatorName}
                              </div>
                              <div>
                                {t("LotSpan")}: {indicator.lot}
                              </div>
                              {indicator.serial && (
                                <div>
                                  {t("SerialSpan")}: {indicator.serial}
                                </div>
                              )}
                              <div>
                                {t("QuantitySpan")}: {indicator.quantity}
                              </div>
                            </div>
                          }
                          arrow
                        >
                          <div
                            className={styles.indicatorItem}
                            onClick={() =>
                              handleIndicator(
                                {
                                  ...indicator,
                                  id: indicator.packageIndicatorId,
                                  indicator: { name: indicator.indicatorName },
                                },
                                true,
                                "Package"
                              )
                            }
                          >
                            <IndicatorLabel
                              name={indicator.indicatorName}
                              lot={indicator.lot}
                              serial={indicator.serial}
                              expirationDate={indicator.expirationDate}
                              backgroundColor={"white"}
                              border={true}
                              animation={true}
                            />
                            {/* <GoLink className={styles.link} /> */}
                            <div>
                              <img
                                src={trazantoImg}
                                className={styles.trazantoIcon}
                              />
                              <GoLink className={styles.link} />
                            </div>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className={styles.titleContainer}>
                      <div className={styles.imageTitleContainer}>
                        <img src={chemicalImg} />
                      </div>
                      <p className={styles.indicatorsTitle}>
                        {t("LinkedChemicalIndicatorSpan")}
                      </p>
                    </div>
                    <div className={styles.indicatorsContainer}>
                      {indicators.linkedIndicatorsCH?.map((indicator) => (
                        <Tooltip
                          key={indicator.id}
                          title={
                            <div>
                              <div>
                                {t("ProductSpan")}: {indicator.indicatorName}
                              </div>
                              <div>
                                {t("LotSpan")}: {indicator.lot}
                              </div>
                              {indicator.serial && (
                                <div>
                                  {t("SerialSpan")}: {indicator.serial}
                                </div>
                              )}
                              <div>
                                {t("QuantitySpan")}: {indicator.quantity}
                              </div>
                              {indicator.readingCheSterilizer && (
                                <div>
                                  <div>
                                    {t("StatusSpan")}:{" "}
                                    {
                                      indicator.readingCheSterilizer
                                        .statusDescription
                                    }
                                  </div>
                                  <div>
                                    {t("ReadingNumber")}:{" "}
                                    {indicator.readingCheSterilizer.readNumber}
                                  </div>
                                  {/* <div>
                                  {t("ResultSpan")}:
                                  {getResultText(
                                    indicator.readingCheSterilizer.result
                                  )}
                                </div> */}
                                  <div>
                                    {t("DateSpan")}:{" "}
                                    {df(
                                      indicator.readingCheSterilizer
                                        ?.creationTest,
                                      "dateTime"
                                    )}
                                  </div>
                                  <div>
                                    {t("BrandSpan")}:{" "}
                                    {indicator.readingCheSterilizer.brand}
                                  </div>
                                  <div>
                                    {t("ResultSpan")}:{" "}
                                    {getResultCH(
                                      indicator.readingCheSterilizer?.result
                                    )}
                                  </div>
                                  <div>
                                    {t("UserEvaluation")}:{" "}
                                    {getUserResultCH(
                                      indicator.readingCheSterilizer
                                        ?.visualResult
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                          arrow
                        >
                          <div className={styles.indicatorItem}>
                            <IndicatorLabel
                              name={indicator.indicatorName}
                              lot={indicator.lot}
                              serial={indicator.serial}
                              expirationDate={indicator.expirationDate}
                              backgroundColor={"white"}
                              border={true}
                              resultCH={indicator.reading?.result}
                              userResultCH={indicator.reading?.visualResult}
                              detail={true}
                            />
                            <div>
                              <img
                                src={trazantoImg}
                                className={styles.trazantoIcon}
                              />
                              <GoUnlink
                                className={styles.unlink}
                                onClick={() =>
                                  unlinkConfirmation(
                                    indicator.packageIndicatorId,
                                    true
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
            {observations && (
              <div>
                <p className={`${styles.titles}`}>{t("ObservationSpan")}</p>

                <div className={styles.observationContainer}>
                  <p className={styles.observation}>{observations}</p>
                  {/* <div className={styles.observationInfo}>
                    <p>{df(pkg.package.creationDate, "dateTime")}</p>
                    <p>{pkg.package.creationUser}</p>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles.timestampContainer}>
          <div className={styles.iconText}>
            <CiCalendar className={styles.icon} />
            <p style={{ margin: 0 }}>
              {df(pkg.package.creationDate, "dateTime")}
            </p>
          </div>
          <div className={styles.iconText}>
            <CiUser className={styles.icon} />
            <p style={{ margin: 0 }}>{pkg.package.creationUser}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
