import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import BatchRecord from "../pages/BatchRecord/BatchRecord.jsx";
import useLocale from "../utils/Hooks/useLocale.js";
import { useParams, useLocation } from "react-router-dom"; 
import BatchRecordDetail from "../components/PackageDetail/BatchRecordDetail.jsx";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Company,
    key: "BatchRecord",
  };
  const screenName = 338;
  const locale = useLocale(screenName);
  const params = useParams()
  const location = useLocation()

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{locale: locale}}>
      <NavBar
        screenName={screenName}
        moduleName={170} MenuOptions={menu} currentPage={currentPage} >
        <BatchRecordDetail params={params} location={location}/>
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};
