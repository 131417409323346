import React, { useContext, useEffect, useState } from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDateTimePicker,
  } from "@material-ui/pickers";
import "date-fns";
import MomentUtils from '@date-io/moment';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import styles from "./TimeRule.module.css";
import moment from "moment";
import t from "../../utils/translation";
import LocalizationContext from '../../utils/LocalizationContext';
import { AiOutlineClockCircle } from "react-icons/ai";
import InputDateTimeQ from '../FormComponents/InputDateTimeQ/InputDateTimeQ';
import SelectQ from '../FormComponents/SelectQ/SelectQ';
import InputQ from '../FormComponents/InputQ/InputQ';
import df from "../../utils/dateFormater";
import parseFiltersDate from "../../utils/parseFiltersDate";
import { getRegionalFormat } from "../../utils/sessionHandler";
import parseFormatDate from '../../utils/parseFormatDate';
import { toast } from 'react-toastify';
import request from '../../utils/request';
import ModalLoading from '../ModalLoading/ModalLoading';

function TimeRule({
  onDateChange, validateDays, onDaysChange, forAny, initialSelectedDay, initialSelectedDate, ruleEditId, options
}) {

  const [days, setDays] = useState(null);
  const [customValue, setCustomValue] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [error, setError] = useState({ type: "", message: "" });
  const [selectedDay, setSelectedDay] = useState("")
  const [modalLoading, setModalLoading] = useState(false);


  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#00b189", 
        contrastText: "#ffffff", 
      },
    },
    overrides: {
      MuiIconButton: {
        root: {
          "&.MuiPickersCalendarHeader-iconButton": {
            color: "#ffffff", 
          },
        },
      },

      MuiTab: {
        root: {
          backgroundColor: "#fff", 
          "&$selected": {
            backgroundColor: "#00b189", 
            color: "#ffffff", 
          },
        },
        selected: {}, 
      },
      MuiSvgIcon: {
        root: {
          color: "#333",
          "&.Mui-selected": {
            color: "#ffffff", 
            "& .MuiSvgIcon-root": {
              color: "#ffffff", 
            },
          },
        },
      },
    },
  });

  const editDate = () => {
    if (initialSelectedDate && ruleEditId) {
      setCalendarDate(initialSelectedDate);
    }
  }


  const editDays = () => {
    if (!options || options.length === 0) {
      return;
    }
    if (forAny) {
      setSelectedDay("");
      setCustomValue("");
      setDays(null);
      setIsCustom(false);
      return; 
    }
  
    if (initialSelectedDay && ruleEditId) {
      const matchingOption = options.find(
        (option) => option.days === initialSelectedDay
      );

      if (matchingOption) {
        setIsCustom(false);
        setDays(initialSelectedDay);
        setSelectedDay(matchingOption.name);
      } else {
        setIsCustom(true);
        setCustomValue(initialSelectedDay);
        setDays(initialSelectedDay);
        setSelectedDay("Customized");
      }
    } else {
      setSelectedDay("");
      setCustomValue("");
      setDays(null);
      setIsCustom(false);
    }
  };

  const initialDate = () => {
    if (!ruleEditId) {
    const initialFormattedDate = parseFiltersDate(
      df(new Date(), "dateTime"),
      getRegionalFormat().dateFormat
    );
    setCalendarDate(initialFormattedDate);
    onDateChange(initialFormattedDate);
  }
  }

  const handleDateChange = (date) => {
    if (date) {
      if (
        new Date(date?._d).getTime() <
        new Date(date?._i).getTime()
      ) {
        toast.error(
          t("FinishDateCannotBeBeforeStartDate") ||
            "Finish date cannot be before start date."
        );

        setError({
          type: "finishDate",
          message: t("FinishDateCannotBeBeforeStartDate"),
        });
        return;
      }
      const formattedDate = parseFiltersDate(
        df(date, "dateTime"),
        getRegionalFormat().dateFormat
      );
      setCalendarDate(formattedDate);
      onDateChange(formattedDate);
    }
  };


  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedDay(value);
    if (value === 'Customized') {
      setIsCustom(true);
      setDays(null);
    } else {
      setIsCustom(false);
      const selectedOption = options.find((option) => option.name === value);
      setDays(selectedOption?.days || null);
      onDaysChange(selectedOption?.days || null)
    }
  };


  const handleCustomInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setCustomValue(e.target.value);
    if (!isNaN(value)) {
      setDays(value);
      onDaysChange(value)
    }
  };

  useEffect(() => {
    initialDate()
    editDate()
    editDays()
  }, [options,forAny]);

  return (
    <div>
    <div className={styles.selectorContainer}>
      <select
        onChange={handleSelectChange}
        value={selectedDay}
        style={{
          width: "70%",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          fontSize: "12px",
          color: "#333",
          fontWeight: "400px",
          cursor: "pointer",
          background: forAny ? "#E5E5E5" : "white",
          pointerEvents: forAny ? "none" : null,
        }}
      >
        <option value="">{t("BlankEntry72") || "Select frequency"}</option>
        {options && options.map((option) => (
          <option key={option.name} value={option.name}>
            {option.translationName}
          </option>
        ))}
      </select>
      {validateDays && !isCustom && !forAny ? (
        <span className={styles.errorMessage}>
          {t("BlankEntry72") || "Select frequency of days"}
        </span>
      ) : (
        ""
      )}
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            margin="normal"
            id="date-picker-dialog"
            label="Start date"
            format={`${parseFormatDate(
              getRegionalFormat().dateFormat
            )} ${getRegionalFormat().timeFormat}`}
            value={calendarDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>

    {isCustom && (
      <div>
        <input
          className={styles.intervalInput}
          style={{
            background: forAny ? "#E5E5E5" : "white",
            pointerEvents: forAny ? "none" : null,
          }}
          id="customIntervalInput"
          type="number"
          min="1"
          placeholder={t("BlankEntry74") || "Days"}
          value={customValue}
          onChange={handleCustomInputChange}
        />
        {validateDays && isCustom ? (
          <span className={styles.errorMessage}>{t("BlankEntry73") || "Select number of days"}</span>
        ) : (
          ""
        )}
      </div>
    )}
  </div>
  );
}

export default TimeRule;