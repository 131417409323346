/**
 * Save a filter value in sessionStorage under a specific screen name.
 * @param {string} key - The filter key (e.g., "status", "method").
 * @param {*} value - The value to save.
 * @param {string} screenName - The screen name to namespace the filters.
 */
export const setFilterValue = (key, value, screenName) => {
  const filters = JSON.parse(sessionStorage.getItem(screenName)) || {};
  filters[key] = value;
  sessionStorage.setItem(screenName, JSON.stringify(filters));
};

/**
 * Retrieve a filter value from sessionStorage for a specific screen name.
 * If the key doesn't exist, return the provided default value.
 * @param {string} key - The filter key to retrieve.
 * @param {*} defaultValue - The default value to return if the key is not found.
 * @param {string} screenName - The screen name to namespace the filters.
 * @returns {*} - The stored value or the default value.
 */
export const getFilterValue = (key, defaultValue, screenName) => {
    const filters = JSON.parse(sessionStorage.getItem(screenName)) || {};
    return filters[key] !== undefined ? filters[key] : defaultValue;
  };
  