import React, { useState, useEffect, useReducer, useContext } from "react";
import request from "../../utils/request";
import Redirect from "../../utils/Redirect";
import df from "../../utils/dateFormater";
import styles from "./Rule.module.css";

import reducer from "../../components/CRUD/CRUDReducer";
import Button2 from "../../components/Button2";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import SterilizationHeader from "../../components/SterilizationHeader/SterilizationHeader";

import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { getUserCompany } from "../../utils/sessionHandler";
import CircularProgress from "@material-ui/core/CircularProgress";
import imgEdit from "../../assets/images/EditItemTable.svg";
import imgDelete from "../../assets/images/DeleteItemTable.svg";
import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles"; // Import makeStyles
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Grid,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import useTableQ from "../../utils/Hooks/useTableQ";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText";

const initialState = {
  data: [],
  selectedObjectsId: [],
  selectedObjectsList: [],
  objectToDelete: {},
  openForm: false,
  openDetail: false,
  openConfirmation: false,
};

export default function CompanyNorms() {
  const company = getUserCompany();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loadingData, setLoadingData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [rowRule, setRowRule] = useState(null);
  const [deleteConfimation, setDeleteConfirmation] = useState(false);
  const [ruleIdToDelete, setRuleIdToDelete] = useState(null);

  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  const { data } = state;

  useEffect(() => {
    fetchRules();
  }, []);

  const fetchRules = () => {
    setLoadingData(true);
    request()
      .get(`/api/Rule/list`)
      .then((response) => {
        //setCompanyNorms(response.data);
        dispatch({
          type: "setData",
          payload: { data: mapRuleData(response.data), idField: "id" },
        });
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const mapRuleData = (ruleDatas) => {
    return ruleDatas.map((r, index) => {
      return {
        id: r.ruleId,
        name: r.ruleName,
        sterilizerName: r.sterilizerName,
        frequency: r.frequency,
        mandatory: r.mandatory,
        helpUser: r.helpUser,
        active: r.active,
        days:r.days,
        methodName:r.methodName
      };
    });
  };

  const concatProductNames = (products) => {
    return products
      .map((p) => {
        return `${p.productName}`;
      })
      .join(" - ");
  };

  const onEditClick = (row) => {
    localStorage.setItem("ruleEditId", row.id);
    Redirect({
      redirect: true,
      path: "/appruleform",
    });
  };

  const onDeleteClick = (ruleId) => {
    setRuleIdToDelete(ruleId);
    confirm({
      title: t("DeleteRule") || "Delete Rule",
      text:
        t("DeleteThisRuleConfirmationText") ||
        "Are you sure you want to delete this rule?",
      icon: "warning",
      confirmButtonText: t("ConfirmButton") || "Confirm",
      cancelButtonText: t("Cancel") || "Cancel",
      onConfirm: () => handleDelete(ruleId),
    });
  };

  const handleDelete = (ruleId) => {
    setLoadingData(true);
    request()
      .delete(`/api/Rule/${ruleId}`)
      .then(() => {
        fetchRules();
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // const onOpenDetail = (row) => {
  //   setRowRule(row);
  //   setOpenModal(true);
  // };

  const getTootltipTxt = (str, color) => {
    return (
      <List component="nav">
        <ListItem button>
          <ListItemText
            primary={t("Products", locale) || "Products"}
            secondary={
              <ul>
                {str.map((item) => (
                  <li style={{ color: color }} key={item.productId}>
                    {item.productName}
                  </li>
                ))}
              </ul>
            }
          />
        </ListItem>
      </List>
    );
  };

  const truncate = (str, limit, trim) => {
    return str.length > limit ? str.substring(0, trim) + "..." : str;
  };

  const {
    currentItems,
    handleSortByColumn
  } = useTableQ(data, [
    "name",
    "process",
    "applyTo",
    "sterilizerName",
    "frequency",
    "mandatory",
    "active",
  ]);

  return (
    <PageContainer
      category={t("SterilizationSpan") || "Sterilization"}
      backUrl={"/appsterilization"}
      subcategory={t("BlankEntry3") || "Rules"}
      categoryId={1}
      newButton={true}
      newButtonUrl={"/appruleform"}
    >
      <div className={styles.container}>
       <div className={styles.tableContainer}>
      <TableContainerQ>
        <TableHeadQ actions={true}>
          <TableHeaderCellQ handleClick={() => handleSortByColumn("name")}>
            {t("NameSpan") || "Name"}
          </TableHeaderCellQ>
          <TableHeaderCellQ handleClick={() => handleSortByColumn("process")}>
            {t("Process") || "Process"}
          </TableHeaderCellQ>
          <TableHeaderCellQ
            handleClick={() => handleSortByColumn("applyTo")}
          >
            {t("ApplyTo") || "Apply to"}
          </TableHeaderCellQ>
          <TableHeaderCellQ
            handleClick={() => handleSortByColumn("sterilizerName")}
          >
            {t("SterilizerSpan") || "Sterilizer"}
          </TableHeaderCellQ>
          <TableHeaderCellQ
            handleClick={() => handleSortByColumn("frequency")}
          >
            {t("BlankEntry75") || "Frequency"} 
          </TableHeaderCellQ>
          <TableHeaderCellQ handleClick={() => handleSortByColumn("mandatory")}>
            {t("Mandatory") || "Mandatory"}
          </TableHeaderCellQ>
          <TableHeaderCellQ handleClick={() => handleSortByColumn("active")}>
            {t("Active") || "Active"}
          </TableHeaderCellQ>
        </TableHeadQ>
        {loadingData ? (
        <div className="spinnerContainer">
            <SpinnerQ />
        </div>
              ) : (
        <TableBodyQ withoutPagination={true}>
          {currentItems.map((item) => (
            <TableRowQ
              key={item.id}
              actions={true}
              edit={true}
              deleteRow={true}
              // detail={true}
              onEdit={() => onEditClick(item)}
              onDelete={() => onDeleteClick(item.id)}
              // onDetail={() => onOpenDetail(item)}
            >
              <TableRowCellQ>
              <TruncatedText text={item?.name} maxLength={25}/>
              </TableRowCellQ>
              <TableRowCellQ>
              <TruncatedText text={t("SterilizationSpan") || "Sterilization"} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
              <TruncatedText text={t("LoadSpan") || "Load"} maxLength={25}/>
              </TableRowCellQ>
              <TableRowCellQ>
              <TruncatedText text={item.sterilizerName + (item.methodName ? ` (${item.methodName})` : '')} maxLength={25}/>
              </TableRowCellQ>
              <TableRowCellQ>
              <TruncatedText text={item.frequency} maxLength={25}/>
              </TableRowCellQ>
              <TableRowCellQ>
                <Checkbox checked={item.mandatory} />
              </TableRowCellQ>
              <TableRowCellQ>
                <Checkbox checked={item.active} />
              </TableRowCellQ>
            </TableRowQ>
          ))}
        </TableBodyQ>
        )}
      </TableContainerQ>
      </div>
    </div>
    </PageContainer>
  );
}
