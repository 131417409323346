import React, { useContext, useState } from "react";

import styles from "./WashCycleCard.module.css";

import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";

import sterilizerIcon from "../../assets/images/blueWasher.svg";

import { FaLink } from "react-icons/fa6";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CiCalendar, CiUser } from "react-icons/ci";
import hasPositive from "../../utils/hasPositive";
import { Tooltip } from "@material-ui/core";

const WashCycleCard = ({
  charge,
  startDrag,
  handleLoadDetail,
  img,
  handleDoubleClick = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [clickTimeout, setClickTimeout] = useState(null);

  const locale = useContext(LocalizationContext);

  const toggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const toLink = charge.totalIndicators - charge.vinculatedIndicators;

  const onClick = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    } else {
      const timeout = setTimeout(() => {
        handleLoadDetail(charge.chargeWashingId);
        setClickTimeout(null);
      }, 300);
      setClickTimeout(timeout);
    }
  };

  const onDoubleClick = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }
    handleDoubleClick(null, charge.chargeStatus + 1, charge);
  };

  return (
    <div
      className={styles.chargeCard}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      draggable
      onDragStart={(e) => startDrag(e, charge)}
    >
      <div className={styles.iconsContainer}>
        <button
          className={styles.iconButton}
          onClick={(event) => toggleExpand(event)}
        >
          {!isExpanded ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      <div className={styles.chargeBody}>
        <div className={styles.headerCard}>
          <img src={img} alt="progress icon" />
          <div className={styles.headerContainer}>
            <div className={styles.headerTitleContainer}>
              <p className={styles.cycle}>
                {charge.chargeStatus === 4 && (
                  <Tooltip
                    title={
                      <span>{` ${
                        charge.hasPositive
                          ? t("BlankEntry18")
                          : toLink ? t("BlankEntry22")
                          : t("BlankEntry19")
                      }`}</span>
                    }
                    arrow
                  >
                    <div
                      className={`${styles.hasPositive} ${
                        charge.hasPositive
                          ? styles.red : toLink ? styles.gray
                          : styles.green
                      }`}
                    ></div>
                  </Tooltip>
                )}
                {`${t("CycleNumberSpan")}: ${charge.cicle ?? ""}`}
              </p>
              <p className={styles.description}>{`${
                t("UsedProgramSpan") || "Used Program"
              }: ${charge.usedProgram ?? ""}`}</p>
            </div>
            <div className={styles.sterilizerContainer}>
              <div className={styles.sterilizerIcon}>
                <img src={sterilizerIcon} alt={t("SterilizerSpan")} />
              </div>
              <p className={styles.chargeText}>{` ${charge.washerName}`}</p>
            </div>
            {charge.chargeStatus === 4 && toLink > 0 && (
              <div className={styles.indicatorWrapper}>
                <FaLink />
                <p className={styles.unlinked}>
                  {`${t("IndicatorsToLinkSpan")}: `}
                  <span className={styles.count}>{toLink}</span>
                </p>
              </div>
            )}
            {isExpanded && (
              <p className={styles.loadObservations}>
                {t("BlankEntry16", locale) || "Load obs"}:
                {charge.chargeObservations ? charge.chargeObservations : ""}
              </p>
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerItem}>
            <CiCalendar className={styles.icon} />
            <p className={styles.footerText}>
              {df(charge.creationDate, "date")} 
            </p>
          </div>

          <div className={styles.footerItem}>
            <CiUser className={`${styles.icon} `} />
            <p className={styles.footerText}>{charge.operator}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WashCycleCard;
