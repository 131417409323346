import React, { useEffect, useState } from "react";
import DDColumn from "./DDColumn/DDColumn";
import DDRow from "./DDRow/DDRow";
import QuantityElement from "../QuantityElement/QuantityElement";
import useWindowSize from "../../utils/Hooks/useWindowSize";
import styles from "./DragAndDropPackages.module.css";
import t from "../../utils/translation";
import ButtonQ from "../../components/ButtonQ";
import { FaArrowRight } from "react-icons/fa";
import SpinnerQ from "../SpinnerQ/SpinnerQ";

const DragAndDropPackages = ({
  data,
  selectedData,
  selectedFilteredData,
  handleSelectedData,
  predifinedData,
  filter1,
  filter2,
  method,
  leftColumnName,
  rightColumnName,
  category = "Sterilization",
  loadingData,
  trayName,
}) => {
  const [quantity, setQuantity] = useState(1);
  const windowSize = useWindowSize();

  const startDragColumn = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
    e.dataTransfer.setData("ColumnMaterial", true);
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const columnID = e.dataTransfer.getData("ColumnMaterial");
    moveItem(itemID, columnID);
  };

  const doubleClick = (itemID, columnID) => {
    moveItem(itemID, columnID);
  };

  const moveItem = (itemID, columnID) => {
    const itemData = data.find((item) => item.id == itemID);
    const item = { ...itemData };
    const newQuantity = quantity ? quantity : 1;

    if (!quantity) {
      setQuantity(1);
    }
    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find((material) => material.id === item.id);

      if (data === predifinedData) {
        if (handleQuantityMaterial(item) < newQuantity) {
          setQuantity(1);
          return;
        }
      }

      if (!isItemExists) {
        if (!item.serial) item.quantity = newQuantity;
        newState = [...newState, { ...item, quantity: newQuantity }];
      } else {
        isItemExists.quantity += newQuantity;
        if (data === predifinedData) {
          item.quantity -= newQuantity;
        }
      }

      if (item.quantity < 0) {
        item.quantity = 0;
      }

      setQuantity(1);
      handleSelectedData(newState);
    }
  };

  const onDropDelete = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const item = selectedData.find((material) => material.id == itemID);
    const originalItem = data.find((material) => material.id == itemID);

    if (
      data === predifinedData &&
      item &&
      originalItem &&
      item.originalQuantity !== undefined
    ) {
      originalItem.quantity += item.quantity;
    }

    const newState = selectedData.filter((material) => material.id != itemID);
    handleSelectedData(newState);
  };

  const onDelete = (id) => {
    const item = selectedData.find((material) => material.id === id);
    const originalItem = data.find((material) => material.id === id);

    if (
      data === predifinedData &&
      item &&
      originalItem &&
      item.originalQuantity !== undefined
    ) {
      originalItem.quantity += item.quantity;
    }

    const newState = selectedData.filter((material) => material.id != id);
    handleSelectedData(newState);
  };

  const increaseQuantity = () => {
    const newQuantity = quantity ? quantity : 1;

    setQuantity(newQuantity + 1);
  };

  const reduceQuantity = () => {
    const newQuantity = quantity ? quantity : 1;

    if (newQuantity > 1) {
      setQuantity(newQuantity - 1);
    }
  };

  const serialExist = (id) => {
    return selectedData.find((m) => m.id === id);
  };

  const handleQuantityMaterial = (item) => {
    const selectedItem = selectedData.find((s) => s.id === item.id);

    return selectedItem ? item.quantity - selectedItem.quantity : item.quantity;
  };

  const handleQuantity = (e) => {
    if (e.target.value === "" || /^[1-9][0-9]*$/.test(e.target.value)) {
      setQuantity(parseInt(e.target.value));
    }
  };

  const selectAllData = () => {
    const newData = predifinedData.filter(
      (pd) =>
        !pd.serial ||
        selectedData.some((selected) => selected.serial === pd.serial)
    );
    handleSelectedData(newData);
  };

  return (
    <div className={styles.container} style={{pointerEvents: trayName || category.toUpperCase() != "WASHING" ? "auto" : "none", userSelect: trayName || category.toUpperCase() !== "WASHING" ? "auto" : "none"}}>
      <div className={styles.columnContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{leftColumnName}</p>
            {filter1}
            <DDColumn id={"Drag"} dragOver={draggingOver} drop={onDropDelete}>
              {loadingData ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : predifinedData[0] ? (
                predifinedData
                  .filter((d) => !d.serial || (d.serial && !serialExist(d.id)))
                  .map(
                    (material) =>
                      handleQuantityMaterial(material) > 0 && (
                        <DDRow
                          key={material.id}
                          obj={material}
                          name={material.name}
                          serial={material.serial}
                          body={material?.materialType?.name}
                          dragStart={startDragColumn}
                          method={method}
                          materialMethod={material.methodId}
                          doubleClick={doubleClick}
                          columnId={true}
                          // quantity={material?.quantity}
                          quantity={` ${handleQuantityMaterial(material)}`}
                          category={category}
                        />
                      )
                  )
              ) : (
                data
                  .filter((d) => !d.serial || (d.serial && !serialExist(d.id)))
                  .map((material) => (
                    <DDRow
                      key={material.id}
                      obj={material}
                      name={material.name}
                      serial={material.serial}
                      body={material?.materialType?.name}
                      dragStart={startDragColumn}
                      method={method}
                      materialMethod={material.methodId}
                      doubleClick={doubleClick}
                      columnId={true}
                      category={category}
                    />
                  ))
              )}
            </DDColumn>
          </div>
        </div>
        <div className={styles.quantityPaper}>
          {trayName && <p>{trayName}</p>}
          {predifinedData[0] && (
            <ButtonQ onClick={() => selectAllData()}>
              <div className={styles.selectAllContainer}>
                <p className={styles.selectAllText}>{t("BlankEntry17")}</p>
                <FaArrowRight className={styles.selectAllIcon} />
              </div>
            </ButtonQ>
          )}
          <QuantityElement
            quantity={quantity}
            reduceQuantity={reduceQuantity}
            increaseQuantity={increaseQuantity}
            category={category}
            handleQuantityChange={handleQuantity}
          />
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{rightColumnName}</p>

            {filter2}

            <DDColumn
              id={"Drop"}
              dragOver={draggingOver}
              drop={onDrop}
              autoScroll={true}
            >
              {selectedFilteredData.map((material) => (
                <DDRow
                  key={material.id}
                  obj={material}
                  name={material.name}
                  serial={material.serial}
                  quantity={material.quantity}
                  body={material?.materialType?.name}
                  dragStart={startDrag}
                  onDelete={onDelete}
                  method={method}
                  materialMethod={material.methodId}
                  doubleClick={onDelete}
                  columnId={null}
                  category={category}
                />
              ))}
            </DDColumn>
          </div>
        </div>
      </div>
      {category == "Sterilization" && (
        <p className={styles.wrongMethod}>
          <div className={styles.circleMethod}></div>
          {t("WrongMethodSpan")}
        </p>
      )}
    </div>
  );
};

export default DragAndDropPackages;
