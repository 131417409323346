import React, { useEffect, useState } from "react";
import InputQ from "../../../components/FormComponents/InputQ/InputQ";
import TextAreaQ from "../../../components/FormComponents/TextAreaQ/TextAreaQ";
import styles from "./FinishModal.module.css";
import t from "../../../utils/translation";
import request from "../../../utils/request";
import { getRegionalFormat, getUserId } from "../../../utils/sessionHandler";
import { toast } from "react-toastify";
import ImageDrop from "../../../components/ImageDrop/ImageDrop";
import InputDateTimeQ from "../../../components/FormComponents/InputDateTimeQ/InputDateTimeQ";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ";
import ModalQ from "../../../components/TableBionovaQ/ModalQ/ModalQ";
import df from "../../../utils/dateFormater";
import parseFiltersDate from "../../../utils/parseFiltersDate";

function FinishModal({
  loadId,
  isModalOpen,
  closeModal,
  handleDrop,
  locale,
  formData,
  handleFormData,
  isEdit = false,
  onConfirmEdit = () => {},
  loadingFinishModal,
}) {
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState({ type: "", message: "" });

  const timeUnitOptions = [
    { value: "Minutes", name: t("MinuteSpan") || "Minutes" },
    { value: "Seconds", name: t("SecondSpan") || "Seconds" },
  ];

  const loggedUserId = getUserId();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    if (name === "cicleNumber") {
      // Remueve cualquier carácter que no sea un número
      if (newValue !== "" && !/^[0-9]+$/.test(newValue)) {
        newValue = newValue.replace(/[^0-9]/g, "");
      }
  
      if (newValue.startsWith("0")) {
        return;
      }
    }
  
    handleFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  

  const handleSelectChange = (e) => {
    let { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onFileChange = (value) => {
    handleFormData((prevData) => ({ ...prevData, img: value }));
  };

  const handleClose = () => {
    closeModal();
  };

  const validateRequiredFields = () => {
    const requiredFields = ["cicleNumber", "usedProgram"];

    if (formData.chargeStatus != 3) {
      for (const field of requiredFields) {
        if (!formData[field] || formData[field].toString().trim() === "") {
          return false;
        }
      }
    }

    return true;
  };

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !validateRequiredFields() ||
      error.type ||
      !isValidDate(formData.cicleStartDate)
    ) {
      if (error.type) {
        toast.error(error.message);
      }
      if (!isValidDate(formData.cicleStartDate)) {
        toast.error(t("InvalidDateSpan"));
      }
      if (!validateRequiredFields()) {
        toast.error(
          t("RequiredFieldMissingSpan") ||
            "Please fill out all required fields."
        );
        setValidate(true);
      }
      return;
    }

    const object = {
      CICLENUMBER: parseInt(formData.cicleNumber),
      CICLEOBSERVATIONS: formData.cicleObservations,
      CICLETEMPERATURE: parseInt(formData.cicleTemperature),
      cicleTime: parseInt(formData.cicleTime),
      UsedProgram: formData.usedProgram,

      TimeUnit: formData.timeUnit,
      cicleStartDate: formData.cicleStartDate,
      cicleFinishDate:
        formData.chargeStatus == 3 ? null : formData.cicleFinishDate,
      TemperatureUnit: temperatureOptions?.find(
        (u) => u.value == formData.temperatureUnit
      )?.name,
      ChargeId: parseInt(loadId),
      ImageData:
        formData.chargeStatus == 3 || isEdit ? null : formData.img?.base64Image,
      ImageName:
        formData.chargeStatus == 3 || isEdit ? null : formData.img?.imageName,
      ImageFormat:
        formData.chargeStatus == 3 || isEdit ? null : formData.img?.fileFormat,
    };

    try {
      await handleDrop(loadId, formData.chargeStatus, e, object);

      if (isEdit) {
        onConfirmEdit(loadId);
      }

      handleClose();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  useEffect(() => {
    const fetchAppParameter = request().get(
      `/api/appparameter?userLoggedId=${loggedUserId}`
    );
    const fetchTemperatureUnit = request().get(
      `/api/temperatureunit?userLoggedId=${loggedUserId}`
    );

    Promise.all([fetchAppParameter, fetchTemperatureUnit])
      .then(([appParameterRes, temperatureUnitRes]) => {
        const appParameterData = appParameterRes.data;
        const temperatureUnitData = temperatureUnitRes.data.map((item) => ({
          value: item.id,
          name: item.description,
        }));

        handleFormData((prevData) => ({
          ...prevData,
          securityMode: appParameterData[4].value,
          temperatureUnit:
            formData.chargeStatus === 4
              ? temperatureUnitData.find(
                  (t) => t.name === formData.temperatureUnit
                ).value
              : appParameterData[8].value,
        }));
        setTemperatureOptions(temperatureUnitData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleStartDate = (value) => {
    const finishDate = formData.cicleFinishDate;

    if (
      finishDate &&
      new Date(value?._d).getTime() >
        new Date(
          parseFiltersDate(
            df(finishDate, "dateTime"),
            getRegionalFormat().dateFormat
          )
        ).getTime()
    ) {
      toast.error(
        t("StartDateCannotBeAfterFinishDate") ||
          "Start date cannot be after finish date."
      );
      return;
    } else if (new Date(value?._d).getTime() > new Date().getTime()) {
      toast.error(
        t("StartDateCannotBeAfterFinishDate") ||
          "Start date cannot be later than the current date."
      );
      return;
    }

    handleFormData((prev) => ({ ...prev, cicleStartDate: value }));
  };

  const handleFinishDate = (value) => {
    const startDate = formData.cicleStartDate;

    if (
      new Date(value?._d).getTime() <
      new Date(
        parseFiltersDate(
          df(startDate, "dateTime"),
          getRegionalFormat().dateFormat
        )
      ).getTime()
    ) {
      toast.error(
        t("FinishDateCannotBeBeforeStartDate") ||
          "Finish date cannot be before start date."
      );

      setError({
        type: "finishDate",
        message: t("FinishDateCannotBeBeforeStartDate"),
      });
      return;
    }

    if (!isValidDate(value)) {
      setError({
        type: "invalidDate",
        message: t("InvalidDateSpan"),
      });
    }

    if (
      (error.type === "finishDate" || error.type === "invalidDate") &&
      isValidDate(value)
    ) {
      setError({
        type: "",
        message: "",
      });
    }
    handleFormData((prev) => ({ ...prev, cicleFinishDate: value }));
  };

  return (
    <ModalQ
      open={isModalOpen}
      title={t("ProcessDataSpan") || "Process Data"}
      handleClose={handleClose}
      loading={loadingFinishModal}
    >
      <div className={styles.inputGroup}>
        <div style={{ width: "48%" }}>
          <InputDateTimeQ
            label={t("StartDateSpan") || "Start Date"}
            name="cicleStartDate"
            required={true}
            errorMessage={t("RequiredFieldError") || "This field is required"}
            validate={validate}
            value={formData.cicleStartDate}
            handleChange={handleStartDate}
          />
        </div>
        {formData.chargeStatus !== 3 && (
          <div style={{ width: "48%" }}>
            <InputDateTimeQ
              label={t("FinishDateSpan") || "Finish Date"}
              name="cicleFinishDate"
              handleChange={handleFinishDate}
              required={true}
              errorMessage={t("RequiredFieldError") || "This field is required"}
              validate={validate}
              defaultValue={formData.cicleFinishDate}
              value={formData.cicleFinishDate}
              onChange={handleFinishDate}
            />
          </div>
        )}
      </div>
      <div className={styles.inputGroup}>
        <InputQ
          label={t("CycleNumberSpan") || "Cycle Number"}
          name="cicleNumber"
          handleChange={handleInputChange}
          value={formData.cicleNumber}
          required={true}
          errorMessage={t("RequiredFieldError") || "This field is required"}
          validate={validate}
          containerWidth={"48%"}
        />
        <InputQ
          label={t("UsedProgramSpan") || "Used Program"}
          name="usedProgram"
          handleChange={handleInputChange}
          value={formData.usedProgram}
          errorMessage={t("RequiredFieldError") || "This field is required"}
          validate={validate}
          required={true}
          containerWidth={"48%"}
        />
      </div>

      <div className={styles.inputGroup}>
        <div style={{ width: "48%" }} className={styles.inputGroup}>
          <InputQ
            label={t("SterilizationTimeSpan") || "Sterilization Time"}
            name="cicleTime"
            handleChange={handleInputChange}
            value={formData.cicleTime}
            containerWidth="68%"
            type="number"
            min={0}
          />
          <SelectQ
            selectValue={formData.timeUnit}
            handleSelect={handleSelectChange}
            name={"timeUnit"}
            width={"100%"}
            containerWidth={"30%"}
            options={timeUnitOptions}
          />
        </div>
        <div style={{ width: "48%" }} className={styles.inputGroup}>
          <InputQ
            label={t("TemperatureSpan") || "Temperature"}
            name="cicleTemperature"
            handleChange={handleInputChange}
            value={formData.cicleTemperature}
            containerWidth="68%"
            type="number"
            min={0}
          />
          <SelectQ
            selectValue={formData.temperatureUnit}
            handleSelect={handleSelectChange}
            name={"temperatureUnit"}
            width={"100%"}
            containerWidth={"30%"}
            options={temperatureOptions}
          />
        </div>
      </div>

      <TextAreaQ
        width="100%"
        label={t("ObservationSpan") || "Observations"}
        name="cicleObservations"
        handleChange={handleInputChange}
        value={formData.cicleObservations}
      />

      {formData.chargeStatus !== 3 && !isEdit && (
        <ImageDrop
          defaultValue={formData.ticketSterilizer}
          locale={locale}
          onFileChange={onFileChange}
          label={
            t("DragFileOrClickToUploadSpan") ||
            "Drag your file here or click to upload"
          }
        />
      )}

      <div className={styles.buttonGroup}>
        <div></div>
        <button
          className={`${styles.button} ${styles.saveButton}`}
          type="submit"
          onClick={handleSubmit}
        >
          {t("Save") || "Save"}
        </button>
      </div>
    </ModalQ>
  );
}

export default FinishModal;
