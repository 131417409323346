import React from 'react';
import DownloadManager from '../../components/Downloader/DownloadManager';
import { useTheme } from '@material-ui/core/styles';
import useStyles from "./PageDownloaderStyles";

const PageDownloader = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.logoTerrageneContainer}>
                <img alt='' src={theme.palette.images.logo} className={classes.logoTerragene}></img>
            </div>
            <div className={classes.textContainer}>
                Download manager
            </div>
            <div className={classes.cardContainer}>
                <DownloadManager apiMethod={'SASUri'} prefixTranslations={'Agent'} allowGoToMainPage={true}></DownloadManager>
            </div>
            {/* <div className={classes.logoBionovaContainer}>
            <img alt='' src={theme.palette.images.logoBionova} className={classes.logoBionova}></img>
            </div> */}
        </div>
    );
};

export default PageDownloader;