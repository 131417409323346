import React, { useState, useEffect } from "react";
import DDColumn from "../DragAndDrop/DDColumn/DDColumn.jsx";
import DDElement from "../DragAndDrop/DDRow/DDRow.jsx";
import QuantityElement from "../QuantityElement/QuantityElement";
import useWindowSize from "../../utils/Hooks/useWindowSize";
import useStyles from "./styles";
import classnames from "classnames";
import BarcodeReader from "react-barcode-reader";
import ModalLoading from "../ModalLoading/ModalLoading";
import request from "../../utils/request";
import DragAndDrop from "../../components/DragAndDrop/DDPackages.jsx";

export const PackagesGenerator = ({
  data,
  dataType,
  defaultValue: defaultData,
  selectedValue,
  callback = () => {},
  isDetail = false,
  props = {},
}) => {
  const variant = props.variant || "standard";
  const fullwidth = props.fullwidth !== undefined ? props.fullwidth : true;
  const [focused, setFocused] = React.useState(false);
  const value =
    typeof props.value !== "boolean"
      ? props.type === "number"
        ? props.value
        : props.value === undefined || props.value === null
        ? ""
        : props.value.toString()
      : props.value;
  const [materials, setMaterials] = useState(data);

  const [selectedMaterials, setSelectedMaterials] = useState(defaultData || []);
  const handleSelectedMaterials = (defaultData) =>
    setSelectedMaterials(defaultData);
  const [editMaterialName, setEditMaterialName] = useState("");
  const [searchTermMaterials, setSearchTermMaterials] = useState("");
  const [searchTermSelectedMaterials, setSearchTermSelectedMaterials] =
    useState("");
  const [quantity, setQuantity] = useState(1);
  const [modalLoading, setModalLoading] = useState(false);

  const filteredMaterials = materials.filter((material) =>
    material?.name?.toLowerCase().includes(searchTermMaterials?.toLowerCase())
  );
  const filteredSelectedMaterials = selectedMaterials.filter((material) =>
    material?.name
      ?.toLowerCase()
      .includes(searchTermSelectedMaterials?.toLowerCase())
  );

  const classes = useStyles();

  async function fetchData(url, setResponse, setScannerMessage) {
    try {
      const response = await request().get(url);
      const condition =
        response !== null &&
        typeof response !== "undefined" &&
        response.data?.id;

      if (condition) {
        setResponse(response.data);
      }

      return { response, condition };
    } catch (e) {
      setScannerMessage(e.response.data.message);
      return { response: null, condition: false };
    }
  }

  function checkIfExists(serial, array) {
    return array.find((item) => item.serial === serial);
  }

  async function handleData(data) {
    try {
      const { response: response2, condition: materialCondition } =
        await fetchData(`/api/Material/BySerial/${data}`);

      const materialSerialExist = materialCondition
        ? checkIfExists(response2?.data?.serial, selectedMaterials)
        : null;

      if (materialCondition) {
        setSelectedMaterials(
          materialSerialExist
            ? selectedMaterials.filter(
                (i) => i.serial !== response2.data.serial
              )
            : [...selectedMaterials, response2.data]
        );
      }
    } catch (error) {
      console.error("Error al manejar los datos:", error);
    } finally {
      setModalLoading(false);
    }
  }

  const onHandleScan = async (data) => {
    try {
      request()
        .get(`/api/Material/BySerial/${data}`)
        .then((response) => {
          const materialSerialExist = checkIfExists(
            response?.data?.serial,
            selectedMaterials
          );

          setSelectedMaterials(
            materialSerialExist
              ? selectedMaterials.filter(
                  (i) => i.serial !== response.data.serial
                )
              : [...selectedMaterials, response.data]
          );
        });
    } catch (error) {
      setModalLoading(false);
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    if (defaultData) {
      let newMaterials = [];
      defaultData.map(
        (item) =>
          (newMaterials = [
            ...newMaterials,
            {
              ...item.material,
              quantity: item?.quantity,
              materialType: findMaterialType(item.material.materialTypeId),
            },
          ])
      );
      setSelectedMaterials(newMaterials);
    }
  }, [defaultData]);

  useEffect(() => {
    const mockedData = selectedMaterials.map((item) => ({
      materialId: item?.id,
      quantity: item?.quantity,
    }));
    callback(mockedData);
  }, [selectedMaterials]);

  useEffect(() => {
    let newMaterials = [];
    data.map(
      (item) =>
        (newMaterials = [
          ...newMaterials,
          {
            ...item,
            materialType: findMaterialType(item.materialTypeId),
          },
        ])
    );
    setMaterials(newMaterials);
  }, [data]);

  const startDragColumn = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
    e.dataTransfer.setData("ColumnMaterial", true);
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  const doubleClick = (itemID, columnID) => {
    moveItem(itemID, columnID);
  };

  const moveItem = (itemID, columnID) => {
    const item = data.find((item) => item.id == itemID);
    const newQuantity = quantity ? quantity : 1;

    if (!quantity) {
      setQuantity(1);
    }

    if (item && columnID) {
      let newState = [...selectedMaterials];
      const isItemExists = newState.find((material) => material.id === item.id);

      if (!isItemExists) {
        if (!item.serial) item.quantity = newQuantity;
        newState = [...newState, { ...item, quantity: newQuantity }];
      } else {
        isItemExists.quantity += newQuantity;
      }
      setQuantity(1);
      handleSelectedMaterials(newState);
    }
  };

  const paperStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: "transparent",
    height: "100%",
  };
  const quantityPaperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const onDrop = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const columnID = e.dataTransfer.getData("ColumnMaterial");
    const item = data.find((item) => item.id == itemID);

    const newQuantity = quantity ? quantity : 1;
    if (item && columnID) {
      let newState = [...selectedMaterials];
      const isItemExists = newState.find((material) => material.id === item.id);

      if (!isItemExists) {
        if (!item.serial) item.quantity = newQuantity;
        newState = [...newState, item];
      } else {
        isItemExists.quantity += newQuantity;
      }
      setQuantity(1);
      setSelectedMaterials(newState);
    }
  };

  const onDropDelete = (e) => {
    const itemID = e.dataTransfer.getData("itemID");

    const newState = selectedMaterials.filter(
      (material) => material.id != itemID
    );
    setSelectedMaterials(newState);
  };

  const onDelete = (id) => {
    const newState = selectedMaterials.filter((material) => material.id != id);

    setSelectedMaterials(newState);
  };

  const increaseQuantity = () => {
    const newQuantity = quantity ? quantity : 1;

    setQuantity(newQuantity + 1);
  };

  const reduceQuantity = () => {
    const newQuantity = quantity ? quantity : 1;

    if (newQuantity > 1) {
      setQuantity(newQuantity - 1);
    }
  };
  const handleQuantity = (e) => {
    if (e.target.value === "" || /^[1-9][0-9]*$/.test(e.target.value)) {
      setQuantity(parseInt(e.target.value));
    }
  };
  const serialExist = (id) => {
    return selectedMaterials.find((m) => m.id === id);
  };

  const findMaterialType = (id) => dataType.find((mt) => mt.id === id);

  return (
    <div
      style={{
        height: "100%",
        padding: "20px 15px",
        backgroundColor: "#f8f9fa",
      }}
    >
      <BarcodeReader onScan={onHandleScan} />
      <ModalLoading open={modalLoading} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: "20px",
          gap: "60px",
        }}
      >
        <div
          className={classnames(classes.root, {
            [classes.focused]: focused,
            [classes.inputLabelShrink]: value || focused || !props.claro,
            [classes.inputLabelShrinkBright]: props.claro,
            [classes.hasError]: props.error,
            [classes.disabled]: props.disabled,
            [classes.fullwidth]: fullwidth,
          })}
        >
          <label
            className={classnames(
              {
                [classes.inputLabel]: !props.claro,
              },
              {
                [classes.inputLabelDark]: props.claro,
              },
              {
                [classes.inputLabelOutlined]: variant === "outlined",
              }
            )}
          >
            Buscar en Materiales
          </label>
          <div
            className={classnames(classes.inputBaseRoot, {
              [classes.inputUnderline]: variant === "standard",
            })}
          >
            <input
              value={searchTermMaterials}
              onChange={(e) => setSearchTermMaterials(e.target.value)}
              className={classnames(classes.inputBaseInput, {
                [classes.outlinedInputInput]: variant === "outlined",
              })}
            />
          </div>
        </div>
        <div
          className={classnames(classes.root, {
            [classes.focused]: focused,
            [classes.inputLabelShrink]: value || focused || !props.claro,
            [classes.inputLabelShrinkBright]: props.claro,
            [classes.hasError]: props.error,
            [classes.disabled]: props.disabled,
            [classes.fullwidth]: fullwidth,
          })}
        >
          <label
            className={classnames(
              {
                [classes.inputLabel]: !props.claro,
              },
              {
                [classes.inputLabelDark]: props.claro,
              },
              {
                [classes.inputLabelOutlined]: variant === "outlined",
              }
            )}
          >
            Buscar Materiales Seleccionados
          </label>
          <div
            className={classnames(classes.inputBaseRoot, {
              [classes.inputUnderline]: variant === "standard",
            })}
          >
            <input
              value={searchTermSelectedMaterials}
              onChange={(e) => setSearchTermSelectedMaterials(e.target.value)}
              className={classnames(classes.inputBaseInput, {
                [classes.outlinedInputInput]: variant === "outlined",
              })}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "42%", marginRight: "10px" }}>
          <div style={paperStyle}>
            <DDColumn
              quitOverflow={true}
              dragOver={draggingOver}
              drop={onDropDelete}
            >
              {filteredMaterials
                .filter((d) => !d.serial || (d.serial && !serialExist(d.id)))
                .map((material) => (
                  <DDElement
                    obj={material}
                    name={material.name}
                    serial={material.serial}
                    body={material?.materialType?.name}
                    dragStart={startDragColumn}
                    method={selectedValue}
                    materialMethod={material.methodId}
                    columnId={true}
                    doubleClick={doubleClick}
                  />
                ))}
            </DDColumn>
          </div>
        </div>
        <div style={quantityPaperStyle}>
          <QuantityElement
            quantity={quantity}
            reduceQuantity={reduceQuantity}
            increaseQuantity={increaseQuantity}
            handleQuantityChange={handleQuantity}
          />
        </div>
        <div style={{ width: "42%" }}>
          <div style={paperStyle}>
            <DDColumn quitOverflow={true} dragOver={draggingOver} drop={onDrop}>
              {filteredSelectedMaterials.map((material) => (
                <DDElement
                  obj={material}
                  name={material.name}
                  serial={material.serial}
                  quantity={material.quantity}
                  body={material?.materialType?.name}
                  dragStart={startDrag}
                  onDelete={onDelete}
                  method={selectedValue}
                  materialMethod={material.methodId}
                  doubleClick={onDelete}
                  columnId={null}
                />
              ))}
            </DDColumn>
          </div>
        </div>
      </div>
      <p className={classes.wrongMethod}>
        <div className={classes.circleMethod}></div>Wrong Method
      </p>
    </div>
  );
};

const styles = {
  list: {
    padding: "15px 25px",
    gap: "10px",
    maxWidth: "50%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
};
