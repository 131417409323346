import AppMain from "../routes/AppMain";
import Sector from "../routes/Sector";
import User from "../routes/User";
import ConnectedIncubators from "../routes/ConnectedIncubators";
import BILive from "../routes/BILive";
import WasherProvisory from "../routes/WasherProvisory";
import SterilizerProvisory from "../routes/SterilizerProvisory";
import Sterilizer from "../routes/Sterilizer";
import Washer from "../routes/Washer";
import Disinfector from "../routes/Disinfector";
import Instrument from "../routes/Intrument";
import AppParameter from "../routes/AppParameter";
import AppRPE from "../routes/AppRPE";
import IQAS from "../routes/IQAS";
import MyLicenses from "../routes/MyLicenses";
import ChangePassword from "../routes/ChangePassword";
import AppLog from "../routes/AppLog";
import ProLive from "../routes/ProLive";
import ProFinished from "../routes/ProFinished";
import Historical from "../routes/Historical";
import Exporter from "../routes/Exporter";
import Suscriptions from "../routes/Suscriptions";
import BIFinished from "../routes/BIFinished";
import AppMigrationDatabase from "../routes/AppMigrationDatabase";

import AppEula from "../routes/AppEula";
import UserResetPassword from "../routes/UserResetPassword";

import AppModels from "../routes/AppModels";
import Incubators from "../routes/Incubators";
import Sterilization from "../routes/Sterilization";
import StatusIncubator from "../routes/StatusIncubator";
import CreatePackages from "../routes/CreatePackages";
import SavedPackages from "../routes/SavedPackages";
import TicketPackages from "../routes/TicketPackages";
import SavedCharges from "../routes/SavedCharges";
import ManageCycles from "../routes/ManageCycles";
import Rules from "../routes/Rules";
import RuleForm from "../routes/RuleForm";

import AppBrands from "../routes/AppBrands";
import AppMaterialsTypes from "../routes/AppMaterialsTypes";
import AppMaterials from "../routes/AppMaterials";
import AppPackagesTypes from "../routes/AppPackagesTypes";
import BatchRecord from "../routes/BatchRecord";
import ConsultPackage from "../routes/ConsultPackage";
import AppMainQ from "../routes/AppMainQ";
import ManagePackages from "../routes/ManagePackages";
import ManageLoads from "../routes/ManageLoads";
import CreateLoads from "../routes/CreateLoads";
import DetailPackage from "../routes/DetailPackage";
import WashingMenu from "../routes/Washing/WashingMenu";
import CreateWasherLoading from "../routes/Washing/CreateWasherLoading";
import ManageWashCycles from "../routes/Washing/ManageWashCycles";
import ManageWasherLoadings from "../routes/Washing/ManageWasherLoadings";
import CycleDetail from "../routes/CycleDetail";
import ManageHygieneMonitoring from "../routes/HygieneMonitoring/ManageHygieneMonitoring";
import CreateHygieneMonitoring from "../routes/HygieneMonitoring/CreateHygieneMonitoring";

const App = [
  { path: "/appbrands", component: AppBrands },
  { path: "/appmodels", component: AppModels },
  { path: "/appmaterialstypes", component: AppMaterialsTypes },
  { path: "/appmaterials", component: AppMaterials },
  { path: "/apppackagestypes", component: AppPackagesTypes },

  { path: "/appmain", component: AppMain },
  { path: "/appmainQ", component: AppMainQ },

  { path: "/appsector", component: Sector },
  { path: "/appuser", component: User },

  // -- HYGIENE MONITORING --

  { path: "/appmanagehygienemonitoring", component: ManageHygieneMonitoring },
  { path: "/appcreatehygienemonitoring", component: CreateHygieneMonitoring },

  // -- WASHING --
  { path: "/appwashingmenu", component: WashingMenu },

  { path: "/appcreatewasherloading", component: CreateWasherLoading },
  { path: "/appmanagewasherloadings", component: ManageWasherLoadings },
  { path: "/appmanagewashcycle", component: ManageWashCycles },

  // -- STERILIZATION --

  { path: "/appsterilization", component: Sterilization },

  { path: "/appmanagepackages", component: ManagePackages },
  { path: "/appcreatepackages", component: CreatePackages },
  { path: "/appmanageloads", component: ManageLoads },

  { path: "/appcreatepackages/:packageId", component: CreatePackages },
  { path: "/appsavedpackages", component: SavedPackages },
  { path: "/appticketpackages", component: TicketPackages },
  { path: "/appticketpackages/:packageId", component: TicketPackages },
  { path: "/appcreateloads/:chargeId", component: CreateLoads },
  { path: "/appcycledetail/:cycleId", component: CycleDetail },
  { path: "/appcreateloads", component: CreateLoads },
  { path: "/appsavedloads", component: SavedCharges },
  { path: "/appmanagecycles", component: ManageCycles },
  { path: "/appcyclerecord", component: BatchRecord },
  { path: "/appconsultpackage", component: ConsultPackage },

  { path: "/appincubators/:cycleId", component: Incubators },
  { path: "/appautoreaders", component: StatusIncubator },

  { path: "/appconnectedincubators", component: ConnectedIncubators },

  { path: "/appbilive", component: BILive },
  { path: "/appbifinished", component: BIFinished },
  { path: "/appsterilization", component: BIFinished },

  { path: "/AppPILive", component: ProLive },
  { path: "/AppPIProvisional", component: ProFinished },

  { path: "/appciwasherprovisional", component: WasherProvisory },
  { path: "/appcisterilizerprovisional", component: SterilizerProvisory },

  { path: "/appsterilizer", component: Sterilizer },
  { path: "/appwasher", component: Washer },
  { path: "/appdisinfector", component: Disinfector },
  { path: "/appinstrument", component: Instrument },

  { path: "/AppHistorical", component: Historical },
  { path: "/AppExporter", component: Exporter },

  { path: "/appparameters", component: AppParameter },
  { path: "/changepassword", component: ChangePassword },
  { path: "/AppLog", component: AppLog },
  { path: "/appmigrationdatabase", component: AppMigrationDatabase },

  { path: "/AppRPE", component: AppRPE },
  { path: "/AppHTM01-01", component: IQAS },

  { path: "/AppMyLicenses", component: MyLicenses },
  { path: "/subscriptions", component: Suscriptions },

  { path: "/appeula", component: AppEula },

  // {path: "/restore-password/:token/:email/:distId", component: UserResetPassword}
  { path: "/apprules", component: Rules },
  { path: "/appruleform", component: RuleForm },

  { path: "/appdetailpackage", component: DetailPackage },
];

export default App;
