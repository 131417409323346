export default function minutesToHourFormat(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    return "0:00 min";
  }

  const hours = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hourFormat = `${hours}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds} min`;

  return hourFormat;
}
