import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './CircularIndicator.module.css';

const CircularIndicator = ({ totalIndicators, vinculatedIndicators, hasPositive }) => {
  return (
    <Tooltip
      title={
        <div>
          <div>Total indicators: {totalIndicators}</div>
          <div>Vinculated Indicators: {vinculatedIndicators}</div>
        </div>
      }
      arrow
    >
      <div className={styles.container}>
        <div
         className={`${styles.circle} ${hasPositive ? styles.error : totalIndicators !== vinculatedIndicators ? styles.gray : styles.primaryGreen}`}
        ></div>
        <div className={styles.indicatorText}>
          {vinculatedIndicators}/{totalIndicators}
        </div>
      </div>
    </Tooltip>
  );
};

export default CircularIndicator;
