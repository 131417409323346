import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../../components/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import { PrintLabelProvider } from "../../utils/context/PrintLabelContext";
import ManageHygieneMonitoring from "../../pages/ManageHygieneMonitoring/ManageHygieneMonitoring";
import useLocale from "../../utils/Hooks/useLocale";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.ABM,
    key: "ManageHygieneMonitoring",
  };
  const screenName = 336;
  const locale = useLocale(screenName);

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{locale: locale}}>
      <NavBar
        screenName={screenName}
        moduleName={170}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <PrintLabelProvider>
          <ManageHygieneMonitoring />
        </PrintLabelProvider>
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};