import React, { useEffect, useState } from "react";
import styles from "./VinculationPro.module.css";
import request from "../../utils/request";
import IncubatorsCarousel from "../../components/incubatorsCarousel/IncubatorsCarousel";
import ShowIncubator from "../../components/showIncubator/ShowIncubator";
import { getRegionalFormat, getUserId } from "../../utils/sessionHandler.js";
import IndicatorSelectedPro from "../../components/IndicatorSelectedPro/IndicatorSelectedPro.jsx";

import { toast } from "react-toastify";
import df from "../../utils/dateFormater.js";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import t from "../../utils/translation.js";
import Redirect from "../../utils/Redirect";

const emptyTwelvePositions = [
  [
    { positionNumber: 1, status: "", programId: "-" },
    { positionNumber: 2, status: "", programId: "-" },
    { positionNumber: 3, status: "", programId: "-" },
    { positionNumber: 4, status: "", programId: "-" },
    { positionNumber: 5, status: "", programId: "-" },
    { positionNumber: 6, status: "", programId: "-" },
  ],

  [
    { positionNumber: 12, status: "", programId: "-" },
    { positionNumber: 11, status: "", programId: "-" },
    { positionNumber: 10, status: "", programId: "-" },
    { positionNumber: 9, status: "", programId: "-" },
    { positionNumber: 8, status: "", programId: "-" },
    { positionNumber: 7, status: "", programId: "-" },
  ],
];
const emptyTwoPositions = [
  { positionNumber: 1, status: "", programId: "-" },
  { positionNumber: 2, status: "", programId: "-" },
];
const emptyThreePositions = [
  { positionNumber: 1, status: "", programId: "-" },
  { positionNumber: 2, status: "", programId: "-" },
  { positionNumber: 3, status: "", programId: "-" },
];

function VinculationPro({
  load,
  selectedIndicator,
  handleSelectedIndicator,
  handleReload,
  chargeId,
  idManage,
  setShowIncubators
}) {
  const [incubators, SetIncubators] = useState([]);
  const [incubator, setIncubator] = useState("");
  const [incubatorCode, setIncubatorCode] = useState(null);
  const [incubatorId, setIncubatorId] = useState(null);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [twelvePositions, setTwelvePositions] = useState([]);
  const [twoPositions, setTwoPositions] = useState([]);
  const [threePositions, setThreePositions] = useState([]);
  const [readings, setReadings] = useState([]);
  const [allReadings, setAllReadings] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [ charge, setCharge] = useState()
  const [status, setStatus] = useState()


const optionIdIndicator = idManage === true ? selectedIndicator.hygieneIndicatorId : selectedIndicator[0].id;


const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setLoadingCarousel(true);
    request()
      .get(`/api/Incubator?${getUserId()}`)
      .then((response) => {
        SetIncubators(
                response.data.filter(
                  (p) => p.incubatorDistributor.description === "MINIPRO" 
                  || p.incubatorDistributor.description === "IC1020FR"
                )
              );
        setLoadingCarousel(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setLoadingImage(true);
    incubatorId &&
      request()
        .get(`/api/ReadingBI/getreadingsbyincubator?incubatorId=${incubatorId}`)
        .then((response) => {
          filteredClick(response.data);
          setLoadingImage(false);
        })
        .catch((error) => console.log(error));
  }, [incubatorId]);


  const getCharges = (chargeId) => {
    try {

      request()
        .get(`/api/hygiene/${chargeId.id}`)
        .then((response) => {
           setCharge(response.data)
                    
        });
    } catch (e) {
    } finally {
    }
  };

  const getPositions = (value) => {
    setLoadingImage(true);
    request()
      .get(`/api/ReadingBI/getreadingsbyincubator?incubatorId=${value.id}`)
      .then((response) => {
        filteredClick(response.data);
        setLoadingImage(false);
      })
      .catch((error) => console.log(error));
  };

  const filteredClick = (value) => {
    incubator.toUpperCase() === "IC1020FR" || incubator == "FRLCD"
      ? handleClickTwelve(value)
      : incubator.toUpperCase() === "MINIPRO" ||
        incubator.toUpperCase() === "MINIBIO"
      ? handleClickThree(value)
      : handleClickTwo(value);
  };

  const onIncubatorHandler = (value) => {
    setIncubator(value.imageName);
    setIncubatorCode(value.toString);
    setIncubatorId(value.id);
    getPositions(value);
  };

  const handleClickTwelve = (array) => {
    const newArr = emptyTwelvePositions.map((row) => {
      return row.map((position) => {
        const matchingNewPosition = array.find(
          (newPosition) =>
            newPosition.positionNumber === position.positionNumber
        );

        if (matchingNewPosition) {
          return matchingNewPosition;
        }

        return position;
      });
    });

    setTwelvePositions(newArr);
  };

  const handleClickTwo = (array) => {
    const updatedPositions = emptyTwoPositions.map((position) => {
      const matchingNewPosition = array.find(
        (newPosition) => newPosition.positionNumber === position.positionNumber
      );

      if (matchingNewPosition) {
        return matchingNewPosition;
      }

      return position;
    });
    setTwoPositions(updatedPositions);
  };

  const handleClickThree = (array) => {
    const updatedPositions = emptyThreePositions.map((position) => {
      const matchingNewPosition = array.find(
        (newPosition) => newPosition.positionNumber === position.positionNumber
      );

      if (matchingNewPosition) {
        return matchingNewPosition;
      }

      return position;
    });
    setThreePositions(updatedPositions);
  };

  const linkHandler = (
    chargeIndicatorId,
    readingBiId,
    valueD = "0",
    valueProcess,
    valueDUnit
  ) => {
    request()
      .put(
        `/api/hygiene/readingPRO?HygieneIndicatorId=${chargeIndicatorId}&readingproid=${readingBiId}`
      )
      .then(() => {
        setReadings([]);
        setIncubator("");
        // handleSelectedIndicator([]);
        setIncubatorId(null);
        setIncubatorCode("");
        handleReload(true);
        Redirect({
          redirect: true,
          path: "/appmanagehygienemonitoring",
        })
      });
  };

  const handleReadings = (
    position,
    status = "",
    result = "",
    dateFrom = new Date(),
    dateTo = new Date(),
    currentPage = 1,
    itemsPerPage = 10
  ) => {
    request()
      .get(
        `/api/Hygiene/unassignedReadings?HygieneIndicatorId=${optionIdIndicator}&incubatorId=${incubatorId}&position=${position}&result=${result}&statusRead=${status}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      )
      .then((response) => {
        setTotalPages(response.data.totalPages);
        if (response.data.readings.length > 0) {
          setReadings(response.data.readings);
        } else {
          setReadings([]);
        }
      });
  };

  const newPosition = (position, currentPage = 1, itemsPerPage = 10) => {
    request()
      .get(
       `/Api/Hygiene/unassignedReadings?HygieneIndicatorId=${optionIdIndicator}&incubatorId=${incubatorId}&position=${position}&unassigned=${true}`
      )
      .then((response) => {
        handleReadings(position);

        if (response.data.readings.length > 0) {
          setAllReadings(response.data.readings);
          setSelectedPosition(position);
          // setShowIncubators(false)
        } else {
          setAllReadings([]);
          setSelectedPosition(null);
          // setShowIncubators(true)
          toast.error(`${t("NoReadingsForPositionSpan")}: ${position}`);
        }
      });
  };
 
  useEffect(() => {
    getCharges(chargeId)
  }, []);

  return (
    <div className={styles.status}>
      <div className={styles.connected}>
        {!allReadings.length ? (
          <IncubatorsCarousel
            onIncubatorHandler={onIncubatorHandler}
            incubators={incubators}
            loadingCarousel={loadingCarousel}
            incubatorCode={incubatorCode}
            cycle={true}
          />
        ) : (
          <IndicatorSelectedPro
            incubatorCode={incubatorCode}
            readings={readings}
            selectedIndicator={selectedIndicator}
            linkHandler={linkHandler}
            charge={charge}
            getUnassignedIndicators={() => {}}
            position={selectedPosition}
            handleList={handleReadings}
            totalPages={totalPages}
            idManage={idManage}
          />
        )}
      </div>
      <div className={styles.show}>
        <div className={styles.header}>
          <p className={styles.text}>{t("SelectedAutoReaderSpan")}</p>
          <p className={`${styles.text} ${styles.green}`}>{incubatorCode}</p>
        </div>
        <ShowIncubator
          incubator={incubator}
          loadingImage={loadingImage}
          twelvePositions={twelvePositions}
          threePositions={threePositions}
          twoPositions={twoPositions}
          vinculation={true}
          handleClick={newPosition}
          position={selectedPosition}
          hygiene={true}
          status={status}
        />
      </div>
    </div>
  );
}

export default VinculationPro;
