import React, { useContext, useEffect, useState } from "react";
import styles from "./Vinculation.module.css";
import request from "../../../utils/request";
import IncubatorsCarousel from "../../../components/incubatorsCarousel/IncubatorsCarousel";
import ShowIncubator from "../../../components/showIncubator/ShowIncubator";
import { getRegionalFormat, getUserId } from "../../../utils/sessionHandler.js";
import IndicatorSelected from "../../../components/indicatorSelectedModal/IndicatorSelected.jsx";
import LocalizationContext from "../../../utils/LocalizationContext";
import { toast } from "react-toastify";
import df from "../../../utils/dateFormater.js";
import parseFiltersDate from "../../../utils/parseFiltersDate.js";
import t from "../../../utils/translation.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const emptyTwelvePositions = [
  [
    { positionNumber: 1, status: "", programId: "-" },
    { positionNumber: 2, status: "", programId: "-" },
    { positionNumber: 3, status: "", programId: "-" },
    { positionNumber: 4, status: "", programId: "-" },
    { positionNumber: 5, status: "", programId: "-" },
    { positionNumber: 6, status: "", programId: "-" },
  ],

  [
    { positionNumber: 12, status: "", programId: "-" },
    { positionNumber: 11, status: "", programId: "-" },
    { positionNumber: 10, status: "", programId: "-" },
    { positionNumber: 9, status: "", programId: "-" },
    { positionNumber: 8, status: "", programId: "-" },
    { positionNumber: 7, status: "", programId: "-" },
  ],
];
const emptyTwoPositions = [
  { positionNumber: 1, status: "", programId: "-" },
  { positionNumber: 2, status: "", programId: "-" },
];
const emptyThreePositions = [
  { positionNumber: 1, status: "", programId: "-" },
  { positionNumber: 2, status: "", programId: "-" },
  { positionNumber: 3, status: "", programId: "-" },
];

function Vinculation({
  load,
  selectedIndicator,
  handleSelectedIndicator,
  handleReload,
  showIncubators,
  setShowIncubators,
  isFromPackage = false
}) {
  const [incubators, SetIncubators] = useState([]);
  const [incubator, setIncubator] = useState("");
  const [incubatorCode, setIncubatorCode] = useState(null);
  const [incubatorId, setIncubatorId] = useState(null);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [twelvePositions, setTwelvePositions] = useState([]);
  const [twoPositions, setTwoPositions] = useState([]);
  const [threePositions, setThreePositions] = useState([]);
  const [readings, setReadings] = useState([]);
  const [allReadings, setAllReadings] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [totalPages, setTotalPages] = useState(1);
  const locale = useContext(LocalizationContext);
  useEffect(() => {
    setLoadingCarousel(true);
    request()
      .get(`/api/Incubator?${getUserId()}`)
      .then((response) => {
        SetIncubators(response.data);
        setLoadingCarousel(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setLoadingImage(true);
    incubatorId &&
      request()
        .get(`/api/ReadingBI/getreadingsbyincubator?incubatorId=${incubatorId}`)
        .then((response) => {
          filteredClick(response.data);
          setLoadingImage(false);
        })
        .catch((error) => console.log(error));
  }, [incubatorId]);

  const getPositions = (value) => {
    setLoadingImage(true);
    request()
      .get(`/api/ReadingBI/getreadingsbyincubator?incubatorId=${value.id}`)
      .then((response) => {
        filteredClick(response.data);
        setLoadingImage(false);
      })
      .catch((error) => console.log(error));
  };

  const filteredClick = (value) => {
    incubator.toUpperCase() === "IC1020FR" || incubator == "FRLCD"
      ? handleClickTwelve(value)
      : incubator.toUpperCase() === "MINIPRO" ||
        incubator.toUpperCase() === "MINIBIO"
      ? handleClickThree(value)
      : handleClickTwo(value);
  };

  const onIncubatorHandler = (value) => {
    setIncubator(value.imageName);
    setIncubatorCode(value.toString);
    setIncubatorId(value.id);
    getPositions(value);
  };

  const handleClickTwelve = (array) => {
    const newArr = emptyTwelvePositions.map((row) => {
      return row.map((position) => {
        const matchingNewPosition = array.find(
          (newPosition) =>
            newPosition.positionNumber === position.positionNumber
        );

        if (matchingNewPosition) {
          return matchingNewPosition;
        }

        return position;
      });
    });

    setTwelvePositions(newArr);
  };

  const handleClickTwo = (array) => {
    const updatedPositions = emptyTwoPositions.map((position) => {
      const matchingNewPosition = array.find(
        (newPosition) => newPosition.positionNumber === position.positionNumber
      );

      if (matchingNewPosition) {
        return matchingNewPosition;
      }

      return position;
    });
    setTwoPositions(updatedPositions);
  };

  const handleClickThree = (array) => {
    const updatedPositions = emptyThreePositions.map((position) => {
      const matchingNewPosition = array.find(
        (newPosition) => newPosition.positionNumber === position.positionNumber
      );

      if (matchingNewPosition) {
        return matchingNewPosition;
      }

      return position;
    });
    setThreePositions(updatedPositions);
  };

  const linkHandler = async (
    chargeIndicatorId,
    readingBiId,
    valueD = "0",
    valueProcess,
    valueDUnit
  ) => {
    try {
      let url = isFromPackage
        ? `/api/package/BI_Link?packageIndicatorId=${chargeIndicatorId}&readingBiId=${readingBiId}&ValueD=${valueD}&process=${valueProcess}&ValueDUnit=${valueDUnit}`
        : `/api/Charge/BI_Link?chargeIndicatorId=${chargeIndicatorId}&readingBiId=${readingBiId}&ValueD=${valueD}&process=${valueProcess}&ValueDUnit=${valueDUnit}`;
      await request().put(url);
      setReadings([]);
      setIncubator("");
      setShowIncubators(true)
      handleSelectedIndicator({});
      setIncubatorId(null);
      setIncubatorCode("");
      handleReload(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred during the process.";

      const result = await MySwal.fire({
        text: errorMessage,
        icon: "error",
        confirmButtonText: t("AcceptButton", locale) || "Accept",
        customClass: {
          confirmButton: "confirmButton",
        },
        confirmButtonColor: "var(--primary-green)",
        backgroundColor: "#f4f4f4",
      });

      if (result.isConfirmed) {
        return;
      }
    }
  };

  const handleReadings = (
    position,
    status = "",
    result = "",
    dateFrom = new Date(),
    dateTo = new Date(),
    currentPage = 1,
    itemsPerPage = 10
  ) => {
    console.log(isFromPackage)
    let chargeIndicatorId = !isFromPackage ? selectedIndicator.id : ""
    let packageIndicatorId = isFromPackage ? selectedIndicator.id : ""
    
    request()
      .get(
        `/api/readingBI/list?pageSize=${itemsPerPage}&packageIndicatorId=${
          packageIndicatorId
        }&chargeIndicatorId=${
          chargeIndicatorId
        }&page=${currentPage}&position=${position}&incubatorId=${incubatorId}&unassigned=${true}&status=${status}&resultStatus=${result}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}&includeCount=${true}&isFromPackage=${isFromPackage}`
      )
      .then((response) => {
        setTotalPages(response.data.totalPages);
        if (response.data.readingBiList.length > 0) {
          setReadings(response.data.readingBiList);
          setShowIncubators(false);

        } else {
          setReadings([]);
        }
      });
  };

  const newPosition = (position, currentPage = 1, itemsPerPage = 10) => {
    let chargeIndicatorId = !isFromPackage ? selectedIndicator.id : ""
    let packageIndicatorId = isFromPackage ? selectedIndicator.id : ""
    
    
    request() 
      .get(
        `/api/readingBI/list?pageSize=${itemsPerPage}&packageIndicatorId=${
          packageIndicatorId
        }&chargeIndicatorId=${
          chargeIndicatorId
        }&page=${currentPage}&position=${position}&incubatorId=${incubatorId}&unassigned=${true}&includeCount=${true}&isFromPackage=${isFromPackage}`
      )
      .then((response) => {
        handleReadings(position);

        if (response.data.readingBiList.length > 0) {
          setAllReadings(response.data.readingBiList);
          setSelectedPosition(position);
          setShowIncubators(false);
        } else {
          setAllReadings([]);
          setShowIncubators(true);

          setSelectedPosition(null);
          toast.error(`${t("NoReadingsForPositionSpan")}: ${position}`);
        }
      });
  };

  return (
    <div className={styles.status}>
      <div className={styles.connected}>
        {showIncubators ? (
          <IncubatorsCarousel
            onIncubatorHandler={onIncubatorHandler}
            incubators={incubators}
            loadingCarousel={loadingCarousel}
            incubatorCode={incubatorCode}
            cycle={true}
          />
        ) : (
          <IndicatorSelected
            incubatorCode={incubatorCode}
            readings={readings}
            selectedIndicator={selectedIndicator}
            linkHandler={linkHandler}
            charge={load}
            getUnassignedIndicators={() => {}}
            position={selectedPosition}
            handleList={handleReadings}
            totalPages={totalPages}
          />
        )}
      </div>
      <div className={styles.show}>
        <div className={styles.header}>
          <p className={styles.text}>{t("SelectedAutoReaderSpan")}</p>
          <p className={`${styles.text} ${styles.green}`}>{incubatorCode}</p>
        </div>
        <ShowIncubator
          incubator={incubator}
          loadingImage={loadingImage}
          twelvePositions={twelvePositions}
          threePositions={threePositions}
          twoPositions={twoPositions}
          vinculation={true}
          handleClick={newPosition}
          position={selectedPosition}
        />
      </div>
    </div>
  );
}

export default Vinculation;
