import React, { useContext } from "react";
import styles from "./IndicatorLabel.module.css";
import { FaRegCalendarTimes } from "react-icons/fa";
import indicatorImg from "../../assets/images/chemical.jpg";
import { getImageIndicatorByName } from "../../utils/indicatorImage";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";

export default function IndicatorLabel({
  name,
  lot,
  expirationDate,
  serial,
  quantity,
  zone,
  load,
  backgroundColor,
  border,
  animation,
  status,
  nro,
  result = null,
  evaluationResult = null,
  resultBiologic = null,
  pcd,
  resultCH = null,
  userResultCH = null,
  detail,
  category = "Sterilization",
}) {
  const getColorForResult = (result) => {
    switch (result) {
      case 3:
        return "red";
      case 4:
        return "green";
      default:
        return "";
    }
  };
  console.log(name, resultBiologic)

  const getColorForEvaluationResult = (evaluationResult) => {
    switch (evaluationResult) {
      case 1:
        return "red";
      case 0:
        return "green";
      default:
        return "";
    }
  };

  const getColorForResultBiologic = (result) => {
    switch (result) {
      case 1:
        return "red";
      case 2:
        return "green";
      case 3:
        return "black";
      default:
        return "";
    }
  };

  const getResultText = (result) => {
    switch (result) {
      case 3:
        return `${t("UnsafeSpan")} - `;
      case 4:
        return `${t("SafeSpan")} - `;
      default:
        return "";
    }
  };

  const getEvaluationText = (evaluationResult) => {
    switch (evaluationResult) {
      case 0:
        return t("AgreeSpan");
      case 1:
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const getResultBiologic = (result) => {
    switch (result) {
      case 1:
        return t("PositiveSpan");
      case 2:
        return t("NegativeSpan");
      case 3:
        return t("CanceledSpan");
      default:
        return "";
    }
  };

  const getResultCH = (result) => {
    switch (result) {
      case 3:
        return `${t("UnsafeSpan")} - `;
      case 4:
        return `${t("SafeSpan")} - `;
      default:
        return "";
    }
  };

  const getColorForResultCH = (result) => {
    switch (result) {
      case 3:
        return "red";
      case 4:
        return "green";
      default:
        return "";
    }
  };

  const getUserResultCH = (result) => {
    switch (result) {
      case 0:
        return t("AgreeSpan");
      case 1 :
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const getColorForUserResultCH = (result) => {
    switch (result) {
      case 0:
        return "green";
      case 1:
        return "red";
      default:
        return "";
    }
  };


  return (
    <div
      className={`${styles.labelContainer} ${styles[backgroundColor]} ${
        border ? styles.border : ""
      } ${animation ? styles.border : ""}`}
    >
      {
        <div className={styles.indicatorInfo}>
          <div>
            <p className={styles.indicatorName}>
              {name} {serial && ` - ${serial}`}
            </p>
            {(result || userResultCH === 0) && (
              <span
                className={styles.result}
                style={{ color: getColorForResult(result) }}
              >
                {getResultText(result)}
              </span>
            )}
            {(evaluationResult || userResultCH === 0) && (
              <span
                className={styles.result}
                style={{ color: getColorForEvaluationResult(evaluationResult) }}
              >
                {getEvaluationText(evaluationResult)}
              </span>
            )}
            {(resultBiologic || userResultCH === 0) && (
              <span
                className={styles.result}
                style={{ color: getColorForResultBiologic(resultBiologic) }}
              >
                {getResultBiologic(resultBiologic)}
              </span>
            )}
            {(resultCH || userResultCH === 0) && (
              <span
                className={styles.result}
                style={{ color: getColorForResultCH(resultCH) }}
              >
                {getResultCH(resultCH)}
              </span>
            )}
            {(userResultCH || userResultCH === 0) && (
              <span
                className={styles.result}
                style={{ color: getColorForUserResultCH(userResultCH) }}
              >
                {getUserResultCH(userResultCH)}
              </span>
            )}
            {!zone && quantity && (
              <p className={styles.quantity}>
                {`${t("QuantitySpan")}: ${quantity}`}
              </p>
            )}
            {pcd && <p className={styles.quantity}>{`PCD: ${pcd}`}</p>}

            {zone && (
              <p className={styles.quantity}>
                {" "}
                {`${
                  category.toUpperCase() === "WASHING"
                    ? t("BlankEntry1")
                    : t("ZoneSpan")
                }: ${zone}`}
              </p>
            )}
          </div>
          <div className={styles.indicatorFooter}>
            <p>{lot}</p>
            <p>
              <FaRegCalendarTimes /> {df(expirationDate, "monthYear")}
            </p>
          </div>
        </div>
      }
      <div className={styles.imgContainer}>
        <img src={getImageIndicatorByName(name?.toUpperCase())} alt={name} />
      </div>
    </div>
  );
}
