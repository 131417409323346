import React, { useEffect, useState } from "react";
import styles from "./LoadDetails.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";
import df from "../../utils/dateFormater";
import packageImg from "../../assets/images/MENU PACKAGE.svg";
import packageInLoad from "../../assets/images/PACKAGE IN A LOAD.svg";
import ColumnQ from "../ColumnQ/ColumnQ";
import request from "../../utils/request";
import loadImg from "../../assets/images/MENU LOAD.svg";
import { Tooltip } from "@material-ui/core";
import sterilizerIcon from "../../assets/images/greenSterilizer.svg";
import PackageCard from "../CicleModal/PackageCard";
import SpinnerQ from "../SpinnerQ/SpinnerQ";
import t from "../../utils/translation";
import { CiCalendar, CiTimer, CiUser } from "react-icons/ci";
import { CiCircleInfo } from "react-icons/ci";
import { GoLink, GoUnlink } from "react-icons/go";
import { IoBookOutline, IoReload } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { FaTemperatureHigh } from "react-icons/fa";
import ModalLoading from "../ModalLoading/ModalLoading";
import biologicalImg from "../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../assets/images/QUIMICO.svg";
import indicatorsImg from "../../assets/images/INDICATORS.svg";
import PCDItemCycle from "../PCDItemCycle/PCDItemCycle";

export default function ManageDetail({ loadId, locale }) {
  const [load, setLoad] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  const getLoadId = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/Charge/chargedto?chargeId=${loadId}`)
        .then((response) => {
          const data = response.data;
          setLoad(data);
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  const status = (value) => {
    return value === 1 ? t("BuildingLoadSpan") : t("ToSterilizeSpan");
  };

  useEffect(() => {
    getLoadId();
    return () => {
      setLoad([]);
    };
  }, []);

  return (
    <div className={styles.container}>
      <ModalLoading open={modalLoading} />
      <div className={styles.detailHeader}>
        <div className={styles.headerContent}>
          <div className={styles.headerImage}>
            <img src={loadImg} alt="Load Image" />
          </div>
          <div className={styles.headerText}>
            <div className={styles.headerTop}>
              <p className={styles.headerTitle}>
                {load.cicleNumber && `Cycle Number: ${load.cicleNumber} - `}{`Used Program: ${load.usedProgram}`}
              </p>
              <div className={styles.detailItem}>
                <div className={styles.detailItem} style={{ gap: "10px" }}>
                  {/* <img
                className={styles.iconImg}
                alt={status(load.chargeStatus)}
                src={findImg(load.chargeStatus)}
              /> */}
                  <span className={styles.infoStatus}>
                    {status(load.chargeStatus)}
                  </span>
                </div>
                {/* {load.observations && (
              <Tooltip
                title={
                  <div className={styles.detailItem}>
                    <span className={styles.info}>{load.observations}</span>
                  </div>
                }
              >
                <CiCircleInfo className={styles.iconInfo} />
              </Tooltip>
            )} */}
              </div>
            </div>
            <div className={styles.headerDetails}>
              <div className={styles.detailItem}>
                <img
                  className={styles.iconSterilizer}
                  src={sterilizerIcon}
                  alt="sterilizer icon"
                />
                <span
                  className={styles.info}
                >{`${load.sterilizerName} - ${load.methodName}`}</span>
              </div>
            </div>
            <div className={styles.headerBottom}>
              <div className={styles.detailItem}>
                <CiCalendar className={styles.iconStyle} />
                <span className={styles.info}>
                  {df(load.creationDate, "dateTime")}
                </span>
              </div>
              <div className={styles.detailItem}>
                <CiUser className={styles.iconStyle} />
                <span className={styles.info}>{load.operator}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.detailInfo}>
        <div className={styles.packagesSection}>
          <p className={styles.infoTitle}>{t("PackageSpan")}</p>
          <div className={styles.packagesContainer}>
            {load.packages &&
              load.packages?.map((pkg) => (
                <PackageCard key={pkg.packageId} pkg={pkg} />
              ))}
          </div>
        </div>
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.packagesSection}>
          <p className={styles.infoTitle}>{t("IndicatorsSpan")}</p>
          {/* <div className={styles.packagesContainer}>
               {load.indicators && load.indicators.length > 0 && (
              <div>
                <div className={styles.indicatorsContainer}>
                  {load.indicators?.map((indicator) => (
                    <div className={styles.indicatorItem}>
                      <IndicatorLabel
                        name={indicator.indicator.name}
                        lot={indicator.lot}
                        serial={indicator.serial}
                        quantity={indicator.quantity}
                        expirationDate={indicator.expirationDate}
                        key={indicator.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            </div> */}
          <div className={styles.imageTitleContainer}>
            <img
              src={biologicalImg}
              alt="biologicalIndicator"
              className={styles.image}
            />
            <h2 className={styles.sectionSubTitle}>
              {" "}
              {t("BiologicalIndicatorSpan")}
            </h2>
          </div>
          <div className={styles.indicatorsContainer}>
            {load.indicators &&
            load.indicators.filter((i) =>
              i.indicator.name.toUpperCase().includes("BT")
            ).length === 0 ? (
              <div className={styles.noDataMessage}>
                {t("NoBiologicalIndicatorSpan")}
              </div>
            ) : (
              load.indicators &&
              load.indicators
                .filter((i) => i.indicator.name.toUpperCase().includes("BT"))
                .map((i) => (
                  <div className={styles.indicatorGeneral2}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "20px",
                      }}
                    >
                      <div className={styles.indicatorItem}>
                        <IndicatorLabel
                          zone={i.indicatorModelArea.description}
                          backgroundColor={"white"}
                          name={i.indicator.name}
                          lot={i.lot}
                          serial={i.serial}
                          expirationDate={i.indicatorExpirationDate}
                          key={i.id}
                          border={true}
                        />
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>

          <div className={styles.imageTitleContainer}>
            <img
              src={chemicalImg}
              alt="chemicalIndicator"
              className={styles.image}
            />
            <h2 className={styles.sectionSubTitle}>
              {" "}
              {t("ChemicalIndicatorSpan")}
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <div className={styles.indicatorsContainer}>
              {load.indicators &&
              load.indicators.filter(
                (i) => !i.indicator.name.toUpperCase().includes("BT")
              ).length === 0 ? (
                <div className={styles.noDataMessage}>
                  {t("NoChemicalIndicatorSpan")}
                </div>
              ) : (
                load.indicators &&
                load.indicators
                  .filter((i) => !i.indicator.name.toUpperCase().includes("BT"))
                  .map((indicator) => (
                    <div className={styles.indicatorItem} key={indicator.id}>
                      <IndicatorLabel
                        zone={indicator.indicatorModelArea.description}
                        backgroundColor={"white"}
                        name={indicator.indicator.name}
                        lot={indicator.lot}
                        serial={indicator.serial}
                        quantity={indicator.quantity}
                        expirationDate={indicator.indicatorExpirationDate}
                        border={true}
                      />
                    </div>
                  ))
              )}
            </div>
          </div>

          <div className={styles.imageTitleContainer}>
            <img
              src={indicatorsImg}
              alt="indicators"
              className={styles.image}
            />
            <h2 className={styles.sectionSubTitle}>{t("BlankEntry9")}</h2>
          </div>
          <div className={styles.indicatorsContainer}>
            {load.pcdList && load.pcdList.length === 0 ? (
              <div className={styles.noDataMessage}>{t("BlankEntry10")}</div>
            ) : (
              load.pcdList &&
              load.pcdList.map((indicator) => (
                <div className={styles.indicatorGeneral2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "20px",
                    }}
                  >
                    <div className={styles.indicatorItem}>
                      <PCDItemCycle
                        zone={indicator.pcd?.indicatorModelArea?.description}
                        detail={true}
                        indicator={indicator}
                        nameModelArea={indicator.pcd.zone}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {load.observations && (
        <div className={styles.detailInfo}>
          <div className={styles.packagesSection}>
            <p className={`${styles.infoTitle}`}>{t("ObservationSpan")}</p>
            <div className={styles.observationContainer}>
              <p className={styles.observation}>{load.observations}</p>
              <div className={styles.observationInfo}>
                <p>{df(load.creationDate, "dateTime")}</p>
                <p>{load.creationUserName}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
