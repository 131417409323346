import React from "react";
import styles from "./PageContainer.module.css";
import { IoIosArrowBack } from "react-icons/io";
import { RiSearchLine } from "react-icons/ri";
import Redirect from "../../utils/Redirect";
import sterilizationImage from "../../assets/images/Sterilization.svg";
import hygieneMonitoringImage from "../../assets/images/ICONO HYGIENE MONITORING.svg";
import { ToastContainer } from "react-toastify";
import StepProgress from "./StepProgress/StepProgress";
import washingImg from "../../assets/images/WASHING.svg";
import t from "../../utils/translation";
import WashingStepper from "./WashingStepper/WashingStepper";
import SterilizationStepper from "./SterilizationStepper/SterilizationStepper";

export default function PageContainer({
  categoryId,
  category,
  subcategory,
  title,
  subtitle,
  children,
  menu = false,
  backUrl,
  newButton = false,
  newButtonUrl,
  scan = false,
  clickBack = false,
  onClickBack = () => {},
  progress = false,
  currentStep,
  washerLoad = false,
}) {
  const image = categoryId == 1 ? sterilizationImage : categoryId == 2 ? washingImg : hygieneMonitoringImage;
  const imageColor =
    categoryId == 1
      ? styles.sterilization
      : categoryId == 2
      ? styles.washing
      : styles.hygieneMonitoring;

      function getCurrentStep(index) {
        // Estos arrays determinan el orden global de pasos
        const firstArray = ["WasherLoadings", "WashCycles"];
        const secondArray = ["Packages", "Loads", "Cycles"];
      
        const totalItems = secondArray.length + firstArray.length;
        const adjustedIndex = (index - 1) % totalItems;
      
        if (adjustedIndex < secondArray.length) {
          return secondArray[adjustedIndex];   // "Packages", "Loads", "Cycles", etc.
        } else {
          return firstArray[adjustedIndex - secondArray.length]; 
          // "WasherLoadings", "WashCycles"
        }
      }
      

  return (
    <div className={styles.pageContainer}>
      <ToastContainer />
      {progress &&
        (categoryId === 1 ? (
          <SterilizationStepper
          currentStepString={getCurrentStep(currentStep)}
          />
        ) : (
          <WashingStepper
          currentStepString={getCurrentStep(currentStep)}
          />
        ))}
      <div className={styles.page}>
        <IoIosArrowBack
          className={styles.backIcon}
          onClick={() =>
            clickBack
              ? onClickBack()
              : Redirect({
                  redirect: true,
                  path: backUrl,
                  // parameters: { moduleId: moduleName, screenId: screenName },
                })
          }
        />
        <div className={styles.header}>
          <div className={styles.breadcrumb}>
            <div className={`${styles.imageContainer} ${imageColor}`}>
              <img src={image} className={styles.image} alt={category} />
            </div>
            <div className={styles.titlesContainer}>
              <p className={styles.category}>{category}</p>
              <p className={styles.category}>
                {subcategory && `| ${subcategory}`}
              </p>
              <p className={styles.title}>{title && `| ${title}`}</p>
              <p className={styles.title}>{subtitle && `| ${subtitle}`}</p>
            </div>
            {newButton && (
              <button
                className={`${styles.newButton} ${
                  categoryId == 1
                    ? styles.backgroundGreen
                    : categoryId == 2
                    ? styles.backgroundBlue
                    : styles.backgroundPurple
                }`}
                onClick={() => {
                  Redirect({
                    redirect: true,
                    path: newButtonUrl,
                    // parameters: { moduleId: moduleName, screenId: screenName },
                  });
                }}
              >
                {t("NewSpanButton")}
              </button>
            )}
          </div>
          {menu && (
            <div className={styles.searchContainer}>
              <RiSearchLine className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Smart search"
                className={styles.searchInput}
              />
            </div>
          )}
          {/* {scan && (
              <img src={smartScan} />
          )} */}
        </div>
        {!washerLoad && <div className={styles.content}>{children}</div>}
      </div>
      {washerLoad && <div className={styles.washerLoad}>{children}</div>}
    </div>
  );
}
