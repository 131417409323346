import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styles from "./InputQ.module.css";


const InputQ = ({
  containerWidth = "100%",
  containerMargin = "0px",
  handleChange,
  defaultValue,
  value,
  label,
  labelTop = false,
  type,
  inputRef,
  required,
  disabled,
  name,
  errorMessage = "Campo requerido",
  validate = false,
  additionalValidation = false,
  additionalMessage = "",
  onHandleScan = null,
  min,
  onKeyDown = () => {},
  onFocus = () => {},
  autocomplete = "on",
  placeholder
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [input, setInput] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleBlur = (e) => {
    if (required && !e.target.value) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  useEffect(() => {
    if (validate && required && !value) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [validate, value, required]);

  const handleKeyDown = (e) => {
    onKeyDown(e);

    if (onHandleScan) {
      const currentTime = new Date().getTime();
      const timeDifference =
        currentTime - (inputRef?.current?.lastInputTime || 0);

      if (
        timeDifference < 100 &&
        e.key === "Enter" &&
        inputRef?.current?.value.length > 6
      ) {
        e.preventDefault();
        inputRef.current.blur();
        const scannedText = inputRef?.current?.value || "";

        for (let i = 0; i < scannedText.length; i++) {
          const substring = scannedText.substring(i);
          onHandleScan(substring);

          const regex = /^01\d{14}17.*$/;
          if (regex.test(substring)) {
            handleChange((prevValue) => prevValue.replace(substring, ""), true);
            break;
          }
        }
      }
      inputRef.current.lastInputTime = currentTime;
    }
  };

  const handleChangeInput = (e) => {
    handleChange(e);
    setInput(e.target.value);
    if (!(required && e.target.value === "")) {
      setShowMessage(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div
      className={styles.inputContainer}
      style={{ width: containerWidth, marginBottom: containerMargin }}
    >
      <div  className={
          labelTop ? styles.inputWrapperTop : styles.inputWrapper
        }>
     {label && labelTop && (
          <label className={styles.labelTop}>
            {label} {required && "*"}
          </label>
        )}

        {!labelTop && label && (
          <label className={styles.label}>
            {label} {required && "*"}
          </label>
        )}
        <div className={styles.inputFieldContainer}>
          <input
            onChange={handleChangeInput}
            onBlur={handleBlur}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            className={styles.inputField}
            type={type === "password" && showPassword ? "text" : type || "text"}
            defaultValue={defaultValue}
            value={value}
            ref={inputRef}
            disabled={disabled}
            name={name}
            min={min}
            autoComplete={autocomplete}
            placeholder={placeholder}
          />
           {type === "password" && (
              <button
                type="button"
                className={styles.passwordToggle}
                onClick={togglePasswordVisibility}
                aria-label="Toggle password visibility"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            )}
        </div>
      </div>
      {showMessage ? (
            <span className={styles.errorMessage}>{errorMessage}</span>
          ) : additionalValidation ? (
            <span className={styles.errorMessage}>{additionalMessage}</span>
          ) : null}
    </div>
  );
};

export default InputQ;