import history from "../utils/history";

const useRedirect = (props) => {
  const { redirect, path, parameters } = props;

  if (redirect) {
    history.push({
      pathname: path,
      state: parameters,
      
    });
  }
};

export default useRedirect;
