import { CircularProgress } from "@material-ui/core";
import React from "react";
import Time from "../../TimeFormat/Time";
import Tooltip from "../../Tooltip/Tooltip";
import TwelveCirclePro from "./TwelveCirclePro";
import SpinnerQ from "../../SpinnerQ/SpinnerQ";

function TwelvePositionPro({
  imgPositionsFiltered,
  loadingImage,
  incubator,
  colorStatus,
  programFiltered,
  position,
  twelvePositions,
  t,
  locale,
  condition,
  handleClick,
  vinculation,
}) {
  // Mapeamos las posiciones actuales (del 1 al 12)
  const twelvePositionsMapped1 = twelvePositions[0]?.map((p, index) => (
    <TwelveCirclePro
      handleClick={handleClick}
      type={"r"}
      p={p}
      condition={condition}
      programFiltered={programFiltered}
      t={t}
      locale={locale}
      colorStatus={colorStatus}
      position={position}
      vinculation={vinculation}
      disabled={true} // Deshabilitamos los círculos 1-12
    />
  ));

  const twelvePositionsMapped2 = twelvePositions[1]?.map((p, index) => (
    <TwelveCirclePro
      handleClick={handleClick}
      type={"l"}
      p={p}
      position={position === p.positionNumber}
      condition={condition}
      programFiltered={programFiltered}
      t={t}
      locale={locale}
      colorStatus={colorStatus}
      vinculation={vinculation}
      disabled={true} // Deshabilitamos los círculos 1-12
    />
  ));

  return incubator == "IC1020FR" || incubator == "FRLCD" ? (
    loadingImage ? (
      <div className="spinnerContainer">
        <SpinnerQ />
      </div>
    ) : (
      <div
        style={{
          backgroundImage: `url(${imgPositionsFiltered(incubator)})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="circleContainer">
          {/* Círculo adicional para el número 1, a la izquierda del 12 */}
          <div style ={{ 
             position: "absolute",
             marginLeft: "130px", 
             marginBottom: "30px"
          }}>
            <TwelveCirclePro
              handleClick={handleClick}
              type={"l"}
              p={{ positionNumber: 1 }}
              colorStatus={colorStatus}
              position={position === 1}
              condition={condition}
              programFiltered={programFiltered}
              t={t}
              locale={locale}
              vinculation={vinculation}
              disabled={false} // Habilitamos este círculo
            />
          </div>
        </div>

        {/* Resto de los círculos */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            height: "100%",
            width: "50%",
            paddingBottom: "15px",
          }}
        >
          {twelvePositionsMapped1}
        </div>

        {/* Los círculos 2 al 12 */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: "100%",
            width: "50%",
            paddingBottom: "15px",
          }}
        >
          {twelvePositionsMapped2}
        </div>
      </div>
    )
  ) : null;
}

export default TwelvePositionPro; 