import React, { useState, useContext, useEffect } from "react";
import request from "../../../utils/request.js";
import LocalizationContext from "../../../utils/LocalizationContext.js";
import PageContainer from "../../../components/PageContainer/PageContainer.jsx";
import WashDetail from "./WashDetail/WashDetail.jsx";
import WashBoard from "./WashCycleBoard/WashCycleBoard.jsx";
import df from "../../../utils/dateFormater";
import { getRegionalFormat, getUserId } from "../../../utils/sessionHandler.js";
import t from "../../../utils/translation.js";
import WashFinishModal from "./WashFinishModal/WashFinishModal.jsx";
import parseFiltersDate from "../../../utils/parseFiltersDate.js";
import {
  getFilterValue,
  setFilterValue,
} from "../../../utils/sessionFilters.js";
import ChemicalVinculation from "../../../components/ChemicalVinculation/ChemicalVinculation.jsx";

const ManageWashCycles = () => {
  const locale = useContext(LocalizationContext);

  const [filters, setFilters] = useState({
    dateFrom: getFilterValue("dateFrom", new Date(), "manageWashCycles"),
    dateTo: getFilterValue("dateTo", new Date(), "manageWashCycles"),
    selectedWasher: getFilterValue("selectedWasher", "", "manageWashCycles"),
  });

  const [loads, setLoads] = useState([]);
  const [toStartLoads, setToStartLoads] = useState([]);
  const [inProgressLoads, setInProgressLoads] = useState([]);
  const [finishedLoads, setFinishedLoads] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [cycleStartDate, setCycleStartDate] = useState(null);
  const [loadID, setLoadID] = useState(null);
  const [detailId, setDetailId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [reloadDetail, setRealoadDetail] = useState(false);
  const [loadingFinishModal, setLoadingFinishModal] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [chemicalLoading, setChemicalLoading] = useState(false);
  const [cycleDetail, setCycleDetail] = useState({});

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };
      setFilterValue(key, value, "manageWashCycles");
      return updatedFilters;
    });
  };

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      handleFilterChange("dateFrom", "");
      handleFilterChange("dateTo", "");
    } else {
      handleFilterChange(type, value);
    }
  };

  const handleLoadingFinishModal = (isLoading) => {
    setLoadingFinishModal(isLoading);
  };

  const getLoads = async () => {
    setModalLoading(true);
    try {
      const { dateFrom, dateTo, selectedWasher } = filters;
      const response = await request().get(
        `/api/Chargewashing/list?pageSize=1000000&Status=6&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setLoads(response.data.chargeWashingListatorList);
      setToStartLoads(
        response.data.chargeWashingListatorList.filter(
          (l) => l.chargeStatus === 2
        )
      );
      setInProgressLoads(
        response.data.chargeWashingListatorList.filter(
          (l) => l.chargeStatus === 3
        )
      );
      setFinishedLoads(
        response.data.chargeWashingListatorList.filter(
          (l) => l.chargeStatus === 4
        )
      );
    } catch (error) {
      console.error("Error fetching loads:", error);
    } finally {
      setModalLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEdit(false);
    setFormData({});
  };

  const handleLoad = (chargeWashingId) => {
    setDetailId(chargeWashingId);
    setCycleDetail(
      loads.find((load) => load.chargeWashingId == chargeWashingId)
    );
    setSelectedIndicator({});
  };

  const handleDrop = async (value, status, e, finishObject) => {
    e.preventDefault();
    try {
      const { data: infoLoad } = await request().get(
        `/api/chargeWashing/chargeWashingDto?chargeWashingId=${value}`
      );

      let newIndicators = infoLoad.indicatorsWashing.map((i) => ({
        IndicatorId: i.indicatorWashingId,
        quantity: 1,
        serial: i.serial || null,
        lot: i.lot || null,
        modelAreasId: i.modelAreasId,
      }));

      let newPackages = infoLoad.packagesWashing.map((p) => ({
        PackageId: p.packageWashingId,
      }));

      const requestData = {
        chargeWashingId: infoLoad.chargeWashingId,
        washerId: infoLoad.washerId,
        description: infoLoad.description,
        chargeStatus: status,
        indicatorsWashing: newIndicators,
        packagesWashing: newPackages,
        ...finishObject,
      };

      await request()
        .put("/api/chargeWashing/modifyChargeWashing", requestData)
        .then(() => {
          if (status === 4) closeModal();
          getLoads();
        })
        .catch((error) => {
          console.error("Error updating charge status:", error);
        });
    } catch (e) {
      console.error("Error handling drop:", e);
    }
  };

  const onConfirmEdit = (id) => {
    setLoadID(id);
    setRealoadDetail(true);
  };

  const closeChemicalVinculation = () => {
    setSelectedIndicator(null);
    setRealoadDetail(true);

  };

  const onChemicalVinculation = async (result, observations, image) => {
    setChemicalLoading(true);
    let requestBody = {
      // El usuario pone 0 (safe) o 1 (unsafe)
      result: result,
      loteProduct: selectedIndicator.lot,
      gtin: "07798164678151",
      productName: selectedIndicator.indicator.name,
      expirationDate: selectedIndicator.indicatorExpirationDate,
      // guid: load.GUID.toUpperCase(),
      // deviceSN: DeviceInfo.getSerialNumberSync(),
      base64Image: image,
      // User observation
      EditUserId: getUserId(),
      Cycle: cycleDetail.cycleNumber,
      DeviceSn: "BionovaQ",
      Guid: "00000000-0000-0000-0000-000000000000",
      VisualResult: 0,
      SterilizerId: cycleDetail.sterilizerId,
      Observations: observations,
      isFromCharge: true,
      ChargeIndicatorId: selectedIndicator.id,
      chargeId: cycleDetail.chargeId,
    };
    try {
      await request().post("/api/ReadingCHELens/washerLink", requestBody);
      closeChemicalVinculation();
    } catch (err) {
      console.error(err);
    } finally {
      setChemicalLoading(false);
    }
  };

  useEffect(() => {
    getLoads();
  }, [filters]);

  return (
    <PageContainer
      categoryId={2}
      currentStep={5}
      category={t("WashingSpan")}
      subcategory={t("WashCycleSpan")}
      title={t("ManageWashCycles")}
      menu={false}
      backUrl={"/appwashingmenu"}
      scan={true}
      clickBack={detailId ? true : null}
      onClickBack={() => {
        setDetailId(null);
      }}
      progress={true}
    >
      {selectedIndicator?.id && (
        <ChemicalVinculation
          isChemical={selectedIndicator?.id}
          onClose={closeChemicalVinculation}
          handleSubmit={onChemicalVinculation}
          category="washing"
        />
      )}
      {isModalOpen && (
        <WashFinishModal
          isModalOpen={isModalOpen}
          handleDrop={handleDrop}
          closeModal={closeModal}
          locale={locale}
          loadId={loadID}
          startDate={cycleStartDate}
          formData={formData}
          handleFormData={setFormData}
          isEdit={isEdit}
          onConfirmEdit={onConfirmEdit}
          loadingFinishModal={loadingFinishModal}
        />
      )}
      {detailId ? (
        <WashDetail
          loadId={detailId}
          locale={locale}
          handleFormData={setFormData}
          handleLoadId={setLoadID}
          openEdit={setIsModalOpen}
          handleIsEdit={setIsEdit}
          reloadDetail={reloadDetail}
          handleReload={setRealoadDetail}
          handleIndicator={setSelectedIndicator}
        />
      ) : (
        <WashBoard
          locale={locale}
          loads={loads}
          toStartLoads={toStartLoads}
          startedLoads={inProgressLoads}
          finishedLoads={finishedLoads}
          handleChangeDate={handleDateChange}
          dateTo={filters.dateTo}
          dateFrom={filters.dateFrom}
          modalLoading={modalLoading}
          handleFilterWasher={(value) =>
            handleFilterChange("selectedWasher", value)
          }
          selectedSterilizer={filters.selectedWasher}
          handleDrop={handleDrop}
          handleLoad={handleLoad}
          handleStartDate={setCycleStartDate}
          handleLoadId={setLoadID}
          handleModal={setIsModalOpen}
          handleFormData={setFormData}
          handleLoadingFinishModal={handleLoadingFinishModal}
 
          />
      )}
    </PageContainer>
  );
};

export default ManageWashCycles;
