import React, { useEffect, useState } from "react";

import styles from "./CycleBoard.module.css";

import { getSectorId, getUserCompany } from "../../../utils/sessionHandler";
import t from "../../../utils/translation";
import request from "../../../utils/request";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

import toStartImg from "../../../assets/images/1. To start.svg";
import startedImg from "../../../assets/images/2. In progress.svg";
import finishedImg from "../../../assets/images/3. Finished.svg";
import toStartIcon from "../../../assets/images/Icono To start.svg";
import inProgressIcon from "../../../assets/images/Icono In progress.svg";
import finishedIcon from "../../../assets/images/Icono Finished.svg";

import CycleCard from "../../../components/CycleCard/CycleCard";
import CycleColumn from "../../../components/CycleColumn/CycleColumn";
import InfoBar from "../../../components/MenuQ/infoBar/InfoBar";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ";
import FilterDate from "../../../components/FilterDate/FilterDate";
import ModalLoading from "../../../components/ModalLoading/ModalLoading";

const CycleBoard = ({
  locale,
  handleLoad,
  getLoads,
  toStartLoads,
  startedLoads,
  finishedLoads,
  handleChangeDate,
  dateTo,
  dateFrom,
  modalLoading,
  handleFormData,
  handleModal,
  handleLoadId,
  handleStartDate,
  handleDrop,
  handleLoadingFinishModal,
  handleFilterSterilizer,
  selectedSterilizer,
}) => {
  const [sterilizers, setSterilizers] = useState([]);

  const getSterilizer = async () => {
    try {
      const response = await request().get(
        `/api/Sterilizer/getBySector?sectorId=${getSectorId()}&companyId=${
          getUserCompany().Id
        }`
      );

      const sterilizers = response.data.map((item) => ({
        value: item.id,
        name: item.name,
        modelId: item.companyMachineModelId,
      }));
      setSterilizers(sterilizers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  const handleSterilizer = (e) => {
    handleFilterSterilizer(e.target.value)
  }

  const handleLoadDetail = (chargeId) => {
    if (chargeId) {
      handleLoad(chargeId);
    } else {
      handleLoad(null);
    }
  };

  const onFinishedDrop = async (e, status, item) => {
    let itemID;
    let startDate;
    let chargeStatus;
    if (item) {
      itemID = item.chargeId;
      startDate = item.startDate;
      chargeStatus = item.chargeStatus;
    } else {
      itemID = e.dataTransfer.getData("itemID");
      startDate = e.dataTransfer.getData("startDate");
      chargeStatus = e.dataTransfer.getData("chargeStatus");
    }

    if (status == chargeStatus) {
      return;
    }
    if (status < chargeStatus) {
      toast.error("Error");
      return;
    } else {
      if (parseInt(chargeStatus) + 1 < 4) {
        toast.error(
          "A cycle that has yet to start cannot be marked as Finished."
        );
      } else {
        handleLoadingFinishModal(true);
        handleModal(true);
        await request()
          .get(`/api/Charge/chargedto?chargeId=${itemID}`)
          .then((response) => {
            handleFormData({
              ...response.data,
              cicleFinishDate: new Date(),
              chargeStatus: 4,
            });
          });
        handleLoadingFinishModal(false);
        handleLoadId(itemID);
        handleStartDate(startDate);
      }
    }
  };
  

  const onInProgressDrop = async (e, status, item = null) => {
    let itemID;
    let startDate;
    let chargeStatus;
    if (item) {
      itemID = item.chargeId;
      startDate = item.startDate;
      chargeStatus = item.chargeStatus;
    } else {
      itemID = e.dataTransfer.getData("itemID");
      startDate = e.dataTransfer.getData("startDate");
      chargeStatus = e.dataTransfer.getData("chargeStatus");
    }

    if (status == chargeStatus) {
      return;
    }
    if (status < chargeStatus) {
      toast.error("Error");
      return;
    }
    try {
      let res = await request().get(`/api/Charge/chargedto?chargeId=${itemID}`);

      handleLoadId(itemID);
      handleModal(true);
      handleStartDate(startDate);

      handleFormData({
        ...res.data,
        id: itemID,
        timeUnit: t("Minutes", locale) || "Minutes",
        cicleStartDate: new Date(),
        chargeStatus: 3,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.chargeId);
    e.dataTransfer.setData("chargeStatus", item.chargeStatus);
    e.dataTransfer.setData("startDate", item.cicleStartDate);
  };

  const onDrop = (e, status) => {
    const itemID = e.dataTransfer.getData("itemID");
    const chargeStatus = parseInt(e.dataTransfer.getData("chargeStatus"));
    if (status == chargeStatus) {
      return;
    }

    if (status < chargeStatus) {
      toast.error("Error");
      return;
    }
    if (status !== chargeStatus) {
      if (chargeStatus - 1 === status) {
        handleDrop(itemID, status, e);
      } else {
        if (chargeStatus > status) {
          handleLoadId(itemID);

          Swal.fire({
            title: "Are you sure?",
            text: "You are going back one cycle",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, continue",
            cancelButtonText: "Cancel",
            customClass: {
              confirmButton: styles.customConfirmButton,
              cancelButton: styles.customCancelButton,
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              handleDrop(itemID, status, e);
            } else if (result.isDismissed) {
            }
          });
        } else if (status !== chargeStatus + 2) {
          handleDrop(itemID, status, e);
        }
      }
    }
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getSterilizer();
  }, []);

  return (
    <>
      <ModalLoading open={modalLoading} />
      <div className={styles.container}>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <SelectQ
              selectValue={selectedSterilizer}
              handleSelect={e => handleSterilizer(e)}
              width={"100%"}
              containerWidth={"100%"}
              options={sterilizers}
              placeholder={
                t("SelectSterilizer", locale) || "Select a Sterilizer"
              }
              hasPlaceholder={true}
            />
          </div>
          <div className={styles.filter}>
            <FilterDate
              handleChange={handleChangeDate}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
        </div>
        <div className={styles.board}>
          <CycleColumn
            scroll={true}
            title={t("ToStartSpan") || "To Start"}
            borderColor={"#5099B5"}
            onDrop={onDrop}
            dragOver={draggingOver}
            status={2}
            info={
              <InfoBar
                title={t("ToStartSpan")}
                color={"green"}
                data={toStartLoads}
              />
            }
            img={toStartImg}
          >
            {toStartLoads?.map((charge) => (
              <CycleCard
                handleLoadDetail={handleLoadDetail}
                key={charge.chargeId}
                charge={charge}
                borderColor={"#5099B5"}
                startDrag={startDrag}
                img={toStartIcon}
                handleDoubleClick={onInProgressDrop}
              />
            ))}
          </CycleColumn>
          <CycleColumn
            scroll={true}
            title={t("StartedSpan") || "Started"}
            borderColor={"#ffc107"}
            onDrop={(e) => onInProgressDrop(e, 3)}
            dragOver={draggingOver}
            status={3}
            info={
              <InfoBar
                title={t("InProgressSpan")}
                color={"yellow"}
                data={startedLoads}
              />
            }
            img={startedImg}
          >
            {startedLoads?.map((charge) => (
              <CycleCard
                handleLoadDetail={handleLoadDetail}
                key={charge.chargeId}
                charge={charge}
                borderColor={"#ffc107"}
                startDrag={startDrag}
                img={inProgressIcon}
                handleDoubleClick={onFinishedDrop}
              />
            ))}
          </CycleColumn>

          <CycleColumn
            scroll={true}
            title={t("FinishedSpan") || "Finished"}
            borderColor={"#20c997"}
            onDrop={(e) => onFinishedDrop(e, 4)}
            dragOver={draggingOver}
            status={4}
            info={
              <InfoBar
                title={t("FinishedSpan")}
                color={"greenInverted"}
                data={finishedLoads}
              />
            }
            img={finishedImg}
          >
            {finishedLoads?.map((charge) => (
              <CycleCard
                handleLoadDetail={handleLoadDetail}
                key={charge.chargeId}
                charge={charge}
                borderColor={"#20c997"}
                startDrag={startDrag}
                img={finishedIcon}
              />
            ))}
          </CycleColumn>
        </div>
      </div>
    </>
  );
};

export default CycleBoard;
