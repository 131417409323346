import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
          fill: "currentcolor",
          width: "1em",
          height: "1em",
          fontSize: "1.5rem",
          userSelect: "none"
        }
    },

    footerAction: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap:"20px"
    },

    detailLoading: {
        display: 'flex',
        justifyContent: 'center'
    }
})