import React, { useContext, useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";
import Swal from "sweetalert2";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";

import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import Redirect from "../../utils/Redirect";
import styles from "./ManageHygieneMonitoring.module.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import printTicket from "../../utils/printTicket.js";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation.js";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import HygieneMonitoringDetail from "../../components/HygieneMonitoringDetail/HygieneMonitoringDetail.jsx";
import PackageLabel from "../../components/packageLabel/PackageLabel.jsx";
import withReactContent from "sweetalert2-react-content";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import t from "../../utils/translation.js";
import { getFilterValue, setFilterValue } from "../../utils/sessionFilters";
import VinculationPro from "../../components/VinculationPro/VinculationPro.jsx";


const startOfYesterday = new Date();
startOfYesterday.setDate(startOfYesterday.getDate() - 1);
startOfYesterday.setHours(0, 0, 0, 0);

const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 999);

export default function ManageHygieneMonitoring() {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [methods, setMethods] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedId, setSelectedId] = useState(null);  
  const [washers, setWashers] = useState([]);
  const [reload, setReload] = useState(false) 
  const [idManage,setIdManage] = useState(true)
  const [users,setUsers] = useState(true)

  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  const [filters, setFilters] = useState({
    relatedCicleNumber: getFilterValue("relatedCicleNumber", "", "hygieneMonitoring"),
    creationUserName: getFilterValue("creationUserName", "", "hygieneMonitoring"),
    dateFrom: getFilterValue("dateFrom", startOfYesterday, "hygieneMonitoring"),
    dateTo: getFilterValue("dateTo", endOfToday, "hygieneMonitoring"),
    washerName: getFilterValue("washerName", "", "hygieneMonitoring"),
    materialName: getFilterValue("materialName", "", "hygieneMonitoring"),
    result: getFilterValue("result", "", "hygieneMonitoring"),
    packageStatus: getFilterValue("packageStatus","", "hygieneMonitoring"),
  });

  const fetchPackages = async (filters, currentPage, itemsPerPage) => {
    setLoadingPackages(true);
    try {
      const {
        relatedCicleNumber = "",
        dateFrom = startOfYesterday,
        dateTo = endOfToday,
        creationUserName = "",
        materialName = "",
        washerName = "",
        result = "",
        packageStatus = "",
      } = filters;

      const response = await request().get(
        `/api/hygiene/list?pageSize=${
          itemsPerPage
        }&page=${currentPage}&includeCount=true&number=${relatedCicleNumber}&material=${materialName}&user=${creationUserName}&washer=${washerName}
        &status=${packageStatus}&result=${result}
        &dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
  
      setPackages(response.data.packageList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  const getParametersCreate = async () => {
    try {
      const response = await request().get(`/api/parameter/chargeWashing`);

      const washers = response.data.washers.map((item) => ({
        value: item.washerId,
        ...item,
      }));

      setWashers(washers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  // const getUserByCompany = async () => {
  //   try {
  //     const response = await request().get(`/api/appuser/getbycompany?CompanyId=715`);
  //     console.log(response)
  //     const users = response.data.users.map((item) => ({
  //       value: item.userId,
  //       ...item,
  //     }));

  //     setWashers(washers);
  //   } catch (error) {
  //     console.error("Error al obtener esterilizadores:", error);
  //   }
  // };
  
  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    setFilterValue(key, value, "hygieneMonitoring");
  };

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      const clearedFilters = { ...filters, dateFrom: "", dateTo: "" };
      setFilters(clearedFilters);
      setFilterValue("dateFrom", "", "hygieneMonitoring");
      setFilterValue("dateTo", "", "hygieneMonitoring");
    } else {
      handleFilterChange(type, value);
    }
  };
  // const getMethods = () => {
  //   request()
  //     .get("/api/method/1")
  //     .then((res) =>
  //       setMethods(
  //         res.data.map((m) => ({ value: m.id, name: m.methodTranslation }))
  //       )
  //     );
  // };

  const handleDetail = (id) => {
    const item = packages.find((p) => p.packageGeneralId === id);
    setPackageDetail(item);
    setIsDetailVisible(true);
  };

  const handleVinculation = (p) => {
    setSelectedId(p.indicators[0]); 
  };



  const handleUnLink = (p, indicatorId) => {
    // Mostrar el cuadro de confirmación
    Swal.fire({
      title: "Unlink test",
      text: "Are you sure you want to unlink this test?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, unlink it!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Si el usuario confirma, realizar la solicitud PUT para desvincular
          await request().put(`/api/hygiene/PRO_Unlink?HygieneIndicatorId=${indicatorId}`);

          setReload(true)
        } catch (error) {
          console.error("Error al desvincular:", error);
          Swal.fire(
            'Error!',
            'There was an error unlinking the package.',
            'error'
          );
        }
      }
    });
  };

  const handleSelectedIndicator = (data) => {
    setSelectedId(data);
  };

  const getPrintPackage = (id) => {
    request()
      .get(`/api/package/info?packageId=${id}`)
      .then((res) => showPrintAlert(res.data));
  };

  const showPrintAlert = (packageDetail) => {
    const ReactSwal = withReactContent(Swal);
    ReactSwal.fire({
      html: <PackageLabel packageDetail={packageDetail} />,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: t("Print"),
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(packageDetail);
      }
    });
  };

  const {
    currentPage,
    currentItems,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
  } = useTableQ(
    packages,
    [
      "relatedCicleNumber",
      getRegionalFormat().dateFormat,
      "creationUserName",
      "materialName",
      "washerName",
      "result",
      "packageStatus",
    ],
    totalPages
  );

  
 useEffect(() => {
  if (reload) {
    setSelectedId(null)
    // setIdManage(false)
    fetchPackages(filters, currentPage, itemsPerPage);
    setReload(false)
  }
}, [reload,filters, currentPage, itemsPerPage]);
  const handleDelete = (id) => {
    // const objectToDelete = packages.find(
    //   (pkg) => pkg.packageGeneralId === id
    // );

    // const adjustedObject = {
    //   ...objectToDelete,
    //   materials: objectToDelete.materials.map(material => ({
    //     ...material, 
    //     quantity: 1, 
    //   })),
    // };
    request()
      .delete(`/api/hygiene`, { id: id })
      .then(() => {
        const newData = packages.filter(
          (pkg) => pkg.packageGeneralId !== id
        );
        setPackages(newData);
        setPackageDetail(null);
      });
  };

  const onDelete = (packageGeneralId) => {
    confirm({
      title: "Delete test",
      text: "Are you sure you want to delete this test?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      onConfirm: () => handleDelete(packageGeneralId),
    });
  };

  useEffect(() => {
    getParametersCreate()
    // getUserByCompany()
  }, []);


  useEffect(() => {
    fetchPackages(filters, currentPage, itemsPerPage);
  }, [filters, currentPage, itemsPerPage]);

  // Opciones de estado
    const arrayStatus = [
      { value: "", name: t("SelectStatusOption") },
      { value: 1, name: "Save"},
      { value: 4, name: "Ready"},
    ];

    const arrayResult = [
      { value: "", name: "No result" }, 
      { value: 1, name: "Negative" },
      { value: 2, name: "Cancelled" },
      { value: 3, name: "Positive" },
      { value: 4, name: "Negative without pencil" },
      { value: 263, name: "Pen not valid" },
      { value: 770, name: "Pen pulled out" },
      { value: 10, name: "Alert" },
      { value: 11, name: "Action" },
      { value: 12, name: "Associate area" },
    ];

  const getStatusName = (id) => {
      return id === 1 ? t("BuildingLoad", locale) : "To vinculation";
    };
  
    const getStatusColor = (id) => {
      return id === 1 ? "yellow" : "green";
    };

    const getResult = (p, result) => {
      if (p.readingPRO.statusDescription === "IN PROGRESS") {
        return "IN PROGRESS"; 
      }
    
      switch (result) {
        case 1:
          return "Negative";
        case 2:
          return "Cancelled";
        case 3:
          return "Positive";
        case 4:
          return "Negative without pencil";
        case 263:
          return "Pen not valid";
        case 770:
          return "Pen pulled out";
        case 10:
          return "Alert";
        case 11:
          return "Action";
        case 12:
          return "Associate area";
        default:
          return "";
      }
    };
const getProperty = (array, property) => array?.[0]?.[property] ?? "";
const getReadingProProperty = (p, property) => {
  // Verificamos si readingPRO existe
  if (p.readingPRO && p.readingPRO[property] !== undefined) {
    return getResult(p, p.readingPRO[property]); // Aplicamos getResult si hay un valor válido
  }
  return "No result"; // Si no existe, devolvemos "No result"
};

    return (
      <PageContainer
        backUrl={"/appmainQ"}
        category={"Hygiene monitoring"}
        newButton={!isDetailVisible && !selectedId}
        newButtonUrl={"/appcreatehygienemonitoring"}
        progress={false}
        subtitle={selectedId ? "Linking Indicators" : null}
        clickBack={selectedId}
        onClickBack={() => setSelectedId(null)}
      >
        <div>
          {selectedId ? (
            <VinculationPro 
            selectedIndicator={selectedId} 
            chargeId={packageDetail}
            handleSelectedIndicator={handleSelectedIndicator}
            idManage={idManage}
            // load={load}
            // handleLoad={handleLoad}
            reloadDetail={reload}
            handleReload={setReload}
            // showIncubators={showIncubators}
            // setShowIncubators={setShowIncubators}
            />
          ) : (
            !isDetailVisible ? (
              <div className={styles.container}>
              <div className={styles.tableContainer}>
                <div className={styles.filterContainer}>
                  <InputQ
                    icon={<FiPackage />}
                    handleChange={(e) =>
                      handleFilterChange("relatedCicleNumber", e.target.value)
                    }
                    placeholder={t("NumberSpan") || "Number"}
                    value={filters.relatedCicleNumber}
                    containerWidth="100%"
                    category={"hygieneMonitoring"}
                  />
                  <FilterDate
                    handleChange={handleDateChange}
                    dateTo={filters.dateTo}
                    dateFrom={filters.dateFrom}
                  />
                  <SelectQ
                    icon={<FiPackage />}
                    handleChange={(e) => handleFilterChange("creationUserName", e.target.value)}
                    placeholder={t("UserSpan") || "User"}
                    hasPlaceholder={true}
                    // options={users}
                    value={filters.creationUserName}
                    containerWidth="100%"
                    category={"hygieneMonitoring"}
                  />
                  <InputQ
                    icon={<FiPackage />}
                    handleChange={(e) =>
                      handleFilterChange("materialName", e.target.value)
                    }
                    placeholder={t("MaterialSpan") || "Material"}
                    value={filters.materialName}
                    containerWidth="100%"
                    category={"hygieneMonitoring"}
                  />
                  <SelectQ
                    placeholder={t("SelectWasher") || "Select A Washer"}
                    hasPlaceholder={true}
                    options={washers}
                    handleSelect={(e) =>
                      handleFilterChange("washerName", e.target.value)
                    }
                    selectValue={filters.washerName}
                    containerWidth={"100%"}
                  />
                   <SelectQ
                    placeholder={t("Result") || "Select A Result"}
                    hasPlaceholder={true}
                    options={arrayResult}
                    handleSelect={(e) =>
                      handleFilterChange("result", e.target.value)
                    }
                    selectValue={filters.result}
                    containerWidth={"100%"}
                  />
                  <SelectQ
                    options={arrayStatus}
                    hasPlaceholder={true}
                    handleSelect={(e) =>
                      handleFilterChange("packageStatus", e.target.value)
                    }
                    selectValue={filters.packageStatus}
                    containerWidth={"100%"}
                  />
                </div>
                <TableContainerQ>
                  <TableHeadQ actions={true}>
                    {/* Table Header */}
                    <TableHeaderCellQ
                      date={true}
                      handleClick={() => handleSortByColumn("relatedCicleNumber")}
                    >
                      {t("CycleNumberSpan", locale) || "Cycle Number"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      date={true}
                      handleClick={() => handleSortByColumn("date")}
                    >
                      {t("DateSpan") || "Date"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      handleClick={() => handleSortByColumn("creationUserName")}
                    >
                      {t("UserSpan") || "User"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      handleClick={() => handleSortByColumn("materialName")}
                    >
                      {t("Material", locale) || "Material"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      handleClick={() => handleSortByColumn("washerName")}
                    >
                      {t("Washer", locale) || "Washer"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      handleClick={() => handleSortByColumn("indicators")}
                    >
                      {t("Indicators", locale) || "Indicators"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      handleClick={() => handleSortByColumn("result")}
                    >
                      {t("Result", locale) || "Result"}
                    </TableHeaderCellQ>
                    <TableHeaderCellQ
                      handleClick={() => handleSortByColumn("packageStatus")}
                    >
                      {"Status"}
                    </TableHeaderCellQ>
                  </TableHeadQ>
                  {/* Table Body */}
                  {loadingPackages ? (
                    <div className="spinnerContainer">
                      <SpinnerQ />
                    </div>
                  ) : (
                    <TableBodyQ>
                      {currentItems.map((p) => (
                        <TableRowQ
                          key={p.packageGeneralId}
                          actions={true}
                          // detail={true}
                          edit={p.packageStatus === 1}
                          deleteRow={true}
                          onEdit={() =>
                            Redirect({
                              redirect: true,
                              path: "/appcreatehygienemonitoring",
                              parameters: { hygieneEditId: p.packageGeneralId },
                            })
                          }
                          onDelete={() => onDelete(p.packageGeneralId)}
                          onDetail={() => handleDetail(p.packageGeneralId)}
                          vinculation={p.packageStatus !== 1}
                          onVinculation={() => {
                            if (p.readingPRO !== null) {
                              handleUnLink(p,p.indicators[0].hygieneIndicatorId);
                            } else {
                              handleVinculation(p);
                            }
                          }}
                          tubeResultPRO={p.readingPRO}
                        >
                          <TableRowCellQ date={true}>
                            <TruncatedText text={p.relatedCicleNumber} maxLength={25} />
                          </TableRowCellQ>
                          <TableRowCellQ date={true}>
                            <TruncatedText
                              text={df(p.date, "date")}
                              maxLength={25}
                            />
                          </TableRowCellQ>
                          <TableRowCellQ>
                            <TruncatedText text={p.creationUserName} maxLength={25} />
                          </TableRowCellQ>
                          <TableRowCellQ>
                            <TruncatedText text={getProperty(p.materials, "materialName")} maxLength={25} />
                          </TableRowCellQ>
                          <TableRowCellQ>
                            <TruncatedText text={p.washerName} maxLength={25} />
                          </TableRowCellQ>
                          <TableRowCellQ>
                            <TruncatedText text={getProperty(p.indicators, "indicatorName")} maxLength={25} />
                          </TableRowCellQ>
                          <TableRowCellQ>
                            <TruncatedText text={getReadingProProperty(p, "result")} maxLength={25} />
                          </TableRowCellQ>
                          <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[p.packageStatus === 1 ? "yellow" : "green"]
                          }`}
                        ></span>
                        <TruncatedText
                          text={
                            p.packageStatus === 1
                              ? "Save"
                              : "Ready"
                          }
                          maxLength={25}
                        />
                      </TableRowCellQ>
                        </TableRowQ>
                      ))}
                    </TableBodyQ>
                  )}
                  <TablePaginationQ
                    handleChangePage={handleChangePage}
                    handleChangeItemsPerPage={handleChangeItemsPerPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                </TableContainerQ>
              </div>
          </div>
            ) : (
              <div className={styles.detailContainer}>
                <HygieneMonitoringDetail
                  packageId={packageDetail.packageGeneralId}
                />
              </div>
            )
          )}
        </div>

      </PageContainer>
    );
}