import React, { useEffect, useState } from "react";

import IndicatorLabel from "../../../../components/IndicatorLabel/IndicatorLabel";

import loadImg from "../../../../assets/images/MENU LOAD.svg";
import indicatorsImg from "../../../../assets/images/INDICATORS.svg";
import chemicalImg from "../../../../assets/images/QUIMICO.svg";
import toStartIcon from "../../../../assets/images/Washing/Icono To startPurple.svg";
import inProgressIcon from "../../../../assets/images/Washing/Icono In progressPurple.svg";
import finishedIcon from "../../../../assets/images/Washing/Icono FinishedPurple.svg";
import washerIcon from "../../../../assets/images/purpleWashing.svg";
import trazantoImg from "../../../../assets/images/QApp.png";

import PersonIcon from "@material-ui/icons/Person";

import t from "../../../../utils/translation";
import df from "../../../../utils/dateFormater";
import request from "../../../../utils/request";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import styles from "./WashDetail.module.css";

import { CiCalendar, CiEdit, CiTimer, CiUser } from "react-icons/ci";
import { GoLink, GoUnlink } from "react-icons/go";
import { Tooltip } from "@material-ui/core";
import { IoBookOutline, IoReload } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import PCDItemCycle from "../../../../components/PCDItemCycle/PCDItemCycle";
import ModalLoading from "../../../../components/ModalLoading/ModalLoading";
import { FaTemperatureHigh } from "react-icons/fa";
import TrayCard from "../../../../components/TraysCard/TrayCard";
import hasPositive from "../../../../utils/hasPositive";

const MySwal = withReactContent(Swal);

const WashDetail = ({
  loadId,
  locale,
  handleFormData,
  openEdit,
  handleLoadId,
  handleIsEdit,
  reloadDetail,
  handleReload,
  handleIndicator,
}) => {
  const [loadDetail, setLoadDetail] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [indicators, setIndicators] = useState({
    indicators: [],
    finishedIndicators: [],
    linkedIndicators: [],
    unlinkedIndicators: [],
    linkedIndicatorsCH: [],
    unlinkedIndicatorsCH: [],
  });

  const status = (value) => {
    return value === 2
      ? t("ToStartSpan", locale) || "To Start"
      : value === 3
      ? t("StartedSpan", locale) || "Started"
      : t("FinishedSpan", locale) || "Finished";
  };

  const findImg = (value) => {
    return value === 2
      ? toStartIcon
      : value === 3
      ? inProgressIcon
      : finishedIcon;
  };

  const getResultText = (result) => {
    switch (result) {
      case 1:
        return t("PositiveSpan");
      case 2:
        return t("NegativeSpan");
      case 3:
        return t("CanceledSpan");
      default:
        return "";
    }
  };

  const getResultCH = (result) => {
    switch (result) {
      case 3:
        return t("UnsafeSpan");
      case 4:
        return t("SafeSpan");
      default:
        return "";
    }
  };

  const getUserResultCH = (result) => {
    switch (result) {
      case 0:
        return t("AgreeSpan");
      case 1:
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const handleIndicators = (value) => {
    setIndicators(value);
  };

  const getLoadId = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/chargeWashing/chargeWashingDto?chargeWashingId=${loadId}`)
        .then((response) => {
          const data = response.data;
          setLoadDetail({ ...data, chargeWashingId: loadId });
          const indicators = [
            ...(data.indicatorsWashing || []),
            ...(data.pcdList || []),
          ];
          const finishedIndicators = [
            ...(data.indicatorsWashing || []),
            ...(data.pcdList || []).flatMap((pcd) => [
              pcd.biologicalIndicator,
              pcd.chemicalIndicator,
            ]),
          ];
          handleIndicators({
            indicators: indicators,
            finishedIndicators: finishedIndicators,
            linkedIndicatorsCH: finishedIndicators.filter(
              (i) =>
                i.readingCheWasher != null &&
                !(
                  (i.indicator?.name?.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT")
                )
            ),
            unlinkedIndicatorsCH: finishedIndicators.filter(
              (i) =>
                i.readingCheWasher == null &&
                !(
                  (i.indicator?.name?.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT")
                )
            ),
          });
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  const mappedIndicators =
    loadDetail.chargeStatus === 4
      ? indicators.finishedIndicators
      : indicators.indicators;

  const onEdit = () => {
    handleFormData(loadDetail);
    openEdit(true);
    handleLoadId(loadId);
    handleIsEdit(true);
  };

  const unlinkHandler = (id) => {
    request()
      .put(`/api/chargewashing/unlink?ChargeWashingIndicatorId=${id}`)
      .then(() => getLoadId());
  };

  const unlinkConfirmation = (id, isChemical = false) => {
    MySwal.fire({
      title: "Are you sure you want to continue?",
      text: "Unlink.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "confirmButton",
        cancelButton: "cancelButton",
      },
      confirmButtonColor: "var(--primary-green)",
      cancelButtonColor: "var(--delete)",
      backgroundColor: "#f4f4f4",
    }).then((result) => {
      if (result.isConfirmed) {
        unlinkHandler(id, isChemical);
      }
    });
  };

  useEffect(() => {
    getLoadId();
    return () => {
      setLoadDetail({});
    };
  }, []);

  useEffect(() => {
    if (reloadDetail) {
      getLoadId();
      handleReload(false);
    }
  }, [reloadDetail]);

  if (modalLoading)
    return <ModalLoading category="washing" open={modalLoading} />;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <p
            className={styles.title}
            // onClick={() => handleCycleRecordDetail(loadDetail.chargeWashingId)}
          >
            {loadDetail.chargeStatus === 4 && (
              <Tooltip
                title={
                  <span>{`${
                    hasPositive(loadDetail.indicators)
                      ? t("BlankEntry18")
                      : t("BlankEntry19")
                  }`}</span>
                }
                arrow
              >
                <div
                  className={`${styles.hasPositive} ${
                    hasPositive(loadDetail.indicators)
                      ? styles.red
                      : indicators.unlinkedIndicatorsCH.length
                      ? styles.gray
                      : styles.green
                  }`}
                ></div>
              </Tooltip>
            )}
            {`${t("CycleNumberSpan", locale) || "Cycle Number"}: ${
              loadDetail.cicle ?? ""
            }`}
          </p>

          <div className={styles.detailItem}>
            <div className={styles.detailItem} style={{ gap: "10px" }}>
              <img
                className={styles.iconImg}
                alt={status(loadDetail.chargeStatus)}
                src={findImg(loadDetail.chargeStatus)}
              />
              <span className={styles.infoStatus}>
                {status(loadDetail.chargeStatus)}
              </span>
            </div>
            {loadDetail.chargeStatus === 4 && (
              <CiEdit
                className={styles.infoHeaderIcon}
                onClick={() => onEdit()}
              />
            )}
          </div>
        </div>

        <div className={styles.sterilizerContainerInfo}>
          <img
            className={styles.iconSterilizer}
            src={washerIcon}
            alt="washer icon"
          />
          <p className={styles.sectionSubTitle}>
            {loadDetail.washerName} - {loadDetail.methodName}
          </p>
        </div>
        <div className={styles.headerDetails}>
          <div>
            <div className={styles.detailItem}>
              <IoReload className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("StartedSpan") || "Started"}:{" "}
                <span className={styles.info}>
                  {df(loadDetail.cicleStartDate, "dateTime")}
                </span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <IoReload className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("FinishedSpan") || "Finished"}:{" "}
                <span className={styles.info}>
                  {df(loadDetail.cicleFinishDate, "dateTime")}
                </span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <PersonIcon className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("OperatorSpan") || "User"}:{" "}
                <span className={styles.info}>{loadDetail.operator}</span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <TiDocumentText className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("UsedProgramSpan") || "Used Program"}:{" "}
                <span className={styles.info}>{loadDetail.usedProgram}</span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <FaTemperatureHigh className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("TemperatureSpan") || "Temperature"}:{" "}
                <span className={styles.info}>
                  {loadDetail.temperature &&
                    loadDetail.temperature + " " + loadDetail.temperatureUnit}
                </span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <CiTimer className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("TimeSpan") || "Time"}:{" "}
                <span className={styles.info}>
                  {loadDetail.washingTime &&
                    loadDetail.washingTime + " " + loadDetail.timeUnit}
                </span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <IoBookOutline className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("DetergentTypeSpan", locale) || "Detergent Type"}:{" "}
                <span className={styles.info}>
                  {loadDetail.detergentTypeDescription}
                </span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <IoBookOutline className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("DetergentConcentrationSpan", locale) ||
                  "Detergent Concentration"}
                :{" "}
                <span className={styles.info}>
                  {loadDetail.detergentConcentration}
                </span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <IoBookOutline className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("WaterPressureSpan", locale) || "Water Pressure"}:{" "}
                <span className={styles.info}>{loadDetail.waterPressure}</span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <IoBookOutline className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("WaterHardnessSpan", locale) || "Water Hardness"}:{" "}
                <span className={styles.info}>{loadDetail.waterHardness}</span>
              </p>
            </div>
          </div>
        </div>

        <div className={styles.observationSection2}>
          <p className={`${styles.observationTitle2}`}>
            {t("ObservationSpan")}
          </p>
          {loadDetail.cicleObservations && (
            <div className={styles.observationContainer2}>
              <p className={styles.observation2}>
                {loadDetail.cicleObservations}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.indicatorContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.imageTitleContainer}>
            <img
              src={
                loadDetail.chargeStatus !== 4 ? indicatorsImg : indicatorsImg
              }
            />
          </div>
          <p className={styles.indicatorsTitle}>
            {loadDetail.chargeStatus !== 4
              ? t("IndicatorsSpan")
              : t("IndicatorsSpan")}
          </p>
        </div>

        <>
          <div className={styles.indicators}>
            {mappedIndicators
              ?.filter((i) => (loadDetail.chargeStatus === 4 ? null : true))
              .map((indicator) => (
                <Tooltip
                  key={indicator.id}
                  title={
                    <div>
                      <div>
                        {t("ProductSpan")}: {indicator.indicator.name}
                      </div>
                      <div>
                        {t("LotSpan")}: {indicator.lot}
                      </div>
                      {indicator.serial && (
                        <div>
                          {t("SerialSpan")}: {indicator.serial}
                        </div>
                      )}
                      <div>
                        {t("BlankEntry1")}:{" "}
                        {indicator.indicatorModelArea.description}
                      </div>
                    </div>
                  }
                  arrow
                >
                  <div
                    className={styles.indicatorItem}
                    style={{ cursor: "auto" }}
                  >
                    <IndicatorLabel
                      category={"washing"}
                      name={indicator.indicator.name}
                      lot={indicator.lot}
                      serial={indicator.serial}
                      expirationDate={indicator.indicatorExpirationDate}
                      backgroundColor={"white"}
                      zone={indicator.indicatorModelArea.description}
                      border={true}
                    />
                    {loadDetail.chargeStatus === 4 && (
                      <img src={trazantoImg} className={styles.trazantoIcon} />
                    )}
                  </div>
                </Tooltip>
              ))}
          </div>

          {/* ///////////////////////////// QUIMICOS /////////////////////////////////////////// */}
          {loadDetail.chargeStatus === 4 && (
            <div className={styles.indicatorSection}>
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.imageTitleContainer}>
                    <img src={chemicalImg} />
                  </div>
                  <p className={styles.indicatorsTitle}>
                    {t("UnlinkedChemicalIndicatorSpan")}
                  </p>
                </div>

                <div className={styles.indicatorsContainer}>
                  {indicators.unlinkedIndicatorsCH?.map((indicator) => (
                    <Tooltip
                      key={indicator.id}
                      title={
                        <div>
                          <div>
                            {t("ProductSpan")}: {indicator.indicator.name}
                          </div>
                          <div>
                            {t("LotSpan")}: {indicator.lot}
                          </div>
                          {indicator.serial && (
                            <div>
                              {t("SerialSpan")}: {indicator.serial}
                            </div>
                          )}
                          <div>
                            {t("BlankEntry1")}:{" "}
                            {indicator.indicatorModelArea.description}
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <div className={styles.indicatorItem}>
                        <IndicatorLabel
                          category={"washing"}
                          name={indicator.indicator.name}
                          lot={indicator.lot}
                          serial={indicator.serial}
                          expirationDate={indicator.indicatorExpirationDate}
                          backgroundColor={"white"}
                          zone={indicator.indicatorModelArea.description}
                          border={true}
                          animation={true}
                        />
                        <GoLink
                          onClick={() => handleIndicator(indicator)}
                          className={styles.link}
                        />
                        <img
                          src={trazantoImg}
                          className={styles.trazantoIcon}
                        />
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.imageTitleContainer}>
                    <img src={chemicalImg} />
                  </div>
                  <p className={styles.indicatorsTitle}>
                    {t("LinkedChemicalIndicatorSpan")}
                  </p>
                </div>
                <div className={styles.indicatorsContainer}>
                  {indicators.linkedIndicatorsCH?.map((indicator) =>
                    indicator.pcD_Group ? (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              PCD:{" "}
                              {`${indicator.pcdName} - ${indicator.pcdLot}`}
                            </div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("BlankEntry1")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                            {indicator.readingCheWasher && (
                              <div>
                                <div>
                                  {t("StatusSpan")}:{" "}
                                  {indicator.readingCheWasher.statusDescription}
                                </div>
                                <div>
                                  {t("ReadingNumber")}:{" "}
                                  {indicator.readingCheWasher.readNumber}
                                </div>
                                <div>
                                  {t("DateSpan")}:{" "}
                                  {df(
                                    indicator.readingCheWasher?.creationTest,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("BrandSpan")}:{" "}
                                  {indicator.readingCheWasher.brand}
                                </div>
                                <div>
                                  {t("ResultSpan")}:{" "}
                                  {getResultCH(
                                    indicator.readingCheWasher?.result
                                  )}
                                </div>
                                <div>
                                  {t("UserEvaluationSpan")}:{" "}
                                  {getUserResultCH(
                                    indicator.readingCheWasher?.visualResult
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            category={"washing"}
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            resultCH={indicator.readingCheWasher?.result}
                            userResultCH={
                              indicator.readingCheWasher?.visualResult
                            }
                          />
                          <GoUnlink
                            className={styles.unlink}
                            onClick={() => unlinkConfirmation(indicator.id)}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("BlankEntry1")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                            {indicator.readingCheWasher && (
                              <div>
                                <div>
                                  {t("StatusSpan")}:{" "}
                                  {indicator.readingCheWasher.statusDescription}
                                </div>
                                <div>
                                  {t("ReadingNumber")}:{" "}
                                  {indicator.readingCheWasher.readNumber}
                                </div>
                                <div>
                                  {t("ResultSpan")}:
                                  {getResultText(
                                    indicator.readingCheWasher.result
                                  )}
                                </div>
                                <div>
                                  {t("DateSpan")}:{" "}
                                  {df(
                                    indicator.readingCheWasher?.creationTest,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("BrandSpan")}:{" "}
                                  {indicator.readingCheWasher.brand}
                                </div>
                                <div>
                                  {t("ResultSpan")}:{" "}
                                  {getResultCH(
                                    indicator.readingCheWasher?.result
                                  )}
                                </div>
                                <div>
                                  {t("UserEvaluationSpan")}:{" "}
                                  {getUserResultCH(
                                    indicator.readingCheWasher?.visualResult
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            category={"washing"}
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            resultCH={indicator.readingCheWasher?.result}
                            userResultCH={
                              indicator.readingCheWasher?.visualResult
                            }
                          />
                          <GoUnlink
                            className={styles.unlink}
                            onClick={() => unlinkConfirmation(indicator.id)}
                          />
                        </div>
                      </Tooltip>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.packagesSection}>
          <p className={styles.infoTitle}>{t("TraysSpan")}</p>

          <div className={styles.packagesContainer}>
            {loadDetail.packagesWashing &&
              loadDetail.packagesWashing.map((pkg) => (
                <TrayCard key={pkg.packageWashingId} pkg={pkg.packageWashing} />
              ))}
          </div>
        </div>
      </div>
      {/* <div className={styles.cycleInfoContainer}>
          <div className={styles.infoHeader}>
            <p className={styles.infoTitle}>{t("WashCycleInformationSpan")}</p>
            {loadDetail.chargeStatus === 4 && (
              <CiEdit
                className={styles.infoHeaderIcon}
                onClick={() => onEdit()}
              />
            )}
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.detailRow}>
              <div className={styles.detailItem}>
                <IoReload className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("StartedSpan", locale) || "Started"}:{" "}
                  <span className={styles.info}>
                    {df(loadDetail.cicleStartDate, "dateTime")}
                  </span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <IoReload className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("FinishedSpan", locale) || "Finished"}:{" "}
                  <span className={styles.info}>
                    {df(loadDetail.cicleFinishDate, "dateTime")}
                  </span>
                </p>
              </div>
            </div>

            <div className={styles.detailRow}>
              <div className={styles.detailItem}>
                <TiDocumentText className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("NumberSpan", locale) || "Number"}:{" "}
                  <span className={styles.info}>{loadDetail.cicle}</span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <TiDocumentText className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("UsedProgramSpan", locale) || "Used Program"}:{" "}
                  <span className={styles.info}>{loadDetail.usedProgram}</span>
                </p>
              </div>
            </div>
            <div className={styles.detailRow}>
              <div className={styles.detailItem}>
                <CiTimer className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("TimeSpan", locale) || "Time"}:{" "}
                  <span className={styles.info}>
                    {loadDetail.washingTime &&
                      `${loadDetail.washingTime} ${
                        loadDetail.timeUnit === "Minutes"
                          ? t("MinuteSpan")
                          : t("SecondSpan")
                      }`}
                  </span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <FaTemperatureHigh className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("TemperatureSpan", locale) || "Temperature"}:{" "}
                  <span className={styles.info}>
                    {loadDetail.temperature &&
                      loadDetail.temperature + " " + loadDetail.temperatureUnit}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.detailRow}>
              <div className={styles.detailItem}>
                <IoBookOutline className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("DetergentTypeSpan", locale) || "Detergent Type"}:{" "}
                  <span className={styles.info}>
                    {loadDetail.detergentTypeDescription}
                  </span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <IoBookOutline className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("DetergentConcentrationSpan", locale) ||
                    "Detergent Concentration"}
                  :{" "}
                  <span className={styles.info}>
                    {loadDetail.detergentConcentration}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.detailRow}>
              <div className={styles.detailItem}>
                <IoBookOutline className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("WaterPressureSpan", locale) || "Water Pressure"}:{" "}
                  <span className={styles.info}>
                    {loadDetail.waterPressure}
                  </span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <IoBookOutline className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("WaterHardnessSpan", locale) || "Water Hardness"}:{" "}
                  <span className={styles.info}>
                    {loadDetail.waterHardness}
                  </span>
                </p>
              </div>
            </div>

            <div className={styles.detailRow}>
              <div className={styles.detailItem}>
                <IoBookOutline className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("PageNumberSpan", locale) || "Page Number"}:{" "}
                  <span className={styles.info}>{loadDetail.pageNumber}</span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <IoBookOutline className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("BookNumberSpan", locale) || "Book Number"}:{" "}
                  <span className={styles.info}>{loadDetail.bookNumber}</span>
                </p>
              </div>
            </div>
          </div>

          {loadDetail.cicleObservations && (
            <div className={styles.observationSection}>
              <p className={`${styles.observationTitle}`}>
                {t("ObservationSpan")}
              </p>

              <div className={styles.observationContainer}>
                <p className={styles.observation}>
                  {loadDetail.cicleObservations}
                </p>
                <div className={styles.observationInfo}>
                  <p>{df(loadDetail.editDate, "dateTime")}</p>
                  <p>{loadDetail.editUserName}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default WashDetail;
