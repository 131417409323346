import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../../components/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import ValidateURL from "../../utils/ValidateURL";
import CreateHygieneMonitoring from "../../pages/CreateHygieneMonitoring/CreateHygieneMonitoring.jsx";
import { PrintLabelProvider } from "../../utils/context/PrintLabelContext";
import useLocale from "../../utils/Hooks/useLocale.js";
import { useLocation } from "react-router-dom";


export default () => {
  const menu = useMenu();

  const currentPage = {
    menuGroup: menu?.Company,
    key: "CreateHygieneMonitoring",
  };
  const screenName = 336;
  const locale = useLocale(screenName);

  const location = useLocation()

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{ locale: locale }}>
      <NavBar
        screenName={screenName}
        moduleName={170}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <PrintLabelProvider>
          <CreateHygieneMonitoring location={location} />
        </PrintLabelProvider>
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};