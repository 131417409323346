import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import request from "../../../utils/request.js";
import t from "../../../utils/translation.js";

import SearchDescription from "../../../components/SearchDescription/SearchDescription.js";
import CrudIndicator from "../../../components/CrudIndicator/CrudIndicator.jsx";

import BarcodeReader from "react-barcode-reader";

import { parseBarcode } from "../../../utils/BarcodeParser.js";
import DragAndDrop from "../../../components/DragAndDrop/DDPackages.jsx";
import LocalizationContext from "../../../utils/LocalizationContext.js";
import { v4 as uuidv4 } from "uuid";
import InputQ from "../../../components/FormComponents/InputQ/InputQ.jsx";
import {
  getSectorId,
  getSession,
  getUserCompany,
} from "../../../utils/sessionHandler.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ.jsx";
import PageContainer from "../../../components/PageContainer/PageContainer.jsx";

import styles from "./CreateWasherLoading.module.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TextAreaQ from "../../../components/FormComponents/TextAreaQ/TextAreaQ.jsx";
import ModalLoading from "../../../components/ModalLoading/ModalLoading.js";
import DragAndDropWasherLoading from "../../../components/DragAndDropWasherLoading/DragAndDropWasherLoading.js";
import Search from "../../../components/Search/Search.js";
import { FaPlus, FaTimes } from "react-icons/fa";

const MySwal = withReactContent(Swal);

export default function CreateWasherLoading() {
  const [indicators, setIndicators] = useState([]);
  const [description, setDescription] = useState("");
  const [observations, setObservations] = useState(null);
  const [trays, setTrays] = useState([]);
  const [filteredTrays, setFilteredTrays] = useState("");
  const [selectedTrays, setSelectedTrays] = useState([]);
  const [selectedFilteredTrays, setSelectedFilteredTrays] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [editId, setEditId] = useState(null);
  const [Washers, setWashers] = useState([]);
  const [selectedWasher, setSelectedWasher] = useState("");
  const [validate, setValidate] = useState(false);
  const [scanMode, setScanMode] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [models, setModels] = useState([]);
  const [openWasherConfirm, setOpenWasherConfirm] = useState(false);
  const [waitWasher, setWaitWasher] = useState(null);
  const [methods, setMethods] = useState([]);
  const [methodName, setMethodName] = useState("");
  const [modelAreas, setModelAreas] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [methodId, setMethodId] = useState(null);
  const [stopButtons, setStopButtons] = useState(false);
  const [idDistributorCompany, setIdDistributorCompany] = useState(null);
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editTrays, setEditTrays] = useState([]);
  const [cycleNumber, setCycleNumber] = useState("");

  const [selectedMethod, setSelectedMethod] = useState(null);

  const [traysType, setTraysType] = useState([]);
  const [packageId, setPackageId] = useState(null);
  const [packageQuantity, setpackageQuantity] = useState(1000000);
  const [materialsTypes, setMaterialsTypes] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedFilteredMaterials, setSelectedFilteredMaterials] = useState(
    []
  );
  const [packagesTypes, setPackagesTypes] = useState([]);
  const [traysQuantity, setTraysQuantity] = useState("");
  const [selectedTray, setSelectedTray] = useState(null);
  const [trayName, setTrayName] = useState("");

  const [predefinedPackageID, setPredefinedPackageID] = useState(null);

  const locale = useContext(LocalizationContext);
  const descriptionRef = useRef();
  const cycleNumberRef = useRef();
  const observationsRef = useRef();

  const traysQuantityRef = useRef();

  const validateFields = (status) => {
    let notValid = false;

    if (!description.length > 0 || description.trim() === "") {
      notValid = true;
    }

    if (!selectedWasher || selectedWasher === "") {
      notValid = true;
    }

    if (status !== 1 && !indicators.length > 0) {
      notValid = true;
    }

    return notValid;
  };

  const getTrays = async (method, addPackages) => {
    setModalLoading(true);
    console.log(addPackages, method);
    let filter = addPackages.filter((p) => p.packageWashing.methodId == method);
    try {
      if (!method) {
        setTrays([]);
      } else {
        console.log("Ejecuto");
        const response = await request().get(
          `/api/PackageWashing/list?&pageSize=${packageQuantity}&Status=2&isInCharge=${false}&method=${method}`
        );
        setTrays([...filter, ...response.data.packageWashingList]);
      }
    } catch (e) {
      console.log(e);
      localStorage.removeItem("packageEditId");
    }
    setModalLoading(false);
  };
  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/chargeWashing`);

      const washers = response.data.washers.map((item) => ({
        value: item.washerId,
        ...item,
      }));

      setWashers(washers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  const getModels = async () => {
    await request()
      .get(`/api/CompanyMachineModel`)
      .then((response) => {
        setModels(response.data);
      });
  };

  const getModelAreas = async (id) => {
    try {
      const response = await request().get(`/api/ModelAreas/${id}`);

      return setModelAreas(response.data);
    } catch (error) {
      console.error(`Error al obtener model areas para el id ${id}:`, error);
      return [];
    }
  };

  const loadEditId = localStorage.getItem("loadingEditId");

  const getEditCharges = () => {
    try {
      setModalEditLoading(true);

      request()
        .get(
          `/api/Chargewashing/chargewashingdto?chargewashingId=${loadEditId}`
        )
        .then((response) => {
          if (response.data.id) localStorage.removeItem("loadingEditId");

          let newIndicators = [];

          response.data.indicatorsWashing.map(
            (i) =>
              (newIndicators = [
                ...newIndicators,
                {
                  ...i,
                  indicator_Id: i.indicatorWashingId,
                  uuid: uuidv4(),
                  expirationDate: i.indicatorExpirationDate,
                  modelAreaName: i.indicatorModelArea.description,
                  modelAreasId: i.indicatorModelArea.id,
                },
              ])
          );

          const formattedData = response.data.packagesWashing.map(
            (item, index) => ({
              materials: item.packageWashing.materials.map((material) => ({
                materialId: material.materialId,
                quantity: material.quantity || 1,
                name: material.material.name,
                serial: material.materialSerial,
                materialType: material.material.materialType,
                id: material.materialId,
              })),
              id: index + 1,
              name: `${t("BlankEntry34")} ${index + 1}`,
              packageWashingId: item.packageWashingId,
            })
          );

          setTrays(formattedData);
          setCycleNumber(response.data.cicle);
          setDescription(response.data.usedProgram);
          setSelectedWasher(response.data.washerId);
          setIndicators(newIndicators);
          setEditId(response.data.id);
          getModelAreas(response.data.washer.companyMachineModelId);
          setMethodName(response.data.methodName);
          setMethodId(response.data.methodId);
          setObservations(response.data.observations);
          getMaterials();
        });
    } catch (e) {
      localStorage.removeItem("loadingEditId");
    } finally {
      setModalEditLoading(false);
    }
  };

  const handleSearchActive = (bool) => setSearchActive(bool);

  const handleIndicators = (data) => {
    setIndicators(data);
  };
  const handleTraysQuantity = (value, scan) => {
    const inputValue = scan ? value : value.target.value;

    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
      setTraysQuantity(inputValue);
    }
  };
  const handleCycleNumber = (value, scan = false) => {
    const inputValue = scan ? value : value.target.value;

    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
      setCycleNumber(inputValue);
    }
  };
  const handleChangeObservations = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setObservations(item);
  };

  const handleChangeDescription = (value, scan = false) => {
    if (!(value.target?.value?.length == 0 && !validate)) {
      setValidate(false);
    }
    const inputValue = scan ? value : value.target.value;
    setDescription(inputValue);
  };

  const handleMaterials = (arr) => setFilteredMaterials(arr);
  const handleSelectedMaterials = (data) => {
    const newArray = trays.map((t) => {
      if (t.id === selectedTray.id) {
        return { ...t, materials: data };
      }
      return t;
    });

    setTrays(newArray);

    setSelectedMaterials(data);
  };

  const handleSelectedFilteredMaterials = (data) =>
    setSelectedFilteredMaterials(data);

  const handleChangeWasher = (e) => {
    const selectedValue = e.target.value;
    const selectedWasher = Washers?.find((s) => s.value == selectedValue);

    if (selectedWasher && (selectedTrays.length > 0 || indicators.length > 0)) {
      MySwal.fire({
        title: "Are you sure you want to continue?",
        text: "All entered trays and indicators will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedWasher(selectedValue);

          getModelAreas(selectedWasher?.companyMachineModelId);

          setMethodName(!selectedValue ? "" : selectedWasher.washerMethodName);
          setMethodId(!selectedValue ? "" : selectedWasher.washerMethodId);
          getTrays(selectedWasher?.washerMethodId, editTrays);

          clearFields(false);
        }
      });
    } else {
      setSelectedWasher(selectedValue);

      getModelAreas(selectedWasher?.companyMachineModelId);

      setMethodName(!selectedValue ? "" : selectedWasher.washerMethodName);
      setMethodId(!selectedValue ? "" : selectedWasher.washerMethodId);
      getMaterials();

      clearFields(false);
    }
  };

  const onHandleScan = async (data) => {
    let itemMaterial = materials.find((m) => m.serial == data);
    let materialCondition = selectedMaterials.find((m) => m.serial == data);

    if (itemMaterial) {
      setScanCode(data);
      setScanCode(data);
      if (materialCondition) {
        setSelectedMaterials(
          selectedMaterials.filter((m) => m != itemMaterial)
        );
        toast.success(t("MaterialRemoved", locale) || "Material removed");
      } else {
        setSelectedMaterials([...selectedMaterials, itemMaterial]);
        toast.success(t("MaterialAdded", locale) || "Material added");
      }
    } else {
      try {
        const variableField = parseBarcode(data).parsedCodeItems[3];
        const Gtin = parseBarcode(data).parsedCodeItems[0];

        let lot = "";

        const handleResponse = async (url) => {
          const response = await request().get(url);
          if (response.data.id) {
            setScanCode(data);
            let newIndicator = response.data;

            setIndicators([
              ...indicators,
              {
                ...newIndicator,
                uuid: uuidv4(),
                modelAreasId: modelAreas[0]?.id,
                modelAreaName: modelAreas[0]?.description,
              },
            ]);

            toast.success(t("IndicatorAdded", locale) || "Indicator Added");
            setDescription(description.replace(data, ""));
          }
        };

        if (variableField.ai === "10") {
          const url = `/api/indicatorLot/GTINforPackage?gtin=${Gtin.data}&lot=${
            variableField.data
          }&disId=${getUserCompany().DistributorId}&methodId=${methodId}`;
          await handleResponse(url);
        } else if (variableField.data.includes("PANTONE")) {
          let match = variableField.data.match(/(.{6})95PANTONE/);
          if (match) {
            lot = match[1];
          }
          const url = `/api/indicatorLot/GTINforPackage?gtin=${
            Gtin.data
          }&lot=${lot}&disId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`;
          await handleResponse(url);
        } else {
          const answer = variableField.data;

          let product;

          const indexA = answer.lastIndexOf("A");
          const indexF = answer.lastIndexOf("F");
          const indexB = answer.lastIndexOf("B");
          const indexBT = answer.lastIndexOf("BT");
          const indexPCD = answer.lastIndexOf("PCD");
          const indexCDWA = answer.lastIndexOf("CDWA");

          if (indexA < indexB) {
            if (indexB !== -1) {
              lot = answer.substring(indexB);
              const startAfterB = indexB - 2;

              if (indexCDWA !== -1) {
                product = answer.substring(indexCDWA, startAfterB);
              }
            }
          } else if (indexA > indexF) {
            if (indexA !== -1) {
              lot = answer.substring(indexA);

              const startAfterA = indexA - 2;

              if (indexBT !== -1) {
                product = answer.substring(indexBT, startAfterA);
              }
            }
          } else if (indexA < indexF) {
            if (indexF !== -1) {
              lot = answer.substring(indexF);
              const startAfterF = indexF - 2;

              if (indexPCD !== -1) {
                product = answer.substring(indexPCD, startAfterF);
              }
            }
          }

          const url = `/api/IndicatorLot/lotForCharge?Product=${product}&lot=${lot}&distId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`;

          await handleResponse(url);
        }
      } catch (e) {
        toast.error(e.response?.data?.message);
      } finally {
      }
    }
  };

  const createCharge = async (status) => {
    // 1. Validación: Obtener las bandejas que no tienen materiales
    const emptyTrays = trays.filter(
      (tray) => !tray.materials || tray.materials.length === 0
    );

    if (emptyTrays.length > 0) {
      // Obtener los nombres de las bandejas vacías
      const trayNames = emptyTrays.map((tray) => tray.name).join(", ");

      toast.error(
        `${
          t("BlankEntry29") || "Las siguientes bandejas deben tener materiales"
        }: ${trayNames}`
      );
      return; // Terminar la ejecución de la función
    }

    // Continuar con la lógica si todas las bandejas tienen materiales
    let newIndicators = indicators.flatMap((i) => [
      {
        IndicatorWashingId: i.indicator_Id,
        quantity: 1,
        serial: i.serial ? i.serial : null,
        lot: i.lot,
        modelAreasId: i.modelAreasId,
      },
    ]);

    const newTrays = trays.map((tray) => {
      const newMaterials = tray.materials.map((material) => ({
        materialId: material.id,
        quantity: material.quantity || 1,
      }));

      return {
        description: tray.name,
        materials: newMaterials,
        packageWashingId: tray.packageWashingId,
      };
    });

    const requestData = {
      newChargeWashing: {
        WasherId: selectedWasher,
        usedProgram: description,
        chargeStatus: status,
        indicatorsWashing: newIndicators,
        cicle: cycleNumber,
        observations: observations,
      },
      newPackagesWashing: newTrays,
    };

    try {
      setStopButtons(true);

      if (!editId) {
        const res = await request().post("/api/chargewashing", requestData);
        if (status === 1) {
          setEditId(res.data);
        }

        status === 1
          ? toast.success(
              t("LoadSaved", locale) || "La carga fue guardada exitosamente"
            )
          : toast.success(
              t("LoadCompleted", locale) || "La carga fue completada exitosamente"
            );
      } else {
        requestData.newChargeWashing.id = editId;
        await request().put("/api/chargewashing", requestData);
        toast.success(
          t("LoadModified", locale) || "La carga fue modificada exitosamente"
        );
      }

      if (status !== 1) clearFields();
      setValidate(false);
    } catch (e) {
      // Manejo de errores más robusto
      const errorMessage =
        e.response?.data?.message ||
        t("AnErrorOccurred") ||
        "Ocurrió un error al procesar la solicitud.";
      toast.error(errorMessage);
    } finally {
      setStopButtons(false);
    }
  };

  const clearFields = (WasherChange = true) => {
    WasherChange && setEditId(null);
    WasherChange && setSelectedWasher("");
    WasherChange && setMethodName("");
    WasherChange && setMethodId(null);
    setCycleNumber("");
    setDescription("");
    setObservations("");
    setIndicators([]);
    setSelectedTrays([]);
    setTrays([]);
    setSelectedTray(null);
    setSelectedMaterials([]);
    setTrayName("");
  };

  const traysMethod = useMemo(() => {
    return (
      trays?.filter(
        (p) =>
          p?.methodId === methodId || p?.packageWashing?.methodId === methodId
      ) ?? []
    );
  }, [methodId, trays]);

  const onClearTray = () => {
    setTrays([]);
    setTrayName("");
    setSelectedTray({});
    setTraysQuantity("");
    setSelectedMaterials([]);
  };

  const findMaterialType = (id) => materialsTypes.find((mt) => mt.id === id);

  const getMaterials = async () => {
    setModalEditLoading(true);
    await request()
      .get("/api/Material/Filtered")
      .then((response) => {
        let newMaterials = [];
        response.data.map(
          (material) =>
            (newMaterials = [
              ...newMaterials,
              {
                ...material,
                materialType: findMaterialType(material.materialTypeId),
              },
            ])
        );

        setMaterials(newMaterials);
        setFilteredMaterials(newMaterials);
        setModalEditLoading(false);
      });
  };
  const getMaterialsTypes = () => {
    request()
      .get("/api/materialType")
      .then((response) => setMaterialsTypes(response.data));
  };

  useEffect(() => {
    setFilteredTrays(traysMethod);
  }, [traysMethod, trays]);

  const getPackagesTypes = async () => {
    try {
      const response = await request().get("/api/predefinedpackage/");
      const newData = response.data.map((p) => ({
        name: p.name,
        value: p.id,
        methodId: p.method.id,
      }));
      setPackagesTypes(newData);
    } catch (error) {
      console.error("Error al obtener tipos de paquetes:", error);
    }
  };

  useEffect(() => {
    getParametersLoad();
    getModels();
    getEditCharges();
    getMaterialsTypes();
    getPackagesTypes();
  }, []);

  useEffect(() => {
    setDescription(description.replace(scanCode, ""));
    setScanCode("");
  }, [scanCode]);

  const showValidationErrors = (status) => {
    console.log("Me ejecuto");
    if (description.length === 0 || description.trim() === "") {
      toast.error(t("NameRequired") || "The Name field is required");
    }
    if (!selectedWasher || selectedWasher === "") {
      toast.error(t("YouMustSelectAWasher") || "You must select a Washer");
    }
    if (status !== 1 && indicators.length === 0) {
      toast.error(t("YouMustAddIndicator") || "You must add an Indicator");
    }
  };

  const handleButtonClick = async (status) => {
    const notValid = validateFields(status);

    if (notValid) {
      setValidate(notValid);
      showValidationErrors(status);
    } else createCharge(status);
  };

  const isSaveDisabled = useMemo(
    () => validateFields(1),
    [description, selectedWasher, indicators]
  );
  const isFinishDisabled = useMemo(
    () => validateFields(2),
    [description, selectedWasher, indicators]
  );

  const addTrays = (e) => {
    e.preventDefault();

    const newTrays = Array.from({ length: traysQuantity }, (_, index) => ({
      id: index + 1,
      name: `${t("BlankEntry34")} ${index + 1}`,
      materials: [],
    }));

    setTraysQuantity("");
    setTrays(newTrays);
  };

  const addOneTray = () => {
    const newTrays = [
      ...trays,
      {
        id: trays.length + 1,
        name: `${t("BlankEntry34")} ${trays.length + 1}`,
        materials: [],
      },
    ];
    setTrays(newTrays);
    handleSelectTray(newTrays.at(-1));
  };

  const handleDeleteTray = (trayId) => {
    let filteredTrays = trays.filter((tray) => tray.id !== trayId);

    if (selectedTray.id === trayId) {
      setSelectedMaterials([]);
      setSelectedTray({});
      setTrayName("");
    }

    const newTrays = filteredTrays.map((tray, index) => ({
      ...tray,
      id: index + 1,
      name: `${t("BlankEntry34")} ${index + 1}`,
    }));

    if (selectedTray.id > trayId) {
      handleSelectTray(newTrays[selectedTray.id - 2]);
    }

    setTrays(newTrays);
  };

  const handleSelectTray = (data) => {
    setSelectedTray(data);
    setSelectedMaterials(data.materials);
    setTrayName(data.name);
  };

  const handleSelectPredefinedPackage = async (e) => {
    let id = e.target.value;
    if (!id) {
      setFilteredMaterials(materials);
    } else {
      try {
        const response = await request().get(`/api/predefinedpackage/${id}`);

        // Iteramos sobre los materiales recibidos y buscamos coincidencias en "materials".
        const nuevosMateriales = response.data.materials
          .map((materialApi) => {
            // Buscamos en el array "materials" un elemento que coincida con el id del material obtenido de la API
            return materials.find((m) => m.id === materialApi.materialId);
          })
          // Se filtran los valores nulos en caso de que no se encuentre coincidencia
          .filter(Boolean);

        setFilteredMaterials(nuevosMateriales);
      } catch (error) {
        console.error(error);
      }
    }

    setPredefinedPackageID(id);
  };

  const handleDisabled = () => {
    if (!selectedWasher) {
      toast.error(`First, you must select a Washer`);
    }
  };

  const availableMaterials = useMemo(() => {
    // Recopilar todos los seriales de los materiales en trays
    const serialsInTrays = new Set();

    trays.forEach((tray) => {
      tray.materials.forEach((material) => {
        serialsInTrays.add(material.serial);
      });
    });

    // Filtrar los materiales de filteredMaterials:
    // - Si el material no tiene serial, se incluye directamente.
    // - Si tiene serial, se incluye solo si su serial no está en serialsInTrays.
    return filteredMaterials.filter((material) => {
      if (!material.serial) {
        // No tiene serial: incluir directamente
        return true;
      }
      // Tiene serial: verificar que no esté en ninguno de los seriales de trays
      return !serialsInTrays.has(material.serial);
    });
  }, [trays, filteredMaterials]);

  const packageTypeTranslate =
    t("SelectPredifinedPackage", locale) || "Select a predifined package";

  return (
    <PageContainer
      categoryId={2}
      currentStep={4}
      category={t("WashingSpan")}
      subcategory={t("WasherLoadingSpan")}
      title={t("CreateWashingLoading")}
      backUrl={"/appmanagewasherloadings"}
      scan={true}
      progress={true}
      washerLoad={true}
    >
      {!modalEditLoading && !modalLoading && (
        <BarcodeReader onScan={onHandleScan} />
      )}
      <ModalLoading category={"washing"} open={modalEditLoading} />
      <ModalLoading category={"washing"} open={modalLoading} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "73%",
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <SelectQ
                selectValue={selectedWasher}
                handleSelect={handleChangeWasher}
                options={Washers}
                label={t("SelectWasherSpan") || "Select a Washer"}
                placeholder={t("SelectWasherSpan") || "Select a Washer"}
                containerWidth={"48%"}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
                category={"washing"}
              />
              <InputQ
                category="washing"
                value={methodName}
                label={t("WashingMethodSpan") || "Washing Method"}
                placeholder={t("None") || "None"}
                containerWidth={"48%"}
                validate={validate}
                disabled={true}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <InputQ
                label={t("UsedProgramSpan") || "Used Program"}
                handleChange={handleChangeDescription}
                value={description}
                containerWidth={"48%"}
                inputRef={descriptionRef}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
                onHandleScan={onHandleScan}
                category={"washing"}
              />
              <InputQ
                label={t("CycleNumberSpan") || "Cycle Number"}
                handleChange={handleCycleNumber}
                value={cycleNumber}
                containerWidth={"48%"}
                onHandleScan={onHandleScan}
                category={"washing"}
                inputRef={cycleNumberRef}
              />
            </div>
            <div>
              <SelectQ
                placeholder={packageTypeTranslate}
                label={packageTypeTranslate}
                selectValue={predefinedPackageID}
                options={packagesTypes}
                // handleSelect={handlePackageType}
                handleSelect={handleSelectPredefinedPackage}
                containerWidth={"48%"}
                category="washing"
                disabled={!selectedTray}
              />
            </div>
            <div
              className={`${styles.manageTraysContainer} ${
                !selectedWasher ? styles.disabled : ""
              }`}
              onClick={handleDisabled}
            >
              <div className={styles.traysContainer}>
                <h2 className={styles.traysTitle}>
                  {!trays.length
                    ? t("BlankEntry33") || "Add Trays"
                    : `${t("BlankEntry28") || "Selected Tray"}: ${trayName}`}
                </h2>
                <div>
                  <div className={styles.trayButtonContainer}>
                    <button
                      className={`${styles.trayButton} ${styles.add}`}
                      onClick={addOneTray}
                    >
                      {t("Add") || "Add"}
                    </button>
                    <button
                      className={`${styles.trayButton} ${styles.delete}`}
                      onClick={onClearTray}
                    >
                      {t("DeleteTrays") || "Delete All"}
                    </button>
                  </div>
                </div>

                <div className={styles.trayItemContainer}>
                  {trays?.map((t) => (
                    <div
                      className={`${styles.trayItem} ${
                        t?.id === selectedTray?.id ? styles.active : ""
                      }`}
                      key={t.id}
                    >
                      <div
                        className={styles.trayContent}
                        onClick={() => handleSelectTray(t)}
                      >
                        {t.name}
                      </div>
                      <FaTimes
                        className={styles.deleteIcon}
                        onClick={() => handleDeleteTray(t.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{ width: "100%", height: "100%", minHeight: "300px" }}
              >
                <DragAndDropWasherLoading
                  trayName={trayName}
                  filter1={
                    <Search
                      category={"washing"}
                      data={packageId ? traysType : materials}
                      handleFilter={handleMaterials}
                      placeholder={
                        t("SearchMaterial", locale) || "Search material..."
                      }
                      searchActive={() => {}}
                      onHandleScan={onHandleScan}
                    />
                  }
                  filter2={
                    <Search
                      category={"washing"}
                      data={selectedMaterials}
                      handleFilter={handleSelectedFilteredMaterials}
                      placeholder={
                        t("SearchAddedMaterial", locale) ||
                        "Search added material..."
                      }
                      searchActive={handleSearchActive}
                      onHandleScan={onHandleScan}
                    />
                  }
                  category={"Washing"}
                  data={availableMaterials}
                  selectedData={selectedMaterials}
                  selectedFilteredData={
                    searchActive ? selectedFilteredMaterials : selectedMaterials
                  }
                  handleSelectedData={handleSelectedMaterials}
                  method={parseInt(selectedMethod)}
                  leftColumnName={t("BlankEntry7")}
                  rightColumnName={t("BlankEntry8")}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "25%", minHeight: "100%" }}>
          <CrudIndicator
            indicators={indicators}
            handleIndicators={handleIndicators}
            onHandleScan={onHandleScan}
            modelAreas={modelAreas}
            zone={true}
            methodId={methodId}
            select={selectedWasher}
            condition={"a Washer"}
            category={"Washing"}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.875rem",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <TextAreaQ
          label={t("ObservationSpan") || "Observations"}
          handleChange={handleChangeObservations}
          value={observations}
          width={"68%"}
          inputRef={observationsRef}
          onHandleScan={onHandleScan}
          category={"washing"}
        />

        <div className={styles.containerButtons}>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => clearFields()}
          >
            {t("Cancel") || "Cancel"}
          </button>
          <button
            className={`${styles.button} ${styles.save} ${
              isSaveDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            {t("Save") || "Save"}
          </button>
          <button
            className={`${styles.button} ${styles.finish} ${
              isFinishDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(2)}
          >
            {t("Finish") || "Finish"}
          </button>
        </div>
      </div>
    </PageContainer>
  );
}
