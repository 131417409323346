import React, { useEffect, useState } from "react";
import DDColumn from "../DragAndDrop/DDColumn/DDColumn.jsx";

import DDRow from "../DragAndDrop/DDRow/DDRow.jsx";
import imgPackage from "../../assets/images/PACKAGE IN A LOAD.svg";

import styles from "./SelectorMaterialHygiene.module.css";
import { MdDragIndicator } from "react-icons/md";

const SelectorMaterialHygiene = ({
  data,
  selectedFilteredData,
  selectedData,
  handleSelectedData,
  filter1,
  filter2,
  leftColumnName,
  rightColumnName,
  editId,
  indicators,
}) => {
  const [selectedCard, setSelectedCard] = useState(null); 

  useEffect(() => {
   
    if (!selectedData || selectedData.length === 0) {
      setSelectedCard(null);
    } else if (editId && selectedData.length > 0) {
      setSelectedCard(selectedData[0]);
    }
  }, [editId, selectedData]);

  const handleSelectCard = (card) => {
    setSelectedCard((prevSelected) => {
      const newSelected = prevSelected === card ? null : card; 
      handleSelectedData(newSelected ? newSelected : []); 
      return newSelected;
    });
  };

  let isCardSelected, isCardDisabled;

  if (!editId) {
    isCardSelected = (card) => selectedCard && selectedCard.id === card.id;
    isCardDisabled = (card) => selectedCard !== null && selectedCard.id !== card.id;
  } else {
    isCardSelected = (card) => selectedCard && selectedCard.materialId === card.id;
    isCardDisabled = (card) => selectedCard && selectedCard.materialId !== card.id && selectedCard !== null;
  }

  const handleDoubleClick = (card) => {
    handleSelectCard(card); 
  };

  
  const uniqueData = Array.isArray(data)
    ? Array.from(new Map(data.map((p) => [p.name, p])).values())
    : [];

  return (
    <div className={styles.container}>
      <div className={styles.columnContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{leftColumnName}</p>
            {filter1}
            <DDColumn id={"Drag"}>
              {uniqueData &&
                uniqueData.map((p) => (
                  <div
                    key={p.id}
                    className={`${styles.card} ${isCardSelected(p) ? styles.selectedCard : ""} ${isCardDisabled(p) ? styles.disabledCard : ""}`}
                    onDoubleClick={() => handleDoubleClick(p)} 
                  >
                    <div className={styles.cell}>
                      <div className={styles.content}>
                        <p className={styles.title}>
                          {p.name}
                          {p.serial && (
                            <span style={{ color: 'gray' }}>
                              - {p.serial}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={isCardSelected(p)}
                      onChange={() => handleSelectCard(p)} 
                      disabled={isCardDisabled(p)} 
                    />
                  </div>
                ))}
            </DDColumn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectorMaterialHygiene;


