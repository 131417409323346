import React, { useState, useEffect, useRef } from "react";
import styles from "./FilterDate.module.css";
import InputDateQ from "./../FormComponents/InputDateQ/InputDateQ";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";

const FilterDate = ({ handleChange, dateTo, dateFrom }) => {
  const [showPicker, setShowPicker] = useState(false);
  const containerRef = useRef(null);


  const handleFromDateChange = (date) => {
    handleChange("dateFrom", date);
    if (dateTo) {
      setShowPicker(false);
    }
  };

  const handleToDateChange = (date) => {
    handleChange("dateTo", date);
    if (dateFrom) {
      setShowPicker(false);
    }
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  const clearDates = () => {
    handleChange("clear");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.inputWrapper} onClick={togglePicker}>
        <CiCalendar className={styles.calendarIcon} />
        <input
          type="text"
          value={`${dateFrom ? df(dateFrom, "date") : t("DateFromSpan")} - ${
            dateTo ? df(dateTo, "date") : t("DateToSpan")
          }`}
          readOnly
          className={styles.input}
        />
        {(dateFrom || dateTo) && (
          <AiOutlineClose className={styles.clearIcon} onClick={clearDates} />
        )}
      </div>
      {showPicker && (
        <div className={styles.datePickers}>
          <InputDateQ
            defaultValue={dateFrom}
            onChange={handleFromDateChange}
            value={dateFrom}
            label={t("dateFromSpan")}
            required={false}
            validate={false}
          />
          <InputDateQ
            defaultValue={dateTo}
            onChange={handleToDateChange}
            label={t("dateToSpan")}
            required={false}
            validate={false}
            value={dateTo}
          />
        </div>
      )}
    </div>
  );
};

export default FilterDate;
