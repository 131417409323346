import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./PackageDetail.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";
import df from "../../utils/dateFormater";
import packageImg from "../../assets/images/MENU PACKAGE.svg";
import packageInLoad from "../../assets/images/PACKAGE IN A LOAD.svg";
import ColumnQ from "../ColumnQ/ColumnQ";
import PageGeneralContainer from "../PageGeneralContainer/PageGeneralContainer";
import { TiDocumentText } from "react-icons/ti";
import { CiUser } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import loadImg from "../../assets/images/MENU LOAD.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Tooltip } from "@material-ui/core";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import biologicalImg from "../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../assets/images/QUIMICO.svg";
import indicatorsImg from "../../assets/images/INDICATORS.svg";
import materialImg from "../../components/NavBar/Menu/icons/INSTRUMENTS.svg";

import PackageCard from "../CicleModal/PackageCard";

import { CiTimer } from "react-icons/ci";
import { CiCircleInfo } from "react-icons/ci";
import { GoLink, GoUnlink } from "react-icons/go";
import t from "../../utils/translation";
import { IoBookOutline, IoReload } from "react-icons/io5";
import { FaTemperatureHigh } from "react-icons/fa";
import sterilizerIcon from "../../assets/images/greenSterilizer.svg";
import request from "../../utils/request";
import ImageModal from "../../components/ImageModal/ImageModal";
import PackageDetail from "./PackageDetail";
import ButtonQ from "../ButtonQ";
import ReportModal from "../ReportModal/ReportModal";
import ModalLoading from "../ModalLoading/ModalLoading";
import LocalizationContext from "../../utils/LocalizationContext";
import Redirect from "../../utils/Redirect";
import PageContainer from "../PageContainer/PageContainer";
import hasPositive from "../../utils/hasPositive";
import IndicatorDetail from "../indicatorDetail/IndicatorDetail";

import toStartIcon from "../../assets/images/Icono To start.svg";
import inProgressIcon from "../../assets/images/Icono In progress.svg";
import finishedIcon from "../../assets/images/Icono Finished.svg";

export default function BatchRecordDetail({ params, location }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const [report, setReport] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cycleDetail, setCycleDetail] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const locale = useContext(LocalizationContext);

  const {cycleId} = params;
  const { state } = location;
  //Datos del ciclo
  // let indicatorsCycle = cycleDetail?.indicators;
  // let materials = cycleDetail?.materials
  // let idCharge=cycleDetail.id
  const handleShowDetail = () => {
    setShowDetail(true);
  };

  const componentRef = useRef();

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseModalReport = () => {
    setShowReport(false);
  };

  const toggleOpen = (id) => {
    setOpenSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // const getPackages = () => {
  //   request()
  //     .get("/api/package")
  //     .then((response) => {
  //       const packageIds = cycleDetail.packages.map((p) => p.packageId);
  //       const filteredPackages = response.data.filter((pkg) =>
  //         packageIds.includes(pkg.id)
  //       );
  //       setPackages(filteredPackages);
  //     });
  // };

  const getLoadDetail = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/Charge/chargedto?chargeId=${cycleId}`)
        .then((response) => {
          const data = response.data;
          setCycleDetail(data);
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  const downloadPdf = (cycleId) => {
    setModalLoading(true);
    request()
      .get(`/api/REPORT/batchrecordreportwithcode?chargeId=${cycleId}`)
      .then((response) => {
        // let html = response.data;
        // const reportData = formatHtml(html);
        const reportData = response.data;
        setReport(reportData);
        setShowReport(true);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  const formatHtml = (html) => {
    html = html.replace(/^<div style="break-after:page"><\/div>/, "");
    html = html.replace(/<div style="break-after:page"><\/div>\s*$/, "");

    const printStyles = `
    <style>
      @media print {
        body {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        div {
          page-break-inside: avoid;
        }
        .page-break {
          page-break-after: always;
        }
      }
    </style>
  `;

    return `${printStyles}${html}`;
  };

  // const packageIds = cycleDetail.packages.map((pkgObj) => {
  //   return pkgObj.package.id;
  // });

  // const getPackages = (packageIds) => {
  //   request()
  //     .get(`/api/package/info?packageId=${packageIds}`)
  //     .then((response) => {
  //       console.log(response)
  //     });
  // };

  // useEffect(() => {
  //   getPackages();
  // }, []);

  const status = (value) => {
    return value === 2
      ? t("ToStartSpan", locale) || "To Start"
      : value === 3
      ? t("StartedSpan", locale) || "Started"
      : t("FinishedSpan", locale) || "Finished";
  };

  const findImg = (value) => {
    return value === 2
      ? toStartIcon
      : value === 3
      ? inProgressIcon
      : finishedIcon;
  };


  useEffect(() => {
    getLoadDetail();
  }, []);

  return (
    <PageContainer
      categoryId={1}
      category={t("SterilizationSpan")}
      backUrl={"/appsterilization"}
      title={t("BatchRecordSpan")}
      subtitle={t("DetailSpan")}
      clickBack={true}
      onClickBack={() =>
        Redirect({
          redirect: true,
          path: state?.packageRecord ? `/appconsultpackage` : state?.cycleRecord ? `/appcyclerecord` : `/appmanagecycles`,
          parameters: { cycleId: !state?.cycleRecord ? cycleId : null,
                        packageId:  state?.packageId ?? null},
        })
      }
    >
      <div className={styles.container}>
        <ModalLoading open={modalLoading} />
        <div className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <p
            className={styles.title}
          >
            {cycleDetail.chargeStatus === 4 && (
              <Tooltip
                title={
                  <span>{` ${
                    hasPositive(cycleDetail.indicators)
                      ? t("BlankEntry18")
                      : t("BlankEntry19")
                  }`}</span>
                }
                arrow
              >
                <div
                  className={`${styles.hasPositive} ${
                    hasPositive(cycleDetail.indicators)
                      ? styles.red : cycleDetail.indicators.some(i => !i.readingBi && !i.readingCheSterilizer) ? styles.gray
                      : styles.green
                  }`}
                ></div>
              </Tooltip>
            )}
            {`${t("CycleNumberSpan", locale) || "Cycle Number"}: ${
              cycleDetail.cicleNumber ?? ""
            }`}
          </p>

          <div className={styles.detailItem}>
            <div className={styles.detailItem} style={{ gap: "10px" }}>
              <img
                className={styles.iconImg}
                alt={status(cycleDetail.chargeStatus)}
                src={findImg(cycleDetail.chargeStatus)}
              />
              <span className={styles.infoStatus}>
                {status(cycleDetail.chargeStatus)}
              </span>
            </div>
         
          </div>
        </div>
          <div>
            <p className={styles.sectionSubTitle}>
              {" "}
              {cycleDetail.sterilizerName} - {cycleDetail.methodName}
            </p>
          </div>
          <div className={styles.headerDetails}>
            <div>
              <div className={styles.detailItem}>
                <IoReload className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("StartedSpan") || "Started"}:{" "}
                  <span className={styles.info}>
                    {df(cycleDetail.cicleStartDate, "dateTime")}
                  </span>
                  {}
                </p>
              </div>
              <div className={styles.detailItem}>
                <IoReload className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("FinishedSpan") || "Finished"}:{" "}
                  <span className={styles.info}>
                    {df(cycleDetail.cicleFinishDate, "dateTime")}
                  </span>
                </p>
              </div>
            </div>

            <div>
              <div className={styles.detailItem}>
                <PersonIcon className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("OperatorSpan") || "User"}:{" "}
                  <span className={styles.info}>{cycleDetail.operator}</span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <TiDocumentText className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("UsedProgramSpan") || "Used Program"}:{" "}
                  <span className={styles.info}>{cycleDetail.usedProgram}</span>
                </p>
              </div>
            </div>

            <div>
              <div className={styles.detailItem}>
                <FaTemperatureHigh className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("TemperatureSpan") || "Temperature"}:{" "}
                  <span className={styles.info}>
                    {cycleDetail.cicleTemperature &&
                      cycleDetail.cicleTemperature +
                        " " +
                        cycleDetail.temperatureUnit}
                  </span>
                </p>
              </div>
              <div className={styles.detailItem}>
                <CiTimer className={styles.iconStyle} />
                <p className={styles.detailText}>
                  {t("TimeSpan") || "Time"}:{" "}
                  <span className={styles.info}>
                    {cycleDetail.cicleTime &&
                      cycleDetail.cicleTime + " " + cycleDetail.timeUnit}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {cycleDetail.cicleObservations && (
            <div className={styles.observationSection2}>
              <p className={`${styles.observationTitle2}`}>
                {t("ObservationSpan")}
              </p>

              <div className={styles.observationContainer2}>
                <p className={styles.observation2}>
                  {cycleDetail.cicleObservations}
                </p>
              </div>
            </div>
          )}
        </div>
        {/* <section className={styles.section}>
       <div className={styles.packagesContainer}>
       {cycleDetail.packages?.map((pkg) => (
         <PackageCard key={pkg.id} pkg={pkg} />
       ))}
     </div>
     </section> */}
        <div className={styles.containerAbajo}>
          <div className={styles.containerLeft}>
            <section className={styles.section}>
              <h2 className={styles.sectionTitle}>{t("IndicatorSpan")}</h2>
              <div className={styles.imageTitleContainer}>
                <img
                  src={biologicalImg}
                  alt="biologicalIndicator"
                  className={styles.image}
                />
                <h2 className={styles.sectionSubTitle}>
                  {t("BiologicalIndicatorSpan")}
                </h2>
              </div>
              {/* {pkgIndicators && pkgIndicators.length === 0 ? (
            <p className={styles.noDataMessage}>No indicators available.</p>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {indicatorsCycle?.map((indicator) => (
                <div className={styles.indicatorItem}>
                  <IndicatorLabel
                    backgroundColor={"white"}
                    name={indicator.indicator.name}
                    lot={indicator.lot}
                    serial={indicator.serial}
                    quantity={indicator.quantity}
                    expirationDate={"2025-03"} // Asegúrate de reemplazar esto con el valor correcto
                    key={indicator.id} // Clave única para cada tarjeta
                    border={true}
                  />
                </div>
              ))}
            </div>
          )} */}

              <div className={styles.indicatorGeneral}>
                {cycleDetail.indicators &&
                cycleDetail.indicators.filter((i) =>
                  i.indicator.name.toUpperCase().includes("BT")
                ).length === 0 ? (
                  <div className={styles.noDataMessage}>
                    {t("NoBiologicalIndicatorSpan")}
                  </div>
                ) : (
                  cycleDetail.indicators &&
                  cycleDetail.indicators
                    .filter((i) =>
                      i.indicator.name.toUpperCase().includes("BT")
                    )
                    .map((i) => (
                      <div className={styles.indicatorGeneral2}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "20px",
                          }}
                        >
                          {i.pcD_Group ? (
                            <div className={styles.indicatorItem2}>
                              <IndicatorLabel
                                readingId={i.readingBI?.id}
                                backgroundColor={"white"}
                                name={i.indicator.name}
                                lot={i.lot}
                                serial={i.serial}
                                expirationDate={i.indicatorExpirationDate}
                                key={i.id}
                                border={true}
                                resultBiologic={i.readingBI?.result}
                                pcd={`${i.pcdName} - ${i.pcdLot}`}
                              />
                            </div>
                          ) : (
                            <div className={styles.indicatorItem2}>
                              <IndicatorLabel
                                readingId={i.readingBI?.id}
                                backgroundColor={"white"}
                                name={i.indicator.name}
                                lot={i.lot}
                                serial={i.serial}
                                expirationDate={i.indicatorExpirationDate}
                                key={i.id}
                                border={true}
                                resultBiologic={i.readingBi?.result}
                              />
                            </div>
                          )}

                          <div className={styles.detailsBox}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <p>{i.readingBi?.incubator?.toString || ""}</p>
                            </div>
                            {Object.keys(i.readingBi || {}).length === 0 ? (
                              <span>{t("WithoutReadingSpan")}</span>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {/* <span>{t("SerialNumber") || "Serial Number"}:</span> */}
                                <span>
                                  {t("ReadingNumber") || "Record number"}:{" "}
                                  {i.readingBi?.readNumber}
                                </span>
                                <span>
                                  {t("PositionSpan") || "Position number"}:{" "}
                                  {i.readingBi?.positionNumber}
                                </span>
                                <span>
                                  {t("StartDateSpan") || "Start date"}:{" "}
                                  {df(i.readingBi?.startedTime, "dateTime")}
                                </span>
                                <span>
                                  {t("ResultDateSpan") || "Result date"}:{" "}
                                  {df(i.readingBi?.resultDate, "dateTime")}
                                </span>
                                <span>
                                  {t("AverageTemperatureSpan") ||
                                    "Average temperature"}
                                  : {i.readingBi?.averageTemperatureFormat}
                                </span>
                                <span>
                                  {t("TicketNumberSpan") || "Ticket number"}:{" "}
                                  {i.readingBi?.ticketNumber}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>

              <div className={styles.imageTitleContainer}>
                <img
                  src={chemicalImg}
                  alt="chemicalIndicator"
                  className={styles.image}
                />
                <h2 className={styles.sectionSubTitle}>
                  {" "}
                  {t("ChemicalIndicatorSpan")}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <div className={styles.indicatorGeneral}>
                  {cycleDetail.indicators &&
                  cycleDetail.indicators.filter(
                    (i) => !i.indicator.name.toUpperCase().includes("BT")
                  ).length === 0 ? (
                    <div className={styles.noDataMessage}>
                      {t("NoChemicalIndicatorSpan")}
                    </div>
                  ) : (
                    cycleDetail.indicators &&
                    cycleDetail.indicators
                      .filter(
                        (i) => !i.indicator.name.toUpperCase().includes("BT")
                      )
                      .map((indicator) => (
                        <div className={styles.indicatorGeneral2}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "20px",
                            }}
                          >
                            {indicator.pcD_Group ? (
                              <div className={styles.indicatorItem2}>
                                <IndicatorLabel
                                  readingId={indicator.readingCheSterilizer?.id}
                                  isChemical={true}
                                  backgroundColor={"white"}
                                  name={indicator.indicator.name}
                                  lot={indicator.lot}
                                  serial={indicator.serial}
                                  // quantity={indicator.quantity}
                                  expirationDate={
                                    indicator.indicatorExpirationDate
                                  }
                                  border={true}
                                  resultCH={
                                    indicator.readingCheSterilizer?.result
                                  }
                                  userResultCH={
                                    indicator.readingCheSterilizer?.visualResult
                                  }
                                  pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                                />
                              </div>
                            ) : (
                              <div className={styles.indicatorItem2}>
                                <IndicatorLabel
                                  readingId={indicator.readingCheSterilizer?.id}
                                  isChemical={true}
                                  backgroundColor={"white"}
                                  name={indicator.indicator.name}
                                  lot={indicator.lot}
                                  serial={indicator.serial}
                                  // quantity={indicator.quantity}
                                  expirationDate={
                                    indicator.indicatorExpirationDate
                                  }
                                  border={true}
                                  resultCH={
                                    indicator.readingCheSterilizer?.result
                                  }
                                  userResultCH={
                                    indicator.readingCheSterilizer?.visualResult
                                  }
                                />
                              </div>
                            )}

                            <div className={styles.detailsBox}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              ></div>
                              {Object.keys(indicator.readingCheSterilizer || {})
                                .length === 0 ? (
                                <span>{t("WithoutReadingSpan")}</span>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span>
                                    {t("ReadingNumber") || "Record number"}:{" "}
                                    {indicator.readingCheSterilizer?.readNumber}
                                  </span>
                                  <span>
                                    {t("DateSpan") || "Date"}:{" "}
                                    {df(
                                      indicator.readingCheSterilizer
                                        ?.creationTest,
                                      "dateTime"
                                    )}
                                  </span>
                                  {/* <span>
                {t("Result") || "Result"}: {getResult(indicator.readingCheSterilizer?.result)}
              </span>
              <span>
                {t("User result") || "User result"}: {getVisualResult(indicator.readingCheSterilizer?.visualResult)} 
              </span> */}
                                  <span>
                                    {t("ObservationSpan") || "Observations"}:{" "}
                                    {indicator.readingCheSterilizer?.notes}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              </div>
            </section>

            <section>
              <div className={styles.imageTitleContainer}>
                <img
                  src={materialImg}
                  alt="Materials"
                  className={styles.image}
                />
                <h2 className={styles.sectionTitle}>{t("MaterialsSpan")}</h2>
              </div>
              {cycleDetail.packages && cycleDetail.packages.length === 0 ? (
                <p className={styles.noDataMessage}>
                  {t("WithoutPackageMaterialSpan")}.
                </p>
              ) : (
                <div className={styles.tableContainer}>
                  {cycleDetail.packages &&
                    cycleDetail.packages.map((pkgObj) => {
                      const pkg = pkgObj.package; // Accede al objeto `package` dentro de cada `packages`

                      return (
                        <div className={styles.packageSection}>
                          {/* <h3 className={styles.packageTitle}>PKG-{pkg.id} {pkg.description}</h3> */}
                          {/* <div>
      <h3 className={styles.packageTitle} onClick={handleShowDetail}>
        PKG-{pkg.id} {pkg.description}
      </h3>
      {showDetail && <PackageDetail />}
          </div> */}

                          <div>
                            <h3
                              className={styles.packageTitle}
                              onClick={() => toggleOpen(pkg.id)}
                            >
                              {pkg.packageNumber} - {pkg.description}
                              {openSections[pkg.id] ? (
                                <IoIosArrowUp className={styles.arrowIcon} />
                              ) : (
                                <IoIosArrowDown className={styles.arrowIcon} />
                              )}
                            </h3>
                            {openSections[pkg.id] && (
                              <div className={styles.additionalContent}>
                                <h2 className={styles.sectionTitle}>
                                  {t("IndicatorSpan")}
                                </h2>
                                <div className={styles.indicatorGeneral}>
                                  {pkg.indicators &&
                                  pkg.indicators.length === 0 ? (
                                    <p className={styles.noDataMessage}>
                                      {t("NoIndicatorsSpan")}
                                    </p>
                                  ) : (
                                    pkg.indicators &&
                                    pkg.indicators.map((indicator) => (
                                      <div
                                        className={styles.indicatorItemChemical}
                                        key={indicator.id}
                                      >
                                        {indicator.readingCheSterilizer ? (
                                          <Tooltip
                                            key={indicator.id}
                                            title={
                                              <div>
                                                <div>
                                                  {t("ReadingNumber")}:{" "}
                                                  {
                                                    indicator
                                                      .readingCheSterilizer
                                                      ?.readNumber
                                                  }
                                                </div>
                                                <div>
                                                  {t("DateSpan")}:{" "}
                                                  {df(
                                                    indicator
                                                      .readingCheSterilizer
                                                      ?.creationTest,
                                                    "dateTime"
                                                  )}
                                                </div>
                                                <div>
                                                  {t("ObservationSpan")}:{" "}
                                                  {
                                                    indicator
                                                      .readingCheSterilizer
                                                      ?.notes
                                                  }
                                                </div>
                                              </div>
                                            }
                                            arrow
                                          >
                                            <div
                                              className={styles.indicatorItem}
                                            >
                                              <IndicatorLabel
                                                readingId={indicator.readingCheSterilizer?.id}

                                                isChemical={true}
                                                backgroundColor={"white"}
                                                name={indicator.indicator.name}
                                                lot={indicator.lot}
                                                serial={indicator.serial}
                                                expirationDate={
                                                  indicator.expirationDate
                                                }
                                                border={true}
                                                resultCH={
                                                  indicator.readingCheSterilizer
                                                    .result
                                                }
                                                userResultCH={
                                                  indicator.readingCheSterilizer
                                                    .visualResult
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        ) : (
                                          <div className={styles.indicatorItem}>
                                            <IndicatorLabel
                                              resultBiologic={indicator.readingBi?.result}
                                              isChemical={true}
                                              backgroundColor={"white"}
                                              name={indicator.indicator.name}
                                              lot={indicator.lot}
                                              serial={indicator.serial}
                                              expirationDate={
                                                indicator.expirationDate
                                              }
                                              border={true}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  )}
                                </div>

                                <div className={styles.observationSection2}>
                                  <p className={`${styles.observationTitle2}`}>
                                    Observation
                                  </p>
                                  <div className={styles.observationContainer2}>
                                    {pkg.observations ? (
                                      <p className={styles.observation2}>
                                        {pkg.observations}
                                      </p>
                                    ) : (
                                      <p className={styles.observation2}>
                                        {t("NoObservationSpan")}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <table className={styles.dataTable}>
                            <thead>
                              <tr>
                                <th className={styles.th}>
                                  {t("MaterialSpan")}
                                </th>
                                <th className={styles.th}>
                                  {t("TypeOfMaterialSpan")}
                                </th>
                                <th className={styles.th}>
                                  {t("QuantitySpan")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pkg.materials && pkg.materials.length > 0 ? (
                                pkg.materials.map((materialObj, index) => {
                                  const material = materialObj.material; // Accede al objeto material

                                  if (!material) {
                                    return null;
                                  }

                                  return (
                                    <tr
                                      key={material.materialId || index}
                                      className={styles.trHover}
                                    >
                                      <td className={styles.td}>
                                        {" "}
                                        {material.name ||
                                          "Nombre no disponible"}
                                        {material.serial && (
                                          <div className={styles.serial}>
                                            {t("SerialSpan")}: {material.serial}
                                          </div>
                                        )}
                                        {/* <div className={styles.iconWrapper}>
                     { <div className={styles.icon}></div>}
                       </div> */}
                                        {/* method !== materialMethod */}
                                      </td>
                                      <td className={styles.td}>
                                        {material.materialType.name || "N/A"}
                                      </td>
                                      <td className={styles.td}>
                                        {materialObj.quantity || 0}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="3" className={styles.td}>
                                    {t("NoMaterialSpan")}{" "}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                </div>
              )}
            </section>
          </div>
          <div className={styles.containerRight}>
            <section>
              <h2 className={styles.titleImage}>{t("ImageSpan")}</h2>

              {cycleDetail.ticketSterilizer ? (
                <div className={styles.imgContainer}>
                  <img
                    src={cycleDetail.ticketSterilizer}
                    alt="No available"
                    className={styles.ticketImage}
                    onClick={handleImageClick}
                  />
                </div>
              ) : (
                <div className={styles.imgContainer2}>
                  <h2 className={styles.titleImage}>
                    {t("BlankEntry12") || "The ticket wasn't loaded"}
                  </h2>
                </div>
              )}
              {isModalOpen && (
                <ImageModal
                  imageSrc={cycleDetail.ticketSterilizer}
                  onClose={handleCloseModal}
                />
              )}
            </section>
          </div>
        </div>
        <div>
          <ButtonQ
            caption={t("DownloadAsPDF", locale)}
            onClick={() => downloadPdf(cycleId)}
          />
          {/* {loading &&  
         <p style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: '30px' }}>
          Loading PDF...
        </p>}   */}
          <ModalLoading open={modalLoading} />
          {showReport && (
            <ReportModal
              htmlContent={report}
              onClose={handleCloseModalReport}
            />
          )}
        </div>

        {/* <PDFDownloadLink
        document={
          <BatchRecordPdf
            cycleDetail={cycleDetail}
            packages={packages}
            getResultBackgroundColor={getResultBackgroundColor}
            getResultText={getResultText}
          />
        }
        fileName={`BatchRecord-${cycleDetail.cicleNumber}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Generando PDF..." : "Descargar PDF"
        }
      </PDFDownloadLink> */}

        {/* <BatchRecordPdf contentId="report-content"/> */}
      </div>
    </PageContainer>
  );
}
