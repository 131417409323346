import React, { useContext } from "react";
import styles from "./AppMainQ.module.css";
import HomeMenuCard from "./HomeMenuCard/HomeMenuCard";
import sterilizationImage from "../../assets/images/ICONO STERILIZATION NEW.svg";
import washingImage from "../../assets/images/WASHING.svg";
import hygieneMonitoringImage from "../../assets/images/ICONO HYGIENE MONITORING.svg";
import DashboardMain from "../DashboardMain/DashboardMain";
import AccordionDownloader from "../../components/Downloader/AccordionDownloader";
import LocalizationContext from "../../utils/LocalizationContext";
import { getSession } from "../../utils/sessionHandler";
import t from "../../utils/translation";

export default function AppMainQ() {
  const tra = useContext(LocalizationContext);

  const user = getSession();
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <div className={styles.home}>
      {/* <div className={'col-lg-12'} style={{marginBottom: 20}}>
          		<AccordionDownloader translations={translations}></AccordionDownloader>
        	</div> */}
      <p className={styles.title}>{t("WelcomeBack")},</p>
      <p className={`${styles.title} ${styles.username}`}>{`${capitalize(
        user.Name
      )} ${user.LastName.charAt(0)}.`}</p>
      <div className={styles.optionsContainer}>
        <HomeMenuCard
          title={t("WashingSpan")}
          categoryId={2}
          image={washingImage}
          url={"/appwashingmenu"}
        />
         <HomeMenuCard
          title={t("HygieneMonitoringSpan") || "Hygiene Monitoring"}
          image={hygieneMonitoringImage}
          url={"/appmanagehygienemonitoring"}
        />
        <HomeMenuCard
          title={t("SterilizationSpan")}
          categoryId={1}
          image={sterilizationImage}
          url={"/appsterilization"}
        />
      </div>
      <DashboardMain />
    </div>
  );
}
