import React, { useContext, useEffect, useState } from "react";

import PageContainer from "../../components/PageGeneralContainer/PageGeneralContainer";

import request from "../../utils/request.js";
import t from "../../utils/translation.js";
import LocalizationContext from "../../utils/LocalizationContext.js";

import styles from "./Sterilizer.module.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import SterilizersTable from "./SterilizersTable/SterilizerTable.jsx";
import { getUserCompany } from "../../utils/sessionHandler.js";
import SterilizersForm from "./SterilizersForm/SterilizersForm.jsx";
import SterilizersDetail from "./SterilizersDetail/SterilizersDetail.jsx";
import BrandsForm from "../../components/BrandsForm/BrandsForm.jsx";
import ModelsForm from "../../components/ModelsForm/ModelsForm.jsx";
import ButtonQ from "../../components/ButtonQ/ButtonQ.js";

const emptyModel = {
  machineType: 1,
  companyMachineMakeId: "",
  name: "",
  methodId: "",
  description: "",
  observations: "",
  technicalService: "",
  technicalServiceMail: "",
  technicalServicePhone: "",
  modelAreas: [],
};

const Sterilizer = () => {
  const [sterilizers, setSterilizers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [machineTypes, setMachineTypes] = useState();
  const [methodName, setMethodName] = useState("");
  const [methods, setMethods] = useState([]);
  const [modal, setModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    brand: "",
    serial: "",
    observations: "",
    companyMachineModelId: "",
    companyMachineMakeId: "",
  });
  const [sterilizerData, setSterilizerData] = useState({});
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [loading, setLoading] = useState(true);
  const [modelModal, setModelModal] = useState(false);
  const [brandModal, setBrandModal] = useState(false);
  const [modelData, setModelData] = useState(emptyModel);
  const [brandData, setBrandData] = useState({});

  const locale = useContext(LocalizationContext);

  const clearForm = () => {
    setFormData({
      name: "",
      brand: "",
      serial: "",
      observations: "",
      companyMachineModelId: "",
      companyMachineMakeId: "",
    });
  };

  const getSectors = () => {
    request()
      .get(`/api/sector?companyId=${getUserCompany().Id}`)
      .then((response) => {
        setSectors(response.data.map((s) => ({ value: s.id, name: s.name })));
      });
  };

  const getSterilizers = (sector) => {
    setLoading(true);
    request()
      .get(
        `/api/Sterilizer/GetBySector?CompanyId=${
          getUserCompany().Id
        }&SectorId=${sector}`
      )
      .then((res) => {
        let newRes = res.data.map((s) => ({
          ...s,
          modelName: s.companyMachineModel.name,
          methodName: s.companyMachineModel.method.name,
        }));
        setSterilizers(newRes);
        setLoading(false);
      });
  };

  const getModels = async () => {
    try {
      const res = await request().get("/api/CompanyMachineModel");
      const filteredModels = res.data
        .filter((m) => m.machineType === 1)
        .map((m) => ({
          ...m,
          value: m.id,
          name: m.name,
          brandId: m.companyMachineMakeId,
          methodName: m.method.name,
        }));
      setModels(filteredModels);
    } catch (error) {
      console.error("Error al obtener los modelos:", error);
    }
  };

  const getBrands = () => {
    request()
      .get("/api/companyMachineMake")
      .then((res) => {
        setBrands(
          res.data.map((b) => ({
            ...b,
            value: b.id,
            name: b.brand,
          }))
        );
      });
  };

  const getMethods = async () => {
    try {
      setLoading(true);
      const res = await request().get("/api/method");
      setMethods(
        res.data.map((m) => ({
          value: m.id,
          name: m.name,
          machineType: m.machineType,
        }))
      );
    } catch (error) {
      console.error("Error fetching methods:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMachineTypes = async () => {
    try {
      setLoading(true);
      setMachineTypes([
        { value: 1, name: "Sterilizer" },
        { value: 2, name: "Washer" },
        { value: 3, name: "Disinfector" },
      ]);
    } catch (error) {
      console.error("Error setting machine types:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSector = (e) => {
    setSelectedSector(e.target.value);
    getSterilizers(e.target.value);
  };

  const handleFormData = (data) => {
    setFormData(data);
  };

  const handleBrandData = (data) => {
    setBrandData(data);
  };

  const handleModelData = (data) => {
    setModelData(data);
  };

  useEffect(() => {
    getSectors();
    getBrands();
    getModels();
    getMethods();
    getMachineTypes();
  }, []);

  const handleAdd = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    clearForm();
  };

  const handleDetail = (data) => {
    setSterilizerData(data);
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setSterilizerData({});
  };

  const handleModalBrand = () => {
    setBrandModal(!brandModal);
    handleBrandData({});
  };

  const handleModalModel = () => {
    setModelModal(!modelModal);
    modelModal &&
      handleModelData({
        ...emptyModel,
        companyMachineMakeId: formData.companyMachineMakeId,
      });
  };

  const handleCreateModel = (id, brandId) => {
    handleFormData((prev) => ({
      ...prev,
      companyMachineMakeId: brandId,
      companyMachineModelId: id,
    }));
  };

  const handleCreateBrand = (data) => {
    const companyMachineMakeId = data;

    handleFormData((prev) => ({
      ...prev,
      companyMachineMakeId,
      companyMachineModelId: "",
    }));
  };

  useEffect(() => {
    handleModelData((prev) => ({
      ...prev,
      companyMachineMakeId: formData.companyMachineMakeId,
    }));
  }, [formData.companyMachineMakeId]);

  return (
    <PageContainer title={"Sterilizers"} category={"sterilizer"}>
      <div className={styles.select}>
        <SelectQ
          label={t("Sector") || "Sector"}
          placeholder={t("SelectASector") || "Select A Sector"}
          value={selectedSector}
          handleSelect={handleSector}
          options={sectors}
        />
      </div>
      {selectedSector && (
        <>
          <div className={styles.container}>
            <SterilizersTable
              sterilizers={sterilizers}
              getSterilizers={getSterilizers}
              handleData={setFormData}
              handleModal={setModal}
              handleDetail={handleDetail}
              selectedSector={selectedSector}
              loading={loading}
            />
            <div className={styles.detailContainer}>
              <ButtonQ
                caption={t("AddButton") || "Add"}
                onClick={() => handleAdd()}
              />
            </div>
          </div>
          {/* <button
            className={`${styles.button} ${styles.addButton}`}
            onClick={() => handleAdd()}
          >
            Add
          </button> */}
        </>
      )}

      <SterilizersForm
        formData={formData}
        handleFormData={handleFormData}
        handleClose={handleClose}
        open={modal}
        getSterilizers={getSterilizers}
        brands={brands}
        models={models}
        methodName={methodName}
        t={t}
        locale={locale}
        selectedSector={selectedSector}
        handleModalBrand={handleModalBrand}
        handleModalModel={handleModalModel}
        handleModelData={handleModelData}
      />

      <SterilizersDetail
        open={showDetail}
        handleClose={handleCloseDetail}
        t={t}
        locale={locale}
        sterilizerData={sterilizerData}
      />

      {brandModal && (
        <BrandsForm
          formData={brandData}
          handleFormData={handleBrandData}
          handleClose={handleModalBrand}
          open={brandModal}
          locale={locale}
          t={t}
          getBrands={getBrands}
          brands={brands}
          handleCreate={handleCreateBrand}
        />
      )}

      {modelModal && (
        <ModelsForm
          formData={modelData}
          handleFormData={handleModelData}
          handleClose={handleModalModel}
          open={modelModal}
          locale={locale}
          t={t}
          getCompanyMachineModels={getModels}
          models={models}
          brands={brands}
          methods={methods}
          machineTypes={machineTypes}
          handleCreate={handleCreateModel}
        />
      )}
    </PageContainer>
  );
};

export default Sterilizer;