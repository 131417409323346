import React, { useState, useContext, useEffect } from "react";
import request from "../../utils/request.js";
import LocalizationContext from "../../utils/LocalizationContext.js";
import PageContainer from "../../components/PageContainer/PageContainer.jsx";
import Vinculation from "./Vinculation/Vinculation.jsx";
import CycleDetail from "./CycleDetail/CycleDetail.jsx";
import CycleBoard from "./CycleBoard/CycleBoard.jsx";
import df from "../../utils/dateFormater";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import t from "../../utils/translation.js";
import FinishModal from "./FinishModal/FinishModal.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import { getFilterValue, setFilterValue } from "../../utils/sessionFilters.js";
import ChemicalVinculation from "../../components/ChemicalVinculation/ChemicalVinculation.jsx";

const ManageCycles = ({ location }) => {
  const locale = useContext(LocalizationContext);

  const [filters, setFilters] = useState({
    dateFrom: getFilterValue(
      "dateFrom",
      new Date(new Date().setHours(-24, 0, 0, 0)),
      "manageCycles"
    ),
    dateTo: getFilterValue("dateTo", new Date(), "manageCycles"),
    selectedSterilizer: getFilterValue(
      "selectedSterilizer",
      "",
      "manageCycles"
    ),
  });

  const [load, setLoadDetail] = useState(null);
  const [loads, setLoads] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState({});
  const [isChemicalIndicator, setIsChemicalIndicator] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [toStartLoads, setToStartLoads] = useState([]);
  const [inProgressLoads, setInProgressLoads] = useState([]);
  const [finishedLoads, setFinishedLoads] = useState([]);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [cycleStartDate, setCycleStartDate] = useState(null);
  const [loadID, setLoadID] = useState(null);
  const [detailId, setDetailId] = useState(location?.state?.cycleId ?? null);
  const [isEdit, setIsEdit] = useState(false);
  const [reloadDetail, setRealoadDetail] = useState(false);
  const [loadingFinishModal, setLoadingFinishModal] = useState(false);
  const [showIncubators, setShowIncubators] = useState(true);
  const [chemicalLoading, setChemicalLoading] = useState(false);
  const [isFromPackage, setIsFromPackage] = useState(false)

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };
      setFilterValue(key, value, "manageCycles");
      return updatedFilters;
    });
  };

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      const clearedFilters = { ...filters, dateFrom: "", dateTo: "" };
      setFilters(clearedFilters);
      setFilterValue("dateFrom", "", "manageCycles");
      setFilterValue("dateTo", "", "manageCycles");
    } else {
      handleFilterChange(type, value);
    }
  };

  const getLoads = async () => {
    setModalLoading(true);
    try {
      const { dateFrom, dateTo, selectedSterilizer } = filters;
      const response = await request().get(
        `/api/Charge/list?pageSize=1000000&Status=6&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setLoads(response.data.chargeListatorList);
      setToStartLoads(
        response.data.chargeListatorList.filter((l) => l.chargeStatus === 2)
      );
      setInProgressLoads(
        response.data.chargeListatorList.filter((l) => l.chargeStatus === 3)
      );
      setFinishedLoads(
        response.data.chargeListatorList.filter((l) => l.chargeStatus === 4)
      );
    } catch (error) {
      console.error("Error fetching loads:", error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleFormData = (data) => {
    setFormData(data);
  };

  const handleLoad = (chargeId) => {
    setLoadDetail(loads.find((load) => load.chargeId == chargeId));
    setDetailId(chargeId);
    setSelectedIndicator({});
  };

  const handleDrop = async (value, status, e, finishObject) => {
    e.preventDefault();

    try {
      const { data: infoLoad } = await request().get(
        `/api/Charge/chargedto?chargeId=${value}`
      );

      let newIndicators = infoLoad.indicators.map((i) => ({
        IndicatorId: i.indicatorId,
        quantity: 1,
        serial: i.serial || null,
        lot: i.lot || null,
        modelAreasId: i.modelAreasId,
      }));

      let newPackages = infoLoad.packages.map((p) => ({
        PackageId: p.packageId,
      }));

      const requestData = {
        chargeId: infoLoad.chargeId,
        chargeStatus: status,
      };

      const requestInProgressData = {
        id: infoLoad.chargeId,
        sterilizerId: infoLoad.sterilizerId,
        description: infoLoad.description,
        chargeStatus: 3,
        indicators: newIndicators,
        packages: newPackages,
        ...finishObject,
        cicleFinishDate: null,
      };

      const requestFinishedData = {
        id: infoLoad.chargeId,
        sterilizerId: infoLoad.sterilizerId,
        description: infoLoad.description,
        chargeStatus: 4,
        indicators: newIndicators,
        packages: newPackages,
        ...finishObject,
      };

      await request()
        .put(
          status === 2
            ? "/api/charge/chargestatus"
            : "/api/charge/modifycharge",

          status === 4
            ? requestFinishedData
            : status === 3
            ? requestInProgressData
            : requestData
        )
        .then(() => {
          status === 4 && closeModal();
          getLoads(selectedSterilizer, dateFrom, dateTo);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEdit(false);
    setFormData({
      cicleNumber: "",
      cicleObservations: "",
      cicleTemperature: "",
      cicleTime: "",
      usedProgram: "",
      bookNumber: "N/A",
      pageNumber: "N/A",
      timeUnit: t("MinuteSpan") || "Minutes",
      cicleStartDate: null,
      securityMode: "",
      temperatureUnit: "",
      img: "",
    });
  };

  const onConfirmEdit = (id) => {
    setLoadID(id);
    setRealoadDetail(true);
  };

  const handleSelectedIndicator = (data, isChemical = false, isFrom = "Charge") => {
    setSelectedIndicator(data);
    setIsChemicalIndicator(isChemical);
    setIsFromPackage(isFrom.toUpperCase() === "PACKAGE")
  };

  const closeChemicalVinculation = () => {
    setIsChemicalIndicator(false);
    setSelectedIndicator({});
  };

  const onChemicalVinculation = async (result, observations, image) => {
    setChemicalLoading(true);
    let requestBody = {
      // El usuario pone 0 (safe) o 1 (unsafe)
      result: result,
      loteProduct: selectedIndicator.lot,
      gtin: "07798164678151",
      productName: selectedIndicator.indicator.name,
      expirationDate: selectedIndicator.indicatorExpirationDate,
      // guid: load.GUID.toUpperCase(),
      // deviceSN: DeviceInfo.getSerialNumberSync(),
      base64Image: image,
      // User observation
      EditUserId: load.EditUserId,
      Cycle: load.cycleNumber,
      DeviceSn: "BionovaQ",
      Guid: "00000000-0000-0000-0000-000000000000",
      VisualResult: 0,
      SterilizerId: load.sterilizerId,
      Observations: observations,
      isFromCharge: true,
      ChargeIndicatorId: selectedIndicator.id,
      chargeId: load.chargeId,
    };
    try {
      await request().post("/api/ReadingCHELens/link", requestBody);
      closeChemicalVinculation();
    } catch (err) {
      console.error(err);
    } finally {
      setChemicalLoading(false);
    }
  };

  useEffect(() => {
    getLoads();
  }, [filters]);

  return (
    <PageContainer
      categoryId={1}
      currentStep={3}
      category={t("SterilizationSpan")}
      subcategory={t("CyclesTitle", locale)}
      title={t("ManageCycle", locale)}
      menu={false}
      backUrl={"/appsterilization"}
      scan={true}
      clickBack={
        (selectedIndicator?.id && !isChemicalIndicator) || detailId
          ? true
          : null
      }
      onClickBack={() => {
        !showIncubators
          ? setShowIncubators(true)
          : selectedIndicator?.id && !isChemicalIndicator
          ? setSelectedIndicator({})
          : setDetailId(null);
      }}
      progress={true}
    >
      {selectedIndicator?.id && !isChemicalIndicator ? (
        <Vinculation
          selectedIndicator={selectedIndicator}
          load={load}
          handleLoad={handleLoad}
          handleReload={setRealoadDetail}
          handleSelectedIndicator={handleSelectedIndicator}
          showIncubators={showIncubators}
          setShowIncubators={setShowIncubators}
          isFromPackage={isFromPackage}
        />
      ) : (
        <>
          {isModalOpen && (
            <FinishModal
              isModalOpen={isModalOpen}
              handleDrop={handleDrop}
              closeModal={closeModal}
              locale={locale}
              loadId={loadID}
              startDate={cycleStartDate}
              formData={formData}
              handleFormData={handleFormData}
              isEdit={isEdit}
              onConfirmEdit={onConfirmEdit}
              loadingFinishModal={loadingFinishModal}
            />
          )}
          {detailId && (
            <>
              <CycleDetail
                loadId={detailId}
                locale={locale}
                handleIndicator={handleSelectedIndicator}
                handleFormData={handleFormData}
                handleLoadId={setLoadID}
                openEdit={setIsModalOpen}
                handleIsEdit={setIsEdit}
                reloadDetail={reloadDetail}
                handleReload={setRealoadDetail}
                isChemicalIndicator={isChemicalIndicator}
                closeChemicalVinculation={closeChemicalVinculation}
                selectedIndicator={selectedIndicator}
                load={load}
              />
              {isChemicalIndicator && !isFromPackage && (
                <ChemicalVinculation
                  isChemical={isChemicalIndicator}
                  onClose={closeChemicalVinculation}
                  handleSubmit={onChemicalVinculation}
                />
              )}
            </>
          )}
          {!detailId && (
            <CycleBoard
              locale={locale}
              handleLoad={handleLoad}
              setLoads={setLoads}
              loads={loads}
              getLoads={getLoads}
              toStartLoads={toStartLoads}
              startedLoads={inProgressLoads}
              finishedLoads={finishedLoads}
              handleChangeDate={handleDateChange}
              dateTo={filters.dateTo}
              dateFrom={filters.dateFrom}
              modalLoading={modalLoading}
              handleStartDate={setCycleStartDate}
              handleLoadId={setLoadID}
              handleDrop={handleDrop}
              handleFormData={handleFormData}
              handleModal={setIsModalOpen}
              handleLoadingFinishModal={setLoadingFinishModal}
              handleFilterSterilizer={(value) =>
                handleFilterChange("selectedSterilizer", value)
              }
              selectedSterilizer={filters.selectedSterilizer}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ManageCycles;
