import BT10 from "../assets/images/indicators/Biological/BT10.png";
import BT20 from "../assets/images/indicators/Biological/BT20.png";
import BT95 from "../assets/images/indicators/Biological/BT95.png";
import BT96 from "../assets/images/indicators/Biological/BT96.png";
import BT97 from "../assets/images/indicators/Biological/BT97.png";
import BT98 from "../assets/images/indicators/Biological/BT98.png";
import BT102 from "../assets/images/indicators/Biological/BT102.png";
import BT110 from "../assets/images/indicators/Biological/BT110.png";
import BT220 from "../assets/images/indicators/Biological/BT220.png";
import BT221 from "../assets/images/indicators/Biological/BT221.png";
import BT222 from "../assets/images/indicators/Biological/BT222.png";
import BT223 from "../assets/images/indicators/Biological/BT223.png";
import BT224 from "../assets/images/indicators/Biological/BT224.png";
import BT225 from "../assets/images/indicators/Biological/BT225.png";
import BD125X1 from "../assets/images/indicators/Chemical/BD125X1.png";
import BD125X2 from "../assets/images/indicators/Chemical/BD125X2.png";
import CD16 from "../assets/images/indicators/Chemical/CD16.png";
import CD29 from "../assets/images/indicators/Chemical/CD29.png";
import CD40 from "../assets/images/indicators/Chemical/CD40.png";
import CD42 from "../assets/images/indicators/Chemical/CD42.png";
import CD50 from "../assets/images/indicators/Chemical/CD50.png";
import IT26C from "../assets/images/indicators/Chemical/IT26C.png";
import IT26SAD from "../assets/images/indicators/Chemical/IT26SAD.png";
import IT26SBL from "../assets/images/indicators/Chemical/IT26SBL.png";
import IT261YS from "../assets/images/indicators/Chemical/IT261YS.png";
import IT2753S from "../assets/images/indicators/Chemical/IT2753S.png";
import IT2735S from "../assets/images/indicators/Chemical/IT2735S.png";
import PCDBI2RC from "../assets/images/indicators/Chemical/PCDBI2RC.png";
import PCD225C from "../assets/images/indicators/Chemical/PCD225C.png";
import PCD220C from "../assets/images/indicators/Chemical/PCD220C.png";
import PCD2202 from "../assets/images/indicators/Chemical/PCD2202.png";
import CDWA4 from "../assets/images/indicators/Chemical/CDWA4.jpg";
import CDWA3 from "../assets/images/indicators/Chemical/CDWA3.jpg";
import PRO1MICRO from "../assets/images/indicators/Proteina/PRO1MICRO.png";
import PRO1ENDO from "../assets/images/indicators/Proteina/PRO1ENDO.png";

const imageMap = {
  BT10,
  BT20,
  BT95,
  BT96,
  BT97,
  BT98,
  BT102,
  BT110,
  BT220,
  BT221,
  BT222,
  BT223,
  BT224,
  BT225,
  BD125X1,
  BD125X2,
  CD16,
  CD29,
  CD40,
  CD42,
  CD50,
  IT26C,
  IT26SAD,
  IT26SBL,
  IT261YS,
  IT2753S,
  IT2735S,
  PCDBI2RC,
  PCD225C,
  PCD220C,
  PCD2202,
  CDWA4,
  CDWA3,
  PRO1MICRO,
  PRO1ENDO,
};

export function getImageIndicatorByName(name) {
  const formattedName = name?.replace(/[-./]/g, "");

  return imageMap[formattedName] || null;
}
