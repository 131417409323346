import React, { useState, useRef, useContext, useEffect, useMemo } from "react";

import LocalizationContext from "../../utils/LocalizationContext";
import TableRowItemIS from "./TableRowItemIS";

import styles from "./IndicatorSelected.module.css";
import TableContainerQ from "../TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TablePaginationQ from "../TableBionovaQ/TablePaginationQ/TablePaginationQ";
import useTableQ from "../../utils/Hooks/useTableQ";
import SelectQ from "../FormComponents/SelectQ/SelectQ";
import InputQ from "../FormComponents/InputQ/InputQ";
import ModalQ from "../TableBionovaQ/ModalQ/ModalQ";
import FilterDate from "../FilterDate/FilterDate";
import ButtonQ from "../ButtonQ/ButtonQ";
import t from "../../utils/translation";
import request from "../../utils/request";
const statusOptions = [
  { value: 1, name: t("InProgressSpan") },
  { value: 3, name: t("FinishedSpan") },
];

const resultsOptions = [
  { value: 1, name: t("PositiveSpan") },
  { value: 2, name: t("NegativeSpan") },
  { value: 3, name: t("CanceledSpan") },
];

const timeUnitOptions = [
  { value: 1, name: t("MinuteSpan") || "Minutes" },
  { value: 2, name: t("SecondSpan") || "Seconds" },
];

const IndicatorSelected = ({
  readings,
  selectedIndicator,
  linkHandler,
  charge,
  position,
  handleList,
  totalPages,
}) => {
  const [statusFilter, setStatusFilter] = useState("");
  const [resultFilter, setResultFilter] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [dataDValue, setDataDValue] = useState({
    valueD: "0",
    valueProcess: "0",
    valueDUnit: "1",
  });
  const [vinculationDValue, setVinculationDValue] = useState({
    valueD: "",
    valueProcess: "",
    valueDUnit: "",
  });
  const [openDValue, setOpenDValue] = useState(false);
  const [processOptions, setProcessOptions] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [savedValues, setSavedValues] = useState({
    valueD: "0",
    valueProcess: "0",
    valueDUnit: "1",
  });

  const [validate, setValidate] = useState(false);

  const locale = useContext(LocalizationContext);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const intervalRef = useRef(null);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    handleList(
      position,
      e.target.value,
      resultFilter,
      dateFrom,
      dateTo,
      currentPage,
      itemsPerPage
    );
  };

  const handleResultFilterChange = (e) => {
    setResultFilter(e.target.value);
    handleList(
      position,
      statusFilter,
      e.target.value,
      dateFrom,
      dateTo,
      currentPage,
      itemsPerPage
    );
  };

  const handleDateChange = (type, value) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
        handleList(
          position,
          statusFilter,
          resultFilter,
          value,
          new Date(),
          currentPage,
          itemsPerPage
        );
      } else {
        handleList(
          position,
          statusFilter,
          resultFilter,
          value,
          dateTo,
          currentPage,
          itemsPerPage
        );
      }
    } else if (type === "dateTo") {
      setDateTo(value);
      handleList(
        position,
        statusFilter,
        resultFilter,
        dateFrom,
        value,
        currentPage,
        itemsPerPage
      );
    } else if (type === "clear") {
      setDateFrom("");
      setDateTo("");
      handleList(
        position,
        statusFilter,
        resultFilter,
        "",
        "",
        currentPage,
        itemsPerPage
      );
    }
  };

  const handleFormData = (data) => {
    setDataDValue(data);
  };

  const handleSelectChange = (e) => {
    let { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onLink = (readingBiId) => {
    linkHandler(
      selectedIndicator.id,
      readingBiId,
      dataDValue.valueD,
      dataDValue.valueProcess,
      dataDValue.valueDUnit
    );
  };

  const {
    currentPage,
    currentItems,
    // totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
    itemsPerPage,
  } = useTableQ(
    readings,
    [
      "positionNumber",
      "statusDescription",
      "readNumber",
      "result",
      "startedTime",
      "resultDate",
      "averageTemperatureFormat",
      "incubationTime",
      "program.nombre",
    ],
    totalPages,
    10
  );

  const onChangePage = (value) => {
    handleList(
      position,
      statusFilter,
      resultFilter,
      dateFrom,
      dateTo,
      value,
      itemsPerPage
    );
    handleChangePage(value);
  };

  const onItemsPerPage = (e) => {
    handleList(
      position,
      statusFilter,
      resultFilter,
      dateFrom,
      dateTo,
      currentPage,
      e.target.value
    );
    handleChangeItemsPerPage(e);
  };

  const onCurrentPage = (value) => {
    handleList(
      position,
      statusFilter,
      resultFilter,
      dateFrom,
      dateTo,
      value,
      itemsPerPage
    );
    setCurrentPage(value);
  };

  const handleValueD = (e) => {
    let value = e.target.value;
    if (value === "" || /^[0-9]+$/.test(value)) {
      handleFormData((prev) => ({ ...prev, valueD: value }));
    }
  };

  // const onCloseDValue = () => {
  //   setDataDValue({valueD: "0",
  //     valueProcess: "0",
  //     valueDUnit: "1",})
  //   setOpenDValue(false);
  //   setValidate(false);
  // };

  const onCloseDValue = () => {
    if (!isConfirmed) {
      setDataDValue({
        valueD: "0",
        valueProcess: "0",
        valueDUnit: "1",
      });
    } else {
      setDataDValue(savedValues);
    }
    setOpenDValue(false);
    setValidate(false);
  };

  const getProcess = async () => {
    try {
      const response = await request().get("/api/ReadingBI/GetProcessBI");
      let data = response.data.map((d) => ({
        value: d.id,
        name: d.description,
      }));
      setVinculationDValue((prev) => ({
        ...prev,
        valueProcess: data.find((d) => d.value == 0).name,
      }));
      setProcessOptions(data);
    } catch (e) {
      console.error(e);
    }
  };

  // const handleDValueSubmit = (e) => {
  //   e.preventDefault();
  //   if (!dataDValue.valueD) {
  //     setValidate(true);
  //     return;
  //   }

  //   setVinculationDValue({
  //     valueD: dataDValue.valueD,
  //     valueProcess: processOptions.find(
  //       (p) => p.value == dataDValue.valueProcess
  //     ).name,
  //     valueDUnit: timeUnitOptions.find((t) => t.value == dataDValue.valueDUnit)
  //       .name,
  //   });
  //   setOpenDValue(false);
  //   setValidate(false);
  // };

  console.log(dataDValue.valueD)

  const handleDValueSubmit = (e) => {
    e.preventDefault();

    if (!dataDValue.valueD.length) {
      setValidate(true);
      return;
    }

    setVinculationDValue({
      valueD: dataDValue.valueD,
      valueProcess: processOptions.find(
        (p) => p.value == dataDValue.valueProcess
      ).name,
      valueDUnit: timeUnitOptions.find((t) => t.value == dataDValue.valueDUnit)
        .name,
    });
    setSavedValues(dataDValue);
    setIsConfirmed(true);
    setOpenDValue(false);
    setValidate(false);
  };

  useEffect(() => {
    getProcess();
  }, []);

  return (
    <div>
      {openDValue && (
        <ModalQ
          open={openDValue}
          handleClose={onCloseDValue}
          title={t("BlankEntry27")}
        >
          {
            <>
              <div className={styles.formItemContainer}>
                <div>
                  <SelectQ
                    containerWidth={"100%"}
                    options={processOptions}
                    name={"valueProcess"}
                    label={t("BlankEntry26") || "Value Process"}
                    selectValue={dataDValue.valueProcess}
                    handleSelect={handleSelectChange}
                  />
                </div>
                <div className={styles.formItem}>
                  <div>
                    <InputQ
                      name="valueD"
                      value={dataDValue.valueD}
                      handleChange={handleValueD}
                      label={t("BlankEntry25")}
                      containerWidth={"100%"}
                      required={true}
                      validate={validate}
                    />
                  </div>
                  <div>
                    <SelectQ
                      containerWidth={"100%"}
                      options={timeUnitOptions}
                      name={"valueDUnit"}
                      label={t("TimeSpan")}
                      selectValue={dataDValue.valueDUnit}
                      handleSelect={handleSelectChange}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <ButtonQ
                  caption={t("ConfirmButton")}
                  onClick={handleDValueSubmit}
                />
              </div>
            </>
          }
        </ModalQ>
      )}
      <div className={styles.linkTableContainer}>
        <div>
          <div className={styles.indicatorInfo}>
            {selectedIndicator && (
              <div>
                <div>
                  <div className={styles.productContainer}>
                    <p className={styles.indicatorDetails}>
                      {t("ProductSpan")}: {selectedIndicator.indicator.name} -{" "}
                      {t("LotSpan")}: {selectedIndicator.lot} -
                      {selectedIndicator.serial
                        ? ` ${t("SerialSpan")}: ${selectedIndicator.serial} - `
                        : " "}
                      {selectedIndicator.indicatorModelArea && <>{t("ZoneSpan")}:{" "}
                      {selectedIndicator.indicatorModelArea.description}</>}
                    </p>
                    <ButtonQ
                      caption={t("BlankEntry24")}
                      onClick={() => {
                        setOpenDValue(true);
                      }}
                    />
                  </div>
                  <p className={styles.indicatorDetails}>
                    {t("BlankEntry26")}: {vinculationDValue.valueProcess}
                    {dataDValue.valueProcess != 0 &&
                      vinculationDValue.valueD &&
                      ` - ${t("BlankEntry25")}: ${vinculationDValue.valueD} - 
                      ${t("TimeSpan")}: ${vinculationDValue.valueDUnit}`}
                  </p>
                  <p
                    className={`${styles.indicatorDetails} ${styles.indicatorDetailsBold}`}
                  >
                    {t("IncubationInProgressCompleted")} -{" "}
                    {t("CycleNumberSpan")}: {charge?.cycleNumber}
                  </p>
                  <p
                    className={`${styles.indicatorDetails} ${styles.indicatorDetailsBold}`}
                  >
                    {t("PositionSpan")}: {position}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className={styles.filtersRow}>
              <div className={styles.filterItem}>
                <SelectQ
                  selectValue={statusFilter}
                  handleSelect={handleStatusFilterChange}
                  containerWidth={"100%"}
                  options={statusOptions}
                  placeholder={t("SelectStatusOption") || "Select Status"}
                  hasPlaceholder={true}
                />
              </div>
              <div className={styles.filterItem}>
                <SelectQ
                  selectValue={resultFilter}
                  handleSelect={handleResultFilterChange}
                  containerWidth={"100%"}
                  options={resultsOptions}
                  placeholder={t("SelectResultOption") || "Select Result"}
                  hasPlaceholder={true}
                />
              </div>
              <div className={styles.filterItem}>
                <FilterDate
                  handleChange={handleDateChange}
                  dateTo={dateTo}
                  dateFrom={dateFrom}
                />
              </div>
            </div>
            <TableContainerQ>
              <TableHeadQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("statusDescription")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("readNumber")}
                >
                  {t("ReadingSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ onClick={() => handleSortByColumn("result")}>
                  {t("ResultSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("startedTime")}
                >
                  {t("StartSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("resultDate")}
                >
                  {t("FinishSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("averageTemperatureFormat")}
                >
                  {t("TemperatureSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("incubationTime")}
                >
                  {t("IncubationTimeSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("programName")}
                >
                  {t("ProgramSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {currentItems.map((rbi, index) => (
                <TableRowItemIS key={index} rbi={rbi} onLink={onLink} />
              ))}
              <TablePaginationQ
                handleChangePage={onChangePage}
                handleChangeItemsPerPage={onItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={onCurrentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
              />
            </TableContainerQ>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorSelected;
