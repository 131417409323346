import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../../components/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import ValidateURL from "../../utils/ValidateURL";
import CreateWasherLoading from "../../pages/Washing/CreateWasherLoading/CreateWasherLoading";
import useLocale from "../../utils/Hooks/useLocale";

export default () => {
  const menu = useMenu();

  const currentPage = {
    menuGroup: menu?.Company,
    key: "CreateWasherLoading",
  };

  const screenName = 338;
  const locale = useLocale(screenName);

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{ locale: locale }}>
      <NavBar
        screenName={screenName}
        moduleName={170}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <CreateWasherLoading />
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};
