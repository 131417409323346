import React, { useContext, useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import PackageDetail from "../../components/PackageDetail/PackageDetail.jsx";
import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import styles from "./ConsultPackage.module.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import CircularProgressIndicators from "../../components/CircularProgressIndicators/CircularIndicator.jsx";
import t from "../../utils/translation.js";
import { getFilterValue, setFilterValue } from "../../utils/sessionFilters.js";
import { Tooltip } from "@material-ui/core";
import hasPositive from "../../utils/hasPositive.js";

export default function ConsultPackage({ location }) {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  const [packageId, setPackageId] = useState(location?.state?.packageId ?? null);
  const [loads, setLoads] = useState([]);
  const [loadDetail, setLoadDetail] = useState(null);
  const [loadStatus, setloadStatus] = useState(null);
  const [indicators, setIndicators] = useState(null);
  const [charges, setCharges] = useState(null);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [methods, setMethods] = useState([]);

  // Filters with default values from sessionStorage
  const [filters, setFilters] = useState({
    packageNumber: getFilterValue("packageNumber", "", "consultPackage"),
    status: getFilterValue("status", "", "consultPackage"),
    name: getFilterValue("name", "", "consultPackage"),
    method: getFilterValue("method", "", "consultPackage"),
    dateFrom: getFilterValue("dateFrom", "", "consultPackage"),
    dateTo: getFilterValue("dateTo", "", "consultPackage"),
  });

  const locale = useContext(LocalizationContext);

  // Fetch packages
  const fetchPackages = async (
    screenName,
    filters,
    setLoadingPackages,
    setPackages,
    setTotalPages,
    currentPage,
    itemsPerPage
  ) => {
    setLoadingPackages(true);
    try {
      const {
        packageNumber = "",
        status = "",
        name = "",
        method = "",
        dateFrom = "",
        dateTo = "",
      } = filters;

      const response = await request().get(
        `/api/Package/list?includeCount=true&pageSize=${
          itemsPerPage
        }&number=${packageNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setPackages(response.data.packageList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  // Handlers for filter changes
  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    setFilterValue(key, value, "consultPackage");
  };

  const handleSearch = (e) => handleFilterChange("name", e.target.value);
  const handlePackageNumber = (e) =>
    handleFilterChange("packageNumber", e.target.value);
  const handleStatus = (e) => handleFilterChange("status", e.target.value);
  const handleMethod = (e) => handleFilterChange("method", e.target.value);

  const handleDateChange = (type, value) => {
    if (type === "clear") {
      // Clear both dateFrom and dateTo
      const updatedDates = { dateFrom: "", dateTo: "" };
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, ...updatedDates };
        // Save both dateFrom and dateTo in sessionStorage
        setFilterValue("dateFrom", "", "consultPackage");
        setFilterValue("dateTo", "", "consultPackage");
        return newFilters;
      });
    } else {
      // Update only one date
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [type]: value };
        setFilterValue(type, value, "consultPackage");
        return newFilters;
      });
    }
  };

  // Fetch methods
  const getMethods = () => {
    request()
      .get("/api/method/1")
      .then((res) =>
        setMethods(
          res.data.map((m) => ({ value: m.id, name: m.methodTranslation }))
        )
      );
  };

  useEffect(() => {
    getMethods();
  }, []);

  const getStatusName = (packageValue) => {
    switch (packageValue) {
      case 1:
        return t("Assembling", locale);
      case 2:
        return t("ReadyForLoad", locale);
      case 3:
        return t("InLoad", locale);
      case 4:
        return t("Sterilized", locale);
      default:
        return "Unknown Status";
    }
  };

  const handlePackageDetail = (packageGeneralId) => {
    const item = packages.find((p) => p.packageGeneralId === packageGeneralId);
    setPackageDetail(item);
    setPackageId(item.packageGeneralId)
    setIsDetailVisible(true);
  };

  // Table hooks
  const {
    currentPage,
    currentItems,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    packages,
    ["packageNumber", "date", "description", "methodId", "packageStatus"],
    totalPages
  );

  const arrayStatus = [
    { value: 1, name: t("Assembling", locale) },
    { value: 2, name: t("ReadyForLoad", locale) },
    { value: 3, name: t("InLoad", locale) },
    { value: 4, name: t("Sterilized", locale) },
  ];

  useEffect(() => {
    fetchPackages(
      "consultPackage",
      filters,
      setLoadingPackages,
      setPackages,
      setTotalPages,
      currentPage,
      itemsPerPage
    );
  }, [filters, currentPage, itemsPerPage]);

  useEffect(() => {
    if(packageId) {
      setIsDetailVisible(true)
    };
  }, []);

  return (
    <PageContainer
      categoryId={1}
      category={t("SterilizationSpan")}
      backUrl={"/appsterilization"}
      title={t("ConsultPackage", locale)}
      subtitle={isDetailVisible ? t("DetailSpan") : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                handleChange={handlePackageNumber}
                placeholder={t("NumberSpan")}
                value={filters.packageNumber}
                containerWidth="100%"
                hasPlaceholder={true}
              />
              <FilterDate
                handleChange={handleDateChange}
                dateTo={filters.dateTo}
                dateFrom={filters.dateFrom}
              />
              <InputQ
                items={packages}
                handleChange={handleSearch}
                placeholder={t("NameSpan")}
                value={filters.name}
                containerWidth="100%"
                hasPlaceholder={true}
              />
              <SelectQ
                placeholder={t("SelectMethodOption")}
                options={methods}
                handleSelect={handleMethod}
                selectValue={filters.method}
                containerWidth={"100%"}
                hasPlaceholder={true}
              />
              <SelectQ
                placeholder={t("SelectStatusOption")}
                options={arrayStatus}
                handleSelect={handleStatus}
                selectValue={filters.status}
                containerWidth={"100%"}
                hasPlaceholder={true}
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions={true} progress={true}>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageNumber")}
                >
                  {t("NumberSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("date")}
                >
                  {t("DateSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("description")}
                >
                  {t("NameSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("MethodSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageStatus")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
                {/* <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeCycleNumber")}
                >
                  {t("CycleNumberSpan")}
                </TableHeaderCellQ> */}
              </TableHeadQ>
              {loadingPackages ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((p) => (
                    <TableRowQ
                      key={p.packageGeneralId}
                      progress={
                        <CircularProgressIndicators
                          totalIndicators={p.totalIndicators}
                          vinculatedIndicators={p.vinculatedIndicators}
                          hasPositive={p.hasPositive}
                        />
                      }
                      actions={true}
                      detail={p.packageStatus}
                      onClick={() => handlePackageDetail(p.packageGeneralId)}
                      onDetail={() => handlePackageDetail(p.packageGeneralId)}
                    >
                      <TableRowCellQ>
                        <span style={{ fontWeight: 500, fontSize: "0.875rem" }}>
                          {p.packageNumber}
                        </span>
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={df(p.date, "date")}
                          maxLength={15}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.description} maxLength={15} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.methodName} maxLength={15} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                      <TruncatedText
                        text={getStatusName(p.packageStatus)}
                        maxLength={15}
                      />
                      {p.packageStatus === 4 && (
                        <span className={styles.cycleWithCircle}>
                          <span className={styles.thickParenthesis}>(</span>
                            <span className={styles.cycleLabel}>{t("CycleNumberSpan")}:</span>
                          <span className={styles.cycleNumber}>{p.chargeCycleNumber}</span>
                          <Tooltip
                            title={
                              <span>
                                {p.chargeHasPositive
                                  ? t("BlankEntry18")
                                  : t("BlankEntry19")}
                              </span>
                            }
                            arrow
                          >
                            <div
                              className={`${styles.circle} ${
                                p.chargeHasPositive
                                  ? styles.red
                                  : p.chargeIndicatorsUnlinked
                                  ? styles.gray
                                  : styles.green
                              }`}
                            ></div>
                          </Tooltip>
                          <span className={styles.thickParenthesis}>)</span>
                        </span>
                      )}
                    </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}
              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
                getPackages={fetchPackages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <PackageDetail
              packageId={packageId}
              locale={locale}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
