import React, { useState } from 'react'
import ShowPassword from '../../components/Form/UserResetPassword/ShowPassword.js';
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";

   const ViewChangePasswordButton = (props) => {
    const {locale,passwordActual,setPasswordActual,passwordNuevo,setPasswordNuevo,passwordConfirma,setPasswordConfirma,setError,error,loading,t,style,validationMessageA,validationMessageC,validationMessageN} = props;


const [viewPasswordActual, setViewPasswordActual] = useState(false);
const [viewPasswordNuevo, setViewPasswordNuevo] = useState(false);
const [viewPasswordConfirma, setViewPasswordConfirma] = useState(false);

const togglePasswordActualVisibility = () => {
    setViewPasswordActual(!viewPasswordActual);
};

const togglePasswordNuevoVisibility = () => {
    setViewPasswordNuevo(!viewPasswordNuevo);
};

const togglePasswordConfirmaVisibility = () => {
    setViewPasswordConfirma(!viewPasswordConfirma);
};

//Función para manejar cambios en la contraseña
const handleChangeCurrentPassword = (value) => {
    setPasswordActual(value.target.value);
    error.type === "passwordActual" &&
      setError({ message: "", type: null });
  };

  const handleChangePasswordNuevo = (value) => {
    setPasswordNuevo(value.target.value);
    error.type === "passwordNuevo" &&
      setError({ message: "", type: null });
  };

  const handleChangePasswordConfirma = (value) => {
    setPasswordConfirma(value.target.value);
    error.type === "passwordConfirma" &&
      setError({ message: "", type: null });
  };

  return (
    <>
        <InputQ 
            placeholder={t("CurrentPasswordLabel",locale) || "Current Password"} 
            label={t("CurrentPasswordLabel",locale) || "Current Password"} 
            claro={true}
            value={passwordActual}
            type={viewPasswordActual ? 'text' : 'password'}
            disabled={loading}
            containerWidth={"100%"}
            containerMargin={"10px"}
            handleChange={handleChangeCurrentPassword}
            required={true}
            additionalMessage={validationMessageA}
            additionalValidation={true}
          />
      <ShowPassword style={style.iconStyle1} parentCallback={togglePasswordActualVisibility} />
      <InputQ 
            placeholder={t("NewPasswordLabel",locale) || "New Password"} 
            label={t("NewPasswordLabel",locale) || "New Password"} 
            claro={true}
            value={passwordNuevo}
            type={viewPasswordNuevo ? 'text' : 'password'}
            disabled={loading}
            containerWidth={"100%"}
            containerMargin={"10px"}
            handleChange={handleChangePasswordNuevo}
            additionalMessage={validationMessageN}
            required={true}
            additionalValidation={true}
      />
        <ShowPassword style={style.iconStyle2} parentCallback={togglePasswordNuevoVisibility} />
        <InputQ 
            placeholder={t("ConfirmPasswordLabel", locale) || "Confirm password"} 
            claro={true}
            value={passwordConfirma}
            label={t("ConfirmPasswordLabel", locale) || "Confirm password"}
            error={error.type === "passwordConfirma" ? error.message : ""}
            type={viewPasswordConfirma ? 'text' : 'password'}
            disabled={loading}
            containerWidth={"100%"}
            containerMargin={"10px"}
            handleChange={handleChangePasswordConfirma}
            additionalMessage={validationMessageC}
            errorMessage={error.type === "passwordConfirma" ? error.message : ""}
            required={true}
            additionalValidation={true}
       />
        <ShowPassword style={style.iconStyle3} parentCallback={togglePasswordConfirmaVisibility}/>
    </>
  )
}
export default ViewChangePasswordButton;