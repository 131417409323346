import React, { useEffect, useState } from "react";

import styles from "./BrandsForm.module.css";

import { toast } from "react-toastify";

import request from "../../utils/request";

import ModalQ from "../TableBionovaQ/ModalQ/ModalQ";
import ErrorMessage from "../TableBionovaQ/ErrorMessage/ErrorMessage";
import InputQ from "../FormComponents/InputQ/InputQ";
import InputSearch from "../FormComponents/InputSearch/InputSearch";

const BrandsForm = ({
  formData,
  handleFormData,
  handleClose,
  open,
  locale,
  t,
  getBrands,
  brands,
  handleCreate = () => {},
}) => {
  const [error, setError] = useState("");
  const [validate, setValidate] = useState(false);
  const [globalBrands, setGlobalBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGlobalBrands = async () => {
    try {
      setLoading(true);

      const response = await request().get("/api/MachineMake");
      const globalBrands = response.data.filter(
        (gb) =>
          !brands.some(
            (b) => b.brand.toUpperCase() === gb.brand.toUpperCase()
          ) || formData.machineMakeId === gb.id
      );

      const formattedBrands = globalBrands.map((gb) => ({
        ...gb,
        value: gb.id,
      }));

      setGlobalBrands(formattedBrands);
    } catch (error) {
      console.error("Error fetching global brands:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBrandName = (data) => {
    let globalBrand = globalBrands.find(
      (gb) =>
        gb.brand.toUpperCase().replace(/\s+/g, "") ===
        data.toUpperCase().replace(/\s+/g, "")
    );

    if (globalBrand) {
      handleFormData((prevData) => ({
        ...prevData,
        brand: data,
        machineMakeId: globalBrand.id,
      }));
    } else {
      handleFormData((prevData) => ({
        ...prevData,
        brand: data,
        machineMakeId: null,
      }));
    }
  };

  const handleClick = (option) => {
    handleFormData((prevData) => ({
      ...prevData,
      brand: option.brand,
      hasGlobal: true,
      machineMakeId: option.id,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.brand || !formData.description || !formData.observations) {
      setValidate(true);
      return;
    } else {
      setValidate(false);
    }
    if (
      brands.find((b) => b.brand === formData.brand && formData.id !== b.id) &&
      globalBrands.find((gb) => gb.id !== formData.machineMakeId)
    ) {
      toast.error("Marca ya creada");
      return;
    }

    try {
      let res;

      if (formData.id) {
        res = await request().put("/api/CompanyMachineMake", formData);
      } else {
        res = await request().post("/api/CompanyMachineMake", formData);
      }

      handleClose();
      setError("");
      setValidate(false);
      getBrands();
      if (!formData.id) {
        handleCreate(res.data);
      }
    } catch (err) {
      setError(err.response?.data?.message || "Error al guardar la marca.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onClose = () => {
    handleClose();
    setError("");
    setValidate(false);
  };

  useEffect(() => {
    getGlobalBrands();
  }, []);

  return (
    <ModalQ
      open={open}
      title={
        !formData.id
          ? t("New Brand", locale) || "New Brand"
          : t("Edit Brand", locale) || "Edit Brand"
      }
      handleClose={onClose}
      loading={loading}
    >
      {error && <ErrorMessage message={error} />}
      <div className={styles.container}>
        <div className={styles.inputGroup}>
          <div style={{ width: "48%" }}>
            <InputSearch
              label={t("SearchBrands", locale) || "Search Brands"}
              name="brand"
              handleInputChange={handleBrandName}
              value={formData.brand}
              required={true}
              errorMessage={
                t("RequiredField", locale) || "This field is required"
              }
              validate={validate}
              options={globalBrands}
              handleClick={handleClick}
              disabled={formData.id && formData.machineMakeId}
            />
          </div>
          <InputQ
            label={t("Description", locale) || "Description"}
            name="description"
            handleChange={handleInputChange}
            value={formData.description}
            required={true}
            validate={validate}
          />
        </div>
        <div className={styles.inputGroup}>
          <InputQ
            label={t("Observations", locale) || "Observations"}
            name="observations"
            handleChange={handleInputChange}
            value={formData.observations}
            required={true}
            validate={validate}
          />
        </div>
        <button
          className={`${styles.button} ${styles.confirmButton}`}
          onClick={(e) => handleSubmit(e)}
        >
          Confirm
        </button>
      </div>
    </ModalQ>
  );
};

export default BrandsForm;
