import React, { useEffect, useState } from "react";
import styles from "./SelectQ.module.css";
import t from "../../../utils/translation";

const SelectQ = ({
  label,
  placeholder,
  options,
  handleSelect,
  selectValue,
  containerWidth = "100%",
  containerMargin = "0px",
  required,
  errorMessage = t("RequiredFieldError"),
  validate,
  category = "sterilization",
  name,
  disabled,
  hasPlaceholder = false
}) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (validate && required && !selectValue) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [validate, selectValue, required]);

  return (
    <div
      className={styles.selectContainer}
      style={{ width: containerWidth, marginBottom: containerMargin }}
    >
      <div className={styles.selectWrapper}>
        {label && (
          <label className={styles.label}>
            {label} {required && "*"}
          </label>
        )}
        <div className={styles.selectFieldContainer}>
          <select
            value={selectValue}
            onChange={(e) => handleSelect(e)}
            className={styles.selectField}
            name={name}
            disabled={disabled}
          >
            {placeholder && (
              <option value="" className={styles.option}>
                {hasPlaceholder ? placeholder : ""}
              </option>
            )}
            {options?.map((item, index) => (
              <option
                className={styles.option}
                value={item.value}
                key={index}
              >
                {item.name}
              </option>
            ))}
          </select>
          {showMessage && (
            <span className={styles.errorMessage}>{errorMessage}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectQ;
