import React, { useState, useRef } from "react";
import styles from "./ImageDrop.module.css";
import { toast } from "react-toastify";
import t from "../../utils/translation";
import { FaImage } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";

function ImageDrop({ onFileChange, label, required = false }) {
  const [base64Image, setBase64Image] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [fileFormat, setFileFormat] = useState(null);
  const fileInputRef = useRef(null);


  const allowedFormats = ["png", "jpg", "jpeg"];

  const parseBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = () => {
        const base64Data = fileReader.result.split(",")[1];
        resolve(base64Data);
      };
      fileReader.onerror = (error) => reject(error);
    });
  };

  const handleImageConversion = async (file) => {
    return parseBlobToBase64(file);
  };

  const getFileFormat = (type) => {
    if (type.startsWith("image/")) {
      return type.split("/")[1];
    }
    return null;
  };

  const getFileNameWithoutExtension = (filename) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  };

  const isValidFormat = (format) => {
    return allowedFormats.includes(format.toLowerCase());
  };

  const handleFileConversion = async (file) => {
    if (!isValidFormat(getFileFormat(file.type))) {
      throw new Error(
        "Unsupported file type. Only PNG, JPG, and JPEG are allowed."
      );
    }
    return handleImageConversion(file);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (!file) {
      toast.error(t("NoFileSelected") || "No file selected.");
      return;
    }

    try {
      const base64 = await handleFileConversion(file);
      const fileFormat = getFileFormat(file.type);
      const fileNameWithoutExtension = getFileNameWithoutExtension(file.name);

      setBase64Image(base64);
      setImageName(fileNameWithoutExtension);
      setFileFormat(fileFormat);
      onFileChange({
        base64Image: base64,
        imageName: fileNameWithoutExtension,
        fileFormat: fileFormat,
      });
      toast.success(t("FileAdded") || "File added.");
    } catch (error) {
      toast.error(
        t("UnsupportedFileType") ||
          "Unsupported file type. Only PNG, JPG, and JPEG are allowed."
      );
      console.error("Error converting file:", error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      toast.error(t("NoFileSelected") || "No file selected.");
      return;
    }

    try {
      const base64 = await handleFileConversion(file);
      const fileFormat = getFileFormat(file.type);
      const fileNameWithoutExtension = getFileNameWithoutExtension(file.name);

      setBase64Image(base64);
      setImageName(fileNameWithoutExtension);
      setFileFormat(fileFormat);
      onFileChange({
        base64Image: base64,
        imageName: fileNameWithoutExtension,
        fileFormat: fileFormat,
      });
    } catch (error) {
      toast.error(
        t("UnsupportedImageFileSpan") ||
          "Unsupported file type. Only PNG, JPG, and JPEG are allowed."
      );
      console.error("Error converting file:", error);
    }
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setBase64Image(null);
    setImageName(null);
    setFileFormat(null);
    fileInputRef.current.value = null;
    onFileChange({
      base64Image: null,
      imageName: null,
      fileFormat: null,
    });
  };

  return (
    <div
      className={styles.dropArea}
      onClick={handleClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {base64Image ? (
        <div className={styles.previewContainer}>
          <Tooltip
            title={
              <img
                src={`data:image/${fileFormat};base64,${base64Image}`}
                alt="Preview"
                className={styles.tooltipImage}
              />
            }
            placement="top"
          >
            <div className={styles.imageIcon}>
              <FaImage size={50} />
              <p>{imageName}</p>
            </div>
          </Tooltip>
          <button className={styles.removeButton} onClick={handleRemoveFile}>
            &times;
          </button>
        </div>
      ) : (
        <p>{`${label}${required ? "*" : ""}`}</p>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/png, image/jpg, image/jpeg"
      />
    </div>
  );
}

export default ImageDrop;
